import { Team } from 'api/teams/types';
import { Button } from 'components/Buttons';
import { Select } from 'components/FormElements/Select';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiForm } from '../types';
import { StyledApiKeyForm } from './StyledApiKey';

interface Props {
    onSubmit: (data: ApiForm) => void;
    team?: Team;
    /** Controls if the dropdown in the user select should be mounted to the body to be visible outside the modal*/
    inModal?: boolean;
}

const ApiKeyForm = ({ onSubmit, team, inModal }: Props) => {
    const { t } = useTranslation('settings');
    const {
        handleSubmit,
        errors,
        control,
        formState: { isSubmitting },
    } = useForm<ApiForm>({
        defaultValues: {},
    });

    const _onSubmit = (data: ApiForm) => onSubmit(data);

    return (
        <>
            <Heading size={HeadingSize.MEDIUM}>
                {t('api.key.generateTitle')}
            </Heading>

            <StyledApiKeyForm onSubmit={handleSubmit(_onSubmit)}>
                <Controller
                    control={control}
                    rules={{
                        required: t('errors.required').toString(),
                    }}
                    name="userId"
                    defaultValue={null}
                    render={({ onChange }) => (
                        <Select
                            label={t('api.key.generateUserLabel')}
                            placeholder={t('api.key.generateUserPlaceholder')}
                            menuPortalTarget={
                                inModal ? document.body : undefined
                            }
                            onChange={(option) => onChange(option?.value)}
                            error={!!errors.userId}
                            errorMessage={errors.userId?.message}
                            options={team?.members.map((member) => ({
                                value: member.id,
                                label: `${member.firstName} ${member.lastName} (${member.email})`,
                            }))}
                        />
                    )}
                />
                <Button loading={isSubmitting} type="submit">
                    {t('api.key.generateButton')}
                </Button>
            </StyledApiKeyForm>
        </>
    );
};

export default ApiKeyForm;
