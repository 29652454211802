import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

interface SpacerContainerProps {
    padding?: string;
    margin?: string;
    display?: string;
    fullWidth?: boolean;
    maxWidth?: string;
    className?: string;
}

const SpacerContainer = styled.div<SpacerContainerProps>`
    display: ${({ display }) => display};
    margin: ${({ margin }) => margin};
    max-width: ${({ maxWidth }) => maxWidth || ''};
    padding: ${({ padding }) => padding};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '')};
`;

export enum SpaceSize {
    NONE,
    X_SMALL,
    SMALL,
    MEDIUM,
    LARGE,
    X_LARGE,
    XX_LARGE,
}

interface Props {
    padding?: SpaceSize;
    paddingTop?: SpaceSize;
    paddingBottom?: SpaceSize;
    paddingLeft?: SpaceSize;
    paddingRight?: SpaceSize;
    margin?: SpaceSize;
    marginTop?: SpaceSize;
    marginBottom?: SpaceSize;
    marginLeft?: SpaceSize;
    marginRight?: SpaceSize;
    fullWidth?: boolean;
    display?: 'block' | 'inline-block' | 'inline-flex' | 'inline';
    maxWidth?: string;
    className?: string;
    children?: React.ReactNode;
}

const DEPRECATEDSpacer = ({
    padding = SpaceSize.NONE,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    margin = SpaceSize.NONE,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    fullWidth,
    display = 'block',
    maxWidth,
    children,
    className,
}: Props) => {
    const theme = useContext(ThemeContext);
    const spaceSizeToPx = (size?: SpaceSize) => {
        switch (size) {
            case SpaceSize.NONE:
                return theme.space.s0;
            case SpaceSize.X_SMALL:
                return theme.space.s1;
            case SpaceSize.SMALL:
                return theme.space.s2;
            case SpaceSize.MEDIUM:
                return theme.space.s3;
            case SpaceSize.LARGE:
                return theme.space.s6;
            case SpaceSize.X_LARGE:
                return theme.space.s8;
            case SpaceSize.XX_LARGE:
                return theme.space.s16;
            default:
                return size;
        }
    };

    const paddingToUse = `${
        spaceSizeToPx(paddingTop) || spaceSizeToPx(padding)
    } ${spaceSizeToPx(paddingRight) || spaceSizeToPx(padding)} ${
        spaceSizeToPx(paddingBottom) || spaceSizeToPx(padding)
    } ${spaceSizeToPx(paddingLeft) || spaceSizeToPx(padding)}`;
    const marginToUse = `${spaceSizeToPx(marginTop) || spaceSizeToPx(margin)} ${
        spaceSizeToPx(marginRight) || spaceSizeToPx(margin)
    } ${spaceSizeToPx(marginBottom) || spaceSizeToPx(margin)} ${
        spaceSizeToPx(marginLeft) || spaceSizeToPx(margin)
    }`;

    return (
        <SpacerContainer
            padding={paddingToUse}
            margin={marginToUse}
            display={display}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            className={className}
        >
            {children}
        </SpacerContainer>
    );
};

export default DEPRECATEDSpacer;
