import { Button } from 'components/Buttons';
import ContentCard from 'components/Layout/ContentCard';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import MainHeader from 'containers/MainHeader';
import UserDataHelper from 'helpers/UserDataHelper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

const ErrorWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: ${({ theme }) => theme.space.s6};
`;

const TextWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.space.s6};
`;

const SuggestionRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => `${theme.space.s3} ${theme.space.s6}`};
`;
const ErrorContent = styled.div`
    max-width: 450px;
    padding: 0 ${({ theme }) => theme.space.s16};
    width: 100%;
`;

const GeneralError = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const reload = () => {
        history.go(0);
    };

    const goBack = () => {
        history.goBack();
    };

    const logOut = () => {
        UserDataHelper.clearUserData();
        history.push('/login?success=true');
    };

    return (
        <>
            <MainHeader />
            <ErrorWrapper>
                <ErrorContent>
                    <TextWrapper>
                        <Heading size={HeadingSize.XLARGE}>
                            {t('errors.general.title')}
                        </Heading>
                        <Text>{t('errors.general.subTitle')}</Text>
                    </TextWrapper>
                    <ContentCard withShadow maxWidth="400px">
                        <SuggestionRow>
                            <Text size={TextSize.MEDIUM}>
                                {t('errors.general.step1')}
                            </Text>
                            <Button variant="primary" onClick={reload}>
                                {t('button.reload')}
                            </Button>
                        </SuggestionRow>
                        <SuggestionRow>
                            <Text size={TextSize.MEDIUM}>
                                {t('errors.general.step2')}
                            </Text>
                            <Button variant="secondary" onClick={goBack}>
                                {t('button.back')}
                            </Button>
                        </SuggestionRow>
                        <SuggestionRow>
                            <Text size={TextSize.MEDIUM}>
                                {t('errors.general.step3')}
                            </Text>
                            <Button variant="secondary" onClick={logOut}>
                                {t('menu.logout')}
                            </Button>
                        </SuggestionRow>
                    </ContentCard>
                </ErrorContent>
                <img
                    alt="error"
                    src="/misc/illustrations/error-image.svg"
                    height="350"
                    width="250"
                />
            </ErrorWrapper>
        </>
    );
};

export default GeneralError;
