import { SearchResult } from 'api/consignments/types';
import { Duration } from 'luxon';
import { useEffect, useState } from 'react';

function animationInterval(ms: number, signal: any, callback: any) {
    // Prefer currentTime, as it'll better sync animtions queued in the
    // same frame, but if it isn't supported, performance.now() is fine.

    const start = document.timeline
        ? document.timeline.currentTime
        : performance.now();

    function frame(time: any) {
        if (signal.aborted) return;
        callback(time);
        scheduleFrame(time);
    }

    function scheduleFrame(time: any) {
        if (start) {
            const elapsed = time - start;
            const roundedElapsed = Math.round(elapsed / ms) * ms;
            const targetNext = start + roundedElapsed + ms;
            const delay = targetNext - performance.now();
            setTimeout(() => requestAnimationFrame(frame), delay);
        }
    }

    scheduleFrame(start);
}

/**
 * Returns the time left until the booking cutoff of the search result. Accurate within one second.
 *
 * Returns a duration of 0 if the cutoff is in the past.
 */
export const useCutoffDuration = (result: SearchResult) => {
    let initialDuration = result.loadingCutoffAt.diffNow();
    if (initialDuration.valueOf() < 0) {
        initialDuration = Duration.fromMillis(0);
    }
    const [duration, setDuration] = useState(initialDuration);

    useEffect(() => {
        setDuration(result.loadingCutoffAt.diffNow());
    }, [result]);

    const controller = new AbortController();
    useEffect(() => {
        // Create an animation callback every second:
        animationInterval(1000, controller.signal, () => {
            const timeLeft = result.loadingCutoffAt.diffNow();
            if (timeLeft.valueOf() > 0) {
                setDuration(timeLeft);
            } else {
                setDuration(Duration.fromMillis(0));
            }
        });
        return () => controller.abort();
    }, [result]);

    return duration;
};

/**
 * Returns a copy of the input results, filtered on whether they have passed their booking cutoff.
 * Accurate within one second.
 */
export const useExpiredSolutions = (originalResults: SearchResult[]) => {
    const hasExpired = (result: SearchResult) =>
        result.loadingCutoffAt.diffNow().valueOf() <= 0;

    const [results, setResults] = useState<SearchResult[]>(originalResults);
    const [expiredResults, setExpiredResults] = useState<SearchResult[]>([]);

    useEffect(() => {
        setExpiredResults(results.filter(hasExpired));
    }, [results]);

    const controller = new AbortController();
    useEffect(() => {
        animationInterval(1000, controller.signal, () => {
            setExpiredResults(results.filter(hasExpired));
        });
        return () => controller.abort();
    }, [results]);

    return { expiredResults, updateTransportSolutions: setResults };
};
