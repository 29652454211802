import styled from 'styled-components/macro';

const ModalFooter = styled.div`
    grid-area: footer;
    padding: ${({ theme }) =>
        `${theme.space.s4} ${theme.space.s8} ${theme.space.s6}`};
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: ${({ theme }) => theme.space.s5};
    }
`;

export default ModalFooter;
