import { Integration } from 'api/subscriptions/types';
import { IntegrationStatus, useFlags } from 'external/launchDarkly';
import { CSSProperties } from 'react';

import {
    FortnoxIcon,
    MagentoIcon,
    MicrosoftBusinessCentralIcon,
    MonitorIcon,
    Ongoing,
    Prosmart,
    Pyramid,
    ShopifyTextIcon,
    SpecterTextIcon,
    VismaTextIcon,
    WinbasIcon,
    WooCommerceTextIcon,
} from '../assets/partnerLogos';
import OwnIntegrationLogo from '../assets/partnerLogos/ownIntegration/OwnIntegrationLogo';

export class IntegrationPartner {
    static Netic = 'Netic';
    static Wipit = 'Wipit';
    static ITStod = 'IT-stöd';
    static Monitor = 'Monitor';
    static Sendify = 'Sendify';
    static Prosmart = 'Prosmart';
}

export type LogoInfo = {
    component: JSX.Element;
    isTextLogo: boolean;
    metadata?: Record<string, any>;
    renderingOptions?: {
        className?: string;
        style?: CSSProperties;
    };
};

function createLogoInfo(component: JSX.Element, isTextLogo: boolean): LogoInfo {
    return {
        component,
        isTextLogo,
    };
}

export type IntegrationCardInformation = {
    integration: Integration;
    status: IntegrationStatus;
    integrationPartner: IntegrationPartner;
    logo: LogoInfo;
};

export const useSupportedIntegrations = () => {
    const {
        integrationOwnIntegration,
        integrationFortnox,
        integrationWooCommerce,
        integrationShopify,
        integrationVisma,
        integrationSpecter,
        integrationOngoing,
        integrationProsmart,
        integrationPyramid,
        integrationMagento,
        integrationVismaAdmin2000,
        integrationDynamics365,
        integrationWinbas,
        integrationMonitor,
    } = useFlags();
    const systems: IntegrationCardInformation[] = [
        {
            integration: 'Fortnox',
            status: integrationFortnox,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<FortnoxIcon />, true),
        },
        {
            integration: 'WooCommerce',
            status: integrationWooCommerce,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<WooCommerceTextIcon />, true),
        },

        {
            integration: 'Shopify',
            status: integrationShopify,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<ShopifyTextIcon />, true),
        },

        {
            integration: 'Specter',
            status: integrationSpecter,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<SpecterTextIcon />, true),
        },
        {
            integration: 'Ongoing',
            status: integrationOngoing,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<Ongoing />, true),
        },
        {
            integration: 'Visma E-Economy',
            status: integrationVisma,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<VismaTextIcon />, false),
        },
        {
            integration: 'Visma Admin 2000',
            status: integrationVismaAdmin2000,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<VismaTextIcon />, false),
        },
        {
            integration: 'Prosmart',
            status: integrationProsmart,
            integrationPartner: IntegrationPartner.Prosmart,
            logo: createLogoInfo(<Prosmart />, true),
        },
        {
            integration: 'Pyramid',
            status: integrationPyramid,
            integrationPartner: IntegrationPartner.Netic,
            logo: createLogoInfo(<Pyramid />, true),
        },
        {
            integration: 'Adobe Commerce (Magento)',
            status: integrationMagento,
            integrationPartner: IntegrationPartner.Wipit,
            logo: createLogoInfo(<MagentoIcon />, true),
        },
        {
            integration: 'Dynamics 365 Business Central',
            status: integrationDynamics365,
            integrationPartner: IntegrationPartner.ITStod,
            logo: createLogoInfo(<MicrosoftBusinessCentralIcon />, true),
        },
        {
            integration: 'Winbas',
            status: integrationWinbas,
            integrationPartner: IntegrationPartner.ITStod,
            logo: createLogoInfo(<WinbasIcon />, true),
        },
        {
            integration: 'Monitor',
            status: integrationMonitor,
            integrationPartner: IntegrationPartner.Monitor,
            logo: createLogoInfo(<MonitorIcon />, true),
        },
        {
            integration: 'Other',
            status: integrationOwnIntegration,
            integrationPartner: IntegrationPartner.Sendify,
            logo: createLogoInfo(<OwnIntegrationLogo />, true),
        },
    ];
    return systems;
};
