import { InfoCircle } from 'assets/icons/material';
import { Button } from 'components/Buttons';
import Notification from 'components/Notifications/Notification';
import { Variant } from 'components/Notifications/types';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { useEffect, useState } from 'react';

import { InfoWrapper } from './StyledInfoBanner';

interface props {
    name: string;
    title: string;
    subtitle?: string;
    subtitle2?: string;
    buttonLink?: string;
    buttonText?: string;
    children?: React.ReactNode;
    variant?: Variant;
}

const InfoBanner = ({
    name,
    title,
    subtitle,
    subtitle2,
    buttonLink,
    buttonText,
    children,
    variant,
}: props) => {
    const [showBanner, setShowBanner] = useState(false);

    const closeBanner = () => {
        setShowBanner(false);
        // we save it to locale storage to not have the user see the banner each page load
        localStorage.setItem(name, 'false');
    };
    useEffect(() => {
        if (name) {
            // check if the user has dismissed the banner previously
            const storageItem = localStorage.getItem(name);
            if (!storageItem) {
                setShowBanner(true);
            } else if (JSON.parse(storageItem)) {
                setShowBanner(true);
            }
        }
    }, [name]);

    if (!name) {
        return null;
    }

    const showButton = !!buttonText && !!buttonLink;
    return (
        <Notification
            showNotification={showBanner}
            handleClose={closeBanner}
            icon={<InfoCircle />}
            variant={variant ? variant : 'banner'}
        >
            <InfoWrapper showButton={showButton}>
                <div>
                    <Heading size={HeadingSize.SMALL}>{title}</Heading>
                    {subtitle && <Text>{subtitle} </Text>}
                    {subtitle2 && <Text>{subtitle2}</Text>}

                    {children}
                </div>
                {showButton && (
                    <a href={buttonLink} target="_blank" rel="noreferrer">
                        <Button variant="secondary">{buttonText}</Button>
                    </a>
                )}
            </InfoWrapper>
        </Notification>
    );
};
export default InfoBanner;
