import {
    AdminPasswordChange,
    BackendAdminPasswordChange,
} from 'api/admin/types';

export const adminPasswordChangeToBackend = (
    userPassword: AdminPasswordChange
): BackendAdminPasswordChange => ({
    new_password: userPassword.newPassword,
    new_password_confirmation: userPassword.newPasswordConfirmation,
});
