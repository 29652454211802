import * as amplitude from '@amplitude/analytics-browser';

type KeyValueObject = { [key: string]: any };

export interface AmplitudeIdentificationOptions extends KeyValueObject {
    userId?: string;
    teamName?: string;
}

export interface AmplitudeTrackOptions extends KeyValueObject {
    revenue?: number;
}

export interface AmplitudeRevenueOptions extends KeyValueObject {
    price: number; // a float
    quantity: number; // an integer
}

/**
 * Identifies the Amplitude session. Don't call unless the user
 * has consented to tracking.
 */
export const initialize = () => {
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    if (apiKey) {
        amplitude.init(apiKey, { minIdLength: 1 });
    }
};

/**
 * Clears the stored user data. Should be called when the user
 * signs out.
 */
export const clearUserData = () => {
    amplitude.setUserId(undefined);
    amplitude.identify(new amplitude.Identify().clearAll());
};

/**
 * Identifies a user in Amplitude.
 */
export const identify = (userId: string, userProperties: KeyValueObject) => {
    amplitude.setUserId(userId);
    const id = new amplitude.Identify();
    Object.entries(userProperties).forEach(([key, value]) => {
        id.set(key, value);
    });
    amplitude.identify(id);
};

/**
 * Groups a user with a team in Amplitude.
 *
 * Since we have moved from Segment to direct connections, the property is
 * still called '[Segment] Group'.
 */
export const group = (teamName: string) =>
    amplitude.setGroup('[Segment] Group', teamName);

/**
 * Tracks any event in Amplitude, except for revenue generating events.
 */
export const track = (
    name: string,
    eventProperties?: AmplitudeTrackOptions
) => {
    amplitude.track(name, eventProperties || undefined);
};

/**
 * Tracks a revenue generating event in Amplitude.
 * As of now the quantity is hard-coded to 1, to ease the transition away
 * from Segment.
 */
export const trackRevenue = (revenue: number, properties: KeyValueObject) => {
    const revenueEvent = new amplitude.Revenue()
        .setPrice(revenue)
        .setQuantity(1)
        .setEventProperties(properties);
    amplitude.revenue(revenueEvent);
};
