import styled from 'styled-components/macro';

import Spinner, {
    spinnerSize,
    spinnerWidth,
} from '../../components/Spinner/Spinner';

const MainWrapper = styled.div`
    height: 100vh;
    position: relative;
    width: 100%;
`;

const CenteredSpinner = styled(Spinner)`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export default () => (
    <MainWrapper>
        <CenteredSpinner size={spinnerSize.large} width={spinnerWidth.large} />
    </MainWrapper>
);
