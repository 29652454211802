/* eslint-disable no-undef */

/* eslint-disable @typescript-eslint/no-var-requires */
import {
    getFrontendLanguage,
    setFrontendLanguage,
} from 'helpers/LanguageHelper';
import { getLanguageFromDomain } from 'helpers/SystemCountryHelper';
import countries from 'i18n-iso-countries';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using xhr -> see /public/locales
    // (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'sv',
        preload: ['sv', 'de', 'en'],
        load: 'languageOnly',
        debug: process.env.REACT_APP_ENV === 'development',
        ns: ['old', 'assets'],
        fallbackNS: 'old',
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        compatibilityJSON: 'v3',
    });

/**
 * Initialize i18n with the fallback language. This is for methods that rely on i18n
 * translations to be able to load before the normal render.
 */
const userLanguage = getFrontendLanguage();

const appLanguage = userLanguage || getLanguageFromDomain();

setFrontendLanguage(appLanguage);

// Ensure i18n-iso-countries are loaded correctly
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default i18n;
