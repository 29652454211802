import Tooltip from 'components/Tooltip';
import Text from 'components/Typography/Text';
import { useState } from 'react';

import { IconButton } from '../IconButton';
import { CopyIcon } from './StyledCopyButton';

interface CopyButtonProps {
    value: string;
    tooltip: string;
    copiedTooltip: string;
}

export const CopyButton = ({
    value,
    tooltip,
    copiedTooltip,
}: CopyButtonProps) => {
    const [copied, setCopied] = useState(false);
    return (
        <>
            <IconButton
                onClick={() => {
                    setCopied(true);
                    navigator.clipboard.writeText(value);
                }}
                onMouseLeave={() => setCopied(false)}
                data-tooltip-id={`tooltip-${tooltip}`}
                icon={<CopyIcon />}
            />
            <Tooltip
                id={`tooltip-${tooltip}`}
                // update the label when the size changes
                render={() => <Text>{copied ? copiedTooltip : tooltip}</Text>}
            />
        </>
    );
};
