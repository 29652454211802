import { css } from 'styled-components/macro';

export default css`
    .flatpickr-calendar {
        background: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => theme.colors.grey400};
        border-radius: 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.175);
        width: max-content !important;
        --day-height: 32px;
        --day-width: 38px;
    }
    .flatpickr-weekdays .flatpickr-weekdaycontainer,
    .dayContainer {
        display: grid;
        grid-gap: 4px;
        width: auto;
        min-width: unset;
        place-items: center;
        grid-template-columns: repeat(7, var(--day-width));
    }

    .flatpickr-day {
        background: ${({ theme }) => theme.colors.grey500};
        border-radius: 0;
        font-weight: 500;
        height: var(--day-height);
        line-height: unset;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .flatpickr-day.nextMonthDay {
        background: ${({ theme }) => theme.colors.grey500};
    }
    .flatpickr-day:hover {
        background: ${({ theme }) => theme.colors.grey500};
        border-color: ${({ theme }) => theme.colors.grey500};
    }
    .flatpickr-day.flatpickr-disabled,
    .flatpickr-day.flatpickr-disabled:hover {
        background: ${({ theme }) => theme.colors.grey500};
        color: ${({ theme }) => theme.colors.grey300};
    }
    .flatpickr-day.today,
    .flatpickr-day.today:hover {
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.black};
        border-radius: 50%;
        /* Needs to be square to a perfect circle */
        width: var(--day-height);
        color: ${({ theme }) => theme.colors.black};
    }
    .flatpickr-day.today.startRange,
    .flatpickr-day.today.startRange:hover {
        color: ${({ theme }) => theme.colors.white};
    }
    .flatpickr-day.selected,
    .flatpickr-day.selected:hover {
        background: ${({ theme }) => theme.colors.black};
        border: 2px solid ${({ theme }) => theme.colors.black};
        /* Needs to be square to a perfect circle */
        width: var(--day-height);
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
    }
    .flatpickr-day.endRange,
    .flatpickr-day.endRange:hover {
        width: 100%;
        background: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};
    }

    .flatpickr-day.startRange,
    .flatpickr-day.startRange:hover {
        width: 100%;
        background: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};
    }

    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus,
    .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay {
        background: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};
    }

    .flatpickr-day.today.inRange,
    .flatpickr-day.inRange {
        width: 100%;
        border-radius: unset;
        background: ${({ theme }) => theme.colors.grey500};
        border: 1px solid ${({ theme }) => theme.colors.grey500};
        box-shadow: ${({ theme }) =>
            `-5px 0 0 ${theme.colors.grey500}, 5px 0 0 ${theme.colors.grey500}`};
    }
    .flatpickr-calendar.multiMonth
        .flatpickr-days
        .dayContainer:nth-child(n + 1)
        .flatpickr-day.inRange:nth-child(7n + 7) {
        box-shadow: ${({ theme }) =>
            `-5px 0 0 ${theme.colors.grey500}, 5px 0 0 ${theme.colors.grey500}`} !important;
    }

    .flatpickr-calendar.multiMonth
        .flatpickr-days
        .dayContainer:nth-child(n + 2)
        .flatpickr-day.inRange:nth-child(7n + 1) {
        box-shadow: ${({ theme }) =>
            `-1px 0 0 ${theme.colors.grey500}, 5px 0 0 ${theme.colors.grey500}`};
    }
    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
    .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
    .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
        box-shadow: ${({ theme }) =>
            `-10px 0 0 ${theme.colors.black}`} !important;
    }

    .flatpickr-months .flatpickr-prev-month svg,
    .flatpickr-months .flatpickr-next-month svg {
        color: ${({ theme }) => theme.colors.black};
        height: 18px;
        stroke: ${({ theme }) => theme.colors.grey100};
        stroke-linecap: round;
        stroke-width: 1px;
        width: 18px;
    }

    .flatpickr-months .flatpickr-prev-month,
    .flatpickr-months .flatpickr-next-month {
        top: unset;
    }
    .numInputWrapper:hover {
        background: inherit;
    }
    .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
        right: 25px;
    }
    .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
        left: 25px;
    }
    .flatpickr-months {
        padding: ${({ theme }) => `${theme.space.s2} ${theme.space.s3}`};
    }
    .flatpickr-days {
        border: none;
        width: auto;
    }
    .flatpickr-current-month input {
        pointer-events: none;
    }
    .flatpickr-current-month .flatpickr-monthDropdown-months,
    .flatpickr-current-month span.cur-month,
    .flatpickr-current-month input.cur-year {
        color: ${({ theme }) => theme.colors.black};
        font-size: 16px;
        font-weight: 500;
    }
    div.flatpickr-current-month {
        padding-top: ${({ theme }) => theme.space.s2};
    }
    span.flatpickr-weekday {
        color: #717171;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        margin: ${({ theme }) => `${theme.space.s3} 0 ${theme.space.s1}`};
    }
    .flatpickr-innerContainer {
        border: none;
        margin: ${({ theme }) =>
            `${theme.space.s2} ${theme.space.s6} ${theme.space.s6}`};
    }

    span.flatpickr-weekday,
    .flatpickr-weekdays,
    .flatpickr-current-month,
    .flatpickr-current-month span.cur-month:hover,
    .flatpickr-current-month .flatpickr-monthDropdown-months,
    .flatpickr-current-month .flatpickr-monthDropdown-months:hover,
    .flatpickr-months .flatpickr-month {
        background: ${({ theme }) => theme.colors.white};
    }
`;
