import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { cancelIntegration } from 'api/subscriptions';
import { Integration } from 'api/subscriptions/types';
import {
    ConfirmButtonWrapper,
    Confirmation,
    Content,
    Footer,
    Overview,
    OverviewList,
    OverviewListItem,
    OverviewListLogo,
} from 'components/APISubscriptionChange/StyledAPISubscriptionChange';
import { Button } from 'components/Buttons';
import { ModalHeader } from 'components/Modal';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import NextSteps from './NextSteps';

interface Props {
    integration: Integration;
    onSuccessfulSubmit: () => void;
}

/**
 * Lets the user cancel an integration.
 */
const CancelIntegration = ({ integration, onSuccessfulSubmit }: Props) => {
    const queryClient = useQueryClient();
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    const [isLoading, setIsLoading] = useState(false);
    const { subscriptions } = useSubscriptions();

    const end = subscriptions?.apiSubscription?.current?.end;

    if (!team || !end) {
        return null;
    }

    const onSubmit = async () => {
        setIsLoading(true);

        try {
            await cancelIntegration(integration);
            onSuccessfulSubmit();
        } catch (e) {
            Sentry.captureException(e);
            toast.error(t('api.subscriptionChange.errors.integrationChange'), {
                autoClose: false,
            });
        } finally {
            setIsLoading(false);
        }

        queryClient.invalidateQueries(['subscriptions']);
    };

    const { currency } = team;
    const decimals = currency === 'EUR' ? 1 : 0;

    return (
        <>
            <ModalHeader
                title={t('api.subscriptionChange.integration.title.cancel')}
            />
            <Content>
                <Overview>
                    <OverviewList>
                        <OverviewListItem>
                            <OverviewListLogo integration={integration} />
                            <Heading size={HeadingSize.MEDIUM}>
                                {t('api.subscriptionChange.costPerMonth', {
                                    cost: formatCost(
                                        monthlyIntegrationCosts[integration][
                                            currency
                                        ],
                                        i18n.language,
                                        currency,
                                        decimals
                                    ),
                                })}
                            </Heading>
                        </OverviewListItem>
                    </OverviewList>
                </Overview>

                <NextSteps
                    listItems={[
                        t(
                            'api.subscriptionChange.integration.nextSteps.untilCancelled'
                        ),
                        t(
                            'api.subscriptionChange.integration.nextSteps.cancelDate',
                            {
                                date: end.toLocaleString(DateTime.DATE_FULL),
                            }
                        ),
                        t(
                            'api.subscriptionChange.integration.nextSteps.reactivateUntil',
                            {
                                date: end.toLocaleString(DateTime.DATE_FULL),
                            }
                        ),
                    ]}
                />
            </Content>
            <Footer>
                <Confirmation>
                    <ConfirmButtonWrapper>
                        <Button
                            variant="danger"
                            fullWidth
                            loading={isLoading}
                            onClick={onSubmit}
                        >
                            {t(
                                'api.subscriptionChange.integration.button.cancel',
                                { integration }
                            )}
                        </Button>
                    </ConfirmButtonWrapper>
                </Confirmation>
            </Footer>
        </>
    );
};

export default CancelIntegration;
