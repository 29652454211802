import { Button } from 'components/Buttons';
import { Select } from 'components/FormElements/Select';
import Notification from 'components/Notifications/Notification';
import Spinner, { spinnerSize } from 'components/Spinner/Spinner';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import EditActiveSlider from 'containers/Compare/EditActiveSlider';
import { useBookingFlow } from 'hooks/BookingFlow';
import { useCartContext } from 'hooks/Cart';
import { ModalType } from 'hooks/Cart/types';
import { useTeam, useUser } from 'hooks/Queries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Consignment } from '../../api/consignments/types';
import { openChat } from '../../external/hubspot';
import { useSubscriptions } from '../../hooks/Queries/subscriptions';
import UpgradeTierModal from '../UpgradeTierModal';
import CartItem from './CartItem';
import DeleteModal from './DeleteModal';
import DraftModal from './DraftModal';
import {
    CartSlider,
    CartSummary,
    CartUserSelect,
    Consignments,
    Footer,
    Header,
    SpinnerWrapper,
} from './StyledCart';

const Cart = () => {
    const {
        setCartVisibility,
        cartState,
        modalOptions,
        closeModal,
        setCartUserId,
        removeFromCart,
    } = useCartContext();
    const {
        consignments,
        isVisible,
        isSubmitting,
        maximumNumberOfImportsExceeded,
    } = cartState;
    const { push } = useHistory();
    const { t } = useTranslation(['goods', 'cart']);
    const { state } = useLocation<{ consignment?: Consignment }>();
    const { startBookingFlow } = useBookingFlow();
    const showEditSlider = modalOptions?.type === ModalType.EDIT;
    const { user } = useUser();
    const { team } = useTeam();
    const { subscriptions } = useSubscriptions();

    const [showUpgradeTierModal, setShowUpgradeTierModal] = useState(false);
    const onClose = () => setShowUpgradeTierModal(false);

    const calculatePrices = () => {
        setCartVisibility(false);

        if (maximumNumberOfImportsExceeded) {
            if (subscriptions?.apiSubscription.current?.end) {
                setShowUpgradeTierModal(true);
            } else {
                openChat();
            }
        } else {
            startBookingFlow(consignments);
            push({
                pathname: '/newshipment/compare',
                state: {
                    consignment: state?.consignment,
                },
            });
        }
    };
    let buttonText = t('button.calculatePrices');
    if (maximumNumberOfImportsExceeded) {
        if (subscriptions?.apiSubscription.current?.end) {
            buttonText = t('cart:upgradePricePlan');
        } else {
            // The user is on an old, invoice-based subscription, which they can't edit using Stripe.
            // They will need to contact support.
            buttonText = t('cart:openChat');
        }
    }
    return (
        <>
            <CartSlider
                data-testid="cart-slider"
                onClose={() => setCartVisibility(false)}
                show={isVisible}
            >
                <Header>
                    <Heading size={HeadingSize.SMALL}>
                        {t('cart:heading')} ({consignments.length})
                    </Heading>
                </Header>

                {user?.showNavigation && (
                    <CartUserSelect>
                        <Heading size={HeadingSize.SMALL}>
                            {t('cart:userSelect.title')}
                        </Heading>
                        <Text>{t('cart:userSelect.subTitle')}</Text>
                        <Select
                            placeholder={t('cart:userSelect.placeholder')}
                            onChange={(option) => {
                                if (option) setCartUserId(+option.value);
                            }}
                            options={team?.members.map((member) => ({
                                value: member.id,
                                label: `${member.firstName} ${member.lastName} (${member.email})`,
                            }))}
                        />
                        <Button
                            type="button"
                            onClick={() => {
                                // loop through consignments and remove them from the cart
                                consignments.forEach((consignment) => {
                                    removeFromCart(consignment.id);
                                });
                            }}
                            variant="secondary"
                            fullWidth
                            data-cy="cart-button-close"
                        >
                            {t('cart:clearAll')}
                        </Button>
                    </CartUserSelect>
                )}
                <Consignments>
                    {user?.showNavigation && isSubmitting ? (
                        <SpinnerWrapper>
                            <Spinner size={spinnerSize.large} />
                        </SpinnerWrapper>
                    ) : (
                        <>
                            {consignments.map((cartItem) => (
                                <CartItem
                                    key={cartItem.id}
                                    consignment={cartItem}
                                />
                            ))}
                        </>
                    )}
                </Consignments>

                <Footer>
                    <CartSummary>
                        <Text size={TextSize.MEDIUM}>{t('goods:total')}</Text>
                        <Text size={TextSize.MEDIUM}>
                            {t('goods:packageWithCount', {
                                count: consignments.length,
                            })}
                        </Text>
                    </CartSummary>
                    {maximumNumberOfImportsExceeded && (
                        <Notification variant="error">
                            <Text>{t('cart:apiLimitExceeded')}</Text>
                        </Notification>
                    )}
                    <Button
                        type="button"
                        onClick={calculatePrices}
                        variant="primary"
                        fullWidth
                        disabled={consignments.length === 0}
                        name="calculate"
                        data-cy="cart-button-submit"
                    >
                        {buttonText}
                    </Button>
                </Footer>
            </CartSlider>
            {modalOptions?.consignment && (
                <EditActiveSlider
                    consignment={modalOptions?.consignment}
                    show={showEditSlider}
                    onClose={closeModal}
                />
            )}
            <DraftModal />
            <DeleteModal />
            <UpgradeTierModal onClose={onClose} show={showUpgradeTierModal} />
        </>
    );
};

export default Cart;
