import { Package } from 'api/packages/types';
import {
    GroupBase,
    Select,
    StylesConfig,
} from 'components/FormElements/Select';
import { useFavoritePackages } from 'hooks/Queries/packages';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type FavoriteOption = { label: string; value: Package };
interface Props {
    onChange: (favorite: FavoriteOption | null) => void;
    label?: string;
    placeholder?: string;
    className?: string;
    styles?: StylesConfig<FavoriteOption>;
}

const FavoritePackageSelect = ({
    onChange,
    className,
    label,
    placeholder,
    styles,
}: Props) => {
    const { t } = useTranslation(['goods', 'settings']);
    const { packages } = useFavoritePackages(0, 100);
    // Sort packages by name. If the name is undefined it will always be later than any defined.
    packages?.sort((a: Package, b: Package) => {
        if (a.name === undefined) {
            return -1;
        }

        if (b.name === undefined) {
            return 1;
        }
        return a.name.localeCompare(b.name);
    });
    const favoritePackages = useMemo<
        ReadonlyArray<GroupBase<FavoriteOption>>
    >(() => {
        if (!packages) {
            return [];
        }
        return [
            {
                options: packages.map((favorite) => ({
                    value: favorite,
                    label: favorite.name || '',
                })),
            },
        ];
    }, [packages, t]);

    return (
        <Select<FavoriteOption>
            className={className}
            inputId="favorite-package-select"
            label={label}
            placeholder={placeholder || ''}
            noOptionsMessage={() =>
                t('settings:favouritePackages.noFavouritePackagesFound')
            }
            options={favoritePackages}
            onChange={onChange}
            styles={styles}
        />
    );
};

export default FavoritePackageSelect;
