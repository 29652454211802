import IntegrationLogo from 'components/IntegrationLogo';
import { ModalContent } from 'components/Modal';
import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const TierRadioButtonContent = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`;

export const SubTitle = styled.div`
    color: ${({ theme }) => theme.colors.grey100};
    display: contents;
`;

export const ApiInfo = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s6};
`;

export const TierHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Content = styled(ModalContent)`
    margin-bottom: ${({ theme }) => theme.space.s2};
    margin-top: 0;
`;

export const LeftAlignText = styled(Text)`
    text-align: end;
`;

export const Footer = styled.div`
    background: ${({ theme }) => theme.colors.grey500};
    padding: ${({ theme }) => theme.space.s8};
    width: 100%;
`;

export const Confirmation = styled.div`
    background: ${({ theme }) => theme.colors.white};
    width: 100%;
`;

export const ConfirmButtonWrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: ${({ theme }) => theme.space.s3};
    padding: ${({ theme }) => theme.space.s6};
`;

export const Overview = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space.s6};
    margin-top: ${({ theme }) => theme.space.s3};
`;

export const OverviewList = styled.ul`
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    list-style-type: none;
    margin: 0;
    padding: 0 ${({ theme }) => theme.space.s4};
`;

export const OverviewListItem = styled.li`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    display: flex;
    gap: ${({ theme }) => theme.space.s3};
    justify-content: space-between;
    padding: ${({ theme }) => theme.space.s4} 0;
    text-align: right;
    &:last-of-type {
        border-bottom: none;
    }
`;
export const OverviewListLogo = styled(IntegrationLogo)`
    height: 24px;
    width: auto;
`;
