/* eslint-disable react/jsx-props-no-spreading */
import { HTMLAttributes, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RawMicrosoftBuinessCentral } from './business_central.svg';
import RawFinqr from './finqr.png';
import { ReactComponent as RawFortnox } from './fortnox.svg';
import RawFortnoxWithText from './fortnox/logo-text.jpg';
import { ReactComponent as RawMagento } from './magento.svg';
import { ReactComponent as RawMonitor } from './monitor.svg';
import { ReactComponent as RawOngoing } from './ongoing_text.svg';
import { ReactComponent as RawProsmart } from './prosmart_text.svg';
import { ReactComponent as RawPyramid } from './pyramid.svg';
import RawRoundAmicron from './round/amicron.png';
import RawRoundBuroware from './round/buroware.png';
import RawRoundFortnox from './round/fortnox.png';
import RawRoundJeeves from './round/jeeves.png';
import RawRoundJtl from './round/jtl.png';
import RawRoundLogtrade from './round/logtrade.png';
import RawRoundMonitor from './round/monitor.png';
import RawRoundOngoing from './round/ongoing.png';
import RawRoundPyramid from './round/pyramid.png';
import RawRoundSage from './round/sage.png';
import RawRoundShopify from './round/shopify.png';
import RawRoundSpecter from './round/specter.png';
import RawRoundStarweb from './round/starweb.png';
import RawRoundVisma from './round/visma.png';
import RawRoundWoocommerce from './round/woocommerce.png';
import { ReactComponent as RawShopify } from './shopify.svg';
import { ReactComponent as RawShopifyText } from './shopify_text.svg';
import { ReactComponent as RawSpecter } from './specter.svg';
import { ReactComponent as RawSpecterText } from './specter_text.svg';
import { ReactComponent as RawVisma } from './visma.svg';
import { ReactComponent as RawVismaText } from './visma_text.svg';
import { ReactComponent as RawWinbas } from './winbas.svg';
import { ReactComponent as RawWooCommerce } from './woocommerce.svg';
import { ReactComponent as RawWooCommerceText } from './woocommerce_text.svg';

export const Finqr = (props: HTMLAttributes<HTMLImageElement>) => {
    const { t } = useTranslation('assets');
    return <img {...props} src={RawFinqr} alt={t('images.partners.finqr')} />;
};

export const Ongoing = (props: HTMLAttributes<HTMLImageElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawOngoing
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Ongoing' })}
        />
    );
};

export const Prosmart = (props: HTMLAttributes<HTMLImageElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawProsmart
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Prosmart' })}
        />
    );
};
export const Pyramid = (props: HTMLAttributes<HTMLImageElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPyramid
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Pyramid' })}
        />
    );
};
export const Fortnox = (props: HTMLAttributes<HTMLImageElement>) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawFortnoxWithText}
            alt={t('images.partners.fortnox')}
        />
    );
};

export const FortnoxIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawFortnox {...props} role="img" alt={t('images.partners.fortnox')} />
    );
};

export const ShopifyTextIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawShopifyText
            {...props}
            role="img"
            aria-label={t('images.partners.shopify')}
        />
    );
};

export const ShopifyIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawShopify
            {...props}
            role="img"
            aria-label={t('images.partners.shopify')}
        />
    );
};

export const WooCommerceTextIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawWooCommerceText
            {...props}
            role="img"
            aria-label={t('images.partners.woocommerce')}
        />
    );
};

export const WooCommerceIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawWooCommerce
            {...props}
            role="img"
            aria-label={t('images.partners.woocommerce')}
        />
    );
};

export const SpecterTextIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawSpecterText
            {...props}
            role="img"
            aria-label={t('images.partners.specter')}
        />
    );
};

export const VismaTextIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawVismaText
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Visma' })}
        />
    );
};

export const VismaIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawVisma
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Visma' })}
        />
    );
};
export const SpecterIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawSpecter
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Specter' })}
        />
    );
};

export const MonitorIcon = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <RawMonitor
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Monitor' })}
        />
    );
};

export const MagentoIcon = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <RawMagento
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Magento' })}
        />
    );
};

export const MicrosoftBusinessCentralIcon = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <RawMicrosoftBuinessCentral
            {...props}
            role="img"
            aria-label={t('images.logoOf', {
                name: 'Microsoft Business Central',
            })}
        />
    );
};

export const WinbasIcon = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <RawWinbas
            {...props}
            role="img"
            aria-label={t('images.logoOf', { name: 'Winbas' })}
        />
    );
};

export const RoundAmicron = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundAmicron}
            alt={t('images.logoOf', { name: 'Amicron' })}
        />
    );
};

export const RoundBuroware = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundBuroware}
            alt={t('images.logoOf', { name: 'Burowar' })}
        />
    );
};

export const RoundFortnox = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundFortnox}
            alt={t('images.logoOf', { name: 'Fortnox' })}
        />
    );
};

export const RoundJeeves = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundJeeves}
            alt={t('images.logoOf', { name: 'Jeeves' })}
        />
    );
};

export const RoundJtl = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundJtl}
            alt={t('images.logoOf', { name: 'Jtl' })}
        />
    );
};

export const RoundLogtrade = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundLogtrade}
            alt={t('images.logoOf', { name: 'Logtrad' })}
        />
    );
};

export const RoundMonitor = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundMonitor}
            alt={t('images.logoOf', { name: 'Monitor' })}
        />
    );
};

export const RoundOngoing = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundOngoing}
            alt={t('images.logoOf', { name: 'Ongoing' })}
        />
    );
};

export const RoundPyramid = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundPyramid}
            alt={t('images.logoOf', { name: 'Pyramid' })}
        />
    );
};

export const RoundSage = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundSage}
            alt={t('images.logoOf', { name: 'Sage' })}
        />
    );
};

export const RoundShopify = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundShopify}
            alt={t('images.logoOf', { name: 'Shopify' })}
        />
    );
};

export const RoundSpecter = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundSpecter}
            alt={t('images.logoOf', { name: 'Specter' })}
        />
    );
};

export const RoundStarweb = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundStarweb}
            alt={t('images.logoOf', { name: 'Starweb' })}
        />
    );
};

export const RoundVisma = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundVisma}
            alt={t('images.logoOf', { name: 'Visma' })}
        />
    );
};

export const RoundWoocommerce = (props: any) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRoundWoocommerce}
            alt={t('images.logoOf', { name: 'Woocomm' })}
        />
    );
};
