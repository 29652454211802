import styled from 'styled-components/macro';

interface SpinnerProps {
    size?: string;
    width?: string;
    display?: string;
    className?: string;
    color?: string;
}
export const spinnerSize = {
    small: '16px',
    medium: '24px',
    large: '48px',
};

export const spinnerWidth = {
    small: '2px',
    medium: '4px',
    large: '4px',
};
export const SpinnerContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;
const SpinnerWrapper = styled.div<SpinnerProps>`
    animation: fadeIn 0.1s ease-in forwards;
    display: ${({ display }) => display || 'block'};
    flex-shrink: 0;
    height: ${({ size }) => size};
    opacity: 0%;
    padding: ${({ width }) => width};
    position: relative;
    width: ${({ size }) => size};

    > .spinner {
        animation: rotate 1.6s linear infinite;
        height: ${({ size }) => size};
        left: 0;
        position: absolute;
        top: 0;
        transform: rotateZ(0);
        width: ${({ size }) => size};
        z-index: 2;

        > .path {
            animation: dash 1.6s ease-in-out infinite;
            stroke: ${({ theme, color }) => color || theme.colors.black};
            stroke-linecap: square;
            stroke-width: ${({ width }) => width};
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0%;
        }
        to {
            opacity: 100%;
        }
    }

    @keyframes rotate {
        100% {
            transform: rotateZ(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

type Props = SpinnerProps;
const Spinner = ({
    display,
    width = spinnerWidth.medium,
    size = spinnerSize.medium,
    className,
    color,
}: Props) => {
    return (
        <SpinnerWrapper
            className={className}
            display={display}
            width={width}
            size={size}
            color={color}
        >
            <svg className="spinner" viewBox="0 0 50 50">
                <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                />
            </svg>
        </SpinnerWrapper>
    );
};

export default Spinner;
