// The import cycle is due to getTeam being used in the analytics package.
// eslint-disable-next-line import/no-cycle
import useIdentifyUser from 'hooks/useIdentifyUser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { WithChildren } from 'types';

/**
 * Identifies the user whenever mounted. This component is used inside the PrivateRoute component
 * So whenever a user visits a signed in rout it is identified.
 *
 * We should make an Identify call:
 *
 * 1. After a user first registers
 *      Since we redirect the user into the app after registrations
 * 2. After a user logs in
 *      Since we redirect the user into the app after login
 * 3. When a user updates their info (for example, they change or add a new address)
 *      Not directly done by this compoent but directly after
 * 4. Upon loading any pages that are accessible by a logged in user (optional)
 *      This is what this component does
 * */

const Identifer = ({ children }: WithChildren) => {
    const { identify } = useIdentifyUser();
    const { pathname } = useLocation();

    useEffect(() => {
        identify();
    }, [pathname]);

    return <>{children}</>;
};

export default Identifer;
