import {
    GroupBase,
    OptionProps,
    components,
} from 'components/FormElements/Select';
import Text, { TextSize } from 'components/Typography/Text';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { IntegrationOptionType } from './IntegrationValue';

const { Option } = components;

const OptionContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

interface Props {
    data: IntegrationOptionType;
}

/**
 * A single option in the IntegrationSelect dropdown.
 *
 * Displays the integration and its monthly cost.
 */
const IntegrationOption = <
    OptionType extends IntegrationOptionType,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(
    props: OptionProps<OptionType, false, GroupType> & Props
) => {
    const { data }: Props = props;
    const { i18n, t } = useTranslation('settings');
    const monthlyCost = props.data.cost[props.data.currency];

    const formattedCost = formatCost(
        monthlyCost,
        i18n.languages[0],
        props.data.currency
    );

    return (
        <Option {...props}>
            <OptionContent>
                <Text>{data.label}</Text>
                <Text size={TextSize.MEDIUM}>
                    {t(
                        'api.subscriptionChange.integrationSelect.costPerMonth',
                        {
                            cost: formattedCost,
                        }
                    )}
                </Text>
            </OptionContent>
        </Option>
    );
};

export default IntegrationOption;
