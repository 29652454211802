import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.fieldset`
    /* Remove the default style of fieldset */
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0.01em 0 0 0;

    body:not(:-moz-handler-blocked) fieldset {
        display: table-cell;
    }
`;

export const Title = styled.legend`
    /* Remove the default style of legend */
    display: table;
    margin-bottom: ${({ theme }) => theme.space.s2};
    padding: 0;
`;

export const RadioButtonWrapper = styled.div<{
    horizontal?: boolean;
    divider?: boolean;
}>`
    display: flex;
    flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
    ${({ divider, theme }) => {
        if (!divider) {
            return css`
                gap: ${theme.space.s2};
            `;
        }
    }}
`;

export const ErrorMessage = styled(Text)`
    color: ${({ theme }) => theme.colors.red100};

    &::first-letter {
        text-transform: capitalize;
    }

    display: block;
`;
