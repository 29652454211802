import { SignupMethod } from 'api/users/types';

import { getHashedEmail } from '../analytics/calculations';
import { QualificationEvent } from '../analytics/types';

const track = (event: string, data: any) => {
    try {
        (window as any).dataLayer.push({
            event,
            ...data,
        });
    } catch (e) {
        // gtm is likely blocked by an adblocker
    }
};

export const gtmSignUp = (
    email: string,
    signup_method: SignupMethod,
    experiments?: { [key: string]: any }
) => {
    const sha256_email = getHashedEmail(email);
    track('SignUp_', { signup_method, sha256_email, ...experiments });
};

export const gtmFirstCheckout = (email: string, revenue: number) => {
    const sha256_email = getHashedEmail(email);
    track('FirstCheckout_', { sha256_email, first_checkout_revenue: revenue });
};
export const gtmCheckout = (
    email: string,
    checkout_revenue: number,
    currency_code: string
) => {
    const sha256_email = getHashedEmail(email);
    track('Checkout_', { sha256_email, checkout_revenue, currency_code });
};

export const gtmLoginAuthenticated = (email: string) => {
    const sha256_email = getHashedEmail(email);
    track('LoginAuthenticated_', { sha256_email });
};

export const gtmVirtualPageView = (
    page_path: string,
    page_url: string,
    document_referrer: string
) => {
    track('VirtualPageView_', { page_path, page_url, document_referrer });
};

export const gtmSignupQualification = (event: QualificationEvent) => {
    track('SignupQualification_', event);
};

export const gtmIdentifyUser = (email: string) => {
    const sha256_email = getHashedEmail(email);
    track('IdentifyUser_', { sha256_email });
};
