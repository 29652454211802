class Logger {
    static isDevelopment = process.env.NODE_ENV === 'development';

    /**
     * Logs informational messages. This is used for general information of the application flow.
     * It is only active in the development environment.
     *
     * @param message The primary message to log.
     * @param optionalParams Additional logging information.
     */
    static info(message: string, ...optionalParams: any[]) {
        if (Logger.isDevelopment) {
            console.info('INFO: ', message, ...optionalParams);
        }
    }

    /**
     * Logs error messages. This is used for logging errors and exceptions.
     * It is active in both development and production environments.
     *
     * @param message The primary error message to log.
     * @param optionalParams Additional error details.
     */
    static error(message: string, ...optionalParams: any[]) {
        console.error('ERROR: ', message, ...optionalParams);
    }

    /**
     * Logs debugging information. This is useful during development for debugging purposes.
     * It is only active in the development environment.
     *
     * @param message The primary debug message.
     * @param optionalParams Additional debug information.
     */
    static debug(message: string, ...optionalParams: any[]) {
        if (Logger.isDevelopment) {
            console.debug('DEBUG: ', message, ...optionalParams);
        }
    }

    /**
     * Logs warnings. This is used for situations that are not errors but should be noted,
     * such as deprecation warnings or potential issues.
     * It is only active in the development environment.
     *
     * @param message The primary warning message.
     * @param optionalParams Additional warning details.
     */
    static warn(message: string, ...optionalParams: any[]) {
        if (Logger.isDevelopment) {
            console.warn('WARN: ', message, ...optionalParams);
        }
    }
}

export default Logger;
