import { useIsTeamMember } from 'hooks/useIsTeamMember';
import styled from 'styled-components/macro';
import { WithChildren } from 'types';

const TopNav = styled.div<{ isTeamMember: boolean }>`
    align-items: center;
    background-color: ${({ theme, isTeamMember }) =>
        isTeamMember ? theme.colors.white : theme.colors.cardboardBrown300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    display: flex;
    height: auto;
    justify-content: center;
    padding: ${({ theme }) => `0 ${theme.space.s6}`};
    top: 0;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: ${({ theme }) => ` 0 ${theme.space.s3} 0 ${theme.space.s5}`};
        height: 44px;
    }
`;

const TopNavContent = styled.div`
    align-items: center;
    display: flex;
    max-width: 1280px;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        justify-content: space-between;
    }
`;

const Navigation = ({ children }: WithChildren) => {
    const isTeamMember = useIsTeamMember();
    return (
        <TopNav isTeamMember={isTeamMember}>
            <TopNavContent>{children}</TopNavContent>
        </TopNav>
    );
};

export default Navigation;
