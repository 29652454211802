import { IntegrationCredentials } from 'api/admin/types';
import { Integration } from 'api/subscriptions/types';
import {
    NarrowInput,
    StyledInstructions,
} from 'components/IntegrationsModal/Instructions/StyledInstructions';
import Link from 'components/Typography/Link';
import Text, { TextSize } from 'components/Typography/Text';
import { useIntegrations } from 'hooks/Integrations';
import { useTeam, useUser } from 'hooks/Queries';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RadioButton from '../../FormElements/RadioButton/RadioButton';
import RadioButtonGroup from '../../FormElements/RadioButtonGroup';

interface Props {
    system: Integration;
}

const Instructions = ({ system }: Props) => {
    const { t } = useTranslation('import');
    const { team } = useTeam();
    const { user } = useUser();
    const { handleSubmit, register, errors, control } =
        useForm<IntegrationCredentials>();

    const { goForward, step, setIsSubmitting, setCredentials, setIntegration } =
        useIntegrations();

    const submit = async (data: IntegrationCredentials) => {
        setIsSubmitting(true);
        // We will always have team and user here since the entire modal waits for these to be fetched.
        // But typescript does not know that we have fetched them already
        if (team && user) {
            try {
                setIntegration(system);
                setCredentials(data);
                goForward();
            } catch (err) {
                toast.error('common:error.refreshAndContact');
            }
        }

        setIsSubmitting(false);
    };

    return (
        <form id={`integration-step-${step}`} onSubmit={handleSubmit(submit)}>
            <StyledInstructions
                prefix={t('import:onboarding.step')}
                data-testid="import-onboarding-instructions"
            >
                <li>
                    <NarrowInput
                        id="integrationName"
                        name="integrationName"
                        ref={register({
                            required: t('errors.required').toString(),
                        })}
                        error={!!errors.integrationName}
                        errorMessage={errors.integrationName?.message}
                        placeholder={t('integrationName.placeholder')}
                        label={t('integrationName.label')}
                    />
                </li>

                {system === 'WooCommerce' && (
                    <li>
                        <Text>
                            <Trans t={t} i18nKey="onboarding.woocommerce.step1">
                                <Text size={TextSize.MEDIUM} />
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={t('onboarding.woocommerce.step1link')}
                                />
                            </Trans>
                        </Text>
                        <NarrowInput
                            name="wooCommerceStore"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.wooCommerceStore}
                            errorMessage={errors.wooCommerceStore?.message}
                            placeholder={t('wooCommerce.StoreUrlPlaceholder')}
                            label={t('wooCommerce.StoreUrlLabel')}
                        />
                        <NarrowInput
                            name="wooCommerceApiKey"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.wooCommerceApiKey}
                            errorMessage={errors.wooCommerceApiKey?.message}
                            label={t('wooCommerce.ApiKeyLabel')}
                        />
                        <NarrowInput
                            name="wooCommerceApiSecret"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.wooCommerceApiSecret}
                            errorMessage={errors.wooCommerceApiSecret?.message}
                            label={t('wooCommerce.ApiSecretLabel')}
                        />
                    </li>
                )}
                {system === 'Shopify' && (
                    <li>
                        <Text>
                            <Trans t={t} i18nKey="onboarding.shopify.step1">
                                <Text size={TextSize.MEDIUM} />
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={t('onboarding.shopify.step1link')}
                                />
                            </Trans>
                        </Text>
                        <NarrowInput
                            name="shopifyStoreName"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            placeholder={t('shopify.UrlIdPlaceholder')}
                            error={!!errors.shopifyStoreName}
                            errorMessage={errors.shopifyStoreName?.message}
                            label={t('shopify.UrlId')}
                        />
                        <NarrowInput
                            name="shopifyUsername"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.shopifyUsername}
                            errorMessage={errors.shopifyUsername?.message}
                            label={t('shopify.ApiKeyLabel')}
                        />
                        <NarrowInput
                            name="shopifyPassword"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.shopifyPassword}
                            errorMessage={errors.shopifyPassword?.message}
                            label={t('shopify.AccessTokenLabel')}
                        />
                    </li>
                )}
                {system === 'Specter' && (
                    <li>
                        <NarrowInput
                            name="specterApiKey"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.specterApiKey}
                            errorMessage={errors.specterApiKey?.message}
                            label={t('specter.ApiKeyLabel')}
                        />
                        <NarrowInput
                            name="specterSbmId"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.specterSbmId}
                            errorMessage={errors.specterSbmId?.message}
                            label={t('specter.SbmIdLabel')}
                        />
                        <NarrowInput
                            name="specterUserId"
                            ref={register({
                                required: t('errors.required').toString(),
                            })}
                            error={!!errors.specterUserId}
                            errorMessage={errors.specterUserId?.message}
                            label={t('specter.UserIdLabel')}
                        />
                        <Controller
                            control={control}
                            name="specterLicense"
                            rules={{
                                required: t('errors.required').toString(),
                            }}
                            render={({ onChange, value }) => (
                                <RadioButtonGroup
                                    title={t('specter.licenseLabel')}
                                    value={value}
                                    onChange={onChange}
                                    horizontal
                                    errorMessage={
                                        errors.specterLicense?.message
                                    }
                                >
                                    <RadioButton
                                        label={t('specter.ecommerceLabel')}
                                        value="ECommerce"
                                    />
                                    <RadioButton
                                        label={t('specter.apiPlusLabel')}
                                        value="ApiPlus"
                                    />
                                </RadioButtonGroup>
                            )}
                        />
                    </li>
                )}
            </StyledInstructions>
        </form>
    );
};

export default Instructions;
