/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RawPlus } from './add-outline.svg';
import { ReactComponent as RawArrowDownCircle } from './arrow-down-circle.svg';
import { ReactComponent as RawCalendarClearOutline } from './calendar-clear-outline.svg';
import { ReactComponent as RawCamera } from './camera.svg';
import { ReactComponent as RawCaretDown } from './caret-down-sharp.svg';
import { ReactComponent as RawCaretUp } from './caret-up-sharp.svg';
import { ReactComponent as RawChevronBackSharp } from './chevron-back-sharp.svg';
import { ReactComponent as RawChevronForwardSharp } from './chevron-forward-sharp.svg';
import { ReactComponent as RawChevronUpOutline } from './chevron-up-outline.svg';
import { ReactComponent as RawCloseCircleOutline } from './close-circle-outline.svg';
import { ReactComponent as RawDocumentAttach } from './document-attach.svg';
import { ReactComponent as RawDocumentOutline } from './document-outline.svg';
import { ReactComponent as RawDocumentSharp } from './document-sharp.svg';
import { ReactComponent as RawDuplicate } from './duplicate.svg';
import { ReactComponent as RawFlagOutline } from './flag-outline.svg';
import { ReactComponent as RawHomeOutline } from './home-outline.svg';
import { ReactComponent as RawListOutline } from './list-outline.svg';
import { ReactComponent as RawLocationOutline } from './location-outline.svg';
import { ReactComponent as RawLogoAmplify } from './logo-amplify.svg';
import { ReactComponent as RawLogoGitlab } from './logo-gitlab.svg';
import { ReactComponent as RawMailOpenOutline } from './mail-open-outline.svg';
import { ReactComponent as RawMail } from './mail.svg';
import { ReactComponent as RawPeopleOutline } from './people-outline.svg';
import { ReactComponent as RawPersonOutline } from './person-outline.svg';
import { ReactComponent as RawPrintOutline } from './print-outline.svg';
import { ReactComponent as RawShareSocialOutline } from './share-social-outline.svg';

export const Camera = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCamera
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.camera')}
        />
    );
};

export const DocumentAttach = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDocumentAttach
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.documentAttach')}
        />
    );
};

export const Plus = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPlus
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.plus')}
        />
    );
};

export const CloseCircleOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCloseCircleOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.closeCircle')}
        />
    );
};
export const FlagOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawFlagOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.flag')}
        />
    );
};

export const List = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawListOutline
            {...props}
            role="img"
            aria-label={t('icons.ionicon.list')}
        />
    );
};

export const LocationOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawLocationOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.location')}
        />
    );
};
export const MailOpenOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawMailOpenOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.mailOpen')}
        />
    );
};
export const PeopleOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPeopleOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.people')}
        />
    );
};
export const PersonOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPersonOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.person')}
        />
    );
};
export const PrintOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawPrintOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.print')}
        />
    );
};
export const ShareSocialOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawShareSocialOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.shareSocial')}
        />
    );
};

export const ArrowDownCircle = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawArrowDownCircle
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.arrowDown')}
        />
    );
};
export const CalendarClearOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCalendarClearOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.calendar')}
        />
    );
};

export const CaretUp = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCaretUp
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.caretUp')}
        />
    );
};

export const CaretDown = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCaretDown
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.caretDown')}
        />
    );
};

export const ChevronForwardSharp = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawChevronForwardSharp
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.chevronForward')}
        />
    );
};
export const ChevronBackSharp = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawChevronBackSharp
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.chevronBackward')}
        />
    );
};
export const ChevronUpOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawChevronUpOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.chevronUp')}
        />
    );
};
export const DocumentSharp = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDocumentSharp
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.document')}
        />
    );
};
export const DocumentOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDocumentOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.document')}
        />
    );
};
export const Duplicate = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDuplicate
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.duplicate')}
        />
    );
};
export const HomeOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawHomeOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.home')}
        />
    );
};
export const LogoAmplify = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawLogoAmplify
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.amplify')}
        />
    );
};
export const LogoGitlab = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawLogoGitlab
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.gitlab')}
        />
    );
};
export const Mail = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawMail
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.mail')}
        />
    );
};
