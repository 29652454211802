import { DateTime } from 'luxon';

export const round = (val: number, decimals: number) =>
    Math.round((val + Number.EPSILON) * Math.pow(10, decimals)) /
    Math.pow(10, decimals);

export const roundToTwoDecimalPlaces = (value: string | number) => {
    if (value !== '') {
        return round(Number(value), 2);
    }
    return undefined;
};

// Parse a JSON with ISO dates in it.
// See https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates
export const parseDates = (json?: any) => {
    const isoRegex =
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    if (json) {
        return JSON.parse(json, (key: string, value: any) => {
            let parsedValue = value;
            if (typeof value === 'string' && isoRegex.exec(value)) {
                parsedValue = DateTime.fromISO(value, { setZone: true });
            }

            return parsedValue;
        });
    }
    return undefined;
};
