import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styled from 'styled-components/macro';

export default styled(ToastContainer)`
    .Toastify__toast--success {
        background: ${({ theme }) => theme.colors.black};
        color: white;
    }
    .Toastify__toast--error {
        background: ${({ theme }) => theme.colors.red200};
        color: white;
    }
`;
