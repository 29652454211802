import Heading from 'components/Typography/Heading';
import styled from 'styled-components/macro';

export const CheckboxWrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: ${({ theme }) => theme.space.s3};
    margin-bottom: ${({ theme }) => theme.space.s6};
    margin-top: ${({ theme }) => theme.space.s2};
`;

export const ModalContent = styled.div`
    margin: ${({ theme }) =>
        `0 ${theme.space.s8} ${theme.space.s6} ${theme.space.s8}`};
`;

export const InfoParagraph = styled.p`
    margin: ${({ theme }) => `${theme.space.s4} 0 ${theme.space.s6} 0`};
`;

export const SubHeading = styled(Heading)`
    margin-bottom: ${({ theme }) => theme.space.s4};
`;

export const BetaWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.space.s3};
    margin-top: ${({ theme }) => theme.space.s6};
`;

export const ButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.space.s4};
`;
