import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Address } from 'api/consignments/types';
import { createSubscription, getSubscriptions } from 'api/subscriptions';
import { Integration, Interval, Tier } from 'api/subscriptions/types';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';

import { useTeam } from './team';
import { useUser } from './user';

/**
 * Creates a card payment method in Stripe.
 * @return The ID of the created payment method
 * @throws Error if the creation fails.
 */
const createStripePayment = async (
    stripe: Stripe,
    cardElement: StripeCardElement,
    name?: string,
    address?: Address
) => {
    const createResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
            name,
            address: {
                city: address?.city,
                country: address?.countryCode,
                line1: address?.addressLine1,
                line2: address?.addressLine2,
                postal_code: address?.postalCode,
                state: address?.state,
            },
        },
    });
    if (createResult.error) {
        throw new Error();
    }
    return createResult.paymentMethod.id;
};

export interface NewSubscription {
    stripe?: Stripe;
    cardElement?: StripeCardElement;
    priceId?: number;
    address?: Address;
    name?: string;
    email?: string;
    tier: Tier;
    integration?: Integration;
    interval: Interval;
}

export const useSubscriptions = () => {
    const { team } = useTeam();
    const { user } = useUser();
    const queryClient = useQueryClient();

    // Set to a number (in milliseconds) to refetch until a change has been discovered
    const [refetchIntervalToChange, setRefetchIntervalToChange] = useState<
        number | undefined
    >();
    const { data: subscriptions, ...rest } = useQuery(
        ['subscriptions'],
        getSubscriptions,
        {
            refetchInterval: refetchIntervalToChange,
            onSuccess: (data) => {
                if (refetchIntervalToChange) {
                    // We should see if the data has changed
                    const oldData = queryClient.getQueryData(['subscriptions']);
                    if (!isEqual(data, oldData)) {
                        // It has changed. Stop refetching.
                        setRefetchIntervalToChange(undefined);
                    }
                }
            },
        }
    );

    /**
     * Creates a Stripe payment method and confirms the payment, if needed.
     * Also updates the backend team subscription.
     */
    const subscribe = async ({
        stripe,
        cardElement,
        priceId,
        address,
        name,
        email,
        tier,
        integration,
        interval,
    }: NewSubscription) => {
        if (!(team && user)) {
            return;
        }
        let paymentMethodId: string | undefined;
        if (stripe && cardElement) {
            paymentMethodId = await createStripePayment(
                stripe,
                cardElement,
                name,
                address
            );
        }

        const { stripeClientSecret } = await createSubscription({
            tier,
            email,
            address,
            paymentMethodId: paymentMethodId,
            priceId,
            currency: team.currency,
            integration,
            interval,
        });

        // Refetch every second until the Stripe has called our backend with updated subscription data.
        setRefetchIntervalToChange(1000);

        queryClient.invalidateQueries(['team']);
        queryClient.invalidateQueries(['subscriptions']);
        if (stripeClientSecret && stripe) {
            return stripe.confirmCardPayment(stripeClientSecret, {
                receipt_email: user.email,
                payment_method: paymentMethodId,
            });
        }
    };

    return {
        subscriptions,
        subscribe,
        ...rest,
    };
};
