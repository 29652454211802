import { get, patch, post, put, remove } from 'api';
import ApiError from 'api/ApiError';
import { AdminPasswordChange } from 'api/admin/types';
import { ApiKeyInformation } from 'api/auth/types';
import {
    addressToBackendAddress,
    backendConsignmentToConsignment,
} from 'api/consignments/mapper';
import { BackendConsignment, Consignment } from 'api/consignments/types';
import { backendUserToUser } from 'api/users/mapper';
import { BackendUser, User } from 'api/users/types';
import { goDomain } from 'config';
import queryString from 'query-string';

import * as config from '../../config';
import {
    APISubscriptionTier,
    PriceSubscriptionTier,
} from '../subscriptions/types';
import { backendTeamToTeam } from '../teams/mapper';
import {
    BackendTeam,
    BackendTeamUpdate,
    Team,
    TeamUpdate,
} from '../teams/types';
import { adminPasswordChangeToBackend } from './mapper';
import { IntegrationCredentials } from './types';

/**
 * Endpoints under the /admin path all require the signed in user to be a god user
 */

export const updateUserPassword = async (
    data: AdminPasswordChange
): Promise<User> => {
    const response = await post<{ data: BackendUser }>(
        `${goDomain}v2/admin/${data.userId}/password`,
        adminPasswordChangeToBackend(data)
    );
    if (response?.parsedBody?.data) {
        return backendUserToUser(response.parsedBody.data);
    }
    throw new ApiError(response);
};

export const searchUser = async (query: string): Promise<User[]> => {
    const params = {
        page: 1,
        per_page: 50,
    };
    const response = await get<{ data: BackendUser[] }>(
        `${goDomain}v2/admin/users?q=${query}&${queryString.stringify(params)}`
    );
    if (response?.parsedBody?.data) {
        return response.parsedBody.data.map((user) => backendUserToUser(user));
    }
    throw new ApiError(response);
};

export const searchConsignment = async (
    query: string
): Promise<Consignment[]> => {
    const response = await get<{ data: BackendConsignment[] }>(
        `${goDomain}v2/admin/consignments?q=${query}&include=order`
    );
    const backendConsignments = response.parsedBody?.data;
    if (backendConsignments) {
        return backendConsignments.map(backendConsignmentToConsignment);
    }
    return [];
};

export const blockTeam = async (teamId: string): Promise<Team> => {
    const response = await patch<BackendTeam>(`${goDomain}v2/admin/block`, {
        team_id: teamId,
        block: true,
    });
    const team = response.parsedBody;
    if (team) {
        return backendTeamToTeam(team);
    }
    throw new ApiError(response);
};

export const unblockTeam = async (teamId: string): Promise<Team> => {
    const response = await patch<BackendTeam>(`${goDomain}v2/admin/block`, {
        team_id: teamId,
        block: false,
    });

    const team = response.parsedBody;
    if (team) {
        return backendTeamToTeam(team);
    }

    throw new ApiError(response);
};

export const getTeamApiInformation = async (
    teamId: string
): Promise<ApiKeyInformation> => {
    const response = await get<ApiKeyInformation>(
        `${goDomain}v2/admin/api/${teamId}`
    );

    const information = response.parsedBody;
    if (information) {
        return information;
    }
    throw new ApiError(response);
};

export const generateApiKey = async (
    teamId: string,
    userId: number
): Promise<string> => {
    const response = await post<{ api_key: string }>(
        `${goDomain}v2/admin/api/${teamId}`,
        { user_id: userId }
    );
    const body = response.parsedBody;
    if (body?.api_key) {
        return body.api_key;
    }
    throw new ApiError(response);
};

export const searchAdminTeams = async (query: string): Promise<Team[]> => {
    const params = {
        page: 1,
        per_page: 50,
    };
    const response = await get<{ data: BackendTeam[] }>(
        `${config.goDomain}v2/admin/teams/search?q=${encodeURIComponent(
            query
        )}&${queryString.stringify(params)}`
    );
    if (response?.parsedBody?.data) {
        return response.parsedBody.data.map((team) => backendTeamToTeam(team));
    }
    throw new ApiError(response);
};

export const getTeam = async (teamId: string): Promise<Team> => {
    const response = await get<{ data: BackendTeam }>(
        `${goDomain}v2/admin/teams/${teamId}`
    );
    if (response?.parsedBody?.data) {
        return backendTeamToTeam(response.parsedBody.data);
    }
    throw new ApiError(response);
};

export const updateTeam = async (team: TeamUpdate): Promise<Team> => {
    const payload: BackendTeamUpdate = {
        identity_number: team.identityNumber,
        name: team.name,
        home_address: addressToBackendAddress(team.homeAddress),
    };
    if (team.invoiceAddress) {
        payload.invoice_address = addressToBackendAddress(team.invoiceAddress);
    }
    const response = await put<{ data: BackendTeam }>(
        `${goDomain}v2/admin/teams/${team.id}`,
        payload
    );
    if (response?.parsedBody?.data) {
        return backendTeamToTeam(response.parsedBody.data);
    } else {
        throw new ApiError(response);
    }
};

export const getIntegrationCredentials = async (
    teamId: string
): Promise<IntegrationCredentials> => {
    const response = await get<IntegrationCredentials>(
        `${goDomain}v2/admin/integrations/${teamId}/credentials`
    );
    if (response.status === 200) {
        const credentials = response.parsedBody;
        if (credentials) {
            return credentials;
        }
        throw new ApiError(response);
        // return an empty object if there are no credentials
    } else if (response.status === 204) {
        return {};
    }
    throw new ApiError(response);
};

export const adminUpdateTeamPriceTier = (
    teamId: string,
    priceTier: PriceSubscriptionTier
) =>
    patch(`${goDomain}v2/admin/teams/${teamId}/price-tier`, {
        priceTier: priceTier,
    });

export const adminUpdateTeamApiTier = (
    teamId: string,
    apiTier: APISubscriptionTier
) =>
    patch(`${goDomain}v2/admin/teams/${teamId}/api-tier`, {
        apiTier,
    });

export const adminUpdateTeamMarginGroup = (
    teamId: string,
    marginGroups: string[]
) =>
    patch(`${goDomain}v2/admin/teams/${teamId}/margin-groups`, {
        marginGroups: marginGroups,
    });

export const clearZwapgridTokens = async (teamId: string): Promise<void> => {
    await remove(`${goDomain}v2/admin/integrations/${teamId}/zwapgrid`);
};

export const getMarginGroups = async (): Promise<string[]> => {
    const response = await get<string[]>(
        `${config.goDomain}v2/admin/margin-groups`
    );

    if (response?.parsedBody) {
        return response.parsedBody;
    }
    throw new ApiError(response);
};

export interface PickupResponse {
    pickupId: string;
    pickupDate: string;
    firstName: string;
    lastName: string;
    bookedAt: string;
    carrierReference: string;
}
export interface ErrorResponse {
    error: string;
}
export const getPickupHistory = async (
    consignmentHash: string
): Promise<PickupResponse[]> => {
    const url = `${goDomain}v2/admin/pickup/history/${consignmentHash}/`;

    const response = await get<PickupResponse[]>(url);

    if (!response.parsedBody) {
        throw new ApiError(response);
    }

    return response.parsedBody;
};
export const bookPickup = async (
    consignmentHash: string,
    requestedPickupDate: string
): Promise<PickupResponse> => {
    const url = `${goDomain}v2/admin/book/pickup`;
    const response = await post<PickupResponse | ErrorResponse>(url, {
        consignmentHash: consignmentHash,
        requestedPickupDate: requestedPickupDate,
    });

    if (!response.parsedBody) {
        throw new ApiError(response);
    }

    if ('error' in response.parsedBody) {
        throw new Error(response.parsedBody.error);
    }

    return response.parsedBody as PickupResponse;
};
