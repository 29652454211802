import { PackageType } from 'api/packages/types';
import Input from 'components/FormElements/Input';
import { FormPackage } from 'components/NewShipmentForm/types';
import Stepper from 'components/Stepper/Stepper';
import Caption, { CaptionSize } from 'components/Typography/Caption';
import Text from 'components/Typography/Text';
import { translatedPackageOptions } from 'constants/packageOptions';
import { scrollToAndFocus } from 'helpers/FormHelper';
import { roundToTwoDecimalPlaces } from 'helpers/FormatHelper';
import { useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    DetailsWrapper,
    DimensionsWrapper,
    PackageTypesWrapper,
    PackageWrapper,
    TimesWrapper,
    Wrapper,
} from './StyledDimensionsForm';

interface Props {
    prefix?: string;
    pack: FormPackage;
    index?: number;
    readOnly?: boolean;
    isHighlighted?: boolean;
}

const DimensionsForm = ({
    prefix,
    pack,
    index,
    readOnly,
    isHighlighted,
}: Props) => {
    const { t } = useTranslation('goods');
    const packagesTypes = translatedPackageOptions(t);

    const { setValue, control, errors } = useFormContext();

    const weightInputRef = useRef<HTMLInputElement>(null);
    const depthInputRef = useRef<HTMLInputElement>(null);
    const widthInputRef = useRef<HTMLInputElement>(null);
    const heightInputRef = useRef<HTMLInputElement>(null);

    const inputPrefix = prefix ? `${prefix}.` : '';

    const quantity = useWatch({
        control,
        name: `${inputPrefix}quantity`,
        defaultValue: pack.quantity || 1,
    });
    const type = useWatch({
        control,
        name: `${inputPrefix}type`,
        defaultValue: pack.type || 'PACKAGE',
    });
    const resetDimensions = (value: PackageType) => {
        if (value !== type) {
            setValue(`${inputPrefix}heightCm`, undefined);
            setValue(`${inputPrefix}weightKg`, undefined);
            setValue(`${inputPrefix}nonStackable`, undefined);
            if (value === 'PALLET') {
                setValue(`${inputPrefix}depthCm`, 120.0);
                setValue(`${inputPrefix}widthCm`, 80.0);
            } else {
                setValue(`${inputPrefix}depthCm`, undefined);
                setValue(`${inputPrefix}widthCm`, undefined);
            }
        }
    };

    return (
        <Wrapper>
            <PackageTypesWrapper>
                <Controller
                    control={control}
                    name={`${inputPrefix}type`}
                    defaultValue={type}
                    render={({ onChange, value }) => {
                        return (
                            <>
                                {packagesTypes.map((option) => {
                                    const selected = value === option.value;
                                    return (
                                        <PackageWrapper
                                            readOnly={readOnly}
                                            key={option.key}
                                            data-testid={`package-type-${option.value}`}
                                            selected={selected}
                                            onClick={
                                                readOnly
                                                    ? undefined
                                                    : () => {
                                                          onChange(
                                                              option.value
                                                          );
                                                          resetDimensions(
                                                              option.value
                                                          );
                                                      }
                                            }
                                        >
                                            <div>
                                                <Caption
                                                    size={CaptionSize.MEDIUM}
                                                >
                                                    {option.label}
                                                </Caption>
                                            </div>
                                            <option.image />
                                            {selected && (
                                                <Controller
                                                    control={control}
                                                    name={`${inputPrefix}quantity`}
                                                    defaultValue={quantity}
                                                    render={({
                                                        value: stepperValue,
                                                        onChange:
                                                            stepperOnChange,
                                                    }) => (
                                                        <Stepper
                                                            data-cy-up={`packages-${index}-quantity-up`}
                                                            data-cy-down={`packages-${index}-quantity-down`}
                                                            value={stepperValue}
                                                            onChange={
                                                                stepperOnChange
                                                            }
                                                            readOnly={readOnly}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </PackageWrapper>
                                    );
                                })}
                            </>
                        );
                    }}
                />
            </PackageTypesWrapper>
            <DetailsWrapper isHighlighted={!!isHighlighted}>
                <Controller
                    control={control}
                    name={`${inputPrefix}weightKg`}
                    defaultValue={
                        pack.weightKg === undefined ? '' : pack.weightKg
                    }
                    rules={{
                        required: t('errors.required').toString(),
                    }}
                    onFocus={() => scrollToAndFocus(weightInputRef)}
                    render={({ onChange, value }) => (
                        <Input
                            ref={weightInputRef}
                            id={`packages-${index}-input-weight`}
                            label={t('goods:weightPerPiece')}
                            suffix="kg"
                            value={value !== undefined ? value : ''}
                            onValueChange={(val: string) =>
                                onChange(roundToTwoDecimalPlaces(val))
                            }
                            min="0"
                            max="9999"
                            readOnly={readOnly}
                            type="number"
                            step="0.01"
                            error={
                                index !== undefined
                                    ? !!errors.packages?.[index]?.weightKg
                                    : !!errors.weightKg
                            }
                            errorMessage={
                                index !== undefined
                                    ? errors.packages?.[index]?.weightKg
                                          ?.message
                                    : errors.weightKg?.message
                            }
                        />
                    )}
                />
                <DimensionsWrapper>
                    <Controller
                        control={control}
                        name={`${inputPrefix}depthCm`}
                        defaultValue={pack.depthCm || ''}
                        rules={{
                            required: t('errors.required').toString(),
                        }}
                        onFocus={() => scrollToAndFocus(depthInputRef)}
                        render={({ onChange, value }) => (
                            <Input
                                ref={depthInputRef}
                                id={`packages-${index}-input-depth`}
                                label={t('goods:length')}
                                suffix="cm"
                                value={value !== undefined ? value : ''}
                                onValueChange={(val: string) =>
                                    onChange(roundToTwoDecimalPlaces(val))
                                }
                                min="0"
                                max="9999"
                                type="number"
                                readOnly={readOnly}
                                step="0.01"
                                error={
                                    index !== undefined
                                        ? !!errors.packages?.[index]?.depthCm
                                        : !!errors.depthCm
                                }
                                errorMessage={
                                    index !== undefined
                                        ? errors.packages?.[index]?.depthCm
                                              ?.message
                                        : errors.depthCm?.message
                                }
                            />
                        )}
                    />
                    <TimesWrapper>
                        <Text>x</Text>
                    </TimesWrapper>
                    <Controller
                        control={control}
                        name={`${inputPrefix}widthCm`}
                        defaultValue={pack.widthCm || ''}
                        rules={{
                            required: t('errors.required').toString(),
                        }}
                        onFocus={() => scrollToAndFocus(widthInputRef)}
                        render={({ onChange, value }) => (
                            <Input
                                ref={widthInputRef}
                                id={`packages-${index}-input-width`}
                                name={`${inputPrefix}widthCm`}
                                label={t('goods:width')}
                                type="number"
                                step="0.01"
                                min="0"
                                max="9999"
                                readOnly={readOnly}
                                suffix="cm"
                                value={value !== undefined ? value : ''}
                                onValueChange={(val: string) =>
                                    onChange(roundToTwoDecimalPlaces(val))
                                }
                                error={
                                    index !== undefined
                                        ? !!errors.packages?.[index]?.widthCm
                                        : !!errors.widthCm
                                }
                                errorMessage={
                                    index !== undefined
                                        ? errors.packages?.[index]?.widthCm
                                              ?.message
                                        : errors.widthCm?.message
                                }
                            />
                        )}
                    />
                    <TimesWrapper>
                        <Text>x</Text>
                    </TimesWrapper>
                    <Controller
                        control={control}
                        name={`${inputPrefix}heightCm`}
                        defaultValue={pack.heightCm || ''}
                        rules={{
                            required: t('errors.required').toString(),
                        }}
                        onFocus={() => scrollToAndFocus(heightInputRef)}
                        render={({ onChange, value }) => (
                            <Input
                                ref={heightInputRef}
                                id={`packages-${index}-input-height`}
                                name={`${inputPrefix}heightCm`}
                                label={t('goods:height')}
                                type="number"
                                step="0.01"
                                min="0"
                                max="9999"
                                suffix="cm"
                                readOnly={readOnly}
                                value={value !== undefined ? value : ''}
                                onValueChange={(val: string) =>
                                    onChange(roundToTwoDecimalPlaces(val))
                                }
                                error={
                                    index !== undefined
                                        ? !!errors.packages?.[index]?.heightCm
                                        : !!errors.heightCm
                                }
                                errorMessage={
                                    index !== undefined
                                        ? errors.packages?.[index]?.heightCm
                                              ?.message
                                        : errors.heightCm?.message
                                }
                            />
                        )}
                    />
                </DimensionsWrapper>
            </DetailsWrapper>
        </Wrapper>
    );
};

export default DimensionsForm;
