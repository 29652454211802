import { Place, WhereToVote } from 'assets/icons';
import Caption from 'components/Typography/Caption';
import styled from 'styled-components/macro';

export const StyledEndpoint = styled.div<{ withBorder: boolean }>`
    border: ${({ theme, withBorder }) =>
        withBorder ? `1px solid ${theme.colors.grey400}` : 'none'};
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s4};
    grid-template-columns: auto 1fr;
    padding: ${({ theme, withBorder }) => (withBorder ? theme.space.s4 : 0)};
`;

export const PathWrapper = styled.div`
    display: grid;
    grid-column: 1;
    grid-gap: ${({ theme }) => theme.space.s2};
    grid-row: 1/-1;
    grid-template-rows: auto 1fr auto;
    justify-items: center;
    padding: ${({ theme }) => `${theme.space.s5} 0`};
`;

export const Party = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;

    div {
        display: grid;
        grid-row-gap: ${({ theme }) => theme.space.s1};
    }
`;
export const Dash = styled.div`
    border: ${({ theme }) => `1px dashed ${theme.colors.black}`};
    width: 0;
`;

export const SenderIcon = styled(Place)`
    height: 24px;
`;

export const ReceiverIcon = styled(WhereToVote)`
    height: 24px;
`;

export const Person = styled(Caption)`
    color: ${({ theme }) => theme.colors.grey100};
`;
