import { Integration } from 'api/subscriptions/types';
import { Currency } from 'types/UserData';

type Price = { [key in Currency]: number };

export const monthlyIntegrationCosts: { [key in Integration]: Price } = {
    Ongoing: { ['SEK']: 99, ['EUR']: 9.9 },
    Prosmart: { ['SEK']: 99, ['EUR']: 9.9 },
    Pyramid: { ['SEK']: 99, ['EUR']: 9.9 },
    Fortnox: { ['SEK']: 99, ['EUR']: 9.9 },
    Shopify: { ['SEK']: 99, ['EUR']: 9.9 },
    WooCommerce: { ['SEK']: 99, ['EUR']: 9.9 },
    'Visma E-Economy': { ['SEK']: 99, ['EUR']: 9.9 },
    Specter: { ['SEK']: 99, ['EUR']: 9.9 },
    Other: { ['SEK']: 0, ['EUR']: 0 },
    'Adobe Commerce (Magento)': { ['SEK']: 0, ['EUR']: 0 },
    'Visma Admin 2000': { ['SEK']: 99, ['EUR']: 9.9 },
    'Dynamics 365 Business Central': { ['SEK']: 99, ['EUR']: 9.9 },
    Winbas: { ['SEK']: 99, ['EUR']: 9.9 },
    Monitor: { ['SEK']: 99, ['EUR']: 9.9 },
};
