import Divider from 'components/Divider';
import { RadioButtonProps } from 'components/FormElements/RadioButton';
import Text, { TextSize } from 'components/Typography/Text';
import { Children, cloneElement, forwardRef, isValidElement } from 'react';
import { WithChildren } from 'types';

import {
    ErrorMessage,
    RadioButtonWrapper,
    Title,
    Wrapper,
} from './StyledRadioButtonGroup';

interface Props {
    title?: string;
    value: any;
    onChange: (value: any) => void;
    className?: string;
    horizontal?: boolean;
    divider?: boolean;
    errorMessage?: string;
    readOnly?: boolean;
}

const RadioButtonGroup = forwardRef<HTMLDivElement, WithChildren<Props>>(
    (
        {
            title,
            value,
            onChange,
            className,
            errorMessage,
            horizontal,
            divider,
            children,
            readOnly,
        },
        ref
    ) => {
        const count = Children.count(children);
        return (
            <Wrapper>
                {title && (
                    <Title>
                        <Text size={TextSize.MEDIUM}>{title}</Text>
                    </Title>
                )}
                <RadioButtonWrapper
                    className={className}
                    ref={ref}
                    horizontal={horizontal}
                    divider={divider}
                >
                    {Children.map(children, (child, index) => {
                        if (!isValidElement<RadioButtonProps>(child)) {
                            return child;
                        }
                        return (
                            <>
                                {cloneElement(child, {
                                    readOnly,
                                    checked: child.props.value === value,
                                    onChange: () => onChange(child.props.value),
                                })}
                                {divider && count > index + 1 && <Divider />}
                            </>
                        );
                    })}
                </RadioButtonWrapper>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Wrapper>
        );
    }
);

export default RadioButtonGroup;
