import { DefaultTheme } from 'styled-components/macro';

import breakpoints from './breakpoints';
import colors from './colors';
import space from './space';
import zIndex from './zIndex';

const lightTheme: DefaultTheme = {
    colors,
    breakpoints,
    zIndex,
    space,
    fontFamily: `"Neue Montreal",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;`,
    navbarHeight: '44px',
    navbarHeightMobile: '44px',
};

export default lightTheme;
