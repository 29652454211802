import { Space } from 'styled-components/macro';

export const baseSize = 4;

const space: Space = {
    s0: `${0 * baseSize}px`,
    s1: `${1 * baseSize}px`,
    s2: `${2 * baseSize}px`,
    s3: `${3 * baseSize}px`,
    s4: `${4 * baseSize}px`,
    s5: `${5 * baseSize}px`,
    s6: `${6 * baseSize}px`,
    s7: `${7 * baseSize}px`,
    s8: `${8 * baseSize}px`,
    s10: `${10 * baseSize}px`,
    s12: `${12 * baseSize}px`,
    s16: `${16 * baseSize}px`,
    s20: `${20 * baseSize}px`,
};
export default space;
