import {
    GroupBase,
    OptionProps,
    components,
} from 'components/FormElements/Select';
import styled from 'styled-components/macro';

import { CountryOption } from './CountrySelect';
import { StyledFlag, StyledLabel } from './StyledFlag';

const { Option } = components;

const OptionContent = styled.div`
    align-items: center;
    display: flex;
`;

interface FlagProps {
    data: CountryOption;
}

const FlagSingleOption = <
    OptionType extends CountryOption,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(
    props: OptionProps<OptionType, false, GroupType> & FlagProps
) => {
    const { data }: FlagProps = props;
    return (
        <Option {...props}>
            <OptionContent>
                <StyledFlag size="20px" countryCode={data.value} />
                <StyledLabel>{data.label}</StyledLabel>
            </OptionContent>
        </Option>
    );
};

export default FlagSingleOption;
