import { searchTeam } from 'api/teams';
import { Button } from 'components/Buttons';
import Notification from 'components/Notifications/Notification';
import { useTeam, useTeamSwitch } from 'hooks/Queries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FullWitdhSelect, SelectContainer } from './StyledTeamSwitcher';

interface Props {
    onClose: () => void;
}

const TeamSwitcher = ({ onClose }: Props) => {
    const { t } = useTranslation(['admin']);
    const [selectedTeam, setSelectedTeam] = useState<{
        label: string;
        value: string;
        blocked: boolean;
    }>();
    const [isLoading, setIsLoading] = useState(false);
    const switchTeam = useTeamSwitch();
    const history = useHistory();
    const { team } = useTeam();

    return (
        <form
            onSubmit={async (e) => {
                e.preventDefault();

                if (selectedTeam && team?.id !== selectedTeam.value) {
                    setIsLoading(true);
                    await switchTeam(selectedTeam.value);
                    onClose();

                    /**
                     * Since we have many pages who do not currently subscribe
                     * to the team hook, we will have to reload the page. This can
                     * be removed, once those components have been rewritten.
                     */
                    history.go(0);
                }
            }}
        >
            <SelectContainer>
                <FullWitdhSelect
                    placeholder={t('team.searchTeam')}
                    menuPortalTarget={document.body}
                    defaultOptions
                    menuPlacement="auto"
                    value={selectedTeam}
                    onChange={(option: any) => {
                        setSelectedTeam(option);
                    }}
                    loadOptions={async (query: string) => {
                        const teams = await searchTeam(query);
                        const options = teams.map((team) => ({
                            label: team.identityNumber
                                ? `${team.name} (${t('admin:team.orgNr')} : ${
                                      team.identityNumber
                                  })`
                                : `${team.name}  (${t(
                                      'team.incompleteRegistration'
                                  )})`,
                            value: team.id,
                            blocked: team.blocked,
                        }));
                        return options;
                    }}
                />

                <Button
                    variant="primary"
                    type="submit"
                    fullWidth={true}
                    loading={isLoading}
                    disabled={!selectedTeam}
                >
                    {selectedTeam
                        ? t('team.switchToTeam', {
                              team: selectedTeam?.label,
                          })
                        : t('team.switchTeams')}
                </Button>
            </SelectContainer>
            {selectedTeam?.blocked && (
                <Notification variant="error">{t('team.blocked')}</Notification>
            )}
        </form>
    );
};

export default TeamSwitcher;
