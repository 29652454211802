import IntegrationsModal from 'components/IntegrationsModal';
import { createContext, useContext } from 'react';
import { WithChildren } from 'types';

import { UseIntegrationsOptions } from './types';
import { useIntegrationsMethods } from './useIntegrationsMethods';

const IntegrationsContext = createContext<UseIntegrationsOptions | null>(null);

IntegrationsContext.displayName = 'IntegrationsContext';
export const useIntegrations = (): UseIntegrationsOptions =>
    useContext(IntegrationsContext) as UseIntegrationsOptions;

export const IntegrationsProvider = ({ children }: WithChildren) => {
    const methods = useIntegrationsMethods();
    return (
        <IntegrationsContext.Provider
            value={{
                ...methods,
            }}
        >
            <IntegrationsModal />
            {children}
        </IntegrationsContext.Provider>
    );
};
