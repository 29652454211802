import { Integration } from 'api/subscriptions/types';
import RadioButtonGroup from 'components/FormElements/RadioButtonGroup';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { formatCost } from 'helpers/CurrencyHelper';
import { useIntegrations } from 'hooks/Integrations';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import {
    IntegrationPartner,
    useSupportedIntegrations,
} from 'hooks/useSupportedIntegrations';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { monthlyIntegrationCosts } from '../../../containers/Settings/components/Api/ApiIntegrations/constants';
import Divider from '../../Divider';
import IntegrationLogo from '../../IntegrationLogo';
import { IconWrapper, StyledRadioButton, SystemsInfo } from './StyledSystems';

const Instructions = () => {
    const { t, i18n } = useTranslation('import');
    const { team } = useTeam();
    const { setIntegration, integration } = useIntegrations();
    const systems = useSupportedIntegrations();
    const enabledSystems = useMemo(
        () =>
            systems.filter(
                (s) =>
                    (s.status === 'ENABLED' ||
                        s.status === 'BETA' ||
                        s.status === 'NEW') &&
                    s.integration !== 'Other'
            ),
        [systems]
    );

    const { subscriptions } = useSubscriptions();
    useEffect(() => {
        if (!integration) {
            const firstSystem = enabledSystems[0]?.integration;
            if (firstSystem) {
                setIntegration(firstSystem, !hasSystemsLeft(firstSystem));
            }
        }
    }, [enabledSystems]);

    const apiTier = team?.apiTier;
    if (!apiTier) {
        return null;
    }

    function getConnectedSystems(integration: string) {
        return (subscriptions?.apiSubscription?.integrations || []).filter(
            (i) => i.integration === integration
        ).length;
    }

    function getPurchasedSystems(integration: string) {
        return (
            (subscriptions?.apiSubscription.current?.integrations || []).find(
                (i) => i.integration === integration
            )?.quantity || 0
        );
    }

    function hasSystemsLeft(integration: Integration) {
        const connectedSystems = getConnectedSystems(integration);
        const purchasedSystems = getPurchasedSystems(integration);
        return connectedSystems < purchasedSystems;
    }

    return (
        <>
            {subscriptions?.apiSubscription.hasIntegrated && (
                <Text>{t('import:onboarding.ingressExisting')}</Text>
            )}
            {subscriptions?.apiSubscription.hasIntegrated === false && (
                <Text>{t('import:onboarding.ingress')}</Text>
            )}
            <Divider />
            <RadioButtonGroup
                value={integration}
                divider
                onChange={(i) => {
                    setIntegration(i, !hasSystemsLeft(i));
                }}
            >
                {enabledSystems.map((s) => {
                    const isFree = hasSystemsLeft(s.integration);
                    const cost =
                        monthlyIntegrationCosts[s.integration][team?.currency];
                    const messageKey =
                        s.integrationPartner === IntegrationPartner.Sendify
                            ? 'import:onboarding.connectMoreForFee.internal'
                            : `import:onboarding.connectMoreForFee.external`;

                    let subTitle = t([messageKey], {
                        integration: s.integration,
                        integrationPartner: s.integrationPartner,
                    });

                    if (isFree) {
                        subTitle = t('import:onboarding.connectMoreFree');
                    }
                    return (
                        <StyledRadioButton
                            key={s.integration}
                            value={s.integration}
                        >
                            <SystemsInfo>
                                <IconWrapper>
                                    <IntegrationLogo
                                        integration={s.integration}
                                    />
                                    <Text>{subTitle}</Text>
                                </IconWrapper>
                                <div>
                                    <Heading size={HeadingSize.SMALL}>
                                        {isFree
                                            ? t(
                                                  'import.onboarding.systems.connectedCount',
                                                  {
                                                      connected:
                                                          getConnectedSystems(
                                                              s.integration
                                                          ),
                                                      purchased:
                                                          getPurchasedSystems(
                                                              s.integration
                                                          ),
                                                  }
                                              )
                                            : t(
                                                  'import:onboarding.systems.freeTierPricePerMonth',
                                                  {
                                                      cost: formatCost(
                                                          cost,
                                                          i18n.language,
                                                          team?.currency,
                                                          0
                                                      ),
                                                  }
                                              )}
                                    </Heading>
                                </div>
                            </SystemsInfo>
                        </StyledRadioButton>
                    );
                })}
            </RadioButtonGroup>
            <Divider />
        </>
    );
};

export default Instructions;
