import ApiError from 'api/ApiError';
import { Consignment } from 'api/consignments/types';
import { formToConsignmentRequest } from 'components/NewShipmentForm/mapper';
import { Form } from 'components/NewShipmentForm/types';
import ShipmentFormSlider from 'components/ShipmentFormSlider';
import { useCartContext } from 'hooks/Cart';
import { useForm } from 'react-hook-form';

interface Props {
    consignment: Consignment;
    show: boolean;
    onClose: () => void;
    onSuccessfulUpdate?: (consignment: Consignment) => void;
}
// Edits an active consignment i.e. a consignment that is in the cart.
const EditActiveSlider = ({
    consignment,
    show,
    onClose,
    onSuccessfulUpdate,
}: Props) => {
    const methods = useForm();
    const { setCartVisibility, update } = useCartContext();
    const submit = async (data: Form) => {
        const consignmentRequest = formToConsignmentRequest(data);
        if (consignment?.id) {
            try {
                /*
                    We want to show the newly edited consignment in the cart, and display
                    the cart so the user knows it has been properly added. 
                */
                const updated = await update(
                    consignment.id,
                    consignmentRequest
                );
                setCartVisibility(true);
                if (updated && onSuccessfulUpdate) {
                    onSuccessfulUpdate(updated);
                }
            } catch (error: unknown) {
                const err = error as ApiError;
                if (err.errors && err.response.status === 422) {
                    return err.errors;
                }
            }
        }
        return undefined;
    };
    return (
        <ShipmentFormSlider
            isEditing
            moveToCart={false}
            methods={methods}
            submit={submit}
            consignment={consignment}
            onClose={onClose}
            show={show}
        />
    );
};

export default EditActiveSlider;
