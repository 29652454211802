import { Button } from 'components/Buttons';
import PickupCalendar from 'components/PickupCalendar';
import Text from 'components/Typography/Text';
import UserDataHelper from 'helpers/UserDataHelper';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, SubmitButton, Wrapper } from './StyledFooter';

export interface FooterProps {
    isEditing?: boolean;
    moveToCart: boolean;
    isLoading: boolean;
    defaultDate: DateTime;
    onDateChange?: (date: DateTime) => void;
    onCancelEdit?: () => void;
    className?: string;
}

const Footer = (props: FooterProps) => {
    const {
        isEditing,
        moveToCart,
        isLoading,
        onDateChange,
        defaultDate,
        onCancelEdit,
        className,
    } = props;
    const { t } = useTranslation();
    const { errors, watch } = useFormContext();
    const hasErrors = Object.keys(errors).length > 0;

    const senderCountryCode = watch('fromAddress.countryCode');
    const alternativeSenderCountryCode = watch('fromViaAddress.countryCode');
    const senderState = watch('fromAddress.state');
    const alternativeSenderState = watch('fromViaAddress.state');

    const countryCode =
        alternativeSenderCountryCode ||
        senderCountryCode ||
        UserDataHelper.getSystemCountry();
    const state = alternativeSenderState || senderState;

    let submitButtonTitle;
    if (moveToCart) {
        submitButtonTitle = t('button.addToCart');
    } else {
        submitButtonTitle = t('button.update');
    }

    return (
        <Wrapper className={className}>
            {hasErrors && (
                <ErrorMessage>
                    <Text>{t('notification.error.fillAllFields')}</Text>
                </ErrorMessage>
            )}

            {isEditing && (
                <Button
                    disabled={isLoading}
                    type="button"
                    variant="secondary"
                    onClick={onCancelEdit}
                >
                    {t('button.cancel')}
                </Button>
            )}

            <PickupCalendar
                defaultDate={defaultDate}
                onChange={onDateChange}
                countryCode={countryCode}
                state={state}
            />

            <SubmitButton
                data-testid="submit-footer"
                type="submit"
                variant="primary"
                loading={isLoading}
            >
                {submitButtonTitle}
            </SubmitButton>
        </Wrapper>
    );
};

export default Footer;
