import Flag from 'components/Flag';
import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const StyledFlag = styled(Flag)`
    margin-right: ${({ theme }) => theme.space.s2};
`;
export const StyledLabel = styled(Text)`
    min-width: 200px; /* We don't want to have too many lines for countries with long names. */
    overflow: hidden;
    pointer-events: none;
    text-overflow: ellipsis;
`;
