import {
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { switchTeam } from 'api/auth';
import { getTeamSettings } from 'api/settings';
import {
    getHasQualified,
    getTeam,
    getTeamAvatar,
    getTeamStats,
    updateTeam,
    updateTeamAvatar,
    updateTeamSettings,
} from 'api/teams';
import { Team, TeamSettings, TeamStats, TeamUpdate } from 'api/teams/types';

export const useTeam = (options?: Omit<UseQueryOptions<Team>, 'queryKey'>) => {
    const { data: team, ...rest } = useQuery(['team'], getTeam, options);
    return { team, ...rest };
};

export const useHasQualified = (
    options?: Omit<UseQueryOptions<boolean>, 'queryKey'>
) => {
    const { data: hasQualified, ...rest } = useQuery(
        ['hasQualified'],
        getHasQualified,
        options
    );
    return { hasQualified, ...rest };
};

export const useTeamStats = (
    options?: Omit<UseQueryOptions<TeamStats>, 'queryKey'>
) => {
    const { data: teamStats, ...rest } = useQuery(
        ['teamStats'],
        getTeamStats,
        options
    );

    return { teamStats, ...rest };
};

export const useUpdateTeam = (
    options?: UseMutationOptions<Team, unknown, TeamUpdate>
) => {
    const queryClient = useQueryClient();
    const { data: updatedTeam, ...rest } = useMutation(updateTeam, {
        ...options,
        onSettled: () => {
            queryClient.invalidateQueries(['team']);
        },
    });
    return { updatedTeam, ...rest };
};

export const useTeamSettings = () => {
    const { data: teamSettings, ...rest } = useQuery(
        ['teamSettings'],
        getTeamSettings
    );
    return { teamSettings, ...rest };
};

export const useTeamSwitch = () => {
    const queryClient = useQueryClient();
    return async (teamId: string) => {
        await switchTeam(teamId);
        await queryClient.invalidateQueries(['team']);
        await queryClient.invalidateQueries(['teamSettings']);
    };
};

/**
 * Optimistically patches the team settings. May be used with one or more settings.
 */
export const useUpdateTeamSetting = (
    options?: UseMutationOptions<TeamSettings, unknown, Partial<TeamSettings>>
) => {
    const queryClient = useQueryClient();
    const { data: updatedTeamSettings, ...rest } = useMutation(
        updateTeamSettings,
        {
            ...options,
            onSettled: (data) => {
                queryClient.setQueryData(['teamSettings'], data);
            },
            onMutate: async (newSettings: Partial<TeamSettings>) => {
                // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries(['teamSettings']);

                const previousValue = queryClient.getQueryData<TeamSettings>([
                    'teamSettings',
                ]);
                queryClient.setQueryData(['teamSettings'], {
                    ...previousValue,
                    ...newSettings,
                });
                return previousValue;
            },
            // Reset on failure
            onError: (err, variables, previousValue) => {
                queryClient.setQueryData(['teamSettings'], previousValue);
                queryClient.invalidateQueries(['teamSettings']);
            },
        }
    );
    return { updatedTeamSettings, ...rest };
};

export const useTeamAvatar = (
    options?: UseQueryOptions<string | undefined>
) => {
    const { data, ...rest } = useQuery<string | undefined>(
        ['teamAvatar'],
        getTeamAvatar,
        {
            ...options,
        }
    );
    return {
        teamAvatar: data,
        ...rest,
    };
};

export const useUpdateTeamAvatar = (
    options?: UseMutationOptions<void, unknown, FormData>
) => {
    const queryClient = useQueryClient();
    return useMutation(updateTeamAvatar, {
        ...options,
        onSuccess: () => {
            queryClient.invalidateQueries(['teamAvatar']);
        },
    });
};
