import { ContactRequest, PersonRequest } from 'api/addressbook/types';
import {
    Consignment,
    ConsignmentRequest,
    Party,
    PartyRequest,
    ReceiverPaysOptions,
} from 'api/consignments/types';
import { Package, PackageRequest } from 'api/packages/types';
import { Alpha2Code } from 'i18n-iso-countries';
import { DateTime } from 'luxon';
import { BackendErrors, FormErrors } from 'types';

import { Form, FormAddress, FormPackage } from './types';

export const formPackageToPackageRequest = (
    pack: FormPackage
): PackageRequest => {
    if (!pack.type) {
        throw new Error('Type missing on package');
    }
    // Packages are always stackable.
    const stackable = pack.type === 'PACKAGE' ? true : pack.stackable;
    if (stackable === undefined) {
        throw new Error(
            'Stackable information is required for pallets and unspecified goods'
        );
    }
    return {
        weightKg: pack.weightKg || 0,
        depthCm: pack.depthCm || 0,
        widthCm: pack.widthCm || 0,
        heightCm: pack.heightCm || 0,
        stackable,
        quantity: pack.quantity || 0,
        description: pack.description || '',
        name: pack.name || '',
        type: pack.type,
    };
};

export const packageToFormPackage = (pack: Package): FormPackage => ({
    packageId: pack.id,
    weightKg: pack.weightKg,
    depthCm: pack.depthCm,
    widthCm: pack.widthCm,
    heightCm: pack.heightCm,
    stackable: pack.stackable,
    description: pack.description || '',
    type: pack.type,
    quantity: pack.quantity,
    name: pack.name,
});
export const formAddressToPartyRequest = (
    address: FormAddress
): PartyRequest => ({
    address: {
        addressLine1: address.addressLine1 || '',
        addressLine2: address.addressLine2,
        addressLine3: address.addressLine3,
        city: address.city || '',
        countryCode: address.countryCode || '',
        state: address.state || undefined,
        postalCode: address.postalCode || '',
    },
    name: address.name,
    isPrivate: address.isPrivate,
    person: {
        email: address.contactEmail || '',
        firstName: address.isPrivate ? address.name : address.contactName || '',
        mobilePhoneNumber: address.contactPhone || '',
        phoneNumber: address.contactPhone || '',
    },
});
const formAddressToPersonRequest = (address?: FormAddress): PersonRequest => {
    return {
        email: address?.contactEmail || '',
        firstName: address?.isPrivate
            ? address?.name
            : address?.contactName || '',
        mobileNumber: address?.contactPhone || '',
    };
};

export const formAddressToContactRequest = (
    address: FormAddress
): ContactRequest => ({
    name: address.name,
    isPrivate: address.isPrivate,
    address: {
        addressLine1: address?.addressLine1 || '',
        addressLine2: address?.addressLine2 || '',
        addressLine3: address?.addressLine3 || '',
        city: address?.city || '',
        countryCode: address?.countryCode || '',
        state: address?.state || '',
        postalCode: address?.postalCode || '',
    },
    people: [formAddressToPersonRequest(address)],
});

const formatPickupDate = (date: DateTime): DateTime => {
    return DateTime.utc(date.year, date.month, date.day, 6);
};

export const formToConsignmentRequest = (form: Form): ConsignmentRequest => ({
    externalReference: form.externalReference,
    sender: formAddressToPartyRequest(form.fromAddress),
    viaSender: form.fromViaAddress
        ? formAddressToPartyRequest(form.fromViaAddress)
        : undefined,
    recipient: formAddressToPartyRequest(form.toAddress),
    viaRecipient: form.toViaAddress
        ? formAddressToPartyRequest(form.toViaAddress)
        : undefined,
    packages: form.packages
        ? form.packages.map(formPackageToPackageRequest)
        : [],
    pickupAtEarliest: formatPickupDate(form.date),
    pickupAtLatest: formatPickupDate(form.date),
});

export const partyToFormAddress = (party: Party): FormAddress => ({
    isPrivate: party.isPrivate,
    name: party.name,

    addressLine1: party.address.addressLine1,
    addressLine2: party.address.addressLine2,
    addressLine3: party.address.addressLine3,
    city: party.address.city,
    countryCode: party.address.countryCode as Alpha2Code,
    state: party.address.state || '',
    postalCode: party.address.postalCode,

    contactName: party.person?.firstName,
    contactPhone: party.person?.mobilePhoneNumber || '',
    contactEmail: party.person?.email || '',
    saveAsContact: false,
});

export const consignmentToForm = (
    consignment: Consignment,
    receiverPaysAccount?: ReceiverPaysOptions
): Form => ({
    date: consignment.pickupAtEarliest,
    fromAddress: {
        ...partyToFormAddress(consignment.sender),
        isPrivate: false,
    },
    toAddress: {
        ...partyToFormAddress(consignment.recipient),
        receiverPaysAccountNumber: receiverPaysAccount?.accountNumber,
    },
    fromViaAddress: consignment.viaSender
        ? partyToFormAddress(consignment.viaSender)
        : undefined,
    toViaAddress: consignment.viaRecipient
        ? partyToFormAddress(consignment.viaRecipient)
        : undefined,
    externalReference: consignment.externalReference,
    packages: consignment.packages.map(packageToFormPackage),
});

export const backendErrorsToFrontend = (
    errors: BackendErrors,
    numberOfPackages: number
): FormErrors => {
    const mappedErrors: FormErrors = {
        date: errors.pickup_at_earliest?.[0],
        externalReference: errors.external_reference?.[0],
        'fromAddress.name': errors.from_name?.[0],
        'fromAddress.addressLine1': errors['from_address.address_line_1']?.[0],
        'fromAddress.addressLine2': errors['from_address.address_line_2']?.[0],
        'fromAddress.addressLine3': errors['from_address.address_line_3']?.[0],
        'fromAddress.city': errors['from_address.city']?.[0],
        'fromAddress.state': errors['from_address.state']?.[0],
        'fromAddress.countryCode': errors['from_address.country_code']?.[0],
        'fromAddress.postalCode': errors['from_address.zip_code']?.[0],
        'fromAddress.contactName': errors['from_person.first_name']?.[0],
        'fromAddress.contactPhone': errors['from_person.mobile_number_1']?.[0],
        'fromAddress.contactEmail': errors['from_person.email']?.[0],
        'fromAddress.isPrivate': errors.from_is_private?.[0],

        'fromViaAddress.name': errors.from_via_name?.[0],
        'fromViaAddress.addressLine1':
            errors['from_via_address.address_line_1']?.[0],
        'fromViaAddress.addressLine2':
            errors['from_via_address.address_line_2']?.[0],
        'fromViaAddress.addressLine3':
            errors['from_via_address.address_line_3']?.[0],
        'fromViaAddress.city': errors['from_via_address.city']?.[0],
        'fromViaAddress.state': errors['from_via_address.state']?.[0],
        'fromViaAddress.countryCode':
            errors['from_via_address.country_code']?.[0],
        'fromViaAddress.postalCode': errors['from_via_address.zip_code']?.[0],
        'fromViaAddress.contactName': errors['from_via_person.first_name']?.[0],
        'fromViaAddress.contactPhone':
            errors['from_via_person.mobile_number_1']?.[0],
        'fromViaAddress.contactEmail': errors['from_via_person.email']?.[0],
        'fromViaAddress.isPrivate': errors.from_via_is_private?.[0],

        'toAddress.addressLine1': errors['to_address.address_line_1']?.[0],
        'toAddress.addressLine2': errors['to_address.address_line_2']?.[0],
        'toAddress.addressLine3': errors['to_address.address_line_3']?.[0],
        'toAddress.city': errors['to_address.city']?.[0],
        'toAddress.state': errors['to_address.state']?.[0],
        'toAddress.countryCode': errors['to_address.country_code']?.[0],
        'toAddress.postalCode': errors['to_address.zip_code']?.[0],
        'toAddress.contactName': errors['to_person.first_name']?.[0],
        'toAddress.contactPhone': errors['to_person.mobile_number_1']?.[0],
        'toAddress.contactEmail': errors['to_person.email']?.[0],
        'toAddress.name': errors.to_name?.[0],
        'toAddress.isPrivate': errors.to_is_private?.[0],

        'toViaAddress.addressLine1':
            errors['to_via_address.address_line_1']?.[0],
        'toViaAddress.addressLine2':
            errors['to_via_address.address_line_2']?.[0],
        'toViaAddress.addressLine3':
            errors['to_via_address.address_line_3']?.[0],
        'toViaAddress.city': errors['to_via_address.city']?.[0],
        'toViaAddress.state': errors['to_via_address.state']?.[0],
        'toViaAddress.countryCode': errors['to_via_address.country_code']?.[0],
        'toViaAddress.postalCode': errors['to_via_address.zip_code']?.[0],
        'toViaAddress.contactName': errors['to_via_person.first_name']?.[0],
        'toViaAddress.contactPhone':
            errors['to_via_person.mobile_number_1']?.[0],
        'toViaAddress.contactEmail': errors['to_via_person.email']?.[0],
        'toViaAddress.name': errors.to_via_name?.[0],
        'toViaAddress.isPrivate': errors.to_via_is_private?.[0],
    };

    for (let i = 0; i < numberOfPackages; i += 1) {
        mappedErrors[`packages.${i}.weightKg`] =
            errors[`packages[${i}].weight_kg`]?.[0];
        mappedErrors[`packages.${i}.depthCm`] =
            errors[`packages[${i}].depth_cm`]?.[0];
        mappedErrors[`packages.${i}.widthCm`] =
            errors[`packages[${i}].width_cm`]?.[0];
        mappedErrors[`packages.${i}.heightCm`] =
            errors[`packages[${i}].height_cm`]?.[0];
        mappedErrors[`packages.${i}.stackable`] =
            errors[`packages[${i}].stackable`]?.[0];
        mappedErrors[`packages.${i}.quantity`] =
            errors[`packages[${i}].quantity`]?.[0];
        mappedErrors[`packages.${i}.description`] =
            errors[`packages[${i}].description`]?.[0];
        mappedErrors[`packages.${i}.type`] = errors[`packages[${i}].type`]?.[0];
    }
    return mappedErrors;
};

export const backendToAddressErrorsToFrontend = (
    errors: BackendErrors
): FormErrors => {
    return {
        'toAddress.addressLine1': errors['to_address.address_line_1']?.[0],
        'toAddress.addressLine2': errors['to_address.address_line_2']?.[0],
        'toAddress.addressLine3': errors['to_address.address_line_3']?.[0],
        'toAddress.city': errors['to_address.city']?.[0],
        'toAddress.state': errors['to_address.state']?.[0],
        'toAddress.countryCode': errors['to_address.country_code']?.[0],
        'toAddress.postalCode': errors['to_address.zip_code']?.[0],
        'toAddress.contactName': errors['to_person.first_name']?.[0],
        'toAddress.contactPhone': errors['to_person.mobile_number_1']?.[0],
        'toAddress.contactEmail': errors['to_person.email']?.[0],
        'toAddress.name': errors.to_name?.[0],
        'toAddress.isPrivate': errors.to_is_private?.[0],

        'toViaAddress.addressLine1':
            errors['to_via_address.address_line_1']?.[0],
        'toViaAddress.addressLine2':
            errors['to_via_address.address_line_2']?.[0],
        'toViaAddress.addressLine3':
            errors['to_via_address.address_line_3']?.[0],
        'toViaAddress.city': errors['to_via_address.city']?.[0],
        'toViaAddress.state': errors['to_via_address.state']?.[0],
        'toViaAddress.countryCode': errors['to_via_address.country_code']?.[0],
        'toViaAddress.postalCode': errors['to_via_address.zip_code']?.[0],
        'toViaAddress.contactName': errors['to_via_person.first_name']?.[0],
        'toViaAddress.contactPhone':
            errors['to_via_person.mobile_number_1']?.[0],
        'toViaAddress.contactEmail': errors['to_via_person.email']?.[0],
        'toViaAddress.name': errors.to_via_name?.[0],
        'toViaAddress.isPrivate': errors.to_via_is_private?.[0],
    };
};
export const backendFromAddressErrorsToFrontend = (
    errors: BackendErrors
): FormErrors => {
    return {
        'fromAddress.addressLine1': errors['from_address.address_line_1']?.[0],
        'fromAddress.addressLine2': errors['from_address.address_line_2']?.[0],
        'fromAddress.addressLine3': errors['from_address.address_line_3']?.[0],
        'fromAddress.city': errors['from_address.city']?.[0],
        'fromAddress.state': errors['from_address.state']?.[0],
        'fromAddress.countryCode': errors['from_address.country_code']?.[0],
        'fromAddress.postalCode': errors['from_address.zip_code']?.[0],
        'fromAddress.contactName': errors['from_person.first_name']?.[0],
        'fromAddress.contactPhone': errors['from_person.mobile_number_1']?.[0],
        'fromAddress.contactEmail': errors['from_person.email']?.[0],
        'fromAddress.name': errors.from_name?.[0],
        'fromAddress.isPrivate': errors.from_is_private?.[0],

        'fromViaAddress.addressLine1':
            errors['from_via_address.address_line_1']?.[0],
        'fromViaAddress.addressLine2':
            errors['from_via_address.address_line_2']?.[0],
        'fromViaAddress.addressLine3':
            errors['from_via_address.address_line_3']?.[0],
        'fromViaAddress.city': errors['from_via_address.city']?.[0],
        'fromViaAddress.state': errors['from_via_address.state']?.[0],
        'fromViaAddress.countryCode':
            errors['from_via_address.country_code']?.[0],
        'fromViaAddress.postalCode': errors['from_via_address.zip_code']?.[0],
        'fromViaAddress.contactName': errors['from_via_person.first_name']?.[0],
        'fromViaAddress.contactPhone':
            errors['from_via_person.mobile_number_1']?.[0],
        'fromViaAddress.contactEmail': errors['from_via_person.email']?.[0],
        'fromViaAddress.name': errors.from_via_name?.[0],
        'fromViaAddress.isPrivate': errors.from_via_is_private?.[0],
    };
};

export const backendPackageErrorsToFrontend = (
    errors: BackendErrors,
    numberOfPackages: number
): FormErrors => {
    const mappedErrors: FormErrors = {};

    for (let i = 0; i < numberOfPackages; i++) {
        mappedErrors[`packages.${i}.weightKg`] =
            errors[`packages[${i}].weight_kg`]?.[0];
        mappedErrors[`packages.${i}.depthCm`] =
            errors[`packages[${i}].depth_cm`]?.[0];
        mappedErrors[`packages.${i}.widthCm`] =
            errors[`packages[${i}].width_cm`]?.[0];
        mappedErrors[`packages.${i}.heightCm`] =
            errors[`packages[${i}].height_cm`]?.[0];
        mappedErrors[`packages.${i}.stackable`] =
            errors[`packages[${i}].stackable`]?.[0];
        mappedErrors[`packages.${i}.quantity`] =
            errors[`packages[${i}].quantity`]?.[0];
        mappedErrors[`packages.${i}.description`] =
            errors[`packages[${i}].description`]?.[0];
        mappedErrors[`packages.${i}.type`] = errors[`packages[${i}].type`]?.[0];
    }

    return mappedErrors;
};

export const backendFavoriteErrorsToFrontend = (
    errors: BackendErrors,
    packageIndex: number
): FormErrors => {
    const mappedErrors: FormErrors = {};

    mappedErrors[`packages.${packageIndex}.weightKg`] = errors.weight_kg?.[0];
    mappedErrors[`packages.${packageIndex}.depthCm`] = errors.depth_cm?.[0];
    mappedErrors[`packages.${packageIndex}.widthCm`] = errors.width_cm?.[0];
    mappedErrors[`packages.${packageIndex}.heightCm`] = errors.height_cm?.[0];
    mappedErrors[`packages.${packageIndex}.stackable`] = errors.stackable?.[0];
    mappedErrors[`packages.${packageIndex}.quantity`] = errors.quantity?.[0];
    mappedErrors[`packages.${packageIndex}.description`] =
        errors.description?.[0];
    mappedErrors[`packages.${packageIndex}.name`] = errors.name?.[0];
    mappedErrors[`packages.${packageIndex}.type`] = errors.type?.[0];

    return mappedErrors;
};
