import { get, post, put } from 'api';
import ApiError from 'api/ApiError';
import {
    backendUserSettingsToUserSettings,
    backendUserToCurrentUser,
    signupRequestToBackendSignupRequest,
    userSettingsToBackendUserSettings,
    userToBackendUserUpdate,
    userpasswordToBackendUserPassword,
} from 'api/users/mapper';
import {
    BackendUser,
    BackendUserSetting,
    CurrentUser,
    OnboardingStatus,
    OnboardingStep,
    SignupRequest,
    User,
    UserPassword,
    UserSettings,
} from 'api/users/types';
import { identifyChat } from 'external/hubspot';
import queryString from 'query-string';
import { SHOW_NAVIGATION } from 'types/LocalStorage';

import * as config from '../../config';
import { backendTeamToTeam } from '../teams/mapper';
import { BackendTeam, Team } from '../teams/types';

export const getUserTeams = async (): Promise<Team[]> => {
    const params = {
        page: 1,
        perPage: 50,
    };
    const response = await get<{ data: BackendTeam[] }>(
        `${config.goDomain}v2/users/me/teams?${queryString.stringify(params)}`
    );
    if (response?.parsedBody?.data) {
        return response.parsedBody.data.map((team) => backendTeamToTeam(team));
    }
    throw new ApiError(response);
};

export const getUser = async (): Promise<CurrentUser> => {
    const response = await get<{ data: BackendUser }>(
        `${config.goDomain}v2/users/me`
    );
    const user = response?.parsedBody?.data;
    if (user) {
        if (user.show_navigation) {
            localStorage.setItem(SHOW_NAVIGATION, 'true');
        } else {
            localStorage.removeItem(SHOW_NAVIGATION);
        }
        const currentUser = backendUserToCurrentUser(user);
        identifyChat(currentUser);
        return currentUser;
    }
    throw new ApiError(response);
};

export const getUsersResendConfirmEmail = async () => {
    const response = await get(`${config.goDomain}v2/users/resendConfirmEmail`);
    return response.parsedBody;
};

export const getUsersConfirmEmail = async (token: string) => {
    const response = await get(
        `${config.goDomain}v2/users/confirmEmail?token=${token}`
    );
    return response.parsedBody;
};

export const updateUser = async (user: User): Promise<User> => {
    const response = await put<{ data: BackendUser }>(
        `${config.goDomain}v2/users/me`,
        userToBackendUserUpdate(user)
    );
    if (response?.parsedBody?.data) {
        const updatedUser = backendUserToCurrentUser(response.parsedBody.data);
        return updatedUser;
    }
    throw new ApiError(response);
};

export const getUserSettings = async (): Promise<UserSettings> => {
    const response = await get<{ data: BackendUserSetting[] }>(
        `${config.goDomain}v2/users/me/settings`
    );
    if (response?.parsedBody?.data) {
        return backendUserSettingsToUserSettings(response?.parsedBody?.data);
    }
    throw new ApiError(response);
};
export const updateUserSettings = async (settings: UserSettings) => {
    const response = await put(
        `${config.goDomain}v2/users/me/settings`,
        userSettingsToBackendUserSettings(settings)
    );

    if (response?.parsedBody?.data) {
        return backendUserSettingsToUserSettings(response?.parsedBody?.data);
    }
    throw new ApiError(response);
};

export const updateUserAvatar = async (data: FormData): Promise<void> => {
    const response = await post(`${config.goDomain}v2/users/me/avatar`, data);
    if (response) {
        return;
    }
    throw new ApiError(response);
};

export const updateUserPassword = async (
    data: UserPassword
): Promise<CurrentUser> => {
    const response = await post<{ data: BackendUser }>(
        `${config.goDomain}v2/users/me/password`,
        userpasswordToBackendUserPassword(data)
    );
    if (response?.parsedBody?.data) {
        return backendUserToCurrentUser(response.parsedBody.data);
    }
    throw new ApiError(response);
};

export const signup = async (signupRequest: SignupRequest) => {
    const response = await post(
        `${config.goDomain}v2/users/signup`,
        signupRequestToBackendSignupRequest(signupRequest)
    );
    return response.parsedBody;
};

export const getOnboarding = async (): Promise<OnboardingStatus> => {
    const response = await get<OnboardingStatus>(
        `${config.goDomain}v2/users/onboarding`
    );
    if (response?.parsedBody) {
        return response.parsedBody;
    }
    throw new ApiError(response);
};

export const dismissOnboardingStep = async (
    step: OnboardingStep
): Promise<void> => {
    const response = await post<void>(
        `${config.goDomain}v2/users/onboarding/${step}/dismiss`
    );
    return response.parsedBody;
};

export const markOnboardingStepAsComplete = async (
    step: OnboardingStep
): Promise<void> => {
    await post<void>(
        `${config.goDomain}v2/users/onboarding/${step}/markComplete`
    );
};

export const completeOnboardingStep = async (
    step: OnboardingStep
): Promise<void> => {
    await post<void>(`${config.goDomain}v2/users/onboarding/${step}/complete`);
};
