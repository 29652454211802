import styled, { css } from 'styled-components/macro';

/* eslint-disable react/jsx-props-no-spreading */
export enum HeadingSize {
    XLARGE,
    LARGE,
    MEDIUM,
    SMALL,
    XSMALL,
}

const XLARGE = css`
    font-size: 36px;
    font-weight: 500;
    line-height: 38px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-weight: 500;
    }
`;

const LARGE = css`
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-weight: 500;
    }
`;

const MEDIUM = css`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-weight: 500;
    }
`;

const SMALL = css`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-weight: 500;
    }
`;
const XSMALL = css`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-weight: 500;
    }
`;

interface HeadingProps {
    size?: HeadingSize;
}

const StyledHeading = styled.div<HeadingProps>`
    ${({ size }) => {
        switch (size) {
            case HeadingSize.XLARGE:
                return XLARGE;
            case HeadingSize.LARGE:
                return LARGE;
            case HeadingSize.MEDIUM:
                return MEDIUM;
            case HeadingSize.SMALL:
                return SMALL;
            case HeadingSize.XSMALL:
                return XSMALL;
        }
        return '';
    }}
`;
type Props = HeadingProps & React.HTMLAttributes<HTMLDivElement>;

const Heading = ({ size = HeadingSize.LARGE, children, ...rest }: Props) => (
    <StyledHeading role="heading" size={size} {...rest}>
        {children}
    </StyledHeading>
);

export const StyleHeading = styled.div`
    text-align: right;
`;

export default Heading;
