import { User } from 'api/users/types';

/**
 * Identifies a user in Hotjar and links them to the current session.
 */
export const identifyUser = (user: User) => {
    try {
        if ((window as any).hj) {
            (window as any).hj('identify', user.id, {
                userId: user.id,
            });
        }
    } catch (e) {
        // hotjar is likely blocked by an adblocker
    }
};
