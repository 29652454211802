import Divider from 'components/Divider';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { useTranslation } from 'react-i18next';

import { List } from './StyledNextSteps';

type Props = {
    /** If empty, nothing will be displayed. */
    listItems: string[];
};

/**
 * Shows a bullet list of what will happen once the user confirms. Does not display anything for new purchases.
 */
const NextSteps = ({ listItems }: Props) => {
    const { t } = useTranslation('settings');

    if (listItems.length === 0) {
        return null;
    }

    return (
        <>
            <Divider />

            <Heading size={HeadingSize.SMALL}>
                {t('api.subscriptionChange.nextSteps.title')}
            </Heading>
            <List>
                {listItems.map((item) => (
                    <li key={item}>
                        <Text>{item}</Text>
                    </li>
                ))}
            </List>
        </>
    );
};

export default NextSteps;
