import Cart from 'components/Cart';
import { createContext, useContext } from 'react';
import { WithChildren } from 'types';

import { UseCartOptions } from './types';
import { useCart } from './useCart';

const CartContext = createContext<UseCartOptions | null>(null);

CartContext.displayName = 'CartContext';

export const useCartContext = (): UseCartOptions => {
    return useContext(CartContext) as UseCartOptions;
};

export const CartProvider = ({ children }: WithChildren) => {
    const cartMethods = useCart();
    return (
        <CartContext.Provider value={{ ...cartMethods }}>
            <>
                <Cart />
                {children}
            </>
        </CartContext.Provider>
    );
};
