import { Integration } from 'api/subscriptions/types';
import {
    GroupBase,
    SingleValueProps,
    components,
} from 'components/FormElements/Select';
import Text, { TextSize } from 'components/Typography/Text';
import { Price } from 'constants/integrations';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Currency } from 'types/UserData';

const { SingleValue } = components;

const OptionContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Wrapper = styled.div`
    padding-left: ${({ theme }) => theme.space.s3};
    position: absolute;
    width: 100%;
`;

export type IntegrationOptionType = {
    label: string;
    integration: Integration;
    cost: Price;
    currency: Currency;
};

/**
 * The currently selected value of the integration Select component.
 *
 * Displays the integration and its monthly cost.
 */
const IntegrationValue = <
    T extends IntegrationOptionType,
    GroupType extends GroupBase<T>
>({
    children,
    ...props
}: SingleValueProps<T, false, GroupType>) => {
    const { i18n, t } = useTranslation('settings');
    const monthlyCost = props.data.cost[props.data.currency];

    const formattedCost = formatCost(
        monthlyCost,
        i18n.languages[0],
        props.data.currency
    );

    return (
        <Wrapper>
            <SingleValue {...props}>
                <OptionContent>
                    <Text>
                        {children === 'Other'
                            ? t('api.ownIntegration')
                            : children}
                    </Text>
                    <Text size={TextSize.MEDIUM}>
                        {t(
                            'api.subscriptionChange.integrationSelect.costPerMonth',
                            {
                                cost: formattedCost,
                            }
                        )}
                    </Text>
                </OptionContent>
            </SingleValue>
        </Wrapper>
    );
};

export default IntegrationValue;
