import { IconButton } from 'components/Buttons';
import styled from 'styled-components/macro';

export const ModalBackground = styled.div`
    align-items: center;
    animation: fade 0.2s ease forwards;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    cursor: default;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        align-items: flex-end;
    }
    @keyframes fade {
        from {
            background-color: rgba(0, 0, 0, 0);
        }
        to {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
`;

export const ModalContainer = styled.div<{ width?: string }>`
    animation: slide 0.2s ease forwards;
    background-color: white;
    max-height: calc(100vh - 40px);
    max-width: ${({ width }) => width || '100%'};
    overflow: hidden;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        align-items: flex-end;
    }
    @keyframes slide {
        from {
            transform: translateY(-20px);
        }
        to {
            transform: translateY(0);
        }
    }
`;
export const CloseButton = styled(IconButton)`
    position: absolute;
    right: 0;
`;
export const ModalContentWrapper = styled.div<{ warning?: boolean }>`
    box-shadow: ${({ warning, theme }) =>
        warning ? `inset 0 16px 0 0 ${theme.colors.yellow200}` : 'none'};
    display: grid;
    grid-template-areas:
        'header'
        'content'
        'footer';
    grid-template-rows: auto 1fr auto;
    max-height: inherit;
    max-width: inherit;
    overflow: auto;
    > :only-child {
        grid-area: content;
    }
`;
