import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

interface StyledTextAreaProps {
    hasError: boolean;
}

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
    border: ${({ hasError, theme }) =>
        hasError
            ? `1px solid ${theme.colors.red100}`
            : `1px solid ${theme.colors.grey300}`};
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
    min-height: 80px;
    padding: ${({ theme }) => `${theme.space.s2} ${theme.space.s3}`};
    transition: all 0.12s ease;
    :read-only {
        background-color: ${({ theme }) => theme.colors.grey500};
        color: ${({ theme }) => theme.colors.grey100};
    }
    :focus {
        ${({ hasError, theme }) =>
            hasError
                ? css`
                      border: 1px solid ${theme.colors.red100};
                      box-shadow: 0 0 0 1px ${theme.colors.red100};
                  `
                : css`
                      border: 1px solid ${theme.colors.black};
                      box-shadow: 0 0 0 1px ${theme.colors.black};
                  `};
        outline: none;
    }
    width: 100%;
`;

export const ErrorMessage = styled(Text)`
    color: ${({ theme }) => theme.colors.red100};
    margin-top: ${({ theme }) => theme.space.s1};
`;

export const TopText = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.space.s2};
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s1};
`;
