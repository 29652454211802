import Heading, { HeadingSize } from 'components/Typography/Heading';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTeam } from 'hooks/Queries';
import { Duration } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    BreakdownText,
    DiscountText,
    OldPrice,
    StyledBreakdownItem,
} from './StyledCostBreakdown';

export interface Props {
    name: string;
    /** If discounted */
    originalMonthlyCost?: number;
    monthlyCost: number;
    /** Must have conversionAccuracy: 'longterm' */
    duration: Duration;
}
const BreakdownItem = ({
    name,
    originalMonthlyCost,
    monthlyCost,
    duration,
}: Props) => {
    const { i18n, t } = useTranslation('settings');
    const { team } = useTeam();
    const currency = team?.currency;
    const shiftedDuration = duration.shiftTo('months', 'days', 'hours');

    const decimals = currency === 'EUR' ? 1 : 0;

    const cost = monthlyCost * shiftedDuration.as('months');
    const daysLeft = Math.round(shiftedDuration.as('days'));

    const intervalCost = useMemo(() => {
        if (!currency) {
            return undefined;
        }

        if (shiftedDuration.as('months') < 1) {
            const dailyCost = cost / daysLeft;

            return formatCost(dailyCost, i18n.language, currency, 2, 2);
        }
        return formatCost(monthlyCost, i18n.language, currency, decimals);
    }, [monthlyCost, cost, shiftedDuration, decimals, i18n, currency]);

    const originalIntervalCost = useMemo(() => {
        if (originalMonthlyCost === undefined || !currency) {
            return undefined;
        }
        const originalCost = originalMonthlyCost * shiftedDuration.as('months');
        if (shiftedDuration.as('months') < 1) {
            const dailyOriginalCost = originalCost / daysLeft;

            return formatCost(dailyOriginalCost, i18n.language, currency, 2, 2);
        }
        return formatCost(
            originalMonthlyCost,
            i18n.language,
            currency,
            decimals
        );
    }, [originalMonthlyCost, currency, i18n, decimals, shiftedDuration]);

    const length = useMemo(() => {
        const daysLeft = Math.round(shiftedDuration.as('days'));
        if (shiftedDuration.as('months') < 1) {
            return t('api.subscriptionChange.costBreakdown.days', {
                count: daysLeft,
            });
        }
        if (shiftedDuration.days > 0) {
            return `${t('api.subscriptionChange.costBreakdown.months', {
                count: shiftedDuration.months,
            })}, ${t('api.subscriptionChange.costBreakdown.days', {
                count: shiftedDuration.days,
            })}`;
        }
        return t('api.subscriptionChange.costBreakdown.months', {
            count: shiftedDuration.months,
        });
    }, [shiftedDuration, t]);

    return (
        <StyledBreakdownItem>
            <Heading size={HeadingSize.SMALL}>{name}</Heading>
            <Heading size={HeadingSize.SMALL}>
                {formatCost(cost, i18n.language, currency, 2, 2)}
            </Heading>

            <BreakdownText>
                {originalIntervalCost !== undefined && (
                    <OldPrice>{originalIntervalCost}</OldPrice>
                )}
                {intervalCost} × {length}
            </BreakdownText>
            {originalMonthlyCost !== undefined && (
                <DiscountText>
                    {t('api.subscriptionChange.costBreakdown.discount', {
                        percentage: Intl.NumberFormat(i18n.language, {
                            style: 'percent',
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        }).format(
                            (originalMonthlyCost - monthlyCost) /
                                originalMonthlyCost
                        ),
                    })}
                </DiscountText>
            )}
        </StyledBreakdownItem>
    );
};

export default BreakdownItem;
