import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import useDetectGoogleTranslate from '../../hooks/DetectGoogleTranslate';
import Notification from './Notification';

const NotificationWrapper = styled.div`
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
`;

const GoogleTranslateWarning = (props) => {
    const isTranslated = useDetectGoogleTranslate();

    const [show, setShow] = useState(isTranslated);

    // Show the notification when the user translates the page, but allow them to close the notification.
    useEffect(() => {
        setShow(isTranslated);
    }, [isTranslated]);

    return (
        <NotificationWrapper>
            <Notification
                showNotification={show}
                handleClose={() => setShow(false)}
                variant="warning"
            >
                {props.t('notification.googleTranslateDetected')}
            </Notification>
        </NotificationWrapper>
    );
};

GoogleTranslateWarning.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation()(GoogleTranslateWarning);
