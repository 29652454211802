import Slider from 'components/Slider';
import styled from 'styled-components/macro';

export const CartSummary = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Consignments = styled.div`
    flex: 1;
    margin-top: ${({ theme }) => theme.space.s4};
    overflow-y: auto;
`;

export const SpinnerWrapper = styled.div`
    display: grid;
    height: 100%;
    place-items: center;
    position: absolute;
    width: 100%;
`;

export const CartUserSelect = styled.div`
    background-color: ${({ theme }) => theme.colors.cardboardBrown200};
    color: ${({ theme }) => theme.colors.black};
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    padding: ${({ theme }) => theme.space.s4};
`;

export const Header = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    padding: ${({ theme }) => theme.space.s6};
    text-align: center;
    width: 100%;
`;

export const Footer = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border-top: 1px solid ${({ theme }) => theme.colors.grey400};
    box-shadow: 0px -1px 9px 2px rgb(0 0 0 / 5%);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: ${({ theme }) => theme.space.s2};
    padding: ${({ theme }) => theme.space.s6};
    width: 100%;
`;

export const CartSlider = styled(Slider)`
    background: ${({ theme }) => theme.colors.grey500};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 0;
`;
