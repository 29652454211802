import { Logo } from 'assets/sendify';
import styled from 'styled-components/macro';

interface HeaderContainerProps {
    actions?: boolean;
    active: boolean;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
    display: flex;
    height: 52px;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1280px;
    position: relative;
    text-align: ${({ actions }) => (actions ? 'center' : 'left')};
    transition: all 0.2s ease;
    width: 100%;
    z-index: 4;

    &:after {
        background: ${({ theme }) => theme.colors.black};
        bottom: 0;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        transition: all 0.3s ease-in-out 0.12s;
        width: ${({ active }) => (active ? '100%' : '')};
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.space.s5};
`;

export const EnvironmentBanner = styled.div<{
    staging: boolean;
}>`
    align-items: center;
    color: ${({ staging, theme }) =>
        staging ? theme.colors.cardboardBrown100 : theme.colors.blue200};
    display: grid;
    font-variant: small-caps;
    grid-column-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: auto 1fr;
    margin-right: ${({ theme }) => theme.space.s6};
    /* The width is set to match the logo. */
    width: 90px;
    svg {
        height: 24px;
        width: 24px;
    }
`;

export const StyledLogo = styled(Logo)`
    fill: ${({ theme }) => theme.colors.black};
    width: 90px;
`;
