import { PropsWithChildren, forwardRef } from 'react';

import { HiddenInput, Label, Radio, RadioText } from './StyledRadioButton';

interface Props {
    label?: string;
}
export type RadioButtonProps = PropsWithChildren<Props> &
    React.InputHTMLAttributes<HTMLInputElement>;

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
    (
        {
            id,
            label,
            checked,
            disabled,
            readOnly,
            name,
            onChange,
            required,
            onFocus,
            children,
            onBlur,
            className,
            value,

            ...rest
        }: RadioButtonProps,
        ref?: any
    ) => {
        const interactive = !(readOnly || disabled);
        return (
            <Label interactive={interactive} htmlFor={id} className={className}>
                <HiddenInput
                    type="radio"
                    id={id}
                    data-testid={`${value}-input`}
                    ref={ref}
                    aria-checked={checked}
                    checked={checked}
                    disabled={disabled}
                    readOnly
                    name={name}
                    onChange={onChange}
                    required={required}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                />
                <Radio interactive={interactive} checked={checked} />
                {!!label && (
                    <RadioText interactive={interactive}>{label}</RadioText>
                )}
                {children}
            </Label>
        );
    }
);

export default RadioButton;
