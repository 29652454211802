import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

const Card = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    ${({ theme, withBorder, customBorder }) =>
        withBorder
            ? css`
                  border: 1px solid ${theme.colors.grey400};
              `
            : customBorder || ''};
    bottom: ${({ bottom }) => bottom || ''};
    box-shadow: ${({ withShadow }) =>
        withShadow
            ? '0px 4px 16px rgba(0, 0, 0, 0.07), 0px 40px 40px -40px rgba(0, 0, 0, 0.15);'
            : ''};
    box-sizing: border-box;
    height: ${({ height }) => height || 'auto'};
    margin-bottom: ${({ widthMarginBottom }) =>
        widthMarginBottom ? '32px;' : ''};
    ${({ padding }) =>
        padding
            ? css`
                  padding: ${padding};
              `
            : ''};
    max-width: ${({ maxWidth }) => maxWidth || ''};
    min-width: ${({ minWidth }) => minWidth || ''};
    overflow: ${({ overflowVisible, overflow }) =>
        overflow || (overflowVisible ? 'visible' : '')};
    ${({ withPadding }) => (withPadding ? 'padding: 24px;' : '')};
    ${({ withSmallPadding }) => (withSmallPadding ? 'padding: 8px 12px ' : '')}
    ${({ withLargePadding }) => (withLargePadding ? 'padding: 32px;' : '')}
    ${({ withPaddingVertical }) =>
        withPaddingVertical ? 'padding: 24px 0;' : ''}
    ${({ withPaddingHorizontal }) =>
        withPaddingHorizontal ? 'padding: 0 24px;' : ''}
    position: relative;
    ${({ withBackground, theme }) =>
        withBackground
            ? css`
                  background-color: ${theme.colors.grey500};
              `
            : ''}
    ${({ widthMarginBottom }) =>
        widthMarginBottom ? 'margin-bottom: 32px;' : ''}
  ${({ padding }) =>
        padding
            ? css`
                  padding: ${padding};
              `
            : ''};
    transition: all 0.2s ease-in-out;
    width: ${({ width }) => width || 'inherit'};

    z-index: ${({ zIndex }) => zIndex || ''};
    ${({ sticky }) =>
        sticky
            ? css`
                  position: sticky;
              `
            : ''}

    ${({ isFooter }) =>
        isFooter
            ? css`
                  align-items: center;
                  border-top: 1px solid #efefef;
                  bottom: 0;
                  display: flex;
                  justify-content: center;
                  left: 200px;
                  position: fixed;
                  width: calc(100% - 200px);
                  > div {
                      display: flex;
                      justify-content: space-between;
                      max-width: 1280px;
                      width: 100%;
                  }
              `
            : ''}
`;

const ContentCard = (props) => {
    return (
        <Card
            className={props.className}
            height={props.height}
            withBorder={props.withBorder}
            withPadding={props.withPadding}
            withSmallPadding={props.withSmallPadding}
            withLargePadding={props.withLargePadding}
            widthMarginBottom={props.widthMarginBottom}
            withShadow={props.withShadow}
            withPaddingHorizontal={props.withPaddingHorizontal}
            withPaddingVertical={props.withPaddingVertical}
            isFooter={props.isFooter}
            withBackground={props.withBackground}
            zIndex={props.zIndex}
            backgroundColor={props.backgroundColor}
            highlight={props.highlight}
            maxWidth={props.maxWidth}
            minWidth={props.minWidth}
            width={props.width}
            hoverShadow={props.hoverShadow}
            bottom={props.bottom}
            sticky={props.sticky}
            overflowVisible={props.overflowVisible}
            overflow={props.overflow}
            customBorder={props.customBorder}
            padding={props.padding}
            data-cy={props['data-cy']}
        >
            {props.children}
        </Card>
    );
};

ContentCard.propTypes = {
    withBorder: PropTypes.bool,
    customBorder: PropTypes.string,
    withShadow: PropTypes.bool,
    /** to have 20px padding inside */
    withPadding: PropTypes.bool,
    withSmallPadding: PropTypes.bool,
    withLargePadding: PropTypes.bool,
    widthMarginBottom: PropTypes.bool,
    withPaddingHorizontal: PropTypes.bool,
    withPaddingVertical: PropTypes.bool,
    padding: PropTypes.string,
    withBackground: PropTypes.bool,
    /** custom style object */
    children: PropTypes.node,
    height: PropTypes.string,
    className: PropTypes.string,
    isFooter: PropTypes.bool,
    zIndex: PropTypes.string,
    backgroundColor: PropTypes.string,
    /** to have a dark 2px solid border */
    highlight: PropTypes.bool,
    maxWidth: PropTypes.string,
    minWidth: PropTypes.string,
    width: PropTypes.string,
    hoverShadow: PropTypes.bool,
    sticky: PropTypes.bool,
    bottom: PropTypes.string,
    overflowVisible: PropTypes.bool,
    overflow: PropTypes.string,
    'data-cy': PropTypes.string,
};

ContentCard.defaultProps = {
    withBorder: false,
};
export default ContentCard;
