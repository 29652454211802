import UserDataHelper from './UserDataHelper';

/**
 * Formats the cost to two fraction digits and the proper currency
 * @param cost
 * @param locale if undefined, the browser's default locale is used
 * @param currency if undefined, the user's default currency is used
 * @param minimumFractionDigits
 * @param maximumFractionDigits
 */
export const formatCost = (
    cost: number,
    locale?: string,
    currency?: string,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
): string | undefined => {
    const formatCurrency = currency || UserDataHelper.getCurrency();
    if (!formatCurrency) {
        throw new Error('No currency found');
    }

    return Intl.NumberFormat(locale, {
        style: 'currency',
        currency: formatCurrency,
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(cost);
};

export const roundUpCost = (cost: number): number => {
    // Math.trunc removes the decimal part which makes
    // 10.5000001 => 105.0000001 => 105 => 10.5
    // instead of 10.5000001 => 105.0000001 => 106 => 10.6
    return UserDataHelper.getCurrency() === 'EUR'
        ? Math.ceil(Math.trunc(cost * 10)) / 10
        : Math.ceil(cost);
};
