import styled from 'styled-components/macro';

export const HorizontalDivider = styled.hr<{ margin: boolean }>`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    border-left: none;
    border-right: none;
    border-top: none;
    margin: ${({ theme, margin }) => `${margin ? theme.space.s4 : 0} 0`};
`;

export const VerticalDivider = styled.span<{ margin: boolean }>`
    border-left: ${({ theme }) => `1px solid ${theme.colors.grey400}`};
    display: inline-block;
    height: 100%;
    margin: ${({ theme, margin }) => `0 ${margin ? theme.space.s3 : 0}`};
`;
