import { useQuery } from '@tanstack/react-query';
import { getHolidays } from 'api/holidays';
import { Alpha2Code } from 'i18n-iso-countries';

const useHolidays = (countryCode?: Alpha2Code, state?: string) => {
    const { data, ...rest } = useQuery<string[] | undefined>(
        ['holidays', countryCode, state],
        () => {
            if (!countryCode) {
                return undefined;
            }
            return getHolidays(countryCode, state);
        },
        {
            staleTime: 1000 * 60 * 60 * 24, // One day
        }
    );
    return { holidays: data, ...rest };
};

export default useHolidays;
