import * as Sentry from '@sentry/react';
import { chunkFailedRecently, setChunkFailed } from 'helpers/LazyLoadingHelper';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import UserDataHelper from '../../helpers/UserDataHelper';
import GeneralError from './GeneralError';
import StorageError from './StorageError';

interface State {
    hasError: boolean;
    shouldReload: boolean;
}
const chunkFailedMessage = /Loading chunk [\d]+ failed/;

/**
 * Error boundaries are not yet fully supported by hooks.
 */
class ErrorBoundary extends Component<any, State> {
    constructor(props: any) {
        super(props);

        this.state = { hasError: false, shouldReload: false };

        const { history } = this.props;

        // Clear the error state so children can be rendered again.
        history.listen(() => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        if (error?.message && chunkFailedMessage.test(error.message)) {
            // Our lazy loading failed, most likely due to browser caching.
            if (!chunkFailedRecently()) {
                setChunkFailed();
                // Reload the page to fetch the chunk anew.
                return { hasError: true, shouldReload: true };
            }
        }

        return { hasError: true, shouldReload: false };
    }

    componentDidCatch(error: Error) {
        if (this.state.shouldReload) {
            this.props.history.go(0);
        } else {
            Sentry.captureException(error);
        }
    }

    render() {
        if (this.state.shouldReload) {
            return null;
        }

        if (this.state.hasError) {
            // Error path
            return <GeneralError />;
        }

        if (!UserDataHelper.isStorageEnabled) {
            // Cookies not activated
            return <StorageError />;
        }

        // Normally, just render children
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
