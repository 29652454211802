import { Size } from 'components/Notifications/Notification';
import { Variant } from 'components/Notifications/types';
import styled, { css } from 'styled-components/macro';

const sizeOptions = {
    small: css`
        padding: ${({ theme }) => `${theme.space.s1} ${theme.space.s2}`};
    `,
    large: css`
        padding: ${({ theme }) => theme.space.s4};
    `,
};

const BaseNotification = css`
    color: ${({ theme }) => theme.colors.black};
    padding: ${({ theme }) => theme.space.s4};
`;

const Warning = css`
    ${BaseNotification};
    background: ${({ theme }) => theme.colors.yellow300};

    svg {
        color: ${({ theme }) => theme.colors.yellow100};
    }
`;

const Error = css`
    ${BaseNotification};
    background: ${({ theme }) => theme.colors.red300};

    svg {
        color: ${({ theme }) => theme.colors.red100};
    }
`;

const Success = css`
    ${BaseNotification};
    background: ${({ theme }) => theme.colors.green300};

    svg {
        color: ${({ theme }) => theme.colors.green100};
    }
`;

const Info = css`
    ${BaseNotification};
    background: ${({ theme }) => theme.colors.blue300};

    svg {
        color: ${({ theme }) => theme.colors.blue100};
    }
`;
const Banner = css`
    ${BaseNotification};
    background: ${({ theme }) => theme.colors.blue200};
    color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.space.s6};
`;
const Note = css<{ size: Size }>`
    ${BaseNotification};
    background: ${({ theme }) => theme.colors.grey500};
    ${({ size }) => sizeOptions[size]}
`;

const Tip = css`
    ${BaseNotification};
    background: ${({ theme }) => theme.colors.brown300};

    svg {
        color: ${({ theme }) => theme.colors.brown100};
    }
`;
export const Message = styled.div<{ justifyContent?: string }>`
    white-space: pre-wrap;
    width: 100%;
`;

const lineHeight = '20px';

export const IconWrapper = styled.div`
    align-self: start;
    // Normalize the icon size to fit the line height.
    height: ${lineHeight};
    width: ${lineHeight};

    svg {
        height: 100%;
        width: 100%;
    }
`;

export const Container = styled.div<{
    showNotification: boolean;
    variant: Variant;
    size: Size;
}>`
    display: ${({ showNotification }) =>
        showNotification === true ? 'flex' : 'none'};
    justify-content: center;
    ${({ variant }) => {
        switch (variant) {
            case 'warning':
                return Warning;
            case 'error':
                return Error;
            case 'info':
                return Info;
            case 'success':
                return Success;
            case 'banner':
                return Banner;
            case 'note':
                return Note;
            case 'tip':
                return Tip;
        }
    }}
`;

export const Content = styled.div<{ variant: Variant }>`
    align-items: center;
    display: flex;
    font-size: ${({ variant }) => (variant !== 'banner' ? '12px' : '')};
    font-weight: 400;
    gap: ${({ theme }) => theme.space.s2};
    line-height: ${lineHeight};
    max-width: 1280px;
    text-align: left;
    width: 100%;
`;
