import { ButtonWrapper, StyledButton } from './ButtonWrapper';

interface Option {
    value: string | number;
    onSelect?: (value: string | number) => void;
    title: string;
}

interface Props {
    onSelect: (order: any) => void;
    value: any;
    options: Option[];
    fullWidth?: boolean;
}

const ToggleButton = ({ onSelect, value, options, fullWidth }: Props) => {
    return (
        <ButtonWrapper>
            {options.map((option) => (
                <StyledButton
                    key={option.value}
                    fullWidth={fullWidth}
                    variant="secondary"
                    size="small"
                    onClick={() => {
                        if (option.onSelect) {
                            option.onSelect(value);
                        }
                        onSelect(option.value);
                    }}
                    active={value === option.value}
                >
                    {option.title}
                </StyledButton>
            ))}
        </ButtonWrapper>
    );
};

export default ToggleButton;
