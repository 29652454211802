import {
    BackendCustomerInformation,
    BackendPhase,
    CustomerInformation,
    Integration,
    Phase,
    Tier,
} from 'api/subscriptions/types';
import { DateTime } from 'luxon';

const toPhase = <T extends Tier>(payload: BackendPhase<T>): Phase<T> => {
    return {
        tier: payload.tier,
        trialEnd: payload.trialEnd
            ? DateTime.fromISO(payload.trialEnd)
            : undefined,
        integrations: payload?.integrations?.map((info) => {
            let parsed: Integration = 'Other';
            try {
                parsed = parseIntegration(info.integration);
            } catch (e) {
                parsed = 'Other';
            }
            return { integration: parsed, quantity: info.quantity };
        }),
        end: payload.end ? DateTime.fromISO(payload.end) : undefined,
        start: payload.start ? DateTime.fromISO(payload.start) : undefined,
    };
};

export const toCustomerInformation = (
    payload: BackendCustomerInformation
): CustomerInformation => {
    const priceTierInfo = payload.priceTierSubscription;
    const apiTierInfo = payload.apiSubscription;

    return {
        apiSubscription: {
            importedOrdersBooked: apiTierInfo.importedOrdersBooked,
            canceled: apiTierInfo.canceled,
            hasIntegrated: apiTierInfo.hasIntegrated,
            integrations: apiTierInfo.integrations?.map((i) => {
                let parsed: Integration = 'Other';
                try {
                    parsed = parseIntegration(i.integration);
                } catch (e) {
                    parsed = 'Other';
                }
                return { name: i.name, integration: parsed, id: i.id };
            }),
            hasUsedTrial: apiTierInfo.hasUsedTrial,
            current: apiTierInfo.current
                ? toPhase(apiTierInfo.current)
                : undefined,
            next: apiTierInfo?.next ? toPhase(apiTierInfo.next) : undefined,
            currency: apiTierInfo.currency,
            interval: apiTierInfo.interval,
        },

        priceTierSubscription: {
            hasUsedTrial: priceTierInfo.hasUsedTrial || false,
            canceled: priceTierInfo.canceled,
            current: toPhase(priceTierInfo.current),
            next: priceTierInfo?.next ? toPhase(priceTierInfo.next) : undefined,
            currency: priceTierInfo.currency,
            interval: priceTierInfo.interval,
        },
        subscriptionProducts: payload.subscriptionProducts,
        subscriptions: payload.subscriptions,
    };
};

/**
 * Parses a string, as stored in the database, to one of our integrations.
 * @returns the parsed integration
 * @throws an error if no integration is found
 */
export const parseIntegration = (raw: string): Integration => {
    switch (raw) {
        case 'OWNINTEGRATION':
            return 'Other';
        case 'VISMAEECONOMY':
            return 'Visma E-Economy';
        case 'VISMAADMIN2000':
            return 'Visma Admin 2000';
        case 'FORTNOX':
            return 'Fortnox';
        case 'SHOPIFY':
            return 'Shopify';
        case 'SPECTER':
            return 'Specter';
        case 'WOOCOMMERCE':
            return 'WooCommerce';
        case 'ONGOING':
            return 'Ongoing';
        case 'PROSMART':
            return 'Prosmart';
        case 'PYRAMID':
            return 'Pyramid';
        case 'ADOBE_COMMERCE_MAGENTO':
            return 'Adobe Commerce (Magento)';
        case 'DYNAMICS_365_BUSINESS_CENTRAL':
            return 'Dynamics 365 Business Central';
        case 'WINBAS':
            return 'Winbas';
        case 'MONITOR':
            return 'Monitor';
        default:
            throw new Error('no integration found');
    }
};

export const toBackendIntegration = (integration: Integration): string => {
    switch (integration) {
        case 'Other':
            return 'OWNINTEGRATION';
        case 'Visma E-Economy':
            return 'VISMAEECONOMY';
        case 'Visma Admin 2000':
            return 'VISMAADMIN2000';
        case 'Fortnox':
            return 'FORTNOX';
        case 'Shopify':
            return 'SHOPIFY';
        case 'Specter':
            return 'SPECTER';
        case 'WooCommerce':
            return 'WOOCOMMERCE';
        case 'Ongoing':
            return 'ONGOING';
        case 'Prosmart':
            return 'PROSMART';
        case 'Pyramid':
            return 'PYRAMID';
        case 'Adobe Commerce (Magento)':
            return 'ADOBE_COMMERCE_MAGENTO';
        case 'Dynamics 365 Business Central':
            return 'DYNAMICS_365_BUSINESS_CENTRAL';
        case 'Winbas':
            return 'WINBAS';
        case 'Monitor':
            return 'MONITOR';
        default:
            throw new Error('no integration found');
    }
};
