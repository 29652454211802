import { Button } from 'components/Buttons';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Paragraph from 'components/Typography/Paragraph';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ContentCard from '../../components/Layout/ContentCard';
import DEPRECATEDFlexContainer from '../../components/Layout/DEPRECATEDFlexContainer';
import DEPRECATEDSpacer, {
    SpaceSize,
} from '../../components/Spacings/DEPRECATEDSpacer';
import { browsers } from '../../helpers/BrowserHelper';
import MainHeader from '../MainHeader';

const ErrorContent = styled.div`
    margin-top: 20vh;
    max-width: 700px;
    padding: 0 ${({ theme }) => theme.space.s16};
    width: 100%;
`;

const Link = styled.a`
    text-decoration-color: ${({ theme }) => theme.colors.black};
`;

const Title = styled(Heading)`
    margin-bottom: ${({ theme }) => theme.space.s6};
    text-align: center;
`;

const Description = styled(Paragraph)`
    margin-bottom: ${({ theme }) => theme.space.s6};
    text-align: center;
`;

const BrowserLinkContent = styled.div`
    color: ${({ theme }) => theme.colors.old100};
    padding: ${({ theme }) => theme.space.s6};
    text-align: center;
    text-transform: capitalize;
    &:hover {
        background-color: ${({ theme }) => theme.colors.grey400};
    }
`;

const BrowserLink = ({ browserName, t }) => {
    const formattedBrowserName = browserName.toLowerCase().replace(/ /g, '_');
    return (
        <Link
            href={t(`errors.storage.links.${formattedBrowserName}`)}
            target="_blank"
            rel="noreferrer noopener"
        >
            <BrowserLinkContent>
                <div>
                    <img
                        alt={browserName}
                        src={`/misc/browser-logos/${formattedBrowserName}.svg`}
                        width="40"
                        height="40"
                    />
                </div>
                {browserName}
            </BrowserLinkContent>
        </Link>
    );
};

BrowserLink.propTypes = {
    browserName: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

const StorageError = ({ t }) => (
    <>
        <MainHeader />
        <DEPRECATEDSpacer padding={SpaceSize.X_LARGE}>
            <DEPRECATEDFlexContainer
                direction="row"
                justifyContent="center"
                flexWrap="wrap"
            >
                <ErrorContent>
                    <DEPRECATEDSpacer marginBottom={SpaceSize.LARGE}>
                        <ContentCard withShadow maxWidth="700px">
                            <DEPRECATEDSpacer padding={SpaceSize.LARGE}>
                                <Title size={HeadingSize.XLARGE}>
                                    {t('errors.storage.title')}
                                </Title>
                                <Description>
                                    {t('errors.storage.subTitle')}
                                </Description>
                                <DEPRECATEDFlexContainer
                                    direction="row"
                                    justifyContent="center"
                                >
                                    <Button
                                        variant="primary"
                                        onClick={() => window.location.reload()}
                                    >
                                        {t('button.reload')}
                                    </Button>
                                </DEPRECATEDFlexContainer>
                            </DEPRECATEDSpacer>
                        </ContentCard>
                    </DEPRECATEDSpacer>
                    <Description>
                        {t('errors.storage.browserLinkDescription')}
                    </Description>
                    <DEPRECATEDFlexContainer
                        direction="row"
                        justifyContent="space-evenly"
                        flexWrap="wrap"
                    >
                        <BrowserLink browserName={browsers.chrome} t={t} />
                        <BrowserLink browserName={browsers.safari} t={t} />
                        <BrowserLink browserName={browsers.firefox} t={t} />
                        <BrowserLink browserName={browsers.edge} t={t} />
                    </DEPRECATEDFlexContainer>
                </ErrorContent>
            </DEPRECATEDFlexContainer>
        </DEPRECATEDSpacer>
    </>
);

StorageError.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({ language: PropTypes.string }).isRequired,
};

export default withTranslation()(StorageError);
