/* eslint-disable react/jsx-props-no-spreading */

/**
 * A middle-layer for importing images and adding any necessary
 * accessability attributes.
 */
import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RawArrowRight } from './arrowRight.svg';
import { ReactComponent as RawEmail } from './email.svg';
import { ReactComponent as RawEmptyAddressBook } from './emptyAddressBookImage.svg';
import { ReactComponent as RawEmptyFavoritePackages } from './emptyFavoritePackagesImage.svg';
import { ReactComponent as RawEmptyShipmentList } from './emptyShipmentListImage.svg';
import { ReactComponent as RawExpiredEmail } from './expired-email.svg';
import { ReactComponent as RawStats } from './insights.svg';
import { ReactComponent as RawMaintenance } from './maintenance.svg';
import { ReactComponent as RawNoDoc } from './noDocumentImage.svg';
import RawParcel from './parcel.png';
import RawRecommendationDE from './recommendationDE.png';
import RawRecommendationEN from './recommendationEN.png';
import RawRecommendationSE from './recommendationSE.png';
import { ReactComponent as RawSendifyLogo } from './sendifyLogo.svg';
import { ReactComponent as RawVerifiedEmail } from './verified-email.svg';

export interface ImageProps
    extends React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {
    size?: 'regular' | 'large';
}

export const SendifyLogo = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawSendifyLogo
            {...props}
            role="img"
            aria-label={t('assets:images.sendifyLogo')}
        />
    );
};

export const Email = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawEmail
            {...props}
            role="img"
            aria-label={t('assets:images.verifyEmail')}
        />
    );
};
export const ExpiredEmail = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawExpiredEmail
            {...props}
            role="img"
            aria-label={t('assets:images.expiredEmail')}
        />
    );
};
export const Maintenance = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawMaintenance
            {...props}
            role="img"
            aria-label={t('assets:images.maintenance')}
        />
    );
};
export const Insights = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawStats
            {...props}
            role="img"
            aria-label={t('assets:images.insights')}
        />
    );
};
export const VerifiedEmail = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawVerifiedEmail
            {...props}
            role="img"
            aria-label={t('assets:images.verifiedEmail')}
        />
    );
};

export const NoDoc = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawNoDoc {...props} role="img" aria-label={t('assets:images.noDoc')} />
    );
};
export const EmptyAddressBook = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawEmptyAddressBook
            {...props}
            role="img"
            aria-label={t('assets:images.emptyAddressBook')}
        />
    );
};

export const EmptyFavoritePackages = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawEmptyFavoritePackages
            {...props}
            role="img"
            aria-label={t('assets:images.emptyFavoritePackages')}
        />
    );
};

export const EmptyShipmentList = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawEmptyShipmentList
            {...props}
            role="img"
            aria-label={t('assets:images.emptyShipmentList')}
        />
    );
};

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawArrowRight
            {...props}
            role="img"
            aria-label={t('assets:images.arrowRight')}
        />
    );
};

export const Parcel = (props: ImageProps) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawParcel}
            aria-label={t('assets:images.parcel')}
        />
    );
};

export const RecommendationEN = (props: ImageProps) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRecommendationEN}
            aria-label={t('assets:images.recommendationEN')}
        />
    );
};

export const RecommendationSE = (props: ImageProps) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRecommendationSE}
            aria-label={t('assets:images.recommendationSE')}
        />
    );
};

export const RecommendationDE = (props: ImageProps) => {
    const { t } = useTranslation('assets');
    return (
        <img
            {...props}
            src={RawRecommendationDE}
            aria-label={t('assets:images.recommendationDE')}
        />
    );
};
