import {
    BadgeWrapper,
    StyledCaptionBadge,
    StyledTextBadge,
} from 'components/Badge/StyledTextBadge';
import { CaptionSize } from 'components/Typography/Caption';
import { TextSize } from 'components/Typography/Text';
import { ReactNode } from 'react';

export type TextType = 'text' | 'caption';
export type BadgeVariant =
    | 'success'
    | 'beta'
    | 'brand'
    | 'primary'
    | 'secondary'
    | 'danger';

interface BadgeProps {
    textType?: TextType;
    variant?: BadgeVariant;
    divider?: boolean;
    children: ReactNode;
}

type Props = BadgeProps & React.HTMLAttributes<HTMLParagraphElement>;

const Badge = ({
    divider,
    textType = 'caption',
    variant = 'primary',
    children,
    ...rest
}: Props) => (
    <BadgeWrapper divider={divider} {...rest}>
        {textType === 'text' && (
            <StyledTextBadge
                size={TextSize.MEDIUM}
                divider={divider}
                variant={variant}
            >
                {children}
            </StyledTextBadge>
        )}
        {textType === 'caption' && (
            <StyledCaptionBadge
                size={CaptionSize.MEDIUM}
                divider={divider}
                variant={variant}
            >
                {children}
            </StyledCaptionBadge>
        )}
    </BadgeWrapper>
);

export default Badge;
