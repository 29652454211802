import {
    UseQueryOptions,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import {
    bulkAddParcels,
    bulkAddToCart,
    bulkEditPickupDate,
    deleteConsignment,
    getConsignments,
    patchConsignment,
    setConsignmentStatus,
    updateConsignment,
} from 'api/consignments';
import {
    Consignment,
    ConsignmentPatch,
    ConsignmentRequest,
    ConsignmentStatus,
} from 'api/consignments/types';
import { getZwapgridOneTimeCode } from 'api/imports';
import { ZwapgridCodes } from 'api/imports/types';
import { Package, PackageRequest } from 'api/packages/types';
import { MetaData } from 'api/types';
import {
    BulkAddToCart,
    BulkEditResponse,
    BulkUpdateParcels,
    BulkUpdatePickupDate,
} from 'containers/Import/components/ConsignmentBulkEditProvider/types';
import { useCartContext } from 'hooks/Cart';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
    trackBulkAddParcels,
    trackBulkAddToCart,
    trackBulkPickupDate,
} from '../../external/analytics';
import { BackendErrors } from '../../types';

interface Response {
    consignments: Consignment[];
    meta: MetaData;
}

export const useImports = (
    page: number,
    perPage: number,
    q?: string,
    options?: Omit<UseQueryOptions<Response>, 'queryKey'>
) => {
    const { t } = useTranslation('import');
    const queryClient = useQueryClient();
    let key = ['imports', page, perPage];
    if (q) {
        key = ['imports', page, perPage, q];
    }
    const patchedOptions: Omit<UseQueryOptions<Response>, 'queryKey'> = {
        keepPreviousData: true,
        staleTime: 120000,
        ...options,
    };

    const query = useQuery(
        key,
        () => {
            const params: Record<string, string | number> = {
                status: 'import',
                include: 'external_source',
                source: 'external',
                page,
                per_page: perPage,
            };
            if (q) {
                params.q = `${q}*`;
            }
            return getConsignments(params);
        },
        patchedOptions
    );

    const { removeFromCart } = useCartContext();

    const deleteImports = async (consignmentsForDeletion: Consignment[]) => {
        const promises: Promise<void>[] = [];
        consignmentsForDeletion.forEach(({ status, id }) => {
            if (status === ConsignmentStatus.ACTIVE) {
                removeFromCart(id);
            } else {
                promises.push(deleteConsignment(id));
            }
        });
        await Promise.all(promises);
    };

    /**
     * An optimistic deletion of several consignment. Will try to delete the consignments and
     * fetch the imports anew. If the deletion fails, the cache will be reset and the user
     * will be notified.
     */
    const deleteMutation = useMutation(deleteImports, {
        onMutate: async (consignmentsForDeletion: Consignment[]) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['imports']);

            const previousValue = queryClient.getQueryData<{
                consignments: Consignment[];
                meta: MetaData;
            }>(['imports', page, perPage]);

            const idsForDeletion = consignmentsForDeletion.map((o) => o.id);
            const optimisticUpdate = previousValue?.consignments?.filter(
                (c) => !idsForDeletion.includes(c.id)
            );

            queryClient.setQueryData(['imports', page, perPage], (old: any) => {
                return {
                    meta: old.meta,
                    consignments: optimisticUpdate,
                };
            });
            return previousValue;
        },
        onError: (err, variables, previousValue) => {
            toast.error(t('import:updateError'));
            queryClient.setQueryData(['imports'], previousValue);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['imports']);
        },
    });

    return {
        ...query,
        optimisticRemoveImports: deleteMutation,
    };
};

/**
 * An optimistic patch of a consignment. Will try to patch and then fetch the imports
 * anew. If the patch fails, no change in the cached imports will be made, but a toast
 * will be shown informing the user of the error.
 */
export const useUpdateImport = (page: number, perPage: number, id: string) => {
    const queryClient = useQueryClient();

    const patch = (patch: ConsignmentPatch) => patchConsignment(id, patch);

    const mutation = useMutation(patch, {
        onMutate: async (patch: ConsignmentPatch) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['imports']);

            const previousValue = queryClient.getQueryData<{
                consignments: Consignment[];
                meta: MetaData;
            }>(['imports', page, perPage]);

            const previousConsignment = previousValue?.consignments?.find(
                (c) => c.id === id
            );

            const updated: Consignment = {
                ...(previousConsignment || {}),
                ...patch,
            } as Consignment;

            const optimisticUpdate = previousValue?.consignments?.map((c) =>
                c.id === id ? updated : c
            );

            queryClient.setQueryData(['imports', page, perPage], (old: any) => {
                return {
                    meta: old?.meta,
                    consignments: optimisticUpdate,
                };
            });
            return previousValue;
        },
        onError: () => {
            // We don't reset the import list here since we want to keep the previous values in the forms.
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['imports']);
        },
    });

    /**
     * The mutation state should be dependent on ID, not on row index (as we have multiple pages)
     */
    useEffect(() => {
        mutation.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return mutation;
};

/**
 * An optimistic status update of a consignment. Will try to update the status  and then fetch the imports
 * anew. If the staus update fails, no change in the cached imports will be made, but a toast
 * will be shown informing the user of the error.
 */
export const useAddImportToCart = (
    page: number,
    perPage: number,
    id: string
) => {
    const queryClient = useQueryClient();

    const patch = async (request: ConsignmentRequest) => {
        await updateConsignment(id, request);
        return setConsignmentStatus(id, 'active');
    };

    const mutation = useMutation(patch, {
        onMutate: async () => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['imports']);

            const previousValue = queryClient.getQueryData<{
                consignments: Consignment[];
                meta: MetaData;
            }>(['imports', page, perPage]);

            const previousConsignment = previousValue?.consignments?.find(
                (c) => c.id === id
            );

            const updated: Consignment = {
                ...(previousConsignment || {}),
                status: ConsignmentStatus.ACTIVE,
            } as Consignment;

            const optimisticUpdate = previousValue?.consignments?.map((c) =>
                c.id === id ? updated : c
            );

            queryClient.setQueryData(['imports', page, perPage], (old: any) => {
                return {
                    meta: old?.meta,
                    consignments: optimisticUpdate,
                };
            });
            return previousValue;
        },
        onError: (err, _, previousValue) => {
            queryClient.setQueryData(['imports'], previousValue);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['imports']);
        },
    });

    /**
     * The mutation state should be dependent on ID, not on row index (as we have multiple pages)
     */
    useEffect(() => {
        mutation.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return mutation;
};

export const useZwapgridCodes = (
    teamId: string,
    options?: Omit<UseQueryOptions<ZwapgridCodes>, 'queryKey'>
) => {
    const { data: codes, ...rest } = useQuery(
        ['zwapgridOneTimeCode'],
        () => getZwapgridOneTimeCode(teamId),
        options
    );
    return { codes, ...rest };
};

const usePickupDateMutation = (setFailed: (err: BulkEditResponse) => void) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const updatePickupDate = ({ ids, pickupDate }: BulkUpdatePickupDate) => {
        setFailed({});
        return bulkEditPickupDate(ids, pickupDate);
    };

    const onMutate = async ({
        ids,
        page,
        perPage,
        pickupDate,
    }: BulkUpdatePickupDate) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['imports']);
        // Here we preform an optimistic update of the pickup date for the imports.
        queryClient.setQueryData(['imports', page, perPage], (old: any) => {
            return {
                meta: old?.meta,
                consignments: old?.consignments?.map((c: Consignment) => {
                    if (ids.includes(c.id)) {
                        return {
                            ...c,
                            pickupAtEarliest: pickupDate,
                            pickupAtLatest: pickupDate,
                        };
                    }
                    return c;
                }),
            };
        });
    };

    const onSuccess = (
        res: { bulkEditErrors: BulkEditResponse },
        { ids }: BulkUpdatePickupDate
    ) => {
        queryClient.invalidateQueries(['imports']);

        const failedUpdates = Object.keys(res.bulkEditErrors);

        if (failedUpdates.length > 0) {
            setFailed(res.bulkEditErrors);
            toast.error(
                t('import:bulkEdit.updateError', {
                    failed: failedUpdates.length,
                })
            );
        }

        trackBulkPickupDate(ids.length);
    };

    const onError = (err: any, _: BulkUpdatePickupDate, previousValue: void) =>
        queryClient.setQueryData(['imports'], previousValue);

    return useMutation(updatePickupDate, {
        onMutate,
        onSuccess,
        onError,
    });
};

const useAddToCart = (setFailed: (err: BulkEditResponse) => void) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { setCartVisibility } = useCartContext();
    const [isAddingToCart, setIsAddingToCart] = useState<{
        [key: string]: boolean;
    }>({});

    const addToCart = ({ ids }: BulkAddToCart) => {
        setFailed({});
        // here to start the loading state.
        setIsAddingToCart(
            ids.reduce((acc, id) => ({ ...acc, [id]: true }), {})
        );

        return bulkAddToCart(ids);
    };

    const onSuccess = (
        res: { bulkEditErrors: BulkEditResponse },
        { ids, perPage, page }: BulkAddToCart
    ) => {
        const failedUpdates = Object.keys(res.bulkEditErrors);
        const successful = ids.filter((id) => !failedUpdates.includes(id));

        if (failedUpdates.length > 0) {
            setFailed(res.bulkEditErrors);
            toast.error(
                t('import:bulkEdit.updateError', {
                    failed: failedUpdates.length,
                })
            );
        }

        // Here we preform an optimistic update of the add to cart for the imports.
        // We need preform two updates, first we update the cart with the
        // new consignments then we want to remove successful consignment from the import list.
        queryClient.setQueryData(['imports', page, perPage], (old: any) => {
            let consignments = old?.consignments;

            if (!consignments) {
                consignments = [];
            }

            const addedToCart = consignments.filter((c: Consignment) =>
                successful.includes(c.id)
            );

            const removedSuccessful = consignments.filter(
                (c: Consignment) => !successful.includes(c.id)
            );

            queryClient.setQueryData(['cart'], (oldcart: any) => {
                return [...oldcart, ...addedToCart];
            });

            return {
                meta: old?.meta,
                consignments: removedSuccessful,
            };
        });

        if (successful.length > 0) {
            setCartVisibility(true);
        }
        // here to stop the loading state.
        setIsAddingToCart({});

        trackBulkAddToCart(ids.length);
    };

    const onError = (err: any, _: BulkAddToCart, previous: void) =>
        queryClient.setQueryData(['imports'], previous);

    const mutation = useMutation(addToCart, { onSuccess, onError });
    return { mutation, isAddingToCart };
};

const useBulkAddParcels = (setFailed: (err: BulkEditResponse) => void) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const addParcels = ({ ids, parcels }: BulkUpdateParcels) => {
        return bulkAddParcels(ids, parcels);
    };

    const onMutate = async ({
        ids,
        page,
        perPage,
        parcels,
    }: BulkUpdateParcels) => {
        // Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['imports']);
        // Here we preform an optimistic update of the parcels for the imports.
        queryClient.setQueryData(['imports', page, perPage], (old: any) => {
            return {
                meta: old?.meta,
                consignments: old?.consignments?.map((c: Consignment) => {
                    if (ids.includes(c.id)) {
                        return {
                            ...c,
                            packages: parcels.map((p) =>
                                packageRequestToPackage(p)
                            ),
                        };
                    }
                    return c;
                }),
            };
        });
    };

    const onSuccess = (
        res: { bulkEditErrors: BulkEditResponse },
        { ids }: BulkUpdateParcels
    ) => {
        queryClient.invalidateQueries(['imports']);

        const failedUpdates = Object.keys(res.bulkEditErrors);

        if (failedUpdates.length > 0) {
            setFailed(res.bulkEditErrors);
            toast.error(
                t('import:bulkEdit.updateError', {
                    failed: failedUpdates.length,
                })
            );
        }

        trackBulkAddParcels(ids.length);
    };
    const onError = (err: any, _: BulkUpdateParcels, previous: void) =>
        queryClient.setQueryData(['imports'], previous);

    return useMutation(addParcels, { onMutate, onSuccess, onError });
};

export const useConsignmentBulkEdit = () => {
    const [failed, setFailed] = useState<BulkEditResponse>({});

    const isValidationErrors = (
        e: { errors: BackendErrors } | string | undefined
    ): e is { errors: BackendErrors } => {
        return e !== undefined && typeof e != 'string';
    };

    return {
        failed,
        isValidationErrors,
        addToCart: useAddToCart(setFailed),
        addParcels: useBulkAddParcels(setFailed),
        updatePickupDate: usePickupDateMutation(setFailed),
    };
};

const packageRequestToPackage = (p: PackageRequest): Package => ({
    weightKg: p.weightKg || 0,
    depthCm: p.depthCm || 0,
    widthCm: p.widthCm || 0,
    heightCm: p.heightCm || 0,
    stackable: p.stackable || false,
    description: p.description || '',
    type: p.type || '',
    quantity: p.quantity || 0,
    name: p.name,
    totalWeightKg: 0,
});
