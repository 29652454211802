import styled from 'styled-components/macro';
import { WithChildren } from 'types';

interface NavigationItemProps {
    state: 'active' | 'normal';
    /** Tells if heading is main or sub, sub heading will be in ligher color and smaller font */
    small?: boolean;
}

type Props = WithChildren<NavigationItemProps>;

const NavItem = styled.div.attrs({ tabIndex: 0 })<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    gap: ${({ theme }) => theme.space.s2};
    position: relative;

    &:after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        transition: all 0.3s ease-in-out 0.12s;
        background: ${({ theme }) => theme.colors.black};
        width: ${({ state }) => (state === 'active' ? '100%' : '')};
    }
    a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: ${({ theme }) => theme.colors.black};
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        &:hover {
            text-decoration: none;
        }
    }
    &:hover {
        text-decoration: none;
        background-color: ${({ theme }) => theme.colors.grey500};
        button {
            background-color: transparent;
        }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 48px;
        a {
            font-size: 16px;
        }
        &:after {
            display: none;
        }
    }
`;

const NavigationItem = ({ state = 'normal', small, children }: Props) => {
    return (
        <NavItem state={state} small={small}>
            {children}
        </NavItem>
    );
};

export default NavigationItem;
