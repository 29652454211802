import styled from 'styled-components/macro';

export const StyledFlag = styled.img<{ size: string }>`
    /* For Chrome and Edge: https://developer.mozilla.org/en-US/docs/Web/CSS/image-rendering */
    /* The flags look blurry otherwise. */
    image-rendering: -webkit-optimize-contrast;
    max-height: ${({ size }) => size};
    max-width: ${({ size }) => size};
    white-space: nowrap;
`;

export const FlagWrapper = styled.div<{ size: string }>`
    display: grid;
    flex-shrink: 0;
    height: ${({ size }) => size};
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    place-content: center;
    width: ${({ size }) => size};
`;
