import { useFlags } from 'external/launchDarkly';
import { getFrontendLanguage } from 'helpers/LanguageHelper';

import InfoBanner from './InfoBanner';

const InfoBannerLD = () => {
    const { infoBanner } = useFlags();
    const lang = getFrontendLanguage();
    if (!lang) {
        return null;
    }

    if (!infoBanner) {
        return null;
    }

    if (!infoBanner?.name) {
        return null;
    }

    const content = infoBanner[lang];
    if (!content) {
        return null;
    }

    return (
        <InfoBanner
            name={infoBanner.name}
            title={content.title}
            subtitle={content.subtitle}
            buttonLink={content.buttonLink ? content.buttonLink : undefined}
            buttonText={content.buttonText ? content.buttonText : undefined}
        />
    );
};

export default InfoBannerLD;
