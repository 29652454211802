import { get } from 'api';
import ApiError from 'api/ApiError';
import { Address, CourierCode, ProductCode } from 'api/consignments/types';
import queryString from 'query-string';

import * as config from '../../config';
import { CityAlternative, State } from './types';

export const getCityAlternatives = async (
    courierCode: CourierCode,
    productCode: ProductCode,
    address: Address
): Promise<CityAlternative[]> => {
    const params = {
        courier_code: courierCode,
        product_code: productCode,
        zip_code: address.postalCode?.replace(/\s/g, ''),
        city: address.city,
        country_code: address.countryCode,
    };

    const response = await get<{ data: CityAlternative[] }>(
        `${config.goDomain}v2/address/cityAlternatives?${queryString.stringify(
            params
        )}`
    );
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    return response.parsedBody.data;
};

export const getStates = async (countryCode: string): Promise<State[]> => {
    const response = await get<{ data: State[] }>(
        `${config.goDomain}v2/address/states?country_code=${countryCode}`
    );
    return response.parsedBody?.data || [];
};
