import { ChangeEvent, ReactNode, forwardRef } from 'react';

import {
    HiddenCheckbox,
    Label,
    StyledCheckbox,
    Wrapper,
} from './StyledCheckbox';

interface BaseProps {
    /**
     * A disabled checkbox can't be clicked.
     */
    disabled?: boolean;
    checked?: boolean;
    /**
     * If this checkbox is a multi-select checkbox, you can use this prop
     * to tell the user that some of the checkboxes (but not all) are
     * checked.
     */
    indeterminate?: boolean;
    /**
     * Returns the checkbox input event.
     */
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    /**
     * Returns the new value of the checkbox; if an unchecked checkbox is
     * clicked, `checked` will be `true`.
     */
    onValueChange?: (checked: boolean) => void;
    value?: string;
    'data-cy'?: string;
    'data-testid'?: string;
}

interface LabelProps extends BaseProps {
    label: string | ReactNode;
    id: string;
}
interface NoLabelProps extends BaseProps {
    id?: string;
    /**
     * If a label is submitted, you must also set the `id` prop.
     */
    label?: never;
}

type Props = LabelProps | NoLabelProps;

/**
 * A single checkbox, which can be either selected, unselected, or
 * indeterminate.
 *
 * If you pass a label, you must also pass an id. This is required
 * for the label to be associated with the Input element.
 */
const Checkbox = forwardRef<HTMLInputElement, Props>(
    (
        {
            onChange,
            onValueChange,
            checked = false,
            disabled = false,
            indeterminate = false,
            'data-cy': dataCy,
            'data-testid': dataTestId,
            value = 'on',
            id,
            label,
        }: Props,
        ref
    ) => {
        const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
            if (onValueChange) {
                onValueChange(event.currentTarget.checked);
            }
            if (onChange) {
                onChange(event);
            }
        };
        return (
            <Wrapper htmlFor={id} disabled={disabled}>
                <HiddenCheckbox
                    type="checkbox"
                    id={id}
                    name={id}
                    checked={checked}
                    disabled={disabled}
                    onChange={changeValue}
                    ref={ref}
                    value={value}
                />
                <StyledCheckbox
                    checked={!!checked}
                    disabled={disabled}
                    indeterminate={indeterminate}
                    data-cy={dataCy}
                    data-testid={dataTestId}
                />
                {label && <Label>{label}</Label>}
            </Wrapper>
        );
    }
);

export default Checkbox;
