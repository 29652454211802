import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const List = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    padding: ${({ theme }) => theme.space.s6};
`;

export const StyledBreakdownItem = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: ${({ theme }) => theme.space.s1};
    grid-template-columns: 1fr auto;
    width: 100%;
    // Right-align the right column
    > :nth-child(even) {
        text-align: right;
    }
`;

export const BreakdownText = styled(Text)`
    color: ${({ theme }) => theme.colors.grey100};
`;

export const DiscountText = styled(Text)`
    color: ${({ theme }) => theme.colors.green200};
`;

export const OldPrice = styled.span`
    margin-right: ${({ theme }) => theme.space.s2};
    text-decoration: line-through;
`;
