import { Integration } from 'api/subscriptions/types';

import CancelIntegration from './CancelIntegration';
import NewIntegration from './NewIntegration';
import ReactivateIntegration from './ReactivateIntegration';

export type IntegrationChangeType = 'reactivate' | 'cancel' | 'purchase';

type Props = {
    integration: Integration;
    type: IntegrationChangeType;
    onSuccessfulSubmit: () => void;
};

/**
 * Lets a user either purchase a new integration, cancel an existing one, or reactivating a cancelled one.
 */
const IntegrationChange = ({
    integration,
    type,
    onSuccessfulSubmit,
}: Props) => {
    if (type === 'cancel') {
        return (
            <CancelIntegration
                integration={integration}
                onSuccessfulSubmit={onSuccessfulSubmit}
            />
        );
    }
    if (type === 'reactivate') {
        return (
            <ReactivateIntegration
                integration={integration}
                onSuccessfulSubmit={onSuccessfulSubmit}
            />
        );
    }

    return (
        <NewIntegration
            integration={integration}
            onSuccessfulSubmit={onSuccessfulSubmit}
        />
    );
};

export default IntegrationChange;
