import {
    BackendSignupRequest,
    BackendUser,
    BackendUserPassword,
    BackendUserSetting,
    BackendUserUpdate,
    CurrentUser,
    SignupRequest,
    User,
    UserPassword,
    UserSettings,
} from 'api/users/types';
import { Language } from 'helpers/LanguageHelper';
import { DateTime } from 'luxon';

export const signupRequestToBackendSignupRequest = (
    signupRequest: SignupRequest
): BackendSignupRequest => ({
    team_name: signupRequest.teamName,
    mobile_number: signupRequest.mobileNumber,
    email: signupRequest.email,
    password: signupRequest.password,
    system_country: signupRequest.systemCountry,
    user_settings: {
        key: 'user_lang',
        value: signupRequest.userSettings.userLang || '',
    },
    method: signupRequest.method,
});

export const backendUserToUser = (backendUser: BackendUser): User => ({
    activated: !!backendUser.activated,
    createdAt: DateTime.fromISO(backendUser.created_at),
    email: backendUser.email,
    firstName: backendUser.first_name,
    id: backendUser.id,
    lastName: backendUser.last_name,
    mobileNumber: backendUser.mobile_number,
    updatedAt: DateTime.fromISO(backendUser.updated_at),
    username: backendUser.username,
    showNavigation: backendUser.show_navigation,
});

export const backendUserToCurrentUser = (
    backendUser: BackendUser
): CurrentUser => ({
    ...backendUserToUser(backendUser),
    hubSpotToken: backendUser.hubspot_token || '',
});

export const userToBackendUserUpdate = <T extends User>(
    user: T
): BackendUserUpdate => ({
    first_name: user.firstName,
    last_name: user.lastName,
    mobile_number: user.mobileNumber,
});

export const userpasswordToBackendUserPassword = (
    userPassword: UserPassword
): BackendUserPassword => ({
    new_password: userPassword.newPassword,
    new_password_confirmation: userPassword.newPasswordConfirmation,
    old_password: userPassword.oldPassword,
});

export const backendUserSettingsToUserSettings = (
    backendSettings: BackendUserSetting[]
): UserSettings => {
    const userSettings: UserSettings = {};
    backendSettings.forEach((setting) => {
        switch (setting.key) {
            case 'user_default_team_id':
                userSettings.userDefaultTeamId = setting.value;
                break;
            case 'user_lang':
                userSettings.userLang = setting.value as Language;
                break;
            case 'user_new_feature_cost_control':
                userSettings.userNewFeatureCostControl = setting.value;
                break;
            case 'user_new_feature_invoice':
                userSettings.userNewFeatureInvoice = setting.value;
                break;
            case 'user_new_feature_settings':
                userSettings.userNewFeatureSettings = setting.value;
                break;
            case 'user_non_stackable_for_half_pall':
                userSettings.userNonStackableForHalfPall = setting.value;
                break;
            case 'user_non_stackable_for_pall':
                userSettings.userNonStackableForPall = setting.value;
                break;
            case 'user_non_stackable_for_standard':
                userSettings.userNonStackableForStandard = setting.value;
                break;
            case 'user_qq_from_country_code':
                userSettings.userQqFromCountryCode = setting.value;
                break;
            case 'user_qq_to_country_code':
                userSettings.userQqToCountryCode = setting.value;
                break;

            default:
                break;
        }
    });
    return userSettings;
};
/**
 * @param userSettings
 * @returns BackendUserSettings key/value pair of non-undefined value in the input
 */
export const userSettingsToBackendUserSettings = (
    userSettings: UserSettings
): BackendUserSetting[] => {
    const backendUserSettings: (BackendUserSetting | undefined)[] = [
        userSettings.userDefaultTeamId !== undefined
            ? {
                  key: 'user_default_team_id',
                  value: userSettings.userDefaultTeamId,
              }
            : undefined,
        userSettings.userLang
            ? { key: 'user_lang', value: userSettings.userLang }
            : undefined,
        userSettings.userNewFeatureCostControl !== undefined
            ? {
                  key: 'user_new_feature_cost_control',
                  value: userSettings.userNewFeatureCostControl,
              }
            : undefined,
        userSettings.userNewFeatureInvoice !== undefined
            ? {
                  key: 'user_new_feature_invoice',
                  value: userSettings.userNewFeatureInvoice,
              }
            : undefined,
        userSettings.userNewFeatureSettings !== undefined
            ? {
                  key: 'user_new_feature_settings',
                  value: userSettings.userNewFeatureSettings,
              }
            : undefined,
        userSettings.userNonStackableForHalfPall !== undefined
            ? {
                  key: 'user_non_stackable_for_half_pall',
                  value: userSettings.userNonStackableForHalfPall,
              }
            : undefined,
        userSettings.userNonStackableForPall !== undefined
            ? {
                  key: 'user_non_stackable_for_pall',
                  value: userSettings.userNonStackableForPall,
              }
            : undefined,
        userSettings.userNonStackableForStandard !== undefined
            ? {
                  key: 'user_non_stackable_for_standard',
                  value: userSettings.userNonStackableForStandard,
              }
            : undefined,
        userSettings.userQqFromCountryCode !== undefined
            ? {
                  key: 'user_qq_from_country_code',
                  value: userSettings.userQqFromCountryCode,
              }
            : undefined,
        userSettings.userQqToCountryCode !== undefined
            ? {
                  key: 'user_qq_to_country_code',
                  value: userSettings.userQqToCountryCode,
              }
            : undefined,
    ];
    return backendUserSettings.filter(
        (setting) => setting !== undefined
    ) as BackendUserSetting[];
};
