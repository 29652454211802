import { getStates } from 'api/address';
import { Option, SelectInstance } from 'components/FormElements/Select';
import { countriesWithDivisionSelection } from 'constants/subnationalDivisions';
import { Alpha2Code } from 'i18n-iso-countries';
import { TFunction } from 'i18next';
import { ErrorOption, ValidateResult } from 'react-hook-form';
import { FormErrors } from 'types';

/**
 * Returns a list of states to be populated within a Select component.
 * Will always return an empty list rather than throwing an error, since we
 * otherwise need to handle errors by setting an empty list in any case.
 * @param countryCode The country code to fetch states from
 */
export const getStateOptions = async (
    countryCode?: Alpha2Code
): Promise<Option[]> => {
    if (countryCode && countriesWithDivisionSelection.has(countryCode)) {
        try {
            const states = await getStates(countryCode.toLowerCase());
            if (states) {
                return states.map((state) => ({
                    label: state.abbreviation
                        ? `${state.name}, ${state.abbreviation}`
                        : state.name,
                    value: state.code,
                }));
            }
        } catch (e) {
            return [];
        }
    }
    return [];
};

/**
 * Sets a set of errors, and focuses on the first one.
 *
 * @param setError passed from useForm
 */
export const setFormErrors = (
    formErrors: FormErrors,
    setError: (name: string, error: ErrorOption) => void
): void => {
    let hasFocused = false;
    Object.keys(formErrors).forEach((key) => {
        const error = formErrors[key];
        if (error) {
            setError(key, {
                message: error,
                shouldFocus: !hasFocused,
            });
            hasFocused = true;
        }
    });
};

/**
 * Scrolls to an input. Useful when setting an error on a field that is
 * shown via a Controller component.
 * @param ref A focusable element
 */
export const scrollToAndFocus = (
    ref: React.MutableRefObject<HTMLInputElement | null>
) => {
    if (ref.current) {
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        ref.current?.focus({
            preventScroll: true,
        });
    }
};

/**
 * Scrolls to an Select component. Useful when setting an error on a Select that is
 * shown via a Controller component, since react-select doesn't support scrolling
 * and focusing simultaneously.
 * @param ref A Select component
 */
export const scrollToSelect = <T extends Option>(
    ref: React.MutableRefObject<SelectInstance<T> | null>
) => {
    if (ref.current) {
        ref.current?.inputRef?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }
};

/**
 * Allows custom validation mechanism for file inputs
 * @param maxMb The maximum file size, in megabytes.
 */
export const fileSizeValidation = (
    maxMb: number,
    t: TFunction,
    value?: FileList
): ValidateResult => {
    const file = value?.[0];
    if (file) {
        const maxFileSize = maxMb * 1024 * 1024;
        return file.size <= maxFileSize || false;
    }
    return true;
};

/**
 * Returns true if and only if the value is defined, i.e. not `null` or `undefined`.
 * Useful for removing undefined values from an array.
 */
export const isDefined = <TValue>(
    value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined;
