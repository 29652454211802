import { countriesWithDivisionSelection } from 'constants/subnationalDivisions';
import { Alpha2Code } from 'i18n-iso-countries';
import { useEffect, useRef, useState } from 'react';

interface AutoCompleteAddress {
    addressLine1: string | undefined | null;
    city: string | undefined | null;
    country: Alpha2Code | undefined | null;
    state: string | undefined | null;
    postalCode: string | undefined | null;
}

/**
 * Each component in the response is initialized as undefined and is auto completed to a value if found or null if not
 * @param el The input element associated with the autocomplete
 * @param enable Set to false for disabling autocompletion
 * @param restrictCountry Restrict the prediction to a specific country
 */
export const useGoogleAutoComplete = (
    el: HTMLElement | null,
    enable = true,
    restrictCountry?: Alpha2Code
): AutoCompleteAddress => {
    const [addressLine1, setAddressLine1] = useState<string | null>();
    const [country, setCountry] = useState<Alpha2Code | null>();
    const [city, setCity] = useState<string | null>();
    const [state, setState] = useState<string | null>();
    const [postalCode, setPostalCode] = useState<string | null>();

    const initialized = useRef(false);
    // Initialize Google Autocomplete
    const { google } = window as any;
    useEffect(() => {
        if (!enable) {
            return;
        }
        if (initialized.current) {
            // we want to run this until the google script has loaded abort if we have initialized
            return;
        }

        let autoComplete: any;
        // Fill the address fields based on Google Maps' autocompletion.
        const handlePlaceSelect = () => {
            const addressObject = autoComplete.getPlace();
            if (!addressObject) return;
            const address = addressObject.address_components;
            if (address) {
                const newAddressLine = address.find((ac: any) =>
                    ac.types.some((type: string) => type === 'route')
                );
                const streetNumber = address.find((ac: any) =>
                    ac.types.some((type: string) => type === 'street_number')
                );
                if (newAddressLine) {
                    if (streetNumber) {
                        setAddressLine1(
                            `${newAddressLine.long_name} ${streetNumber.long_name}`
                        );
                    } else {
                        setAddressLine1(newAddressLine.long_name);
                    }
                } else {
                    setAddressLine1(null);
                }
                const newCity = address.find((ac: any) =>
                    ac.types.some(
                        (type: string) =>
                            type === 'postal_town' || type === 'locality'
                    )
                );
                if (newCity) {
                    setCity(newCity.long_name);
                } else {
                    setCity(null);
                }

                const newCountry = address.find((ac: any) =>
                    ac.types.some((type: string) => type === 'country')
                );

                if (newCountry) {
                    setCountry(newCountry.short_name);
                } else {
                    setCountry(null);
                }

                const newState = address.find((ac: any) =>
                    ac.types.some(
                        (type: string) => type === 'administrative_area_level_1'
                    )
                );

                const validState =
                    newState && !/[^a-z]/i.test(newState.short_name);

                const countryHasStates = countriesWithDivisionSelection.has(
                    newCountry.short_name
                );

                if (validState && countryHasStates) {
                    setState(newState.short_name.toLowerCase());
                } else {
                    setState(null);
                }
                const newPostalCode = address.find((ac: any) =>
                    ac.types.some((type: string) => type === 'postal_code')
                );
                if (newPostalCode) {
                    setPostalCode(newPostalCode.long_name);
                } else {
                    setPostalCode(null);
                }
            }
        };

        let listener: any;
        // Declare Options For Autocomplete

        if (el !== null && google) {
            initialized.current = true;
            el.setAttribute('autocomplete', 'NONE');
            autoComplete = new google.maps.places.Autocomplete(el, {
                types: ['address'],
            });
            if (restrictCountry) {
                autoComplete.setComponentRestrictions({
                    country: restrictCountry,
                });
            }
            // Avoid paying for data that you don't need by restricting the set of
            // place fields that are returned to just the address components.
            autoComplete.setFields(['address_component']);
            // Fire Event when a suggested name is selected
            autoComplete.addListener('place_changed', handlePlaceSelect);

            listener = google.maps.event.addDomListener(
                el,
                'keydown',
                (event: any) => {
                    if (event.keyCode === 13) {
                        event.preventDefault();
                    }
                }
            );
        }
        return () => {
            if (listener) {
                google.maps.event.removeListener(listener);
            }
        };
    });

    return { addressLine1, city, country, state, postalCode };
};
