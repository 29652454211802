import { Button } from 'components/Buttons';
import styled, { css } from 'styled-components/macro';

export const ButtonWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const StyledButton = styled(Button)<{ active: boolean }>`
    border: ${({ theme }) => `${theme.space.s1} solid ${theme.colors.grey400}`};

    &:not(:last-of-type) {
        /* Offsets the border. */
        border-right: ${({ theme }) =>
            `calc(${theme.space.s1} / 2) solid ${theme.colors.grey400}`};
    }

    &:not(:first-of-type) {
        /* Offsets the border. */
        border-left: ${({ theme }) =>
            `calc(${theme.space.s1} / 2) solid ${theme.colors.grey400}`};
    }

    :focus-visible {
        z-index: 1;
    }

    ${({ active }) => {
        if (active) {
            return css`
                background-color: ${({ theme }) => theme.colors.white};
            `;
        } else {
            return css`
                background-color: ${({ theme }) => theme.colors.grey400};
            `;
        }
    }};
`;
