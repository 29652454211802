import { TextSize } from 'components/Typography/Text';
import { ReactNode, useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components/macro';

import {
    ButtonContent,
    ButtonText,
    IconAfterWrapper,
    IconBeforeWrapper,
    SpinnerWrapper,
    StyledButton,
    StyledButtonSpinner,
} from './StyledButton';

export type ButtonVariant = 'primary' | 'secondary' | 'text' | 'danger';
export type Size = 'large' | 'small';
interface AdditionalProps {
    'data-cy'?: string;
    'data-for'?: string;
    'data-testid'?: string;
    'data-tooltip-id'?: string;
    fullWidth?: boolean;
    iconAfter?: ReactNode;
    iconBefore?: ReactNode;
    loading?: boolean;
    size?: Size;
    variant?: ButtonVariant;
}

export type ButtonProps = AdditionalProps &
    React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
    'aria-label': ariaLabel,
    'data-cy': dataCy,
    'data-for': dataFor,
    'data-testid': dataTestId,
    children,
    className,
    disabled = false,
    fullWidth = false,
    iconAfter,
    iconBefore,
    loading = false,
    onClick,
    size = 'large',
    type = 'button',
    variant = 'primary',
    ...rest
}: React.PropsWithChildren<ButtonProps>) => {
    const { colors } = useContext(ThemeContext);

    const { black, red100, red300, white } = colors;
    const interactive = !(disabled || loading);

    let classes = className;
    if (!interactive) {
        classes += ' disabled';
    }
    const buttonRef = useRef<HTMLButtonElement>(null);
    buttonRef.current?.addEventListener('click', (event) => {
        const target = event.currentTarget;
        if (!target) {
            return;
        }
        // This is to stop the form submit buttons to submit forms
        // when they are disabled
        if ((target as any).classList.contains('disabled')) {
            event.preventDefault();
        }
    });

    const commonProps = {
        /**
         * We use aria-disabled instead of disabled. This is for users
         * with screen readers to be able to tab to and read the button.
         */
        'aria-disabled': !interactive,
        /**
         * The aria label is set since the content of the button also includes the loading spinner and any icons
         */
        'aria-label': ariaLabel || children?.toString(),
        'data-cy': dataCy,
        'data-for': dataFor,
        'data-testid': dataTestId,
        $fullWidth: fullWidth,
        /**
         * We use isDisabled instead of disabled to not actually set the disabled
         * attribute on the button (see above on aria-disabled)
         */
        $isDisabled: disabled,
        $loading: loading,
        onClick: interactive ? onClick : undefined,
        $size: size,
        type,
        variant,
        'data-variant': variant,
        className: classes,
        ref: buttonRef,
        ...rest,
    };

    const innerButton = (
        <>
            {loading && (
                <SpinnerWrapper data-testid="spinner">
                    <StyledButtonSpinner />
                </SpinnerWrapper>
            )}
            <ButtonContent $loading={loading}>
                {iconBefore && (
                    <IconBeforeWrapper $size={size}>
                        {iconBefore}
                    </IconBeforeWrapper>
                )}
                <ButtonText size={TextSize.MEDIUM}>{children}</ButtonText>
                {iconAfter && (
                    <IconAfterWrapper $size={size}>
                        {iconAfter}
                    </IconAfterWrapper>
                )}
            </ButtonContent>
        </>
    );

    switch (variant) {
        case 'primary':
            return (
                <StyledButton
                    {...commonProps}
                    $colors={{
                        background: black,
                        // `cc = 80% opacity,
                        border: black,
                        hover: `${black}cc`,
                        active: black,
                        content: white,
                    }}
                >
                    {innerButton}
                </StyledButton>
            );
        case 'secondary':
            return (
                <StyledButton {...commonProps} $colors={{ content: black }}>
                    {innerButton}
                </StyledButton>
            );
        case 'text':
            return (
                <StyledButton
                    {...commonProps}
                    $colors={{
                        content: black,
                        border: 'transparent',
                        background: 'transparent',
                    }}
                >
                    {innerButton}
                </StyledButton>
            );
        case 'danger':
            return (
                <StyledButton
                    {...commonProps}
                    $colors={{
                        border: red100,
                        content: red100,
                        hover: red300,
                        active: red300,
                    }}
                >
                    {innerButton}
                </StyledButton>
            );
    }
};
