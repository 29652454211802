const key = 'chunk_failed';
const timeOut = 10000;

/**
 * Lets us know that a chunk has failed to load.
 */
export const setChunkFailed = () => {
    const chunkErrorTimestamp = new Date().getTime() + timeOut;
    localStorage.setItem(key, JSON.stringify(chunkErrorTimestamp));
};
/**
 * Returns whether a chunk has failed to load within the last 10 seconds.
 * This is done to avoid infinite reloads, since we reload on chunk loading
 * failure.
 */
export const chunkFailedRecently = () => {
    const timestampString = window.localStorage.getItem(key);
    if (!timestampString) {
        return false;
    }
    const expiryTimestamp: number = JSON.parse(timestampString);
    const isExpired = new Date().getTime() > expiryTimestamp;

    if (isExpired) {
        localStorage.removeItem(key);
        return false;
    }

    return true;
};
