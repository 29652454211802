import { PriceSubscriptionTier } from 'api/subscriptions/types';
import Divider from 'components/Divider';
import CheckedListItem from 'components/Lists/CheckedListItem';
import Text from 'components/Typography/Text';
import { getFreeTier } from 'constants/priceTiers';
import { useTeam } from 'hooks/Queries';
import { useTranslation } from 'react-i18next';

const Features = ({ tier }: { tier: PriceSubscriptionTier }) => {
    const { t } = useTranslation('settings');
    const { team } = useTeam();

    if (!team) {
        return null;
    }
    return (
        <>
            <>
                <CheckedListItem>
                    <Text>{t('settings:pricePanel.sendifyFree.feature1')}</Text>
                </CheckedListItem>
                <CheckedListItem>
                    <Text>{t('settings:pricePanel.sendifyFree.feature2')}</Text>
                </CheckedListItem>
                <CheckedListItem>
                    <Text>{t('settings:pricePanel.sendifyFree.feature3')}</Text>
                </CheckedListItem>
                <CheckedListItem>
                    <Text>{t('settings:pricePanel.sendifyFree.feature4')}</Text>
                </CheckedListItem>
                <CheckedListItem>
                    <Text>{t('settings:pricePanel.sendifyFree.feature5')}</Text>
                </CheckedListItem>
                <CheckedListItem>
                    <Text>{t('settings:pricePanel.sendifyFree.feature6')}</Text>
                </CheckedListItem>
                <Divider />
                <CheckedListItem
                    checked={tier !== getFreeTier(team.systemCountry)}
                >
                    <Text>{t('settings:pricePanel.sendifyPlus.feature1')}</Text>
                </CheckedListItem>
                <CheckedListItem
                    checked={tier !== getFreeTier(team.systemCountry)}
                >
                    <Text>{t('settings:pricePanel.sendifyPlus.feature2')}</Text>
                </CheckedListItem>
            </>
        </>
    );
};
export default Features;
