import { Button } from 'components/Buttons';
import Modal, {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from 'components/Modal';
import Spinner, { spinnerSize } from 'components/Spinner/Spinner';
import { useCartContext } from 'hooks/Cart';
import { ModalType } from 'hooks/Cart/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ButtonContainer, SpinnerContainer } from './StyledDraftModal';

const DraftModal = () => {
    const {
        closeModal,
        modalOptions,
        cartState,
        saveAsDraft: saveAsDraftInCart,
    } = useCartContext();
    const { isSubmitting } = cartState;

    const { t } = useTranslation();

    const consignmentId = modalOptions?.consignment?.id;

    const saveAsDraft = async () => {
        if (!consignmentId) {
            throw new TypeError('no consignment ID');
        }
        saveAsDraftInCart(consignmentId);
        closeModal();
        toast.success(t('modal.saveDraft.completedNotification'));
    };

    const show = modalOptions?.type === ModalType.DRAFT;

    return (
        <Modal onClose={isSubmitting ? undefined : closeModal} showModal={show}>
            <ModalHeader title={t('modal.saveDraft.title')} />
            {isSubmitting && (
                <ModalContent>
                    {isSubmitting && (
                        <SpinnerContainer>
                            <div data-testid="spinner" />
                            <Spinner size={spinnerSize.large} />
                        </SpinnerContainer>
                    )}
                </ModalContent>
            )}
            <ModalFooter>
                <ButtonContainer>
                    <Button
                        loading={isSubmitting}
                        variant="primary"
                        name="submit"
                        type="button"
                        onClick={saveAsDraft}
                    >
                        {t('modal.saveDraft.save')}
                    </Button>

                    <Button
                        disabled={isSubmitting}
                        variant="secondary"
                        name="cancel"
                        type="button"
                        onClick={close}
                    >
                        {t('button.cancel')}
                    </Button>
                </ButtonContainer>
            </ModalFooter>
        </Modal>
    );
};

export default DraftModal;
