/**
 * Promise.allSettled is supported by among others
 *  - Chrome 76
 *  - Edge 79
 *  - Firefox 71
 *  - Safari 13
 *
 * This polyfill should handle older browsers, as well as
 * Samsung's built-in browser.
 */
import allSettled from 'promise.allsettled';

// Needs to access the promise object before the polyfill is used.
console.log(Promise);
allSettled.shim(); // This will be a no-op if not needed.
