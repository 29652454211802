import { TextSize } from 'components/Typography/Text';
import { useTranslation } from 'react-i18next';

import { Code } from '../../icons';
import {
    CodeLogoWrapper,
    OwnIntegrationContainer,
    StyledText,
} from './ StyledOwnIntegration';

const OwnIntegrationLogo = () => {
    const { t } = useTranslation('settings');
    return (
        <OwnIntegrationContainer>
            <CodeLogoWrapper>
                <Code />
            </CodeLogoWrapper>
            <StyledText size={TextSize.MEDIUM}>
                {t('settings:api.ownIntegration')}
            </StyledText>
        </OwnIntegrationContainer>
    );
};

export default OwnIntegrationLogo;
