import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';
import { WithChildren } from 'types';

const ErrorMessageWrapper = styled.div`
    &::first-letter {
        text-transform: capitalize;
    }
    color: ${({ theme }) => theme.colors.red100};
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
    text-align: left;
    .message {
        display: inline;
        vertical-align: top;
    }
`;

const Message = styled(Text)`
    vertical-align: top;
`;

const ErrorMessage = ({ children }: WithChildren) => {
    return (
        <ErrorMessageWrapper>
            <Message>{children}</Message>
        </ErrorMessageWrapper>
    );
};

export default ErrorMessage;
