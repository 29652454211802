import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { reactivateSubscription } from 'api/subscriptions';
import {
    ConfirmButtonWrapper,
    Confirmation,
    Content,
    Footer,
    Overview,
    OverviewList,
    OverviewListItem,
    OverviewListLogo,
} from 'components/APISubscriptionChange/StyledAPISubscriptionChange';
import { Button } from 'components/Buttons';
import { ModalHeader } from 'components/Modal';
import Caption, { CaptionSize } from 'components/Typography/Caption';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { monthlyCosts, yearlyCosts } from 'constants/integrations';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import NextSteps from './NextSteps';

interface Props {
    onSuccessfulSubmit?: () => void;
}

/**
 * Lets the user reactivate a cancelled bundle.
 */
const Reactivate = ({ onSuccessfulSubmit }: Props) => {
    const queryClient = useQueryClient();
    const { subscriptions } = useSubscriptions();
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const subscription = subscriptions?.apiSubscription;
    const currentTier = subscription?.current?.tier;

    const onSubmit = async () => {
        setIsSubmitting(true);
        if (!currentTier) {
            return;
        }
        try {
            await reactivateSubscription(currentTier);
            if (onSuccessfulSubmit) {
                onSuccessfulSubmit();
            }
            queryClient.invalidateQueries(['subscriptions']);
            queryClient.invalidateQueries(['team']);
        } catch (err) {
            Sentry.captureException(err);
            toast.error(t('settings:priceTier.tierError'), {
                autoClose: false,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const end = subscription?.current?.end;

    if (!team || !subscription?.current || !currentTier || !end) {
        return null;
    }

    const { interval } = subscription;
    const integrations = subscriptions?.apiSubscription.current?.integrations;
    const { currency } = team;

    let monthlyCost = monthlyCosts[currentTier][currency];
    if (interval === 'year') {
        monthlyCost = yearlyCosts[currentTier][currency] / 12;
    }

    const decimals = currency === 'EUR' ? 1 : 0;
    return (
        <>
            <ModalHeader
                title={t('api.subscriptionChange.bundle.title.reactivate')}
            />
            <Content>
                <Overview>
                    <div>
                        <Caption size={CaptionSize.MEDIUM}>
                            {t('api.subscriptionChange.bundle.reactivatedTier')}
                        </Caption>
                        <OverviewList>
                            <OverviewListItem>
                                <Heading size={HeadingSize.SMALL}>
                                    {t(`tier.${currentTier}`)}
                                </Heading>
                                <Heading size={HeadingSize.MEDIUM}>
                                    {t('api.subscriptionChange.costPerMonth', {
                                        cost: formatCost(
                                            monthlyCost,
                                            i18n.language,
                                            currency,
                                            decimals
                                        ),
                                    })}
                                </Heading>
                            </OverviewListItem>
                        </OverviewList>
                    </div>
                    {integrations && integrations.length > 0 && (
                        <div>
                            <Caption size={CaptionSize.MEDIUM}>
                                {t(
                                    'api.subscriptionChange.bundle.reactivatedSubscription',
                                    { count: integrations.length }
                                )}
                            </Caption>
                            <OverviewList>
                                {integrations.map(
                                    ({ integration, quantity }) => {
                                        return Array.from(
                                            Array(quantity).keys()
                                        ).map((i) => (
                                            <OverviewListItem
                                                key={`${integration}-${i}`}
                                            >
                                                <OverviewListLogo
                                                    integration={integration}
                                                />
                                                <Heading
                                                    size={HeadingSize.MEDIUM}
                                                >
                                                    {t(
                                                        'api.subscriptionChange.costPerMonth',
                                                        {
                                                            cost: formatCost(
                                                                monthlyIntegrationCosts[
                                                                    integration
                                                                ][currency],
                                                                i18n.language,
                                                                currency,
                                                                decimals
                                                            ),
                                                        }
                                                    )}
                                                </Heading>
                                            </OverviewListItem>
                                        ));
                                    }
                                )}
                            </OverviewList>
                        </div>
                    )}
                </Overview>
                <NextSteps
                    listItems={[
                        t(
                            'api.subscriptionChange.bundle.nextSteps.integrationContinue'
                        ),
                        interval === 'year'
                            ? t(
                                  'api.subscriptionChange.bundle.nextSteps.yearlyPayment'
                              )
                            : t(
                                  'api.subscriptionChange.bundle.nextSteps.monthlyPayment'
                              ),
                    ]}
                />
            </Content>
            <Footer>
                <Confirmation>
                    <ConfirmButtonWrapper>
                        <Text>
                            {interval === 'year'
                                ? t(
                                      'api.subscriptionChange.bundle.reactivateYearInfo',
                                      {
                                          date: end.toLocaleString({
                                              month: 'long',
                                              day: 'numeric',
                                          }),
                                      }
                                  )
                                : t(
                                      'api.subscriptionChange.bundle.reactivateMonthInfo',
                                      {
                                          date: end.day,
                                      }
                                  )}
                        </Text>
                        <Button
                            fullWidth
                            loading={isSubmitting}
                            onClick={onSubmit}
                        >
                            {t(
                                'api.subscriptionChange.bundle.button.reactivate'
                            )}
                        </Button>
                    </ConfirmButtonWrapper>
                </Confirmation>
            </Footer>
        </>
    );
};

export default Reactivate;
