import { Button } from 'components/Buttons';
import Modal, {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from 'components/Modal';
import Spinner, { spinnerSize } from 'components/Spinner/Spinner';
import { useCartContext } from 'hooks/Cart';
import { ModalType } from 'hooks/Cart/types';
import { useTranslation } from 'react-i18next';

import { ButtonContainer, SpinnerContainer } from './StyledDeleteModal';

const DeleteModal = () => {
    const { t } = useTranslation();
    const { cartState, modalOptions, closeModal, removeFromCart } =
        useCartContext();
    const { isSubmitting } = cartState;

    const consignmentId = modalOptions?.consignment?.id;

    const submitRemoval = async () => {
        if (!consignmentId) {
            throw new TypeError('no consignment ID');
        }
        removeFromCart(consignmentId);
        closeModal();
    };

    const show = modalOptions?.type === ModalType.DELETE;

    return (
        <Modal onClose={isSubmitting ? undefined : closeModal} showModal={show}>
            <ModalHeader title={t('modal.delete.title')} />
            {isSubmitting && (
                <ModalContent>
                    <SpinnerContainer>
                        <Spinner size={spinnerSize.large} />
                    </SpinnerContainer>
                </ModalContent>
            )}
            <ModalFooter>
                <ButtonContainer>
                    <Button
                        type="button"
                        disabled={isSubmitting}
                        variant="secondary"
                        name="cancel"
                        onClick={closeModal}
                    >
                        {t('button.cancel')}
                    </Button>
                    <Button
                        type="button"
                        loading={isSubmitting}
                        variant="primary"
                        name="submit"
                        onClick={submitRemoval}
                    >
                        {t('button.delete')}
                    </Button>
                </ButtonContainer>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
