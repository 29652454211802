import PageHeader from 'components/Header';
import InfoBannerLD from 'components/InfoBanner/InfoBannerLD';
import InfoBannerTrial from 'components/InfoBanner/InfoBannerTrial';
import DesktopMenu from 'components/Navigation/DesktopMenu';
import MobileMenu from 'components/Navigation/MobileMenu';
import Navigation from 'components/Navigation/Navigation';
import { useFlags } from 'external/launchDarkly';
import { useUser } from 'hooks/Queries';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useWindowWidthContext } from '../hooks/WindowWidth';
import breakpoints from '../theme/breakpoints';

const NavBarContainer = styled.div`
    width: 100%;
`;

const NavBar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const mobileMathcer = window.matchMedia(`(max-width: ${breakpoints.md})`);
    const width = useWindowWidthContext();
    const { userOnboarding } = useFlags();

    // Old users should not see the onboarding.
    const { user } = useUser();
    const onboardingCutoffDate = DateTime.fromISO('2024-05-24T00:00:00.000Z');
    const enableOnboarding =
        userOnboarding &&
        user?.createdAt &&
        user.createdAt > onboardingCutoffDate;

    const location = useLocation();
    useEffect(() => {
        setIsMobile(mobileMathcer.matches);
    }, [mobileMathcer.matches, width]);
    return (
        <NavBarContainer>
            <InfoBannerLD />
            <InfoBannerTrial />
            <Navigation>
                <Link to={enableOnboarding ? '/onboarding' : '/dashboard'}>
                    <PageHeader
                        active={location.pathname === '/onboarding'}
                        logo
                    />
                </Link>
                {isMobile ? <MobileMenu /> : <DesktopMenu />}
            </Navigation>
        </NavBarContainer>
    );
};

export default NavBar;
