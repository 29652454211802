import { AddonCode, AddonInput } from 'api/search';
import { TFunction } from 'i18next';
import camelCase from 'lodash/camelCase';

/**
 * Returns the localized name of the addon. If none can be found, the addon code is returned.
 */
export const getAddonName = (addonCode: AddonCode, t: TFunction) =>
    t(`addons:${camelCase(addonCode)}`, addonCode);

/**
 * Returns the localized name of the addon, with input if it affects
 * to the input price and selection. If nothing can be found, the addon code
 * is returned.
 */
export const getAddonInput = (
    addon: AddonInput,
    t: TFunction,
    language: string
) => {
    const { code, payload } = addon;
    const localizedAddonName = t(`addons:${camelCase(code)}`, code);
    if (!payload) {
        return localizedAddonName;
    }

    let input;
    switch (code) {
        case 'sendify_insurance':
        case 'carrier_insurance': {
            const val = payload.declaredValue;
            const curr = payload.declaredCurrency;
            if (val === undefined || !curr) {
                input = t('addons:insurance');
            }
            input = Intl.NumberFormat(language, {
                style: 'currency',
                currency: curr,
            }).format(val as number);
            break;
        }
        case 'guaranteed_delivery_time': {
            const { timeOfDay } = payload;
            input = timeOfDay;
            break;
        }
    }
    return input ? `${localizedAddonName} (${input})` : localizedAddonName;
};
