import { ReactNode, useContext } from 'react';
import { ThemeContext } from 'styled-components/macro';

import Heading, { HeadingSize } from '../../Typography/Heading';
import { ButtonProps } from '../Button/Button';
import { SpinnerWrapper, StyledButtonSpinner } from '../Button/StyledButton';
import {
    Badge,
    Container,
    IconButtonColors,
    StyledIconButton,
    Wrapper,
} from './StyledIconButton';

export type IconButtonVariant = 'standard' | 'outlined' | 'danger';
interface IconButtonProps
    extends Omit<
        ButtonProps,
        'fullWidth' | 'iconBefore' | 'iconAfter' | 'variant'
    > {
    id?: string;
    icon?: ReactNode;
    variant?: IconButtonVariant;
    badge?: number;
    badgeColor?: string;
}

export const IconButton = ({
    id,
    icon,
    badge,
    loading,
    'aria-label': ariaLabel,
    'data-cy': dataCy,
    'data-for': dataFor,
    'data-testid': dataTestId,
    children,
    disabled = false,
    onClick,
    size = 'large',
    type = 'button',
    variant = 'standard',
    badgeColor,
    ...rest
}: IconButtonProps) => {
    const { colors } = useContext(ThemeContext);

    const { black, red100, red300, blue200, red200, white } = colors;
    const interactive = !disabled && !loading;

    const commonProps = {
        /**
         * We use aria-disabled instead of disabled. This is for users
         * with screen readers to be able to tab to and read the button.
         */
        'aria-disabled': !interactive,
        /**
         * The aria label is set since the content of the button also includes the loading spinner and any icons
         */
        'aria-label': ariaLabel || icon?.toString(),
        'data-cy': dataCy,
        'data-for': dataFor,
        'data-testid': dataTestId,
        'data-variant': variant,
        /**
         * We use isDisabled instead of disabled to not actually set the disabled
         * attribute on the button (see above on aria-disabled)
         */
        $isDisabled: disabled,
        onClick: interactive ? onClick : undefined,
        $size: size,
        type,
        variant,
        ...rest,
    };
    let $colors: IconButtonColors = { content: 'inherit' };
    switch (variant) {
        case 'standard':
            $colors = {
                background: 'transparent',
                badgeBackground: badgeColor || blue200,
                badgeColor: white,
                hover: `${black}09`,
                border: 'transparent',
                content: 'inherit',
            };
            break;
        case 'outlined':
            $colors = {
                background: 'transparent',
                badgeBackground: badgeColor || blue200,
                badgeColor: white,
                hover: `${black}09`,
                border: black,
                active: black,
                content: 'inherit',
            };
            break;

        case 'danger':
            $colors = {
                background: 'transparent',
                border: 'transparent',
                content: red100,
                badgeBackground: badgeColor || red200,
                badgeColor: white,
                hover: red300,
                active: red300,
            };
    }

    const button = (
        <StyledIconButton
            id={id}
            $colors={$colors}
            $loading={loading}
            {...commonProps}
        >
            {loading && (
                <SpinnerWrapper data-testid="spinner">
                    <StyledButtonSpinner />
                </SpinnerWrapper>
            )}
            <Wrapper $loading={loading}>
                {icon}
                {badge !== undefined && (
                    <Badge $colors={$colors}>{badge}</Badge>
                )}
            </Wrapper>
        </StyledIconButton>
    );
    if (children) {
        return (
            <Container $colors={$colors}>
                {button}
                <label htmlFor={id}>
                    <Heading size={HeadingSize.XSMALL}>{children}</Heading>
                </label>
            </Container>
        );
    }
    return button;
};
