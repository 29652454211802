import { CurrentUser } from '../api/users/types';

export const openChat = () => {
    if ((window as any).HubSpotConversations) {
        (window as any).HubSpotConversations?.widget?.open();
    } else {
        (window as any).hsConversationsOnReady = [
            () => {
                (window as any).HubSpotConversations?.widget?.open();
            },
        ];
    }
};
/**
 * Identifies the user with the hubspot chat to make sure that
 * No one can spoof a different user
 * @param user
 */
export const identifyChat = (user: CurrentUser) => {
    (window as any).hsConversationsSettings = {
        identificationEmail: user.email,
        identificationToken: user.hubSpotToken,
    };
    (window as any).HubSpotConversations?.widget?.load();
};

export const track = (name: string, event: any) => {
    const _hsq = (window as any)._hsq || [];
    _hsq.push([
        'trackCustomBehavioralEvent',
        {
            name,
            properties: {
                ...event,
                occurred_at: new Date().getTime(),
            },
        },
    ]);
};

/**
 * Identifies the current user with properties to a hubspot contacts
 * @param userId
 * @param properties
 */
export const identifyHubspot = (userId: string, properties: any) => {
    const _hsq = (window as any)._hsq || [];
    _hsq.push([
        'identify',
        {
            id: userId,
            ...properties,
        },
    ]);
};
