import {
    StyledCheckmark,
    StyledConfirmation,
} from 'components/IntegrationsModal/Confirmation/StyledConfirmation';
import Heading from 'components/Typography/Heading';
import { Trans, useTranslation } from 'react-i18next';

import { Integration } from '../../../api/subscriptions/types';
import Link from '../../Typography/Link';

interface Props {
    integration: Integration;
}

const Confirmation = ({ integration }: Props) => {
    const { t } = useTranslation('import');
    let confirmationText = (
        <Heading>{t('import:onboarding.iframeConfirmation')}</Heading>
    );
    switch (integration) {
        case 'Prosmart':
        case 'Ongoing':
        case 'Pyramid':
            confirmationText = (
                <Trans t={t} i18nKey="import:onboarding.manualConfirmation">
                    <Link
                        target="_blank"
                        href={t(
                            `import:onboarding.confirmationLink${integration}`
                        )}
                    />
                </Trans>
            );
    }
    return (
        <StyledConfirmation>
            <StyledCheckmark />
            {confirmationText}
        </StyledConfirmation>
    );
};

export default Confirmation;
