import { Consignment, ConsignmentRequest } from 'api/consignments/types';
import Divider from 'components/Divider';
import Flag from 'components/Flag';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';

import {
    Dash,
    Party,
    PathWrapper,
    Person,
    ReceiverIcon,
    SenderIcon,
    StyledEndpoint,
} from './StyledEndpoint';

interface Props {
    consignment: Consignment | ConsignmentRequest;
    withBorder?: boolean;
}
const Endpoint = ({ consignment, withBorder = true }: Props) => {
    const { sender, recipient, viaRecipient, viaSender } = consignment;

    const fromAddress = viaSender?.address || sender.address;
    const toAddress = viaRecipient?.address || recipient.address;
    const senderName = viaSender?.name || sender.name;
    const recipientName = viaRecipient?.name || recipient.name;
    const fromPerson = viaSender?.person || sender.person;
    const toPerson = viaRecipient?.person || recipient.person;

    const fromPersonName = fromPerson?.firstName;
    const fromPersonEmail = fromPerson?.email;
    const fromPersonPhone = fromPerson?.mobilePhoneNumber;
    const fromPersonInfo = [fromPersonName, fromPersonEmail, fromPersonPhone]
        .filter((value) => value)
        .join(', ');

    const toPersonName = toPerson?.firstName;
    const toPersonEmail = toPerson?.email;
    const toPersonPhone = toPerson?.mobilePhoneNumber;
    const toPersonInfo = [toPersonName, toPersonEmail, toPersonPhone]
        .filter((value) => value)
        .join(', ');

    const senderCity = fromAddress.city;
    const senderAddressLine = `${fromAddress.addressLine1}${
        fromAddress.addressLine2 ? ` ${fromAddress.addressLine2}` : ''
    }`;

    const senderPostalCode = fromAddress.postalCode;
    const senderCountryCode = fromAddress.countryCode;
    const senderAddress = senderCity
        ? `${senderAddressLine} ${senderCity}, ${senderPostalCode} ${senderCountryCode}`
        : `${senderPostalCode} ${senderCountryCode}`;

    const recipientCity = toAddress.city;
    const recipientAddressLine = `${toAddress.addressLine1}${
        toAddress.addressLine2 ? ` ${toAddress.addressLine2}` : ''
    }`;

    const recipientPostalCode = toAddress.postalCode;
    const recipientCountryCode = toAddress.countryCode;
    const recipientAddress = recipientCity
        ? `${recipientAddressLine} ${recipientCity}, ${recipientPostalCode} ${recipientCountryCode}`
        : `${recipientPostalCode} ${recipientCountryCode}`;
    return (
        <StyledEndpoint withBorder={withBorder}>
            <PathWrapper>
                <SenderIcon />
                <Dash />
                <ReceiverIcon />
            </PathWrapper>
            <div>
                <Party>
                    <div>
                        <Heading size={HeadingSize.SMALL}>
                            {senderName || '\u00A0'}
                        </Heading>
                        <Text>{senderAddress}</Text>
                        <Person>{fromPersonInfo || '\u00A0'}</Person>
                    </div>
                    <Flag size="20px" countryCode={senderCountryCode} />
                </Party>
                <Divider />
                <Party>
                    <div>
                        <Heading size={HeadingSize.SMALL}>
                            {recipientName || '\u00A0'}
                        </Heading>
                        <Text>{recipientAddress}</Text>
                        <Person>{toPersonInfo || '\u00A0'}</Person>
                    </div>
                    <Flag size="20px" countryCode={recipientCountryCode} />
                </Party>
            </div>
        </StyledEndpoint>
    );
};
export default Endpoint;
