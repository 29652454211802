import { CheckmarkFilled, CrossFilled } from 'assets/icons';
import styled from 'styled-components/macro';
import { WithChildren } from 'types';

interface CheckedListItemProps {
    checked?: boolean;
}

type Props = WithChildren<CheckedListItemProps>;

const CheckedListItemContainer = styled.div<Props>`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: min-content 1fr;
    text-align: left;
`;

const StyledCheckmark = styled(CheckmarkFilled)`
    color: ${({ theme }) => theme.colors.green200};
    height: 20px;
    width: 20px;
`;
const StyledCross = styled(CrossFilled)`
    color: ${({ theme }) => theme.colors.grey200};
    height: 20px;
    width: 20px;
`;

const CheckedListItem = ({ checked = true, children }: Props) => {
    return (
        <CheckedListItemContainer checked={checked}>
            {checked ? <StyledCheckmark /> : <StyledCross />}
            {children}
        </CheckedListItemContainer>
    );
};

export default CheckedListItem;
