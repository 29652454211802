import Modal from 'components/Modal';
import { Variant } from 'components/Notifications/types';
import Payment from 'containers/Settings/components/PriceTierPayment';
import { useFlags } from 'external/launchDarkly';
import { getFrontendLanguage } from 'helpers/LanguageHelper';
import { useTeam } from 'hooks/Queries';
import { usePersistentState } from 'hooks/usePersistentState';
import usePriceTierTrial from 'hooks/usePriceTierTrial';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import InfoBanner from './InfoBanner';

const InfoBannerTrial = () => {
    const { t } = useTranslation(['dashboard']);
    const { plusTrialMarginGroup, enableInvoiceSubscription } = useFlags();
    const { team } = useTeam();
    const trialStatus = usePriceTierTrial();

    const [hasSeenPayment, setHasSeenPayment] = usePersistentState(
        'seenPaymentModal',
        false
    );
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const onClose = () => {
        // Assume that they have seen the payment modal only when they actively
        // close it. This is to ensure that we show the modal again if they
        // navigate away from the page.
        setHasSeenPayment(true);
        setShowPaymentModal(false);
    };

    // Show the payment modal if the trial has ended. German teams are not yet
    // onboarded on this flow, so show it for Swedish teams only via LD.
    useEffect(() => {
        if (
            enableInvoiceSubscription &&
            trialStatus === 'ended' &&
            !hasSeenPayment
        ) {
            setShowPaymentModal(true);
        }
    }, [trialStatus, team, enableInvoiceSubscription]);

    if (!trialStatus) {
        return null;
    }
    if (!team) {
        return null;
    }

    if (!plusTrialMarginGroup.targetingEnabled) {
        return null;
    }

    if (!plusTrialMarginGroup.userSelected) {
        return null;
    }

    if (trialStatus === 'used' || trialStatus === 'notUsed') {
        return null;
    }

    let title;
    let variant: Variant = 'info';

    const lang = getFrontendLanguage();
    if (!lang) {
        return null;
    }

    switch (trialStatus) {
        case 'started':
            title = t('dashboard.trialBanner.started.title');
            variant = 'success';
            break;

        case 'ending':
            title = t('dashboard.trialBanner.ending.title');
            variant = 'success';
            break;

        case 'ended':
            title = t('dashboard.trialBanner.ended.title');
            variant = 'tip';
            break;
    }
    return (
        <>
            <InfoBanner
                name={`trialBanner_${team.id}_${trialStatus}`}
                title={title}
                variant={variant}
            >
                <Trans
                    t={t}
                    i18nKey={`dashboard.trialBanner.${trialStatus}.subtitle`}
                >
                    <StyledExternalLink
                        href={plusTrialMarginGroup.externalLink}
                    />
                    <StyledReactLink to="/settings/price-tier" />
                </Trans>
            </InfoBanner>
            {!hasSeenPayment && (
                <Modal
                    showModal={showPaymentModal}
                    onClose={onClose}
                    width="600px"
                >
                    <Payment tier="se_tier_2" onClose={onClose} />
                </Modal>
            )}
        </>
    );
};

export default InfoBannerTrial;

const StyledReactLink = styled(Link)`
    color: black;
    text-decoration: underline;
`;

const StyledExternalLink = styled.a`
    color: black;
    text-decoration: underline;
`;
