import Bowser from 'bowser';

export const browsers = Bowser.BROWSER_MAP;

export const isSupportedBrowser = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    if (!browser.getBrowserName()) {
        // Something has gone wrong in Bowser, take them to the app.
        return true;
    }

    const browserConstants = browsers;

    const unSupportedBrowsers = {
        [browserConstants.ie]: '<12',
        [browserConstants.chrome]: '<77',
        [browserConstants.firefox]: '<78',
        [browserConstants.android]: '<103',
        [browserConstants.safari]: '<14.1',
        [browserConstants.opera]: '<64',
        [browserConstants.edge]: '<79',
        [browserConstants.samsung_internet]: '<12',
        [browserConstants.vivaldi]: '<2',
        [browserConstants.uc]: '<12.12',
        // Cypress
        [browserConstants.electron]: '<8.3.1',
    };

    return !browser.satisfies(unSupportedBrowsers);
};

export const getAlternativeBrowsers = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    switch (browser.getOSName()) {
        case Bowser.OS_MAP.Android:
            return [browsers.chrome, browsers.firefox, browsers.opera];
        case Bowser.OS_MAP.iOS:
        case Bowser.OS_MAP.MacOS:
            return [browsers.chrome, browsers.safari, browsers.firefox];
        case Bowser.OS_MAP.Windows:
            return [browsers.chrome, browsers.edge, browsers.firefox];
        default:
            return [browsers.chrome, browsers.firefox];
    }
};

export const getBrowserWithVersion = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    const browserVersion = browser.getBrowserVersion();
    return browserName && browserVersion
        ? `${browserName} ${browserVersion}`
        : '';
};

export const getLongName = (browserName: string) => {
    switch (browserName) {
        case browsers.chrome:
            return 'Google Chrome';
        case browsers.firefox:
            return 'Mozilla Firefox';
        case browsers.edge:
            return 'Microsoft Edge';
        default:
            return browserName;
    }
};

export const getShortName = (browserName: string) => {
    switch (browserName) {
        case browsers.chrome:
            return 'Chrome';
        case browsers.firefox:
            return 'Firefox';
        case browsers.edge:
            return 'Edge';
        default:
            return browserName;
    }
};

/**
 *
 * @param browserName As defined in the browser constant
 * @return An international download link for the browser
 */
export const getDownloadLink = (browserName: string) => {
    switch (browserName) {
        case browsers.chrome:
            return 'https://www.google.com/chrome/';
        case browsers.firefox:
            return 'https://www.mozilla.org/firefox/download/';
        case browsers.edge:
            return 'https://www.microsoft.com/edge';
        case browsers.opera:
            return 'https://www.opera.com/download';
        case browsers.safari:
            return 'https://support.apple.com/downloads/safari';
        default:
            return undefined;
    }
};

export const getLogoSource = (browserName: string) => {
    switch (browserName) {
        case browsers.chrome:
            return '/misc/browser-logos/chrome.svg';
        case browsers.firefox:
            return '/misc/browser-logos/firefox.svg';
        case browsers.edge:
            return '/misc/browser-logos/microsoft_edge.svg';
        case browsers.opera:
            return '/misc/browser-logos/opera.svg';
        case browsers.safari:
            return '/misc/browser-logos/safari.svg';
        default:
            return undefined;
    }
};
