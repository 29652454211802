import CountrySelect from 'components/FormElements/CountrySelect';
import Input from 'components/FormElements/Input';
import { Select } from 'components/FormElements/Select';
import Notification from 'components/Notifications/Notification';
import styled from 'styled-components/macro';

export const InputGrid = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    grid-column-gap: ${({ theme }) => theme.space.s2};
    grid-row-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: 1fr 2fr;
`;

export const TwoColumnInput = styled(Input)`
    grid-column: 1/3;
`;
export const SecondColumnInput = styled(Input)`
    grid-column: 2;
`;
export const FirstColumnInput = styled(Input)`
    grid-column: 1;
`;
export const TwoColumnCountrySelect = styled(CountrySelect)`
    grid-column: 1/3;
`;
export const TwoColumnSelect = styled(Select)`
    grid-column: 1/3;
`;

export const TwoColumnNotification = styled(Notification)`
    grid-column: 1/3;
`;
