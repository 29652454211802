import { parseDates } from 'helpers/FormatHelper';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
export function usePersistentState<S>(
    key: string
): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

export function usePersistentState<S>(
    key: string,
    defaultValue: S
): [S, Dispatch<SetStateAction<S>>];

export function usePersistentState<S>(
    key: string,
    defaultValue: S,
    storage: Storage
): [S, Dispatch<SetStateAction<S>>];

/**
 * Similar to useState, except the state is also saved in localStorage.
 */
export function usePersistentState<S>(
    key: string,
    defaultValue?: S,
    storage: Storage = localStorage
): [S, Dispatch<SetStateAction<S>>] {
    const [state, setState] = useState<S>(
        () => parseDates(storage.getItem(key))?.value ?? defaultValue
    );
    useEffect(() => {
        storage.setItem(key, JSON.stringify({ value: state }));
    }, [key, storage, state]);
    return [state, setState];
}
