import { PackageType } from 'api/packages/types';
import RadioButton from 'components/FormElements/RadioButton';
import RadioButtonGroup from 'components/FormElements/RadioButtonGroup';
import Notification from 'components/Notifications/Notification';
import Text from 'components/Typography/Text';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioButtonWrapper } from './StyledStackableSelect';

interface Props {
    errorMessage?: string;
    value?: true | false;
    packageType?: PackageType;
    readOnly?: boolean;
    onChange: (value: boolean) => void;
}

const StackableSelect = (
    { packageType, errorMessage, value, onChange, readOnly }: Props,
    ref?: any
) => {
    const { t } = useTranslation('goods');

    return (
        <div>
            {packageType !== 'PACKAGE' && (
                <RadioButtonWrapper>
                    <RadioButtonGroup
                        ref={ref}
                        horizontal
                        title={t('goods:stackableTitle')}
                        value={(() => {
                            if (value === undefined) {
                                return undefined;
                            } else return value ? 'STACKABLE' : 'NON_STACKABLE';
                        })()}
                        onChange={(newValue) =>
                            newValue === 'STACKABLE'
                                ? onChange(true)
                                : onChange(false)
                        }
                        errorMessage={errorMessage}
                        readOnly={readOnly}
                    >
                        <RadioButton
                            label={t('common.yes')}
                            value="STACKABLE"
                        />
                        <RadioButton
                            label={t('common.no')}
                            value="NON_STACKABLE"
                        />
                    </RadioButtonGroup>
                </RadioButtonWrapper>
            )}

            {packageType === 'PACKAGE' && (
                <Notification variant="note">
                    <Text>{t('goods:description.stackablePackage')}</Text>
                </Notification>
            )}

            {packageType === 'PALLET' && (
                <Notification variant="note">
                    <Text>{t('goods:description.stackablePallet')}</Text>
                </Notification>
            )}

            {packageType === 'UNSPECIFIED' && (
                <Notification variant="note">
                    <Text>{t('goods:description.stackableUnspecified')}</Text>
                </Notification>
            )}
        </div>
    );
};

export default forwardRef(StackableSelect);
