import Input from 'components/FormElements/Input';
import { borderWidth } from 'components/FormElements/Input/StyledInput';
import styled from 'styled-components/macro';

export const AddressWrapper = styled.div`
    display: grid;
    grid-column-gap: ${({ theme }) => theme.space.s3};
    grid-row-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: 3fr minmax(auto, 2fr) 3fr;
    margin-top: ${({ theme }) => theme.space.s1};
    white-space: nowrap;
`;

export const FullWidthInput = styled(Input)`
    grid-column: -1/1;
`;
export const CardWrapper = styled.div`
    display: grid;
    grid-row-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: 1fr;
    margin-top: ${({ theme }) => theme.space.s1};
`;
export const CardInputWrapper = styled.div<{
    hasLoaded: boolean;
    invalid: boolean;
}>`
    border: ${({ theme, hasLoaded, invalid }) => {
        if (hasLoaded) {
            if (invalid) {
                return `${borderWidth} solid ${theme.colors.red100}`;
            } else return `${borderWidth} solid ${theme.colors.grey300}`;
        } else return 'none';
    }};
    border-radius: 0;
    margin-top: ${({ theme }) => theme.space.s1};
    padding: ${({ theme }) => `${theme.space.s2} ${theme.space.s3}`};
`;
