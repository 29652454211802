import { InfoCircle } from 'assets/icons';
import Input from 'components/FormElements/Input';
import {
    InputWrapper,
    StyledInput,
} from 'components/FormElements/Input/StyledInput';
import Tooltip from 'components/Tooltip';
import Text, { TextSize } from 'components/Typography/Text';
import { ReactNode, useId } from 'react';
import styled, { css } from 'styled-components/macro';

export type Size = 'medium' | 'small';

interface Props {
    label?: string;
    value?: number;
    name?: string;
    min?: number;
    max?: number;
    size?: Size;
    readOnly?: boolean;
    onChange: (newValue?: number) => void;
    'data-cy-down'?: string;
    'data-cy-up'?: string;
    className?: string;
    id?: string;
    /** Displays an information icon with this as the content of its tooltip. */
    tooltip?: string | ReactNode;
}

const StyledStepper = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey300};
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
`;

const StepperButton = styled.button<{ stepperSize: Size }>`
    background: white;
    border: none;
    ${({ stepperSize }) =>
        stepperSize === 'medium'
            ? css`
                  font-size: 16px;
                  width: 40px;
              `
            : css`
                  width: 36px;
              `};

    :disabled {
        cursor: not-allowed;
    }

    cursor: pointer;
`;
export const Label = styled.label`
    display: block;
`;
const Minus = styled(StepperButton)`
    border-right: 1px solid ${({ theme }) => theme.colors.grey500};
`;
const Plus = styled(StepperButton)`
    border-left: 1px solid ${({ theme }) => theme.colors.grey500};
`;
const ValueInput = styled(Input)<{ stepperSize: Size; readOnly?: boolean }>`
    ${({ readOnly, theme }) =>
        readOnly ? `color: ${theme.colors.grey100}` : ''};

    ${InputWrapper} {
        border: none;
        border-radius: 0;
        height: auto;
        min-width: 40px;
    }

    ${StyledInput} {
        ${({ stepperSize }) =>
            stepperSize === 'small'
                ? css`
                      height: auto;
                      padding: 0;
                  `
                : css`
                      /* This will match input fields, given the border of the buttons */
                      height: 42px;
                  `};
        text-align: center;
        width: 100%;
    }
`;

export const TopText = styled.div`
    align-items: center;
    cursor: default;
    display: flex;
    gap: ${({ theme }) => theme.space.s2};
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s1};
    width: 100%;
`;

const Stepper = ({
    label,
    value,
    onChange,
    className,
    size = 'small',
    min = 1,
    max = 99,
    readOnly,
    name,
    tooltip,
    id,
    'data-cy-up': dataCyUp,
    'data-cy-down': dataCyDown,
}: Props) => {
    /* 
        We always need an ID if we have a label, for label click etc to work. 
        If the developer doesn't supply an ID, generate a unique one.
        */
    const generatedId = useId();
    const inputId = id ?? generatedId;
    return (
        <div>
            {(label || tooltip) && (
                <TopText>
                    {label && (
                        <Label htmlFor={inputId}>
                            <Text size={TextSize.MEDIUM}>{label}</Text>
                        </Label>
                    )}
                    {tooltip && (
                        <>
                            <InfoCircle
                                height="20"
                                data-tooltip-id={`tooltip-${inputId}`}
                            />
                            <Tooltip id={`tooltip-${inputId}`}>
                                {tooltip}
                            </Tooltip>
                        </>
                    )}
                </TopText>
            )}
            <StyledStepper className={className}>
                <Minus
                    stepperSize={size}
                    data-cy={dataCyDown}
                    data-testid="stepper-down"
                    type="button"
                    disabled={!value || value - 1 < min || readOnly}
                    onClick={() => {
                        if (value) {
                            onChange(value - 1);
                        }
                    }}
                >
                    -
                </Minus>
                <ValueInput
                    stepperSize={size}
                    value={value || ''}
                    max={max}
                    min={min}
                    name={name}
                    id={inputId}
                    type="number"
                    onBlur={() => {
                        // Reset to min if leaving blank field
                        if (!value) {
                            onChange(min);
                        }
                    }}
                    onValueChange={(v) => {
                        const number = parseInt(v);

                        // Make it possible to delete the last digit
                        if (number) {
                            if (number >= min && number <= max) {
                                onChange(number);
                            }
                        } else {
                            onChange(undefined);
                        }
                    }}
                    readOnly={readOnly}
                    data-testid="stepper-value"
                />
                <Plus
                    stepperSize={size}
                    type="button"
                    data-testid="stepper-up"
                    data-cy={dataCyUp}
                    disabled={!value || value + 1 > max || readOnly}
                    onClick={() => {
                        if (value) {
                            onChange(value + 1);
                        }
                    }}
                >
                    +
                </Plus>
            </StyledStepper>
        </div>
    );
};

export default Stepper;
