import {
    BackendMetaData,
    BackendPagination,
    MetaData,
    Pagination,
} from 'api/types';

export const backendMetadataToMetaData = (
    backend: BackendMetaData
): MetaData => ({
    pagination: backendPaginationToPagination(backend.pagination),
});

export const backendPaginationToPagination = (
    backend: BackendPagination
): Pagination => ({
    count: backend.count,
    perPage: backend.per_page,
    currentPage: backend.current_page,
    total: backend.total,
    totalPages: backend.total_pages,
});

export const getBase64 = (file?: File): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
        if (!file) {
            resolve(undefined);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        }
    });
};
