import { createContext, useContext } from 'react';
import { WithChildren } from 'types';

import { useBeamer } from './beamer';
import { UseBeamerOptions } from './types';

const BeamerContext = createContext<UseBeamerOptions | null>(null);

BeamerContext.displayName = 'BeamerContext';

export const useBeamerContext = () => {
    return useContext(BeamerContext) as UseBeamerOptions;
};

export const BeamerProvider = ({ children }: WithChildren) => {
    const beamer = useBeamer();
    return (
        <BeamerContext.Provider value={beamer}>
            {children}
        </BeamerContext.Provider>
    );
};
