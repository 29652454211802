import * as Sentry from '@sentry/react';
import { Language, getFrontendLanguage } from 'helpers/LanguageHelper';
import { useEffect, useState } from 'react';
import { SystemCountry } from 'types/UserData';

/**
 * This must the same as in the initialization script in the .env file.
 * The selector can't be updated using Beamer.update(), and needs to be
 * set in the original script.
 */
export const selectorId = 'jsBeamerSelector';

/** Filters as used in Beamer's segmentation. */
type filter = 'internal' | 'SE' | 'DE';

/** Set the language used when filtering items to display in Beamer. */
export const setLanguage = (language: Language) => {
    // Beamer uses ISO 639-1 language codes (EN, SV, etc), always in upper case.
    if (window.Beamer) {
        window.Beamer.update({ language: language.toUpperCase() });
    }
};

export const useBeamer = () => {
    const [notificationNumber, setNotificationNumber] = useState(0);

    const updateNumberOfPosts = () => {
        setNotificationNumber(window.Beamer?.notificationNumber || 0);
    };

    useEffect(() => {
        /**
         * Beamer updates its notification status every 10 minutes. Check for updates on
         * this once a minute (leading to a 11 minute update frequency in the worst case
         * scenario), to update our own notification count. This is useful for when users
         * are signed in for longer periods of time.
         */
        const intervalId = setInterval(() => updateNumberOfPosts, 1000 * 60); // 1 minute
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const onOpen = () => {
        // Use a small delay so the notification number can be set to 0.
        setTimeout(updateNumberOfPosts, 500);
    };

    /** Identifies a user in Beamer. Also initializes Beamer, if not already started. */
    const identify = async (
        userID: string,
        email: string,
        validated: boolean,
        systemCountry: SystemCountry
    ) => {
        if (!window.Beamer?.started) {
            await initialize(userID, email);
        }

        // Filters, see https://www.getbeamer.com/docs/#parameters
        const filters: filter[] = [systemCountry];

        const sendifyEmailMatcher = new RegExp(/@sendify\.(se|de|com)$/g);
        if (email.match(sendifyEmailMatcher) && validated) {
            // Define internal users as those with a validated Sendify email address.
            filters.push('internal');
        }

        window.Beamer?.update({
            user_email: email,
            user_id: userID,
            filter: filters.join(';'),
            callback: setNotificationNumber,
            onopen: onOpen,
            onclose: updateNumberOfPosts,
        });
    };

    /** Start Beamer. */
    const initialize = async (userID: string, email: string) => {
        // Wait for beamer to load
        const maxRetries = 5;
        let retries = 0;
        (async () => {
            while (!window.Beamer && retries < maxRetries) {
                await new Promise((resolve) => setTimeout(resolve, 250));
                retries++;
            }
        })();

        if (retries === maxRetries) {
            // Break the initialization. The user can't do anything about it, so just log to Sentry.
            Sentry.captureException('Could not initialize Beamer');
            return;
        }

        const language = getFrontendLanguage();
        if (language) {
            setLanguage(language);
        }

        window.Beamer?.update({
            user_id: userID,
            user_email: email,
            callback: setNotificationNumber,
            onopen: onOpen,
            onclose: updateNumberOfPosts,
        });
        // Start
        window.Beamer?.init();
    };

    return { identify, notificationNumber };
};
