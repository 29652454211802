import {
    RoundAmicron,
    RoundBuroware,
    RoundFortnox,
    RoundJeeves,
    RoundJtl,
    RoundLogtrade,
    RoundMonitor,
    RoundOngoing,
    RoundPyramid,
    RoundSage,
    RoundShopify,
    RoundSpecter,
    RoundStarweb,
    RoundVisma,
    RoundWoocommerce,
} from 'assets/partnerLogos';

import { KnownIntegrations } from '../../api/subscriptions/types';

interface Props {
    integration: KnownIntegrations;
    className?: string;
}

/**
 * A smaller version of a company's logo.
 */
const SubmarkIntegrationLogo = ({ integration, className }: Props) => {
    switch (integration) {
        case 'Fortnox':
            return <RoundFortnox className={className} />;
        case 'Shopify':
            return <RoundShopify className={className} />;
        case 'WooCommerce':
            return <RoundWoocommerce className={className} />;
        case 'Visma E-Economy':
            return <RoundVisma className={className} />;
        case 'Specter':
            return <RoundSpecter className={className} />;
        case 'Pyramid':
            return <RoundPyramid className={className} />;
        case 'Monitor':
            return <RoundMonitor className={className} />;
        case 'Visma Admin 500':
            return <RoundVisma className={className} />;
        case 'Visma Admin 1000':
            return <RoundVisma className={className} />;
        case 'Visma Admin 2000':
            return <RoundVisma className={className} />;
        case 'Jeeves':
            return <RoundJeeves className={className} />;
        case 'Starweb':
            return <RoundStarweb className={className} />;
        case 'Ongoing':
            return <RoundOngoing className={className} />;
        case 'Logtrade':
            return <RoundLogtrade className={className} />;
        case 'JTL':
            return <RoundJtl className={className} />;
        case 'Amicron':
            return <RoundAmicron className={className} />;
        case 'Büroware':
            return <RoundBuroware className={className} />;
        case 'Sage':
            return <RoundSage className={className} />;
        default:
            return null;
    }
};

export default SubmarkIntegrationLogo;
