export const TRACKERS_BOOTED = 'trackersBooted';
export const TEAM_ID = 'teamId';
export const CURRENCY = 'currency';
export const SYSTEM_COUNTRY = 'country';
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const SHOW_NAVIGATION = 'showNavigation';
export const LANGUAGE = 'language';
export const signupFormExperiment = 'signupFormExperiment';
export const tokenChangedKey = 'tokenChanged';
