import { InfoCircle } from 'assets/icons';
import Tooltip from 'components/Tooltip';
import Text, { TextSize } from 'components/Typography/Text';
import { PropsWithChildren, ReactNode } from 'react';

import ErrorMessage from '../ErrorMessage';
import { StyledSelect, TopText } from './StyledSelect';

type Props = {
    label?: string | ReactNode;
    tooltip?: string | ReactNode;
    inputId: string;
    error?: boolean;
    errorMessage?: string | ReactNode;
    className?: string;
};

/**
 * A wrapper for all our select components. Adds a title, tooltip, and handles error messages.
 */
const Wrapper = ({
    label: title,
    tooltip,
    inputId,
    error,
    errorMessage,
    children,
    className,
}: PropsWithChildren<Props>) => {
    return (
        <>
            <StyledSelect error={!!error} className={className}>
                {(title || tooltip) && (
                    <TopText>
                        {title && (
                            <label htmlFor={inputId}>
                                <Text size={TextSize.MEDIUM}>{title}</Text>
                            </label>
                        )}
                        {tooltip && (
                            <>
                                <InfoCircle
                                    height="20"
                                    data-tooltip-id={`tooltip-${inputId}`}
                                />
                                <Tooltip id={`tooltip-${inputId}`}>
                                    {tooltip}
                                </Tooltip>
                            </>
                        )}
                    </TopText>
                )}
                {children}
                {errorMessage && error && (
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
            </StyledSelect>
        </>
    );
};

export default Wrapper;
