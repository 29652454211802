import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

const size = '20px';
const padding = '8px';
export const HiddenInput = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    left: 0;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 1px;
`;

export const Radio = styled.div<{ interactive: boolean; checked?: boolean }>`
    :before {
        box-sizing: border-box;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.white};
        bottom: 0;
        content: '';
        height: ${size};
        margin-bottom: auto;
        margin-top: auto;
        position: absolute;
        top: 0;
        width: ${size};
    }

    :after {
        box-sizing: border-box;
        bottom: 0;
        content: '';
        margin-bottom: auto;
        margin-top: auto;
        position: absolute;
        top: 0;
    }

    ~ * {
        margin-left: ${({ theme }) => theme.space.s7};
    }

    ${({ theme }) => {
        return css`
            // When the "real" radiobutton is focused, we want to highlight the visible one.
            ${HiddenInput}:focus + &:before {
                box-shadow: 0 0 0 1px ${theme.colors.white},
                    0 0 0 2px ${theme.colors.blue200};
            }

            ${HiddenInput}:focus:not(:focus-visible) + &:before {
                box-shadow: none;
            }
        `;
    }}
    ${({ interactive, checked }) => {
        if (checked) {
            if (!interactive) {
                return css`
                    :before {
                        background: ${({ theme }) => theme.colors.grey400};
                        border-color: ${({ theme }) => theme.colors.grey200};

                        :hover {
                            border-color: ${({ theme }) => theme.colors.black};
                        }
                    }

                    :after {
                        background: ${({ theme }) => theme.colors.grey200};
                        border-radius: 50%;
                        content: '';
                        height: 8px;
                        margin-left: 6px;
                        position: absolute;
                        width: 8px;
                    }
                `;
            }
            return css`
                :before {
                    background: ${({ theme }) => theme.colors.black};
                    border-color: ${({ theme }) => theme.colors.black};

                    :hover {
                        border-color: ${({ theme }) => theme.colors.black};
                    }
                }

                :after {
                    background: ${({ theme }) => theme.colors.white};
                    border-radius: 50%;
                    content: '';
                    height: 8px;
                    margin-left: 6px;
                    position: absolute;
                    width: 8px;
                }
            `;
        } else {
            if (!interactive) {
                return css`
                    :before {
                        background-color: ${({ theme }) =>
                            theme.colors.grey400};
                        border: 1px solid ${({ theme }) => theme.colors.grey200};
                    }
                `;
            }
            return css`
                :before {
                    border: 1px solid ${({ theme }) => theme.colors.grey100};
                }

                :hover:before {
                    border: 1px solid ${({ theme }) => theme.colors.black};
                }
            `;
        }
    }}
`;

export const RadioText = styled(Text)<{ interactive: boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${({ interactive, theme }) =>
        !interactive
            ? css`
                  color: ${theme.colors.grey100};
              `
            : ''};
`;

export const Label = styled.label<{ interactive: boolean }>`
    min-height: calc(${size} + ${padding} * 2);
    // Offset the padding, so we can keep the clickable area but not
    // interrupt the layout.
    margin-left: calc(${padding} * -1);
    min-width: calc(${size} + ${padding} * 2);
    // Add some clickable area outside the radiobutton. The total clickable
    // area is the same size as a small Button.
    padding: ${padding};
    cursor: ${({ interactive }) => (interactive ? 'pointer' : 'not-allowed')};
    display: grid;
    min-height: ${size};
    min-width: ${size};
    position: relative;
`;
