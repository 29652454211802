import styled from 'styled-components/macro';

export const ChangeTierContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 48px 1fr;
    justify-items: center;
`;

export const PlanInfo = styled.div`
    align-self: flex-start;
    height: 100%;
    width: 100%;
`;
