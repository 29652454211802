import { post } from 'api';

import * as config from '../../config';
import { toBackendFreightForwarding } from './mapper';
import { FreightForwarding } from './types';

export const newFreightForwarding = async (
    freightForwarding: FreightForwarding
): Promise<void> => {
    await post<void>(
        `${config.goDomain}v2/freight-forwarding`,
        toBackendFreightForwarding(freightForwarding)
    );
};
