import styled from 'styled-components/macro';

export const InfoWrapper = styled.div<{ showButton: boolean }>`
    align-items: center;
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s6};
    grid-template-columns: ${({ showButton }) =>
        showButton ? '1fr auto' : '1fr'};
    width: 100%;
`;
