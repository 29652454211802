import { InfoCircle } from 'assets/icons';
import Tooltip from 'components/Tooltip';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import {
    InputHTMLAttributes,
    ReactNode,
    forwardRef,
    useEffect,
    useId,
    useState,
} from 'react';

import { ErrorMessage, StyledTextArea, TopText } from './StyledTextArea';

interface Props {
    errorMessage?: string;
    label?: string;
    /** Displays an information icon with this as the content of its tooltip. */
    tooltip?: string | ReactNode;
}

type TextAreaProps = Props & InputHTMLAttributes<HTMLTextAreaElement>;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            id,
            disabled,
            errorMessage,
            label,
            tooltip,
            maxLength,
            name,
            onBlur,
            onChange,
            onFocus,
            placeholder,
            required,
            value,
            className,
            autoComplete,
            ...rest
        }: TextAreaProps,
        ref
    ) => {
        /* 
        We always need an ID if we have a label, for label click etc to work. 
        If the developer doesn't supply an ID, generate a unique one.
        */
        const generatedId = useId();
        const inputId = id ?? generatedId;

        /* 
        Use useState for keeping track of the input length rather than accessing
        the ref. Even if the underlying value of the ref changes, this won't trigger
        a re-render.
        */
        const [inputLength, setInputLength] = useState(
            value?.toString().length || 0
        );

        useEffect(() => {
            setInputLength(value?.toString().length || 0);
        }, [value]);

        const changeValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setInputLength(event.target.value.toString().length);
            if (onChange) {
                onChange(event);
            }
        };
        return (
            <div className={className}>
                <TopText>
                    <Heading size={HeadingSize.XSMALL}>
                        <label htmlFor={inputId}>{label}</label>
                    </Heading>
                    {tooltip && (
                        <>
                            <InfoCircle
                                height="20"
                                data-tooltip-id={`tooltip-${inputId}`}
                            />
                            <Tooltip id={`tooltip-${inputId}`}>
                                {tooltip}
                            </Tooltip>
                        </>
                    )}
                    {maxLength && (
                        <Text data-testid="max-length">{`${inputLength}/${maxLength}`}</Text>
                    )}
                </TopText>
                <StyledTextArea
                    data-testid="input"
                    ref={ref}
                    id={inputId}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={changeValue}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    required={required}
                    disabled={disabled}
                    maxLength={maxLength}
                    autoComplete={autoComplete}
                    hasError={!!errorMessage}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                />

                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>
        );
    }
);

export default TextArea;
