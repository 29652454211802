import ApiError from 'api/ApiError';
import { getUsersConfirmEmail } from 'api/users';
import Spinner, { spinnerSize } from 'components/Spinner/Spinner';
import Confirmed from 'containers/ConfirmEmail/Confirmed';
import { CenteredContainer } from 'containers/ConfirmEmail/StyledConfirmEmail';
import { useUser } from 'hooks/Queries';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Expired from './Expired';

enum STATUS {
    ACTIVATED,
    EXPIRED,
}

const ConfirmEmail = () => {
    const { pathname } = useLocation();
    const [status, setStatus] = useState<STATUS>();
    const [isCheckingToken, setIsCheckingToken] = useState(true);
    const { user, isLoading: isFetchingUser } = useUser({ retry: false });
    useEffect(() => {
        const checkToken = async () => {
            const token = pathname.substring(pathname.lastIndexOf('/') + 1);
            try {
                await getUsersConfirmEmail(token);
                setStatus(STATUS.ACTIVATED);
            } catch (error: unknown) {
                const err = error as ApiError;
                if (err.response.status === 422) {
                    // BE returns 422 only for the case when the token is already activated.
                    setStatus(STATUS.ACTIVATED);
                } else {
                    setStatus(STATUS.EXPIRED);
                }
            }
            setIsCheckingToken(false);
        };

        checkToken();
    }, [pathname]);

    useEffect(() => {
        if (user?.activated) {
            setStatus(STATUS.ACTIVATED);
        }
    }, [user]);

    if (isCheckingToken || isFetchingUser) {
        return (
            <CenteredContainer>
                <div data-testid="spinner" />
                <Spinner size={spinnerSize.large} />
            </CenteredContainer>
        );
    }
    switch (status) {
        case STATUS.ACTIVATED:
            return <Confirmed />;
        case STATUS.EXPIRED:
            return <Expired email={user?.email} />;
        default:
            return null;
    }
};

export default ConfirmEmail;
