import CountrySelect from 'components/FormElements/CountrySelect';
import Input from 'components/FormElements/Input';
import { Select, SelectComponent } from 'components/FormElements/Select';
import Notification from 'components/Notifications/Notification';
import styled, { css } from 'styled-components/macro';

export const LinkButton = styled.button`
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.blue200};
    cursor: pointer;
    display: block;
    font-size: 12px;
    grid-column: 1/3;
    padding: 0;
    place-self: flex-start;
`;

export const Wrapper = styled.div`
    display: grid;
    grid-row-gap: ${({ theme }) => theme.space.s4};
    grid-template-columns: 100%;
    white-space: normal;
`;

export const Header = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s3};
`;

export const InputGrid = styled.div`
    border: ${({ theme }) => `1px solid ${theme.colors.grey400}`};
    border-radius: ${({ theme }) => theme.space.s1};
    display: grid;
    grid-column-gap: ${({ theme }) => theme.space.s2};
    grid-row-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: 1fr 2fr;
    padding: ${({ theme }) => theme.space.s4};
`;

export const TwoColumnInput = styled(Input)`
    grid-column: 1/3;
`;
export const SecondColumnInput = styled(Input)`
    grid-column: 2;
`;
export const FirstColumnInput = styled(Input)`
    grid-column: 1;
`;
export const TwoColumnCountrySelect = styled(CountrySelect)`
    grid-column: 1/3;
`;
export const TwoColumnSelect = styled(Select)`
    grid-column: 1/3;
` as SelectComponent;

export const TwoColumnNotification = styled(Notification)`
    grid-column: 1/3;
`;

export const ToggleWrapper = styled.div<{ interactive: boolean }>`
    align-items: center;
    display: flex;
    justify-content: space-between;

    label {
        ${({ interactive, theme }) =>
            !interactive
                ? css`
                      color: ${theme.colors.grey100};
                  `
                : ''};
        cursor: ${({ interactive }) =>
            interactive ? 'pointer' : 'not-allowed'};
    }
`;

export const TwoColumnToggle = styled(ToggleWrapper)`
    grid-column: 1/3;
`;
export const StyledTip = styled(Notification)`
    grid-column: 1/3;
    margin-top: ${({ theme }) => theme.space.s2};
`;
