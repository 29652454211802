import { get, post, put, remove } from 'api';
import queryString from 'query-string';

import * as config from '../../config';
import { backendMetadataToMetaData } from '../mapper';
import { backendPackageToPackage, toBackendPackageRequest } from './mapper';
import { Package, PackageRequest } from './types';

export const getFavoritePackages = async (pageNo: number, perPage: number) => {
    const params = { page: pageNo, per_page: perPage };
    const response = await get(
        `${config.goDomain}v2/packages/favorites?${queryString.stringify(
            params
        )}`
    );

    const packages: Package[] =
        response.parsedBody?.data.map(backendPackageToPackage) || [];

    return {
        packages,
        meta: backendMetadataToMetaData(response.parsedBody?.meta),
    };
};

export const createFavoritePackage = async (packageInfo: PackageRequest) => {
    const response = await post(
        `${config.goDomain}v2/packages/favorites`,
        toBackendPackageRequest(packageInfo)
    );
    return response.parsedBody;
};

export const updateFavoritePackage = async (
    id: number,
    packageInfo: PackageRequest
) => {
    const response = await put(
        `${config.goDomain}v2/packages/favorites/${id}`,
        toBackendPackageRequest(packageInfo)
    );
    return response.parsedBody;
};

export const deleteFavoritePackage = async (favid: string) => {
    const response = await remove(
        `${config.goDomain}v2/packages/favorites/${favid}`
    );
    return response.parsedBody;
};
