import { Position } from 'components/Slider/Slider';
import styled, { css } from 'styled-components/macro';

export const BackdropContainer = styled.div<{ show: boolean }>`
    background-color: ${({ theme }) => theme.colors.black};
    bottom: 0;
    height: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.2s;
    z-index: ${({ theme }) => theme.zIndex.sliderBackdrop};
    ${({ show }) => {
        if (show) {
            return css`
                height: auto;
                opacity: 0.5;
                pointer-events: all;
            `;
        }
    }};
`;

export const Container = styled.div<{
    show: boolean;
    background?: string;
    position: Position;
    width: string;
}>`
    -webkit-overflow-scrolling: touch;
    background: ${({ background, theme }) => background || theme.colors.white};
    bottom: 0;
    box-shadow: ${({ show }) =>
        show ? '0px 4px 16px rgba(0, 0, 0, 0.1)' : ''};
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.black};
    font-size: 12px;
    font-weight: normal;
    height: 100vh;
    line-height: 16px;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;

    position: fixed;
    top: 0;
    ${({ position, show, width }) =>
        position === 'left'
            ? css`
                  left: 0;
                  transform: ${show
                      ? 'translateX(0)'
                      : `translateX(-${width})`};
              `
            : css`
                  right: 0;
                  transform: ${show ? 'translateX(0)' : `translateX(${width})`};
              `}

    transition: transform 0.3s;
    width: ${({ width }) => width};
    will-change: transform;
    z-index: ${({ theme }) => theme.zIndex.slider};
`;

export const AbsoluteContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.popover};
`;

export const Content = styled.div`
    padding: 24px;
`;
