import {
    MutableRefObject,
    ReactElement,
    RefAttributes,
    forwardRef,
    useId,
} from 'react';
import type { GroupBase, SelectInstance } from 'react-select';
import type { AsyncProps } from 'react-select/async';
import AsyncReactSelect from 'react-select/async';

import Wrapper from './Wrapper';
import useSelectProps from './useSelectProps';

export type AsyncSelectComponent = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: AsyncProps<Option, IsMulti, Group> &
        RefAttributes<SelectInstance<Option, IsMulti, Group>>
) => ReactElement;

/**
 * An asynchronous select component that loads options as the user types.
 */
const AsyncSelect = forwardRef(
    <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
        props: AsyncProps<Option, IsMulti, Group>,
        ref:
            | ((
                  instance: SelectInstance<Option, IsMulti, Group> | null
              ) => void)
            | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
            | null
    ) => {
        const selectProps = useSelectProps(props);
        const { inputId: originalId } = selectProps;
        /* 
        We always need an ID if we have a label, for label click etc to work. 
        If the developer doesn't supply an ID, generate a unique one.
        */
        const generatedId = useId();
        const inputId = originalId ?? generatedId;

        return (
            <Wrapper {...selectProps} inputId={inputId}>
                <AsyncReactSelect
                    ref={ref}
                    {...selectProps}
                    inputId={inputId}
                />
            </Wrapper>
        );
    }
) as AsyncSelectComponent;

export default AsyncSelect;
