import styled from 'styled-components/macro';

export const StyledApiKeyForm = styled.form`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s4};
`;

export const StyledApiKey = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s4};
    margin-top: ${({ theme }) => theme.space.s6};
    position: relative;
    width: max-content;
`;

export const ApiInformationTable = styled.div`
    align-items: center;
    display: grid;
    grid-column-gap: ${({ theme }) => theme.space.s6};
    grid-row-gap: ${({ theme }) => theme.space.s1};
    grid-template-columns: max-content auto;
    grid-template-rows: auto auto;
    margin-top: ${({ theme }) => theme.space.s4};
    position: relative;
`;
export const ApiKeyWrapper = styled.div`
    align-items: flex-end;
    display: grid;
    grid-template-areas:
        'input copy'
        'note note';
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    margin-top: ${({ theme }) => theme.space.s2};
    width: 500px;
`;

export const SpinnerWrapper = styled.div`
    /*  50% opacity in hexadecimal */
    background: #ffffff80;
    display: grid;
    height: 100%;
    place-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.popover};
`;
