export enum Environment {
    PRODUCTION = 1,
    SANDBOX = 2,
}
export interface ZwapgridParams {
    otc?: string;
    clientId?: string;
    orgNo?: string;
    name?: string;
    email?: string;
    source?: string;
    companyId?: string;
    target?: 'Sendify';
    lang: string;
    'output.connection.apiKey'?: string;
    'output.connection.EndpointType'?: Environment;
    'output.connection.title'?: string;
    'export.connection.title'?: string;
    'export.connection.BaseUrl'?: string;
    'export.connection.ConsumerKey'?: string;
    'export.connection.ConsumerSecret'?: string;
    'export.connection.urlId'?: string;
    'export.connection.token'?: string;
    'export.connection.apiKey'?: string;
    'export.connection.ApiKey'?: string;
    'export.connection.License'?: string;
    'export.connection.SbmId'?: string;
    'export.connection.UserId'?: string;
}

export interface ZwapgridEvent {
    authorizationCode: string;
}
