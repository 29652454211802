import { ChevronDown } from 'assets/icons';
import Flag from 'components/Flag';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';

const DropdownButton = styled.button`
    align-items: center;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 44px;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        height: 44px;
    }
    justify-content: space-between;
    line-height: 18px;
    outline: none;
    padding: ${({ theme }) => `0 ${theme.space.s3}`};
    width: 144px;
    ${({ border }) =>
        border &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.black};
        `}
    ${({ background }) =>
        background &&
        css`
            background: ${({ theme }) => theme.colors.white};
        `}
    &:hover {
        background: ${({ theme }) => theme.colors.grey500};
    }
    &:focus {
        background: ${({ theme }) => theme.colors.grey500};
    }
`;

const LanguageName = styled.span`
    margin-left: ${({ theme }) => theme.space.s2};
`;

const Dropdown = styled.div`
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
    padding: ${({ theme }) => theme.space.s2} 0;
    position: absolute;
    right: 0;
    width: 142px;
    ${({ theme }) =>
        css`
            top: calc(40px + ${theme.space.s3});
        `}

    z-index: ${({ theme }) => theme.zIndex.dropdown};
`;

const DropDownDownArrow = styled(ChevronDown)`
    fill: currentColor;
    height: 20px;
    width: 20px;
`;

const OptionContent = styled.div`
    align-items: center;
    display: flex;
`;

const DropdownOption = styled.button`
    align-items: center;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    display: flex;
    font-size: 14px;

    font-style: normal;
    font-weight: 500;
    height: 40px;
    line-height: 18px;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        height: 44px;
    }
    outline: none;
    padding: 0 ${({ theme }) => theme.space.s3};
    width: 100%;

    &:hover {
        background: ${({ theme }) => theme.colors.grey500};
    }
    &:focus {
        background: ${({ theme }) => theme.colors.grey500};
    }
`;
const DropdownContainer = styled.div`
    position: relative;
`;

const getFlag = (languageCode) => {
    switch (languageCode) {
        case 'sv':
            return 'SE';
        case 'de':
            return 'DE';
        case 'en':
            return 'GB';
        default:
            return '';
    }
};

const getLanguageName = (languageCode) => {
    switch (languageCode) {
        case 'sv':
            return 'Svenska';
        case 'de':
            return 'Deutsch';
        case 'en':
            return 'English';
        default:
            return '';
    }
};

const languageOptions = ['sv', 'de', 'en'];

const getRenderedOption = (countryCode) => {
    const flagCode = getFlag(countryCode);
    return (
        <OptionContent>
            <Flag countryCode={flagCode} />
            <LanguageName>{getLanguageName(countryCode)}</LanguageName>
        </OptionContent>
    );
};

// The default dropdown component is too restrictive and does not
// fit this use case without major rewrite.
const DEPRECATEDLanguageDropdown = (props) => {
    const { language, selectLanguage } = props;

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const changeLanguageFromDropdown = (newLanguage) => {
        setOpen(false);
        selectLanguage(newLanguage);
    };

    const dropdownContainer = useRef(null);
    const handleClickOutside = (event) => {
        if (
            dropdownContainer.current &&
            !dropdownContainer.current.contains(event.target)
        ) {
            setOpen(false);
        }
    };
    // Handle clicks outside the dropdown.
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    // Fetch what options to display in the dropdown.
    const inactiveLanguages = languageOptions.slice();
    const activeLanguageIndex = inactiveLanguages.indexOf(language);
    if (activeLanguageIndex > -1) {
        inactiveLanguages.splice(activeLanguageIndex, 1);
    }

    return (
        <DropdownContainer ref={dropdownContainer}>
            <DropdownButton
                data-testid="active-language"
                onClick={toggleOpen}
                border={props.border}
                background={props.background}
            >
                {getRenderedOption(language)}
                <DropDownDownArrow />
            </DropdownButton>

            {open && (
                <Dropdown data-testid="inactive-languages">
                    {inactiveLanguages.map((inactiveLanguage) => (
                        <DropdownOption
                            key={inactiveLanguage}
                            data-testid={`inactive-language-${inactiveLanguage}`}
                            onClick={() =>
                                changeLanguageFromDropdown(inactiveLanguage)
                            }
                        >
                            {getRenderedOption(inactiveLanguage)}
                        </DropdownOption>
                    ))}
                </Dropdown>
            )}
        </DropdownContainer>
    );
};

DEPRECATEDLanguageDropdown.propTypes = {
    language: PropTypes.string.isRequired,
    border: PropTypes.bool,
    background: PropTypes.bool,
    selectLanguage: PropTypes.func.isRequired,
};

export default DEPRECATEDLanguageDropdown;
