import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: grid;
    grid-row-gap: ${({ theme }) => theme.space.s4};
    grid-template-columns: 100%;
    white-space: normal;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const SwitchWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.space.s2};
    justify-content: space-between;
`;
