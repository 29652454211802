import { Breakpoints } from 'styled-components/macro';

const breakpoints: Breakpoints = {
    xs: '480px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1400px',
};
export default breakpoints;
