import Divider from 'components/Divider';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTeam } from 'hooks/Queries';
import { Duration } from 'luxon';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import BreakdownItem from './BreakdownItem';
import { List, StyledBreakdownItem } from './StyledCostBreakdown';

export interface CostItem {
    name: string;
    /** If discounted */
    originalMonthlyCost?: number;
    monthlyCost: number;
    /** Must have conversionAccuracy: 'longterm' */
    duration: Duration;
}

type Props = {
    /** May be empty. If this is the case, a total sum of 0 is displayed. */
    items: CostItem[];
    sumStartText: string;
    isTrial?: boolean;
};

/**
 * A list of subscription items, that add up to a total. The total will be displayed below all items.
 *
 * The sums, and any discounts, are calculated automatically based on the number of intervals and the cost per interval.
 */
const CostBreakdown = ({ items, isTrial, sumStartText }: Props) => {
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    if (!team) {
        return null;
    }

    const { currency } = team;
    const totalCost = items.reduce(
        (acc, i) => acc + i.monthlyCost * i.duration.as('months'),
        0
    );

    if (!currency) {
        return null;
    }
    return (
        <List>
            {items.map(
                (
                    { monthlyCost, duration, originalMonthlyCost, name },
                    index
                ) => {
                    {
                        /* We have to use the index in the key, since there might be
                         multiple integrations of the same type */
                    }
                    return (
                        <Fragment key={`${name}-${index}`}>
                            <BreakdownItem
                                name={name}
                                monthlyCost={monthlyCost}
                                originalMonthlyCost={originalMonthlyCost}
                                duration={duration}
                            />
                            <Divider key={`${name}-${index}-divider`} />
                        </Fragment>
                    );
                }
            )}
            <StyledBreakdownItem>
                <Heading size={HeadingSize.MEDIUM}>
                    {isTrial
                        ? t('api.subscriptionChange.costBreakdown.totalTrial')
                        : t('api.subscriptionChange.costBreakdown.total')}
                </Heading>
                <Heading size={HeadingSize.MEDIUM}>
                    {formatCost(totalCost, i18n.language, currency, 2, 2)}
                </Heading>
                <Text>{sumStartText}</Text>
                <Text>
                    {t('api.subscriptionChange.costBreakdown.withoutVAT')}
                </Text>
            </StyledBreakdownItem>
        </List>
    );
};

export default CostBreakdown;
