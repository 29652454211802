import { HttpResponse } from 'api/types';

class ApiError extends Error {
    response: HttpResponse;

    errors: any;

    error: any;

    constructor(response: HttpResponse) {
        super(`Call to ${response.url} failed with status ${response.status}`);
        this.response = response;
        this.errors = response?.parsedBody?.errors;
        this.error =
            response?.parsedBody?.error || response?.parsedBody?.message;
        this.name = 'ApiError';
    }
}

export default ApiError;
