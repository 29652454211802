import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const CodeLogoWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
    display: grid;
    height: 20px;
    place-items: center;
    width: 20px;
    svg {
        height: 16px;
        width: 16px;
    }
`;

export const StyledText = styled(Text)`
    white-space: nowrap;
`;

export const OwnIntegrationContainer = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: auto 1fr;
`;
