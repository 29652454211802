import styled from 'styled-components/macro';

import RadioButton from '../../FormElements/RadioButton/RadioButton';

export const IconWrapper = styled.div`
    align-items: start;
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    justify-content: start;

    svg {
        height: 24px;
        width: auto;
    }
`;
export const StyledRadioButton = styled(RadioButton)`
    width: 100%;
`;
export const SystemsInfo = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    text-align: left;
`;
