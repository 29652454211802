import { createContext, useContext } from 'react';
import { WithChildren } from 'types';

import { UseWindowWidthOptions } from './types';
import { useWindowWidth } from './useWindowWidth';

const WindowWidthContext = createContext<UseWindowWidthOptions | null>(null);

WindowWidthContext.displayName = 'WindowWidthContext';

export const useWindowWidthContext = () => {
    return (useContext(WindowWidthContext) as UseWindowWidthOptions).width;
};

export const WindowWidthProvider = ({ children }: WithChildren) => {
    const width = useWindowWidth();
    return (
        <WindowWidthContext.Provider value={{ width }}>
            {children}
        </WindowWidthContext.Provider>
    );
};
