import {
    Consignment,
    ConsignmentRequest,
    Source,
    isRequest,
} from 'api/consignments/types';
import { Package, PackageRequest } from 'api/packages/types';
import { sha256 } from 'js-sha256';

export const getMaximumPackageWeight = (
    consignment: Consignment | ConsignmentRequest
) => {
    const { packages } = consignment;

    return Math.max(
        ...(packages as (Package | PackageRequest)[]).map(
            (pack) => pack.weightKg
        )
    );
};

export const getMaximumPackageLength = (
    consignment: Consignment | ConsignmentRequest
) => {
    const { packages } = consignment;

    return Math.max(
        ...(packages as (Package | PackageRequest)[]).map(
            (pack) => pack.depthCm
        )
    );
};

export const getMaximumPackageWidth = (
    consignment: Consignment | ConsignmentRequest
) => {
    const { packages } = consignment;

    return Math.max(
        ...(packages as (Package | PackageRequest)[]).map(
            (pack) => pack.widthCm
        )
    );
};

export const getMaximumPackageHeight = (
    consignment: Consignment | ConsignmentRequest
) => {
    const { packages } = consignment;

    return Math.max(
        ...(packages as (Package | PackageRequest)[]).map(
            (pack) => pack.heightCm
        )
    );
};

export const getTotalWeight = (
    consignment: Consignment | ConsignmentRequest
) => {
    const { packages } = consignment;

    return (packages as (PackageRequest | Package)[]).reduce(
        (acc: number, pack: Package | PackageRequest) => acc + pack.weightKg,
        0
    );
};

export const getVolume = (consignment: Consignment | ConsignmentRequest) => {
    const { packages } = consignment;

    const totalVolume = (packages as (PackageRequest | Package)[]).reduce(
        (acc: number, pack: Package | PackageRequest) =>
            // Convert from cm² to m²
            acc + (pack.widthCm * pack.depthCm * pack.heightCm) / 1000000,
        0
    );

    return totalVolume.toFixed(5);
};

export const getPrivate = (isPrivate: boolean) => {
    return isPrivate ? 'Private' : 'Business';
};

export const getHashedEmail = (email: string) => {
    // hashed email for the data later for google analytics tracking.
    const hash = sha256.create();
    hash.update(email);
    return hash.hex();
};

export const getConsignmentSource = (
    consignment: Consignment | ConsignmentRequest
): Source => {
    if (isRequest(consignment)) {
        return 'SENDIFY';
    }
    return consignment?.externalSource?.source ?? 'SENDIFY';
};

/**
 * Takes a string range from the qualification modal on the form 0, X–Y or Z+ and converts it to a min and max value
 * @param input string matching N, X–Y or Z+
 */
export const getQualificationMinMax = (input?: string) => {
    if (!input) {
        return { min: undefined, max: undefined };
    }
    const array = input.split('–');
    // The input is not a range but a single value X or the end value X+
    if (array.length === 1) {
        // If the range is the final one we denote it as X+
        if (input.includes('+')) {
            const cleaned = input.replace('+', '');
            const number = Number(cleaned);
            if (isNaN(number)) {
                return { min: undefined, max: undefined };
            } else {
                return { min: number, max: undefined };
            }
        }
    }
    // default the max to the first item if the value is not a range
    const [minString, maxString = minString] = array;
    const min = Number(minString);
    const max = Number(maxString);
    if (isNaN(min) || isNaN(max)) {
        return { min: undefined, max: undefined };
    }
    return { min, max };
};
