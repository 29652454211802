import { Checkmark, Lightbulb, WarningOutline } from 'assets/icons';
import { Close, InfoCircle } from 'assets/icons/material';
import { IconButton } from 'components/Buttons';
import {
    Container,
    Content,
    IconWrapper,
    Message,
} from 'components/Notifications/StyledNotification';
import { Variant } from 'components/Notifications/types';
import Text from 'components/Typography/Text';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WithChildren } from 'types';

interface Props {
    variant?: Variant;
    /** Overrides the default icon. */
    icon?: ReactNode;
    showNotification?: boolean;
    handleClose?: () => void;
    'data-cy'?: string;
    className?: string;
    size?: Size;
}
export type Size = 'large' | 'small';

const Notification = ({
    className,
    variant = 'info',
    showNotification = true,
    'data-cy': dataCy,
    handleClose,
    children,
    icon: propsIcon,
    size = 'large',
}: WithChildren<Props>) => {
    const { t } = useTranslation('old');
    let icon = propsIcon;
    if (icon === undefined) {
        switch (variant) {
            case 'info':
                icon = <InfoCircle />;
                break;
            case 'success':
                icon = <Checkmark />;
                break;
            case 'error':
                icon = <InfoCircle />;
                break;
            case 'warning':
                icon = <WarningOutline />;
                break;
            case 'tip':
                icon = <Lightbulb />;
                break;
            default:
                break;
        }
    }
    return (
        <Container
            className={className}
            variant={variant}
            size={size}
            showNotification={showNotification}
        >
            <Content variant={variant}>
                {icon && <IconWrapper>{icon}</IconWrapper>}
                <Message data-cy={dataCy}>
                    <Text>{children}</Text>
                </Message>
            </Content>
            {handleClose && (
                <IconButton
                    aria-label={t('modal.generic.close')}
                    icon={<Close />}
                    onClick={handleClose}
                />
            )}
        </Container>
    );
};

export default Notification;
