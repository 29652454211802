import {
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { generateApiKey, getApiKeyInformation } from 'api/auth';
import { ApiKeyInformation } from 'api/auth/types';

export const useApiKeyInformation = (
    options?: Omit<UseQueryOptions<ApiKeyInformation>, 'queryKey'>
) => {
    const { data: apiKeyInformation, ...rest } = useQuery(
        ['apiKeyInformation'],
        getApiKeyInformation,
        options
    );
    return { apiKeyInformation, ...rest };
};

/**
 * Generating a new API key via mutating this hook both returns a new key,
 * and will use the supplied data to optimistically update the
 * API key information getter with a masked key and the API user
 * ID.
 */
export const useGenerateApiKey = (
    options?: UseMutationOptions<string, unknown, number>
) => {
    const queryClient = useQueryClient();

    const { data: apiKey, ...rest } = useMutation(generateApiKey, {
        ...options,
        onSettled: async (apiKey, error, userId) => {
            await queryClient.cancelQueries(['apiKeyInformation']);

            queryClient.setQueryData<ApiKeyInformation>(
                ['apiKeyInformation'],
                () => {
                    return {
                        userId: userId || null,
                        apiKey: apiKey || null,
                    };
                }
            );

            queryClient.invalidateQueries(['apiKeyInformation']);
        },
    });
    return { apiKey, ...rest };
};
