import styled from 'styled-components/macro';

export const TopText = styled.span`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s1};
`;

export const StyledSelect = styled.div<{ error: boolean }>`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    flex-grow: 0.5;
`;
