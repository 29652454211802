import { DateTime } from 'luxon';

export enum RelativeDate {
    TODAY = 'TODAY',
    TOMORROW = 'TOMORROW',
    YESTERDAY = 'YESTERDAY',
    WITHIN_A_WEEK = 'WITHIN_A_WEEK',
    FORMATTED = 'FORMATTED',
}
export default class DateHelper {
    static isInPast(date: DateTime) {
        return date.diffNow().milliseconds < 0;
    }

    static isHoliday(date: DateTime, holidays: string[]) {
        return holidays.includes(date.toISODate());
    }

    static isWeekend(date: DateTime) {
        const weekday = date.weekday;
        return weekday === 6 || weekday === 7;
    }

    static isValidPickupDate(date: DateTime, holidays: string[]) {
        return (
            !this.isHoliday(date, holidays) &&
            !this.isWeekend(date) &&
            date > DateTime.now().minus({ days: 1 })
        );
    }

    static getNextValidPickupDate(date: DateTime, holidays: string[]) {
        let newDate = date;
        while (!this.isValidPickupDate(newDate, holidays)) {
            newDate = newDate.plus({ days: 1 });
        }
        newDate = newDate.set({ hour: 8 });
        return newDate;
    }

    static getRelativeDate(from: DateTime, to: DateTime): RelativeDate {
        const yesterday = from.minus({ days: 1 });
        const tomorrow = from.plus({ days: 1 });
        const nextWeek = from.plus({ weeks: 1 }).endOf('day');

        if (to.hasSame(from, 'day')) {
            return RelativeDate.TODAY;
        }
        if (to.hasSame(yesterday, 'day')) {
            return RelativeDate.YESTERDAY;
        }
        if (to.hasSame(tomorrow, 'day')) {
            return RelativeDate.TOMORROW;
        }
        if (to > tomorrow && to < nextWeek) {
            return RelativeDate.WITHIN_A_WEEK;
        }
        return RelativeDate.FORMATTED;
    }
    /* This function is needed since output and input formats differ between the different endpoints.
     *  when all dates are in one format this can be removed.
     */
    static parseDate(date: string): DateTime {
        let parsed = DateTime.fromISO(date);
        if (!parsed.isValid) {
            parsed = DateTime.fromFormat(date, 'yyyy-MM-dd TT');
        }
        return parsed;
    }

    static getTimeIntervalString(
        date: DateTime,
        hoursBefore: number,
        hoursAfter: number
    ): string {
        const startString = date
            .set({
                hour: date.hour - hoursBefore,
                minute: 0,
            })
            .toLocaleString(DateTime.TIME_24_SIMPLE);
        const endString = date
            .set({
                hour: date.hour + hoursAfter,
                minute: 0,
            })
            .toLocaleString(DateTime.TIME_24_SIMPLE);
        return `${startString}–${endString}`;
    }
}
