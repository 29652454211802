import { Close } from 'assets/icons';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Typography/Text';
import { useContext } from 'react';
import {
    DropdownIndicatorProps,
    GroupBase,
    MultiValueGenericProps,
    MultiValueRemoveProps,
    OptionProps,
    PlaceholderProps,
    SingleValueProps,
    components,
} from 'react-select';
import { ThemeContext } from 'styled-components/macro';

import {
    StyledIndicator,
    StyledMultiValueContainer,
    StyledMultiValueLabel,
    StyledMultiValueRemove,
    StyledPlaceholder,
    StyledSingleValue,
} from './StyledSelectComponents';

/**
 * Shown as a button for removing a value in a multi-value select component.
 */
const MultiValueRemove = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: MultiValueRemoveProps<Option, IsMulti, Group>
) => {
    return (
        <StyledMultiValueRemove>
            <components.MultiValueRemove {...props}>
                <Close width="16px" height="16px" />
            </components.MultiValueRemove>
        </StyledMultiValueRemove>
    );
};

/**
 * Shown as a label for a value in a multi-value select component.
 */
const MultiValueLabel = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: MultiValueGenericProps<Option, IsMulti, Group>
) => <StyledMultiValueLabel>{props.children}</StyledMultiValueLabel>;

/**
 * A wrapper for the values in a multi-value select component.
 */
const MultiValueContainer = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: MultiValueGenericProps<Option, IsMulti, Group>
) => <StyledMultiValueContainer>{props.children}</StyledMultiValueContainer>;

/**
 * A placeholder, displayed when no value has been selected.
 */
const Placeholder = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: PlaceholderProps<Option, IsMulti, Group>
) => (
    // The test ID is used in TestHelper.selectOption
    <StyledPlaceholder data-testid="select-placeholder">
        {props.children}
    </StyledPlaceholder>
);

/**
 * The little arrow on the right-hand side of the Select component.
 */
const DropdownIndicator = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>
) => <StyledIndicator isDisabled={props.isDisabled} />;

/**
 * A single selected value.
 */
const SingleValue = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: SingleValueProps<Option, IsMulti, Group>
) => <StyledSingleValue>{props.children}</StyledSingleValue>;

/**
 * A single value in the dropdown.
 */
const Option = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: OptionProps<Option, IsMulti, Group>
) => (
    <Text>
        <components.Option {...props} />
    </Text>
);

const LoadingIndicator = () => {
    const { colors } = useContext(ThemeContext);

    return (
        <div data-testid="select-spinner">
            {/* Make the spinner visible, but not eye-catching */}
            <Spinner color={colors.grey200} />
        </div>
    );
};

export default {
    DropdownIndicator,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    LoadingIndicator,
    Placeholder,
    SingleValue,
    Option,
};
