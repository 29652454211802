import { useQuery } from '@tanstack/react-query';
import { getFavoritePackages } from 'api/packages';
import { Package } from 'api/packages/types';

import { MetaData } from './../../api/types';

export const useFavoritePackages = (pageNo: number, perPage: number) => {
    const { data, ...rest } = useQuery<{ packages: Package[]; meta: MetaData }>(
        ['favoritePackages', pageNo, perPage],
        () => getFavoritePackages(perPage, pageNo)
    );
    return {
        packages: data?.packages,
        meta: data?.meta,
        ...rest,
    };
};
