import { Checkmark } from 'assets/icons';
import styled from 'styled-components/macro';

export const StyledConfirmation = styled.div`
    align-content: center;
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s3};
    height: 30vh;
    place-items: center;
    text-align: center;
    width: 774px;
`;
export const StyledCheckmark = styled(Checkmark)`
    color: ${({ theme }) => theme.colors.green200};
    fill: ${({ theme }) => theme.colors.green200};
    height: 40px;
    width: 40px;
`;
