import Input from 'components/FormElements/Input';
import styled from 'styled-components/macro';

export const StyledInstructions = styled.ol<{ prefix: string }>`
    counter-reset: item;
    list-style-type: none;
    padding-left: ${({ theme }) => theme.space.s20};
    li {
        counter-increment: item;
        padding-bottom: ${({ theme }) => theme.space.s4};
        padding-left: ${({ theme }) => theme.space.s8};
        padding-top: ${({ theme }) => theme.space.s4};
    }
    li::marker {
        content: ${(props) => `'${props.prefix} ' counter(item, decimal) ' '`};
        font-size: 14px;
        font-weight: 500;
    }
`;

export const NarrowInput = styled(Input)`
    margin-top: ${({ theme }) => theme.space.s4};
    max-width: 450px;
`;
