import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RawLogo } from './logo.svg';

export const Logo = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawLogo {...props} role="img" aria-label={t('assets:sendify.logo')} />
    );
};
