import styled, { css } from 'styled-components/macro';
import { WithChildren } from 'types';

interface FlexContainerProps {
    direction?: 'row' | 'column';
    alignItems?: string;
    justifyContent?: string;
    fullWidth?: boolean;
    fullHeight?: boolean;
    flexWrap?: string;
    className?: string;
    background?: string;
    maxWidth?: string;
    inlineFlex?: boolean;
    margin?: string;
    padding?: string;
    height?: string;
    minHeight?: string;
    mobileWrap?: boolean;
    mobileColumn?: boolean;
    justifyContentMobile?: string;
}

type Props = WithChildren<FlexContainerProps>;

const Flex = styled.div<Props>`
    align-items: ${({ alignItems }) => alignItems};
    background: ${({ background }) => background || ''};
    display: ${({ inlineFlex }) => (inlineFlex ? 'inline-flex' : 'flex')};
    flex-direction: ${({ direction }) =>
        direction === 'row' ? 'row' : 'column'};
    flex-wrap: ${({ flexWrap }) => flexWrap || ''};
    height: ${({ fullHeight, height }) =>
        fullHeight ? '100%' : height || 'auto'};
    justify-content: ${({ justifyContent }) => justifyContent};
    margin: ${({ margin }) => margin || ''};
    max-width: ${({ maxWidth }) => maxWidth || ''};
    min-height: ${({ minHeight }) => minHeight || ''};
    padding: ${({ padding }) => padding || ''};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        ${({ mobileColumn }) => (mobileColumn ? 'flex-direction:column;' : '')}
        height:auto;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        ${({ mobileWrap }) => (mobileWrap ? 'flex-wrap: wrap;' : '')}
        ${({ justifyContentMobile, justifyContent }) =>
            justifyContentMobile
                ? css`
                      justify-content: ${justifyContentMobile};
                  `
                : css`
                      justify-content: ${justifyContent};
                  `}
    }
`;

const DEPRECATEDFlexContainer = ({
    direction = 'column',
    className = '',
    alignItems,
    justifyContent,
    background,
    fullHeight,
    fullWidth,
    maxWidth,
    flexWrap,
    inlineFlex,
    mobileColumn,
    margin,
    padding,
    height,
    minHeight,
    mobileWrap,
    justifyContentMobile,
    children,
}: Props) => {
    return (
        <Flex
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}
            className={className}
            background={background}
            fullHeight={fullHeight}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            flexWrap={flexWrap}
            inlineFlex={inlineFlex}
            mobileColumn={mobileColumn}
            margin={margin}
            padding={padding}
            height={height}
            minHeight={minHeight}
            mobileWrap={mobileWrap}
            justifyContentMobile={justifyContentMobile}
        >
            {children}
        </Flex>
    );
};

export default DEPRECATEDFlexContainer;
