import styled from 'styled-components/macro';

export const ItemWrapper = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    border-top: 1px solid ${({ theme }) => theme.colors.grey400};
    margin-bottom: ${({ theme }) => theme.space.s4};
    padding: ${({ theme }) => `${theme.space.s4} ${theme.space.s6}`};
    width: 100%;
`;

export const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
`;
