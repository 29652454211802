import { Alpha2Code } from 'i18n-iso-countries';

type PostalCode = string;
type EuMemberExceptions = Partial<Record<Alpha2Code, Set<PostalCode>>>;

export const euMembers = new Set<Alpha2Code>([
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
]);

// Postal codes that belong to EU member states but do not themselves belong to the EU.
export const euMemberExceptions: EuMemberExceptions = {
    // Åland Islands
    FI: new Set([
        '22920',
        '22270',
        '22830',
        '22220',
        '22930',
        '22710',
        '22340',
        '22410',
        '22411',
        '22130',
        '22240',
        '22150',
        '22950',
        '22520',
        '22820',
        '22730',
        '22840',
        '22610',
        '22630',
        '22100',
        '22101',
        '22110',
        '22111',
        '22120',
        '22140',
        '22160',
        '22310',
        '22430',
        '22810',
        '22720',
        '22530',
        '22330',
        '22910',
        '22550',
        '22940',
        '22320',
    ]),
    // Büsingen am Hochrhein and Heligoland
    DE: new Set(['78266', '27498']),
    // Mount Athos
    GR: new Set(['63087']),
    // Livigno and Campione d'Italia
    IT: new Set(['23030', '22060']),
    // Melilla, Ceuta, and the Canary Islands
    ES: new Set([
        '52001',
        '52002',
        '52003',
        '52004',
        '52005',
        '52006',
        '51001',
        '51002',
        '51003',
        '51004',
        '51005',
        '35329',
        '35350',
        '35610',
        '35230',
        '35509',
        '35107',
        '35480',
        '35638',
        '35299',
        '35259',
        '35259',
        '35260',
        '35628',
        '35639',
        '35458',
        '35119',
        '35018',
        '35018',
        '35217',
        '35300',
        '35414',
        '35630',
        '35459',
        '35129',
        '35333',
        '35307',
        '35412',
        '35211',
        '35120',
        '35118',
        '35328',
        '35500',
        '35542',
        '35108',
        '35478',
        '35350',
        '35400',
        '35329',
        '35571',
        '35612',
        '35307',
        '35369',
        '35109',
        '35627',
        '35100',
        '35210',
        '35129',
        '35110',
        '35414',
        '35307',
        '35319',
        '35349',
        '35129',
        '35488',
        '35260',
        '35571',
        '35571',
        '35610',
        '35129',
        '35431',
        '35571',
        '35421',
        '35626',
        '35421',
        '35457',
        '35339',
        '35510',
        '35458',
        '35468',
        '35468',
        '35626',
        '35338',
        '35119',
        '35349',
        '35128',
        '35110',
        '35469',
        '35457',
        '35411',
        '35411',
        '35469',
        '35610',
        '35330',
        '35469',
        '35412',
        '35457',
        '35230',
        '35310',
        '35450',
        '35107',
        '35637',
        '35570',
        '35330',
        '35468',
        '35431',
        '35422',
        '35468',
        '35129',
        '35319',
        '35432',
        '35558',
        '35649',
        '35106',
        '35329',
        '35469',
        '35558',
        '35610',
        '35540',
        '35540',
        '35450',
        '35329',
        '35432',
        '35400',
        '35413',
        '35412',
        '35110',
        '35100',
        '35627',
        '35459',
        '35560',
        '35110',
        '35350',
        '35100',
        '35628',
        '35413',
        '35468',
        '35415',
        '35349',
        '35423',
        '35368',
        '35240',
        '35329',
        '35629',
        '35368',
        '35110',
        '35328',
        '35119',
        '35431',
        '35149',
        '35216',
        '35280',
        '35478',
        '35458',
        '35369',
        '35149',
        '35639',
        '35369',
        '35214',
        '35217',
        '35308',
        '35457',
        '35349',
        '35218',
        '35218',
        '35638',
        '35611',
        '35149',
        '35216',
        '35308',
        '35570',
        '35479',
        '35610',
        '35580',
        '35107',
        '35610',
        '35211',
        '35218',
        '35479',
        '35149',
        '35299',
        '35128',
        '35450',
        '35108',
        '35130',
        '35489',
        '35120',
        '35629',
        '35627',
        '35128',
        '35468',
        '35329',
        '35368',
        '35280',
        '35216',
        '35216',
        '35611',
        '35580',
        '35307',
        '35469',
        '35572',
        '35120',
        '35120',
        '35660',
        '35660',
        '35338',
        '35469',
        '35328',
        '35130',
        '35328',
        '35638',
        '35571',
        '35018',
        '35350',
        '35421',
        '35413',
        '35627',
        '35610',
        '35580',
        '35100',
        '35508',
        '35560',
        '35118',
        '35300',
        '35110',
        '35479',
        '35128',
        '35369',
        '35220',
        '35413',
        '35328',
        '35328',
        '35329',
        '35432',
        '35478',
        '35489',
        '35329',
        '35215',
        '35479',
        '35457',
        '35300',
        '35310',
        '35339',
        '35307',
        '35260',
        '35329',
        '35369',
        '35309',
        '35217',
        '35329',
        '35350',
        '35489',
        '35299',
        '35368',
        '35570',
        '35369',
        '35468',
        '35415',
        '35458',
        '35629',
        '35458',
        '35423',
        '35017',
        '35017',
        '35423',
        '35469',
        '35339',
        '35479',
        '35216',
        '35400',
        '35329',
        '35422',
        '35240',
        '35128',
        '35458',
        '35215',
        '35299',
        '35478',
        '35215',
        '35413',
        '35250',
        '35300',
        '35300',
        '35400',
        '35330',
        '35469',
        '35310',
        '35432',
        '35650',
        '35560',
        '35110',
        '35269',
        '35339',
        '35368',
        '35338',
        '35328',
        '35219',
        '35413',
        '35216',
        '35400',
        '35413',
        '35128',
        '35559',
        '35368',
        '35423',
        '35106',
        '35328',
        '35369',
        '35400',
        '35310',
        '35539',
        '35216',
        '35422',
        '35280',
        '35107',
        '35130',
        '35280',
        '35128',
        '35339',
        '35309',
        '35423',
        '35308',
        '35280',
        '35339',
        '35414',
        '35415',
        '35412',
        '35479',
        '35018',
        '35328',
        '35328',
        '35411',
        '35414',
        '35307',
        '35479',
        '35017',
        '35106',
        '35309',
        '35018',
        '35330',
        '35280',
        '35280',
        '35320',
        '35414',
        '35309',
        '35613',
        '35216',
        '35128',
        '35328',
        '35414',
        '35338',
        '35626',
        '35649',
        '35300',
        '35307',
        '35328',
        '35458',
        '35469',
        '35488',
        '35108',
        '35570',
        '35430',
        '35107',
        '35559',
        '35421',
        '35018',
        '35215',
        '35422',
        '35319',
        '35412',
        '35328',
        '35460',
        '35308',
        '35308',
        '35219',
        '35218',
        '35308',
        '35660',
        '35118',
        '35400',
        '35610',
        '35570',
        '35218',
        '35307',
        '35308',
        '35620',
        '35339',
        '35544',
        '35489',
        '35450',
        '35559',
        '35541',
        '35612',
        '35520',
        '35613',
        '35368',
        '35413',
        '35149',
        '35329',
        '35330',
        '35468',
        '35149',
        '35220',
        '35218',
        '35018',
        '35308',
        '35310',
        '35411',
        '35328',
        '35468',
        '35468',
        '35400',
        '35299',
        '35329',
        '35457',
        '35299',
        '35299',
        '35329',
        '35110',
        '35329',
        '35421',
        '35413',
        '35330',
        '35478',
        '35627',
        '35339',
        '35128',
        '35468',
        '35250',
        '35457',
        '35280',
        '35431',
        '35626',
        '35215',
        '35215',
        '35470',
        '35629',
        '35107',
        '35488',
        '35421',
        '35468',
        '35457',
        '35468',
        '35470',
        '35637',
        '35309',
        '35450',
        '35269',
        '35217',
        '35110',
        '35329',
        '35629',
        '35489',
        '35017',
        '35369',
        '35217',
        '35309',
        '35478',
        '35110',
        '35211',
        '35422',
        '35414',
        '35458',
        '35216',
        '35212',
        '35629',
        '35400',
        '35469',
        '35018',
        '35212',
        '35570',
        '35411',
        '35329',
        '35218',
        '35413',
        '35479',
        '35149',
        '35479',
        '35627',
        '35329',
        '35629',
        '35220',
        '35613',
        '35015',
        '35018',
        '35109',
        '35640',
        '35413',
        '35213',
        '35627',
        '35110',
        '35259',
        '35412',
        '35299',
        '35213',
        '35479',
        '35149',
        '35560',
        '35328',
        '35328',
        '35280',
        '35018',
        '35216',
        '35369',
        '35149',
        '35110',
        '35560',
        '35329',
        '35110',
        '35215',
        '35328',
        '35479',
        '35558',
        '35650',
        '35349',
        '35650',
        '35340',
        '35319',
        '35459',
        '35308',
        '35149',
        '35507',
        '35309',
        '35468',
        '35508',
        '35422',
        '35629',
        '35413',
        '35217',
        '35307',
        '35469',
        '35128',
        '35625',
        '35309',
        '35413',
        '35215',
        '35280',
        '35328',
        '35259',
        '35479',
        '35215',
        '35250',
        '35309',
        '35100',
        '35018',
        '35018',
        '35001',
        '35002',
        '35003',
        '35004',
        '35005',
        '35006',
        '35007',
        '35008',
        '35009',
        '35010',
        '35011',
        '35012',
        '35013',
        '35014',
        '35015',
        '35016',
        '35017',
        '35018',
        '35019',
        '35070',
        '35071',
        '35080',
        '35220',
        '35229',
        '35411',
        '35339',
        '35431',
        '35339',
        '35018',
        '35328',
        '35629',
        '35638',
        '35509',
        '35259',
        '35488',
        '35213',
        '35339',
        '35269',
        '35107',
        '35479',
        '35109',
        '35349',
        '35329',
        '35310',
        '35269',
        '35413',
        '35629',
        '35330',
        '35229',
        '35637',
        '35600',
        '35017',
        '35459',
        '35217',
        '35612',
        '35110',
        '35149',
        '35468',
        '35338',
        '35660',
        '35280',
        '35413',
        '35431',
        '35217',
        '35329',
        '35457',
        '35017',
        '35220',
        '35423',
        '35329',
        '35328',
        '35215',
        '35330',
        '35411',
        '35488',
        '35210',
        '35299',
        '35458',
        '35369',
        '35106',
        '35109',
        '35413',
        '35488',
        '35400',
        '35489',
        '35338',
        '35250',
        '35107',
        '35468',
        '35421',
        '35309',
        '35415',
        '35128',
        '35109',
        '35411',
        '35309',
        '35329',
        '35458',
        '35338',
        '35139',
        '35432',
        '35413',
        '35215',
        '35330',
        '35411',
        '35479',
        '35211',
        '35211',
        '35329',
        '35489',
        '35627',
        '35149',
        '35412',
        '35310',
        '35508',
        '35432',
        '35489',
        '35129',
        '35470',
        '35510',
        '35479',
        '35108',
        '35413',
        '35412',
        '35329',
        '35432',
        '35469',
        '35479',
        '35612',
        '35108',
        '35017',
        '35217',
        '35217',
        '35339',
        '35432',
        '35510',
        '35479',
        '35259',
        '35140',
        '35309',
        '35413',
        '35510',
        '35412',
        '35432',
        '35280',
        '35421',
        '35422',
        '35310',
        '35539',
        '35300',
        '35468',
        '35308',
        '35350',
        '35217',
        '35571',
        '35570',
        '35340',
        '35308',
        '35541',
        '35639',
        '35213',
        '35488',
        '35018',
        '35650',
        '35217',
        '35543',
        '35560',
        '35629',
        '35450',
        '35457',
        '35259',
        '35488',
        '35212',
        '35229',
        '35572',
        '35100',
        '35107',
        '35600',
        '35629',
        '35479',
        '35109',
        '35214',
        '35229',
        '35329',
        '35628',
        '35217',
        '35412',
        '35338',
        '35140',
        '35138',
        '35479',
        '35149',
        '35478',
        '35308',
        '35468',
        '35349',
        '35259',
        '35458',
        '35457',
        '35106',
        '35559',
        '35415',
        '35400',
        '35459',
        '35106',
        '35210',
        '35269',
        '35280',
        '35118',
        '35629',
        '35109',
        '35580',
        '35280',
        '35415',
        '35539',
        '35017',
        '35300',
        '35319',
        '35400',
        '35468',
        '35310',
        '35109',
        '35017',
        '35300',
        '35625',
        '35420',
        '35561',
        '35330',
        '35330',
        '35558',
        '35539',
        '35469',
        '35610',
        '35269',
        '35338',
        '35219',
        '35110',
        '35541',
        '35432',
        '35414',
        '35368',
        '35628',
        '35328',
        '35478',
        '35300',
        '35480',
        '35431',
        '35269',
        '35218',
        '35458',
        '35422',
        '35109',
        '35109',
        '35220',
        '35649',
        '35280',
        '35106',
        '35149',
        '35478',
        '35457',
        '35129',
        '35106',
        '35106',
        '35216',
        '35540',
        '35339',
        '35128',
        '35412',
        '35299',
        '35017',
        '35118',
        '35149',
        '35629',
        '35018',
        '35215',
        '35309',
        '35309',
        '35217',
        '35468',
        '35138',
        '35580',
        '35130',
        '35214',
        '35214',
        '35214',
        '35478',
        '35138',
        '35219',
        '35149',
        '35100',
        '35509',
        '35214',
        '35100',
        '35509',
        '35219',
        '35570',
        '35479',
        '35300',
        '35219',
        '35118',
        '35219',
        '35308',
        '35478',
        '35119',
        '35639',
        '35629',
        '35571',
        '35489',
        '35510',
        '35600',
        '35612',
        '35130',
        '35469',
        '35542',
        '35339',
        '35414',
        '35310',
        '35216',
        '35330',
        '35369',
        '35280',
        '35627',
        '35489',
        '35369',
        '35328',
        '35414',
        '35432',
        '35107',
        '35217',
        '35259',
        '35368',
        '35414',
        '35650',
        '35629',
        '35612',
        '35612',
        '35639',
        '35629',
        '35118',
        '35468',
        '35478',
        '35488',
        '35280',
        '35610',
        '35214',
        '35100',
        '35414',
        '35431',
        '35213',
        '35421',
        '35550',
        '35290',
        '35458',
        '35212',
        '35414',
        '35100',
        '35421',
        '35200',
        '35328',
        '35411',
        '35411',
        '35200',
        '35411',
        '35213',
        '35338',
        '35488',
        '35210',
        '35300',
        '35200',
        '35458',
        '35018',
        '35580',
        '35320',
        '35339',
        '35470',
        '35489',
        '35110',
        '35110',
        '35217',
        '35128',
        '35300',
        '35457',
        '35412',
        '35280',
        '35229',
        '35450',
        '35411',
        '35411',
        '35489',
        '35469',
        '35110',
        '35300',
        '35468',
        '35250',
        '35299',
        '35017',
        '35339',
        '35308',
        '35468',
        '35469',
        '35625',
        '35218',
        '35328',
        '35368',
        '35412',
        '35100',
        '35558',
        '35128',
        '35458',
        '35489',
        '35149',
        '35542',
        '35109',
        '35421',
        '35649',
        '35017',
        '35507',
        '35280',
        '35560',
        '35214',
        '35018',
        '35629',
        '35613',
        '35413',
        '35561',
        '35611',
        '35210',
        '35629',
        '35107',
        '35478',
        '35478',
        '35138',
        '35488',
        '35211',
        '35217',
        '35611',
        '35572',
        '35468',
        '35530',
        '35360',
        '35200',
        '35210',
        '35211',
        '35212',
        '35213',
        '35214',
        '35215',
        '35218',
        '35219',
        '35220',
        '35270',
        '35629',
        '35216',
        '35330',
        '35400',
        '35539',
        '35629',
        '35611',
        '35613',
        '35558',
        '35572',
        '35369',
        '35560',
        '35649',
        '35413',
        '35638',
        '35478',
        '35561',
        '35338',
        '35368',
        '35628',
        '35413',
        '35432',
        '35413',
        '35280',
        '35413',
        '35457',
        '35660',
        '35458',
        '35639',
        '35468',
        '35422',
        '35468',
        '35629',
        '35290',
        '35480',
        '35570',
        '35328',
        '35468',
        '35613',
        '35489',
        '35218',
        '35220',
        '35211',
        '35217',
        '35637',
        '35280',
        '35649',
        '35638',
        '35340',
        '35349',
        '35217',
        '35269',
        '35489',
        '35110',
        '35637',
        '35320',
        '35507',
        '35216',
        '35328',
        '35457',
        '35458',
        '35640',
        '35310',
        '35307',
        '35412',
        '35507',
        '35570',
        '35541',
        '35560',
        '35349',
        '35349',
        '35431',
        '38687',
        '38683',
        '38892',
        '38688',
        '38111',
        '38670',
        '38910',
        '38738',
        '38297',
        '38297',
        '38610',
        '38139',
        '38355',
        '38687',
        '38310',
        '38780',
        '38591',
        '38830',
        '38812',
        '38713',
        '38686',
        '38628',
        '38111',
        '38129',
        '38812',
        '38852',
        '38780',
        '38639',
        '38111',
        '38360',
        '38813',
        '38687',
        '38550',
        '38540',
        '38780',
        '38312',
        '38760',
        '38850',
        '38690',
        '38812',
        '38589',
        '38589',
        '38688',
        '38678',
        '38509',
        '38640',
        '38892',
        '38628',
        '38629',
        '38611',
        '38811',
        '38250',
        '38849',
        '38726',
        '38355',
        '38811',
        '38713',
        '38107',
        '38510',
        '38627',
        '38758',
        '38758',
        '38758',
        '38479',
        '38768',
        '38294',
        '38294',
        '38294',
        '38713',
        '38780',
        '38434',
        '38811',
        '38129',
        '38313',
        '38729',
        '38109',
        '38870',
        '38713',
        '38710',
        '38509',
        '38434',
        '38713',
        '38713',
        '38480',
        '38627',
        '38727',
        '38829',
        '38627',
        '38460',
        '38910',
        '38749',
        '38678',
        '38820',
        '38738',
        '38293',
        '38109',
        '38340',
        '38530',
        '38715',
        '38509',
        '38350',
        '38399',
        '38150',
        '38460',
        '38769',
        '38879',
        '38588',
        '38611',
        '38139',
        '38649',
        '38829',
        '38788',
        '38611',
        '38714',
        '38869',
        '38639',
        '38379',
        '38129',
        '38595',
        '38310',
        '38616',
        '38652',
        '38801',
        '38879',
        '38688',
        '38591',
        '38594',
        '38294',
        '38689',
        '38869',
        '38688',
        '38632',
        '38611',
        '38441',
        '38829',
        '38660',
        '38660',
        '38660',
        '38630',
        '38779',
        '38460',
        '38441',
        '38626',
        '38616',
        '38312',
        '38616',
        '38439',
        '38294',
        '38890',
        '38413',
        '38759',
        '38390',
        '38713',
        '38727',
        '38180',
        '38788',
        '38686',
        '38438',
        '38314',
        '38180',
        '38589',
        '38311',
        '38769',
        '38357',
        '38438',
        '38801',
        '38129',
        '38310',
        '38550',
        '38470',
        '38749',
        '38109',
        '38820',
        '38616',
        '38129',
        '38617',
        '38314',
        '38591',
        '38629',
        '38712',
        '38714',
        '38879',
        '38292',
        '38688',
        '38780',
        '38801',
        '38612',
        '38801',
        '38310',
        '38489',
        '38759',
        '38750',
        '38769',
        '38107',
        '38449',
        '38739',
        '38714',
        '38358',
        '38360',
        '38678',
        '38508',
        '38779',
        '38330',
        '38769',
        '38879',
        '38593',
        '38629',
        '38107',
        '38617',
        '38310',
        '38360',
        '38107',
        '38712',
        '38129',
        '38820',
        '38591',
        '38107',
        '38715',
        '38435',
        '38350',
        '38686',
        '38852',
        '38570',
        '38592',
        '38916',
        '38435',
        '38869',
        '38869',
        '38829',
        '38789',
        '38679',
        '38588',
        '38398',
        '38570',
        '38413',
        '38311',
        '38434',
        '38687',
        '38632',
        '38911',
        '38740',
        '38439',
        '38714',
        '38727',
        '38450',
        '38787',
        '38297',
        '38459',
        '38639',
        '38600',
        '38619',
        '38419',
        '38330',
        '38916',
        '38632',
        '38812',
        '38358',
        '38632',
        '38680',
        '38500',
        '38458',
        '38639',
        '38310',
        '38820',
        '38768',
        '38616',
        '38879',
        '38411',
        '38297',
        '38788',
        '38449',
        '38829',
        '38311',
        '38829',
        '38430',
        '38414',
        '38592',
        '38615',
        '38869',
        '38520',
        '38140',
        '38812',
        '38801',
        '38915',
        '38379',
        '38629',
        '38419',
        '38759',
        '38811',
        '38358',
        '38315',
        '38660',
        '38870',
        '38460',
        '38715',
        '38627',
        '38340',
        '38419',
        '38589',
        '38677',
        '38769',
        '38399',
        '38320',
        '38129',
        '38869',
        '38614',
        '38290',
        '38311',
        '38729',
        '38107',
        '38688',
        '38440',
        '38550',
        '38311',
        '38588',
        '38801',
        '38312',
        '38434',
        '38370',
        '38560',
        '38419',
        '38300',
        '38438',
        '38315',
        '38711',
        '38870',
        '38849',
        '38860',
        '38314',
        '38917',
        '38589',
        '38310',
        '38449',
        '38435',
        '38439',
        '38439',
        '38389',
        '38380',
        '38509',
        '38579',
        '38811',
        '38489',
        '38439',
        '38429',
        '38290',
        '38530',
        '38300',
        '38434',
        '38678',
        '38312',
        '38294',
        '38914',
        '38357',
        '38108',
        '38150',
        '38540',
        '38631',
        '38892',
        '38749',
        '38712',
        '38441',
        '38670',
        '38820',
        '38617',
        '38726',
        '38489',
        '38829',
        '38713',
        '38670',
        '38811',
        '38788',
        '38107',
        '38628',
        '38830',
        '38415',
        '38434',
        '38820',
        '38107',
        '38290',
        '38729',
        '38449',
        '38714',
        '38788',
        '38760',
        '38617',
        '38811',
        '38739',
        '38715',
        '38801',
        '38440',
        '38357',
        '38129',
        '38329',
        '38727',
        '38715',
        '38590',
        '38688',
        '38879',
        '38879',
        '38714',
        '38714',
        '38726',
        '38726',
        '38738',
        '38290',
        '38714',
        '38727',
        '38390',
        '38727',
        '38418',
        '38618',
        '38890',
        '38389',
        '38108',
        '38291',
        '38616',
        '38627',
        '38849',
        '38616',
        '38170',
        '38712',
        '38489',
        '38849',
        '38879',
        '38311',
        '38589',
        '38311',
        '38879',
        '38849',
        '38677',
        '38911',
        '38340',
        '38428',
        '38410',
        '38314',
        '38470',
        '38626',
        '38911',
        '38340',
        '38849',
        '38190',
        '38540',
        '38759',
        '38738',
        '38759',
        '38690',
        '38759',
        '38727',
        '38678',
        '38180',
        '38779',
        '38489',
        '38110',
        '38730',
        '38293',
        '38358',
        '38291',
        '38439',
        '38411',
        '38916',
        '38690',
        '38611',
        '38649',
        '38712',
        '38459',
        '38441',
        '38739',
        '38738',
        '38739',
        '38829',
        '38677',
        '38700',
        '38713',
        '38727',
        '38297',
        '38292',
        '38890',
        '38560',
        '38632',
        '38820',
        '38727',
        '38890',
        '38415',
        '38300',
        '38759',
        '38811',
        '38869',
        '38438',
        '38768',
        '38292',
        '38290',
        '38434',
        '38687',
        '38820',
        '38789',
        '38914',
        '38311',
        '38310',
        '38650',
        '38687',
        '38588',
        '38292',
        '38310',
        '38688',
        '38290',
        '38780',
        '38479',
        '38400',
        '38910',
        '38769',
        '38683',
        '38358',
        '38588',
        '38240',
        '38560',
        '38780',
        '38789',
        '38715',
        '38911',
        '38379',
        '38749',
        '38390',
        '38729',
        '38677',
        '38812',
        '38109',
        '38729',
        '38359',
        '38410',
        '38412',
        '38632',
        '38389',
        '38760',
        '38690',
        '38314',
        '38294',
        '38129',
        '38728',
        '38139',
        '38729',
        '38789',
        '38570',
        '38849',
        '38739',
        '38758',
        '38428',
        '38290',
        '38594',
        '38631',
        '38767',
        '38890',
        '38435',
        '38579',
        '38738',
        '38649',
        '38912',
        '38120',
        '38915',
        '38729',
        '38720',
        '38379',
        '38711',
        '38801',
        '38314',
        '38714',
        '38419',
        '38479',
        '38779',
        '38108',
        '38200',
        '38201',
        '38202',
        '38203',
        '38204',
        '38205',
        '38206',
        '38207',
        '38208',
        '38291',
        '38293',
        '38296',
        '38320',
        '38329',
        '38430',
        '38109',
        '38611',
        '38312',
        '38356',
        '38428',
        '38712',
        '38435',
        '38729',
        '38420',
        '38459',
        '38356',
        '38430',
        '38108',
        '38620',
        '38296',
        '38588',
        '38759',
        '38460',
        '38800',
        '38738',
        '38434',
        '38441',
        '38358',
        '38700',
        '38001',
        '38002',
        '38003',
        '38004',
        '38005',
        '38006',
        '38007',
        '38008',
        '38009',
        '38010',
        '38070',
        '38071',
        '38080',
        '38107',
        '38110',
        '38111',
        '38160',
        '38170',
        '38320',
        '38111',
        '38390',
        '38690',
        '38810',
        '38441',
        '38890',
        '38479',
        '38292',
        '38508',
        '38579',
        '38190',
        '38294',
        '38759',
        '38758',
        '38108',
        '38350',
        '38130',
        '38358',
        '38852',
        '38914',
        '38769',
        '38910',
        '38399',
        '38628',
        '38684',
        '38891',
        '38811',
        '38813',
        '38677',
        '38770',
        '38852',
        '38811',
        '38589',
        '38280',
        '38811',
        '38260',
        '38685',
        '38910',
        '38869',
        '38630',
        '38489',
        '38441',
        '38913',
        '38416',
        '38738',
        '38738',
        '38780',
        '38677',
        '38677',
        '38107',
        '38780',
        '38915',
        '38769',
        '38727',
        '38398',
        '38398',
        '38727',
        '38417',
        '38379',
        '38715',
        '38310',
        '38615',
        '38767',
        '38649',
        '38891',
        '38180',
        '38180',
        '38690',
        '38139',
        '38626',
        '38359',
        '38870',
        '38270',
        '38329',
        '38329',
        '38160',
        '38840',
        '38150',
        '38900',
        '38890',
        '38811',
        '38594',
        '38649',
        '38688',
        '38390',
        '38729',
        '38729',
        '38616',
        '38613',
        '38580',
        '38617',
        '38611',
        '38879',
        '38870',
        '38617',
        '38419',
    ]),
};
