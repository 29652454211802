import {
    GroupBase,
    SingleValueProps,
    components,
} from 'components/FormElements/Select';
import styled from 'styled-components/macro';

import { CountryOption } from './CountrySelect';
import { StyledFlag, StyledLabel } from './StyledFlag';

const { SingleValue } = components;

const OptionContent = styled.div`
    align-items: center;
    display: flex;
`;

const FlagValue = <
    OptionType extends CountryOption,
    GroupType extends GroupBase<OptionType>
>({
    children,
    ...props
}: SingleValueProps<OptionType, false, GroupType>) => (
    <SingleValue {...props}>
        <OptionContent data-testid="flag-value">
            <StyledFlag size="20px" countryCode={props.data.value} />
            <StyledLabel>{children}</StyledLabel>
        </OptionContent>
    </SingleValue>
);

export default FlagValue;
