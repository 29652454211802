import { getNextTierUpgrade } from '../../constants/integrations';
import { useTeam } from '../../hooks/Queries';
import { BundleChange } from '../APISubscriptionChange';
import { Change } from '../APISubscriptionChange/BundleChange';
import Modal from '../Modal';

interface Props {
    onClose: () => void;
    show: boolean;
}
const UpgradeTierModal = ({ onClose, show }: Props) => {
    const { team } = useTeam();
    const currentTier = team?.apiTier;
    const nextTier = currentTier
        ? getNextTierUpgrade(currentTier)
        : 'integration_starter';

    let change: Change = {
        type: 'change',
        tier: nextTier ?? 'integration_medium',
    };
    if (!currentTier) {
        change = {
            interval: 'year',
            tier: 'integration_starter',
            type: 'new',
        };
    }

    return (
        <Modal showModal={show} onClose={onClose} width="600px">
            <BundleChange change={change} onSuccessfulSubmit={onClose} />
        </Modal>
    );
};

export default UpgradeTierModal;
