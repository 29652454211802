import { page, trackPageView } from 'external/analytics';
import { gtmVirtualPageView } from 'external/gtm';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { WithChildren } from 'types';

/**
 * This component can be used as wrapper to send Page event to our trackers on location change
 */
const PageTracker = ({ children }: WithChildren) => {
    const { pathname } = useLocation();
    /**
     * Intialize prevpath to document.referrer so that we track the correct referrer to GTM
     * when referred to the app from external sources.
     */
    const prevPath = useRef<string>(document.referrer);
    useEffect(() => {
        const url = `${window.location.origin}${pathname}`;
        page();
        gtmVirtualPageView(pathname, url, prevPath.current);
        /**
         * Update the ref with current path so the next internal routing gets referred to by it.
         */
        prevPath.current = url;

        trackPageView();
    }, [pathname]);

    return <>{children}</>;
};

export default PageTracker;
