import { refreshLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking';
import { useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

export enum LinkSize {
    CAPTION,
    REGULAR,
}

interface AnchorProps {
    size?: LinkSize;
    /** The color of the slider when checked. Defaults to blue200. */
    color?: string;
}

const CAPTION = css`
    font-weight: 500;
`;
const REGULAR = css`
    font-weight: normal;
`;

const StyledLink = styled.a<AnchorProps>`
    color: ${({ color, theme }) => color ?? theme.colors.blue200};
    ${({ size }) => {
        switch (size) {
            case LinkSize.CAPTION:
                return CAPTION;
            case LinkSize.REGULAR:
                return REGULAR;
        }
        return '';
    }};
    font-size: inherit;
`;
type Props = AnchorProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * A link to an internal or external site. Equivilent to an `<a>` tag with additional styling.
 *
 * Each click on a Link is tracked via our data pipeline.
 */
const Link = ({ size = LinkSize.REGULAR, children, ...rest }: Props) => {
    /**
     * Enable link tracking via Snowplow. Snowplow needs to refresh the links on each page as the page
     * loads. Use a useEffect for this as a shortcut.
     */
    useEffect(() => {
        refreshLinkClickTracking();
    }, []);
    return (
        <StyledLink size={size} {...rest}>
            {children}
        </StyledLink>
    );
};

export default Link;
