import { KnownIntegrations } from 'api/subscriptions/types';
import {
    FortnoxIcon,
    MagentoIcon,
    MicrosoftBusinessCentralIcon,
    MonitorIcon,
    Ongoing,
    Prosmart,
    Pyramid,
    ShopifyTextIcon,
    SpecterTextIcon,
    VismaTextIcon,
    WinbasIcon,
    WooCommerceTextIcon,
} from 'assets/partnerLogos';
import OwnIntegrationLogo from 'assets/partnerLogos/ownIntegration/OwnIntegrationLogo';

interface Props {
    integration: KnownIntegrations;
    className?: string;
}

/**
 * The 'normal' version of a company's logo.
 */
const PrimaryIntegrationLogo = ({ integration, className }: Props) => {
    switch (integration) {
        case 'Fortnox':
            return <FortnoxIcon className={className} />;
        case 'Shopify':
            return <ShopifyTextIcon className={className} />;
        case 'WooCommerce':
            return <WooCommerceTextIcon className={className} />;
        case 'Visma E-Economy':
            return <VismaTextIcon className={className} />;
        case 'Specter':
            return <SpecterTextIcon className={className} />;
        case 'Ongoing':
            return <Ongoing className={className} />;
        case 'Prosmart':
            return <Prosmart className={className} />;
        case 'Pyramid':
            return <Pyramid className={className} />;
        case 'Monitor':
            return <MonitorIcon className={className} />;
        case 'Visma Admin 500':
            return <VismaTextIcon className={className} />;
        case 'Visma Admin 1000':
            return <VismaTextIcon className={className} />;
        case 'Visma Admin 2000':
            return <VismaTextIcon className={className} />;
        case 'Winbas':
            return <WinbasIcon className={className} />;
        case 'Dynamics 365 Business Central':
            return <MicrosoftBusinessCentralIcon className={className} />;
        case 'Adobe Commerce (Magento)':
            return <MagentoIcon className={className} />;
        case 'Jeeves':
            return <div className={className} />;
        case 'Starweb':
            return <div className={className} />;
        case 'Logtrade':
            return <div className={className} />;
        case 'JTL':
            return <div className={className} />;
        case 'Amicron':
            return <div className={className} />;
        case 'Büroware':
            return <div className={className} />;
        case 'Sage':
            return <div className={className} />;
        case 'Other':
            return <OwnIntegrationLogo />;
        default:
            return null;
    }
};

export default PrimaryIntegrationLogo;
