/**
 * Checks the class name of the <html> tag to decide whether or not the user has translated the
 * page with Google Translate.
 */
import { useState } from 'react';

const useDetectGoogleTranslate = () => {
    /**
     * Google Translate adds either 'translate-ltr' or 'translate-rtl' to the main <html> element
     * depending on target language.
     */
    const htmlElement = document.documentElement;
    const documentHasTranslateClass = () =>
        !!htmlElement.className.match('translated');

    const [usesTranslation, setUsesTranslation] = useState(
        documentHasTranslateClass()
    );

    const observer = new MutationObserver(() =>
        setUsesTranslation(documentHasTranslateClass())
    );

    observer.observe(htmlElement, {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false,
    });

    return usesTranslation;
};

export default useDetectGoogleTranslate;
