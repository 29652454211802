import countries, { Alpha2Code } from 'i18n-iso-countries';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, SingleValue } from '../Select';
import FlagOption from './FlagSingleOption';
import FlagValue from './FlagValue';

interface Props {
    inputId?: string;
    name?: string;
    label?: string;
    placeholder?: string;
    autoComplete?: string;
    countryCode?: string;
    menuPortalTarget?: HTMLElement;
    onValueChange: (value: Alpha2Code | null) => void;
    error: boolean;
    errorMessage?: string;
    isDisabled?: boolean;
    className?: string;
    'data-cy'?: string;
}

export interface CountryOption {
    value: Alpha2Code;
    label: string;
}

const CountrySelect = forwardRef<any, Props>((props: Props, ref?: any) => {
    const { i18n } = useTranslation();

    const {
        inputId,
        countryCode,
        isDisabled,
        onValueChange,
        name,
        label,
        placeholder,
        error,
        errorMessage,
        className,
        menuPortalTarget,
        'data-cy': dataCy,
    } = props;
    const currentMainLanguage = i18n.languages[0];
    const allCountries = countries.getNames(currentMainLanguage);
    const dropdownOptions: CountryOption[] = Object.entries(allCountries).map(
        ([key, value]) => ({
            value: key as Alpha2Code,
            label: value,
        })
    );

    dropdownOptions.sort((a, b) => {
        if (a && b) {
            return a.label
                .toString()
                .localeCompare(b.label, currentMainLanguage);
        }
        return 0;
    });

    // Set to null if the find() returns undefined, since null is the
    // default value for react-select.
    const value = countryCode
        ? dropdownOptions.find((option) => option?.value === countryCode)
        : null;

    // As of now no parent needs anything other than the value.
    const onChange = (option: SingleValue<CountryOption>) =>
        onValueChange(option?.value || null);

    return (
        <Select<CountryOption, false>
            ref={ref}
            inputId={inputId}
            data-cy={dataCy}
            className={className}
            label={label}
            name={name}
            menuPortalTarget={menuPortalTarget}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            error={error}
            errorMessage={errorMessage}
            isDisabled={isDisabled}
            components={{ Option: FlagOption, SingleValue: FlagValue }}
            options={dropdownOptions}
        />
    );
});

export default CountrySelect;
