import * as Sentry from '@sentry/react';
import {
    APISubscriptionTier,
    Integration,
    Interval,
} from 'api/subscriptions/types';
import { useTeam } from 'hooks/Queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { tierOrderByTier } from '../../constants/integrations';
import CancelBundle from './CancelBundle';
import DowngradeBundle from './DowngradeBundle';
import New from './NewBundle';
import Reactivate from './ReactivateBundle';
import Upgrade from './UpgradeBundle';

interface UpgradeProps {
    tier: APISubscriptionTier;
    interval: Interval;
    bundledIntegration?: Integration;
    type: 'new';
}

interface ChangeProps {
    tier: APISubscriptionTier;
    type: 'change';
}

export type Change =
    | UpgradeProps
    | ChangeProps
    | { type: 'cancel' }
    | { type: 'reactivate' };

interface Props {
    change: Change;
    /** Will be called if the change was successful. */
    onSuccessfulSubmit?: () => void;
}

/**
 * Shows information for when the user modifies their integration subscription bundle (tier and integration).
 *
 * There are four types: a new purchase, cancellation, reactivation of a cancelled bundle, and changing the
 * tier of a bundle. Each of these require different properties.
 */
const BundleChange = ({ change, onSuccessfulSubmit }: Props) => {
    const { team } = useTeam();
    const { type } = change;
    const { t } = useTranslation('settings');
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (error) {
            // Something has gone wrong.
            Sentry.captureException(error);
            toast.error(t('api.subscriptionChange.errors.bundleChange'), {
                autoClose: false,
            });
        }
    }, [error]);
    if (!team || error) {
        return null;
    }

    if (type === 'new') {
        const { tier, interval, bundledIntegration } = change;
        return (
            <New
                selectedTier={tier}
                onSuccessfulSubmit={onSuccessfulSubmit}
                interval={interval}
                integration={bundledIntegration}
            />
        );
    }

    if (type === 'cancel') {
        return <CancelBundle onSuccessfulSubmit={onSuccessfulSubmit} />;
    }

    if (type === 'reactivate') {
        return <Reactivate onSuccessfulSubmit={onSuccessfulSubmit} />;
    }

    const { tier } = change;
    const tierValue =
        tier === null ? Number.NEGATIVE_INFINITY : tierOrderByTier[tier];
    const { apiTier: currentTier } = team;
    if (!currentTier) {
        // Something has gone wrong
        setError(
            `BundleChange: no valid current tier, but 'new' not selected. Change type: ${type}`
        );
        return null;
    }

    const currentValue = tierOrderByTier[currentTier];

    if (currentValue < tierValue) {
        return (
            <Upgrade
                selectedTier={tier}
                onSuccessfulSubmit={onSuccessfulSubmit}
            />
        );
    }
    if (currentValue > tierValue) {
        return (
            <DowngradeBundle
                selectedTier={tier}
                onSuccessfulSubmit={onSuccessfulSubmit}
            />
        );
    }

    // Something has gone wrong
    setError(
        `BundleChange: no valid change. Current: ${currentTier}, next: ${tier}`
    );
    return null;
};

export default BundleChange;
