/* eslint-disable react-hooks/exhaustive-deps */
import { getIntegrationCredentials } from 'api/admin';
import { IntegrationCredentials } from 'api/admin/types';
import { refreshZwapgrid, zwapgridToken } from 'api/imports';
import { ZwapgridToken } from 'api/imports/types';
import { Integration } from 'api/subscriptions/types';
import { Team } from 'api/teams/types';
import {
    Environment,
    ZwapgridEvent,
    ZwapgridParams,
} from 'components/IntegrationsModal/Zwapgrid/types';
import Spinner, { spinnerSize } from 'components/Spinner/Spinner';
import {
    trackIntegrationComplete,
    trackIntegrationStarted,
} from 'external/analytics';
import { useUser, useZwapgridCodes } from 'hooks/Queries';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SpinnerWrapper, ZwapgridIframe } from './StyledZwapgrid';

interface Props {
    team: Team;
    credentials?: IntegrationCredentials;
    integration: Integration;
    apiKey: string;
    onIntegrationStarted?: () => void;
    onIntegrationCreated?: () => void;
}

const Zwapgrid = ({
    team,
    apiKey,
    credentials,
    integration,
    onIntegrationStarted,
    onIntegrationCreated,
}: Props) => {
    const { user } = useUser();
    const { i18n } = useTranslation();
    const [integrationCredentials, setCredentials] = useState<
        IntegrationCredentials | undefined
    >(credentials);
    // prevent re-fetch on window focus to prevent the iframe to refresh
    const { codes } = useZwapgridCodes(team.id, {
        refetchOnWindowFocus: false,
    });
    const production = window.location.href.includes('web.sendify');

    const source = useMemo(() => {
        switch (integration) {
            case 'Visma E-Economy':
                return 'VismaEconomy';
            default:
                return integration;
        }
    }, [integration]);
    // Preselect correct environemnt
    const environmentType = production
        ? Environment.PRODUCTION
        : Environment.SANDBOX;
    const url = useMemo(() => {
        const baseUrl = 'https://app.zwapgrid.com/marketplace?';
        const params: ZwapgridParams = {
            otc: codes?.oneTimeCode,
            clientId: '785a65aeccf54b2eabc94cc9b9ea7239',
            lang: i18n.languages[0],
            companyId: team?.identityNumber,
            name: team?.name.trim(),
            email: 'api@sendify.com',
            target: 'Sendify',
            source,
            'output.connection.apiKey': apiKey,
            'output.connection.EndpointType': environmentType,
            'output.connection.title': `sendify-to-${integration}`,
            'export.connection.title': `${integration}-to-sendify`,
        };
        if (integration === 'WooCommerce') {
            params['export.connection.BaseUrl'] =
                integrationCredentials?.wooCommerceStore;
            params['export.connection.ConsumerKey'] =
                integrationCredentials?.wooCommerceApiKey;
            params['export.connection.ConsumerSecret'] =
                integrationCredentials?.wooCommerceApiSecret;
        }
        if (integration === 'Shopify') {
            params['export.connection.urlId'] =
                integrationCredentials?.shopifyStoreName;
            params['export.connection.apiKey'] =
                integrationCredentials?.shopifyUsername;
            params['export.connection.token'] =
                integrationCredentials?.shopifyPassword;
        }
        if (integration === 'Specter') {
            params['export.connection.ApiKey'] =
                integrationCredentials?.specterApiKey;
            params['export.connection.License'] =
                integrationCredentials?.specterLicense;
            params['export.connection.SbmId'] =
                integrationCredentials?.specterSbmId;
            params['export.connection.UserId'] =
                integrationCredentials?.specterUserId;
        }
        return `${baseUrl}${queryString.stringify(params)}`;
    }, [integration, team, user, codes]);

    useEffect(() => {
        if (onIntegrationStarted) {
            onIntegrationStarted();
        }
        trackIntegrationStarted(integration);
    }, [integration]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const credentials = await getIntegrationCredentials(team.id);
                setCredentials(credentials);
            } catch (e) {
                // TODO https://sendify.atlassian.net/browse/SOL-393
            }
        };
        // credentials not needed for fortnox
        if (!credentials && integration !== 'Fortnox') {
            fetchData();
        }
    }, [team]);

    useEffect(() => {
        const onMessage = (e: MessageEvent) => {
            if (!new URL(e.origin).host.includes('zwapgrid')) {
                return;
            }
            try {
                if (!e.data) {
                    return;
                }
                const message = JSON.parse(e.data);
                if (!message) {
                    return;
                }

                // These arrays will be expanded in future with new message types and handlers
                const messageTypes = [
                    'integration.created',
                    'client.authenticated',
                    'client.accessTokenMissing',
                ];
                const handlers = [
                    onIntegrationCreatedEvent,
                    onClientAuthenticatedEvent,
                    onAccessTokenMissingEvent,
                ];

                if (messageTypes.indexOf(message.type) < 0) {
                    return;
                }

                const handler = handlers[messageTypes.indexOf(message.type)];
                handler(message.data);
            } catch (error) {
                return;
            }
        };
        const onClientAuthenticatedEvent = async (d: ZwapgridEvent) => {
            const data = await zwapgridToken(team.id, d.authorizationCode);
            sendToIframe(data);
        };
        const onAccessTokenMissingEvent = async () => {
            const data = await refreshZwapgrid(team.id);
            sendToIframe(data);
        };
        const onIntegrationCreatedEvent = () => {
            trackIntegrationComplete(integration);
            if (onIntegrationCreated) {
                onIntegrationCreated();
            }
        };

        window.addEventListener('message', onMessage, false);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);
    const sendToIframe = (tokens: ZwapgridToken) => {
        const iframe = document.getElementById(
            'zwapgrid-iframe'
        ) as HTMLIFrameElement;
        if (!iframe) {
            return;
        }

        const message = {
            type: 'tokens',
            data: {
                accessToken: tokens.zwapgridAccessToken,
                encryptedAccessToken: tokens.zwapgridEncryptedAccessToken,
            },
        };
        iframe?.contentWindow?.postMessage(message, '*');
    };

    // no credentials needed for fortnox
    const hasCredentials = integrationCredentials || integration === 'Fortnox';
    if (!team || !user || !codes || !hasCredentials) {
        return (
            <SpinnerWrapper>
                <Spinner size={spinnerSize.large} />
            </SpinnerWrapper>
        );
    }

    return (
        <ZwapgridIframe
            data-testid="zwapgrid-iframe"
            id="zwapgrid-iframe"
            src={url}
        />
    );
};

export default Zwapgrid;
