import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { Integration } from 'api/subscriptions/types';
import {
    ConfirmButtonWrapper,
    Confirmation,
    Content,
    Footer,
    Overview,
    OverviewList,
    OverviewListItem,
    OverviewListLogo,
} from 'components/APISubscriptionChange/StyledAPISubscriptionChange';
import { Button } from 'components/Buttons';
import { ModalHeader } from 'components/Modal';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { DateTime, Duration } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { purchaseIntegration } from '../../api/subscriptions';
import Logger from '../../helpers/LogHelper';
import CostBreakdown from './CostBreakdown';
import { CostItem } from './CostBreakdown/CostBreakdown';
import NextSteps from './NextSteps';

interface Props {
    integration: Integration;
    onSuccessfulSubmit: () => void;
}

/**
 * Lets the user purchase a new integration.
 */
const NewIntegration = ({ integration, onSuccessfulSubmit }: Props) => {
    const queryClient = useQueryClient();
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    const [isLoading, setIsLoading] = useState(false);

    const { subscriptions } = useSubscriptions();
    const current = subscriptions?.apiSubscription.current;

    const onSubmit = async () => {
        Logger.info('onSubmit called');

        setIsLoading(true);
        Logger.info('isLoading set to true');

        try {
            Logger.info('Attempting to purchase integration:', integration);
            await purchaseIntegration(integration);
            Logger.info('Purchase successful');

            Logger.info('Calling onSuccessfulSubmit');
            onSuccessfulSubmit();

            Logger.info("Invalidating queries for ['subscriptions']");
            queryClient.invalidateQueries(['subscriptions']);
        } catch (e) {
            Logger.error('Caught exception:', e);
            toast.error(t('api.subscriptionChange.errors.integrationChange'), {
                autoClose: false,
            });
            Sentry.captureException(e);
        } finally {
            setIsLoading(false);
            Logger.info('isLoading set to false');
        }
    };

    const isTrial = !!subscriptions?.apiSubscription.current?.trialEnd;
    const interval = subscriptions?.apiSubscription.interval;
    const currency = team?.currency;

    const breakdownItems: CostItem[] = useMemo(() => {
        if (!current?.end || !currency || !interval) {
            return [];
        }

        let timeLeft = current.end.diffNow(
            interval === 'year' ? 'years' : 'months',
            {
                conversionAccuracy: 'longterm',
            }
        );

        if (isTrial) {
            timeLeft =
                interval === 'month'
                    ? Duration.fromDurationLike({ month: 1 })
                    : Duration.fromDurationLike({ year: 1 });
        }
        const monthlyCost = monthlyIntegrationCosts[integration][currency];

        return [
            {
                name:
                    integration === 'Other'
                        ? t('api.ownIntegration')
                        : t(
                              'api.subscriptionChange.costBreakdown.integration',
                              {
                                  integration,
                              }
                          ),
                duration: timeLeft,
                monthlyCost: monthlyCost,
            },
        ];
    }, [current, interval, currency, isTrial]);

    const end = subscriptions?.apiSubscription?.current?.end;
    if (!currency || !subscriptions || !end) {
        return null;
    }

    const getPaymentText = () => {
        if (current?.trialEnd) {
            return t(`api.subscriptionChange.integration.trialInfo`, {
                date: current.trialEnd.toLocaleString(DateTime.DATE_FULL),
                price: formatCost(
                    breakdownItems.reduce(
                        (acc, i) =>
                            acc + i.monthlyCost * i.duration.as('months'),
                        0
                    ),
                    i18n.language,
                    team.currency,
                    2,
                    2
                ),
            });
        }
        if (subscriptions.apiSubscription.interval === 'year') {
            return t('api.subscriptionChange.integration.yearInfo', {
                startDate: end.toLocaleString(DateTime.DATE_FULL),
                billingDate: end.toLocaleString({
                    month: 'long',
                    day: 'numeric',
                }),
            });
        }
        return t('api.subscriptionChange.integration.monthInfo', {
            startDate: end.toLocaleString(DateTime.DATE_FULL),
            billingDate: end.day,
        });
    };

    const listItems = [
        t('api.subscriptionChange.integration.nextSteps.activeImmediately'),
        interval === 'year'
            ? t('api.subscriptionChange.integration.nextSteps.payPerYear')
            : t('api.subscriptionChange.integration.nextSteps.payPerMonth'),
    ];
    if (!isTrial) {
        listItems.push(
            t('api.subscriptionChange.integration.nextSteps.payRemainingDays')
        );
    }
    listItems.push(
        t('api.subscriptionChange.integration.nextSteps.payInFull', {
            from: current?.trialEnd
                ? current.trialEnd.toLocaleString(DateTime.DATE_FULL)
                : end.toLocaleString(DateTime.DATETIME_FULL),
        })
    );

    const decimals = currency === 'EUR' ? 1 : 0;
    return (
        <>
            <ModalHeader
                title={
                    isTrial
                        ? t('api.subscriptionChange.integration.title.trial')
                        : t('api.subscriptionChange.integration.title.new')
                }
            />
            <Content>
                <Overview>
                    <OverviewList>
                        <OverviewListItem>
                            <OverviewListLogo integration={integration} />
                            <Heading size={HeadingSize.MEDIUM}>
                                {t('api.subscriptionChange.costPerMonth', {
                                    cost: formatCost(
                                        monthlyIntegrationCosts[integration][
                                            currency
                                        ],
                                        i18n.language,
                                        currency,
                                        decimals
                                    ),
                                })}
                            </Heading>
                        </OverviewListItem>
                    </OverviewList>
                </Overview>
                <NextSteps listItems={listItems} />
            </Content>

            <Footer>
                <Confirmation>
                    <CostBreakdown
                        items={breakdownItems}
                        isTrial={isTrial}
                        sumStartText={t(
                            'api.subscriptionChange.integration.start',
                            {
                                date: current?.trialEnd
                                    ? current.trialEnd.toLocaleString(
                                          DateTime.DATE_FULL
                                      )
                                    : DateTime.local().toLocaleString(
                                          DateTime.DATE_FULL
                                      ),
                            }
                        )}
                    />
                    <ConfirmButtonWrapper>
                        <Text>{getPaymentText()}</Text>
                        <Button
                            fullWidth
                            loading={isLoading}
                            onClick={onSubmit}
                        >
                            {isTrial
                                ? t(
                                      'api.subscriptionChange.integration.button.trial'
                                  )
                                : t(
                                      'api.subscriptionChange.integration.button.new'
                                  )}
                        </Button>
                    </ConfirmButtonWrapper>
                </Confirmation>
            </Footer>
        </>
    );
};

export default NewIntegration;
