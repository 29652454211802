import Dropdown from 'components/Dropdown/Dropdown';
import Modal, { ModalContent, ModalHeader } from 'components/Modal';
import TeamSwitcher from 'components/TeamSwitcher';
import UserDataHelper from 'helpers/UserDataHelper';
import { useTeam } from 'hooks/Queries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface MenuItem {
    id: number;
    title: string;
    selected: boolean;
    key: string;
    link?: string;
    toggleItem?: () => void;
}

const UserMenu = () => {
    const { t } = useTranslation();

    const [showTeamModal, setShowTeamModal] = useState(false);
    const { team } = useTeam();

    const closeTeamModal = () => {
        setShowTeamModal(false);
    };

    const logout = () => {
        UserDataHelper.clearUserData();
        toast.success(t('login:logoutMessage'));
    };

    const links: MenuItem[] = [
        {
            id: 1,
            title: t('menu.teams', { teamName: team?.name }),
            selected: false,
            key: 'teams',
            toggleItem: () => setShowTeamModal(true),
        },
        {
            id: 2,
            title: t('menu.logout'),
            selected: false,
            key: 'logout',
            toggleItem: logout,
        },
    ];

    return (
        <>
            <Dropdown light dtype="img" open="left" list={links} />
            <Modal showModal={showTeamModal} onClose={closeTeamModal}>
                <ModalHeader title={t('team.switchTeams')} />
                <ModalContent>
                    <TeamSwitcher onClose={closeTeamModal} />
                </ModalContent>
            </Modal>
        </>
    );
};

export default UserMenu;
