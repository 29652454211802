import * as Sentry from '@sentry/react';
import { Team } from 'api/teams/types';
import { CurrentUser } from 'api/users/types';
import { clearUser } from 'external/analytics';
import {
    CURRENCY,
    SHOW_NAVIGATION,
    SYSTEM_COUNTRY,
    TEAM_ID,
    TOKEN,
    TRACKERS_BOOTED,
    tokenChangedKey,
} from 'types/LocalStorage';
import { Currency, SystemCountry } from 'types/UserData';

class UserDataHelper {
    static get isStorageEnabled() {
        try {
            localStorage.getItem('test');
        } catch (exception) {
            return false;
        }
        return true;
    }

    static get showNavigation() {
        return !!localStorage.getItem(SHOW_NAVIGATION);
    }

    static bootUserTrackers(user: CurrentUser, team: Team) {
        // Satismeter
        if ((window as any).satismeter) {
            (window as any).satismeter({
                writeKey: 'EroTIZf3PoNSirm9',
                userId: user.id,
                traits: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    createdAt: user.createdAt,
                    team: team.id,
                },
            });
        }
    }

    static clearUserData() {
        try {
            Sentry.configureScope((scope) => {
                scope.setUser(null);
            });
        } catch (e) {
            // Sentry is blocked or otherwise inactive.
            console.error(e);
        }
        // Clear the user set by our analytics integrations.
        clearUser();
        this.clearStorage();
    }

    static getTeamId() {
        if (this.isStorageEnabled) {
            return localStorage.getItem(TEAM_ID) || '';
        }
        return '';
    }

    // This and getCurrency can definitely be refactored
    static getSystemCountry(): SystemCountry | undefined {
        if (this.isStorageEnabled) {
            const systemCountry = localStorage.getItem(SYSTEM_COUNTRY);
            if (systemCountry) {
                return systemCountry as SystemCountry;
            }
        }

        return undefined;
    }

    static getCurrency(): Currency | undefined {
        if (this.isStorageEnabled) {
            const localCurrency = localStorage.getItem(CURRENCY);
            if (localCurrency) {
                return localCurrency as Currency;
            }
        }
        return undefined;
    }

    static clearStorage() {
        localStorage.removeItem(TOKEN);
        document.dispatchEvent(new Event(tokenChangedKey)); // Used for navigating to login page
        localStorage.removeItem(SHOW_NAVIGATION);
        sessionStorage.removeItem(TRACKERS_BOOTED);
        localStorage.removeItem(TEAM_ID);
    }
}

export default UserDataHelper;
