import styled from 'styled-components/macro';

import PageHeader from '../components/Header';

const LoginHeader = styled.div`
    box-sizing: border-box;
    left: 0;
    right: 0;
    // To match the website
    padding: 34px 75px;
    position: absolute;
    margin: 0 auto;
    top: 0;
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: ${({ theme }) => `0 ${theme.space.s3} 0 ${theme.space.s5}`};
        height: 44px;
    }
    display: grid;
    place-items: center;
`;

const MainHeader = () => (
    <LoginHeader>
        <PageHeader login language logo />
    </LoginHeader>
);

export default MainHeader;
