import { AddUmbrella, StarBorder, Umbrella } from 'assets/icons';
import Input from 'components/FormElements/Input';
import Link from 'components/Typography/Link';
import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

import Heading from '../../../components/Typography/Heading';

export const Wrapper = styled.div`
    align-items: center;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey400}`};
    border-top: ${({ theme }) => `1px solid ${theme.colors.grey400}`};
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: auto 1fr auto auto;
    padding: ${({ theme }) => `${theme.space.s3} ${theme.space.s2}`};
    transition: background-color 0.15s ease-in-out;
`;

const Icon = css`
    grid-area: icon;
    height: 24px;
`;

export const EnabledIcon = styled(Umbrella)`
    color: ${({ theme }) => theme.colors.vinylGreen100};
    ${Icon}
`;

export const DisabledIcon = styled(AddUmbrella)`
    color: ${({ theme }) => theme.colors.grey200};
    ${Icon}
`;

export const StarIcon = styled(StarBorder)`
    height: 16px;
    width: 16px;
`;

export const InsuranceText = styled.div`
    align-items: center;
    display: grid;
    grid-column-gap: ${({ theme }) => theme.space.s2};
    grid-template-areas:
        'icon title'
        'icon links';
    margin-right: ${({ theme }) => theme.space.s4};
`;

export const Cost = styled.div`
    align-items: center;
    display: flex;
    justify-self: end;
`;

export const CostText = styled(Text)<{
    state: 'enabled' | 'loading' | 'disabled';
}>`
    ${({ theme, state }) => {
        if (state === 'loading' || state === 'disabled') {
            return css`
                color: ${theme.colors.grey100};
            `;
        }
    }};
`;

export const GoodsValueInput = styled(Input)`
    input {
        /* Needs to accept up to 1 000 000 */
        width: 255px;
    }
`;
export const HeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.space.s2};
`;
export const InputWrapper = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    justify-content: center;
    justify-items: center;
    justify-self: center;
    width: 100%;
`;

export const Dot = styled.div`
    background-color: ${({ theme }) => theme.colors.grey100};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.black};
    display: inline-block;
    height: 3px;
    margin: 0 ${({ theme }) => theme.space.s2};
    width: 3px;
`;

export const Links = styled.div`
    grid-area: links;
    white-space: nowrap;
`;

export const InsuranceLink = styled(Link)`
    font-size: 12px;
    font-weight: 500;
`;
export const InsuranceCTA = styled(Heading)`
    justify-self: end;
`;
