import styled from 'styled-components/macro';

export const ZwapgridIframe = styled.iframe`
    border: none;
    min-height: 75vh;
    width: 100%;
`;
export const SpinnerWrapper = styled.div`
    display: grid;
    min-height: 50vh;
    place-items: center;
    width: 100%;
`;
