import { get, post, remove } from 'api';
import ApiError from 'api/ApiError';
import { addressToBackendAddress } from 'api/consignments/mapper';
import { Address, BackendAddress } from 'api/consignments/types';
import {
    toBackendIntegration,
    toCustomerInformation,
} from 'api/subscriptions/mapper';
import {
    APISubscriptionTier,
    BackendCustomerInformation,
    CreateSubscriptionResponse,
    Integration,
    IntegrationConnectRequest,
    IntegrationDemo,
    IntegrationDisconnectRequest,
    IntegrationFeedback,
    Interval,
    PriceSubscriptionTier,
    Tier,
} from 'api/subscriptions/types';
import { goDomain } from 'config';
import { Currency } from 'types/UserData';

import Logger from '../../helpers/LogHelper';

export interface CreateSubscriptionProps {
    tier: APISubscriptionTier | PriceSubscriptionTier;
    integration?: Integration;
    email?: string;
    address?: Address;
    paymentMethodId?: string;
    priceId?: number;
    currency: Currency;
    interval: Interval;
}

interface CreateSubscriptionRequest {
    stripePaymentMethodId?: string;
    priceId?: number;
    tier: APISubscriptionTier | PriceSubscriptionTier;
    integration?: string;
    email?: string;
    address?: BackendAddress;
    currency: Currency;
    interval: Interval;
}

export const createSubscription = async ({
    paymentMethodId,
    priceId,
    email,
    tier,
    currency,
    interval,
    integration,
    address,
}: CreateSubscriptionProps) => {
    const data: CreateSubscriptionRequest = {
        stripePaymentMethodId: paymentMethodId,
        priceId,
        email,
        tier,
        currency,
        interval,
        address: address ? addressToBackendAddress(address) : undefined,
    };
    if (integration) {
        data.integration = toBackendIntegration(integration);
    }
    const response = await post<CreateSubscriptionResponse>(
        `${goDomain}v2/subscriptions/create-subscription`,
        data
    );
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    return response.parsedBody;
};

export const getSubscriptions = async () => {
    const response = await get<BackendCustomerInformation>(
        `${goDomain}v2/subscriptions/customer-information`
    );

    if (response?.parsedBody) {
        return toCustomerInformation(response.parsedBody);
    }

    throw new ApiError(response);
};

export const cancelSubscription = async (
    currency: Currency,
    tier?: Tier,
    interval?: Interval,
    subscriptionId?: number
) => {
    await post<void>(`${goDomain}v2/subscriptions/cancel-subscription`, {
        tier,
        currency,
        interval,
        subscriptionId,
    });
};

export const reactivateSubscription = async (
    tier?: Tier,
    subscriptionId?: number
) => {
    await post<void>(`${goDomain}v2/subscriptions/reactivate-subscription`, {
        tier,
        subscriptionId,
    });
};

export const changeSubscriptionTier = async (
    tier: Tier,
    currency: Currency,
    interval: Interval
) => {
    await post<void>(`${goDomain}v2/subscriptions/change-tier`, {
        tier,
        currency,
        interval,
    });
};

export const bookIntegrationDemo = async (data: IntegrationDemo) => {
    await post<void>(`${goDomain}v2/subscriptions/integration-demo`, data);
};

export const sendIntegrationFeedback = async (data: IntegrationFeedback) => {
    await post<void>(`${goDomain}v2/subscriptions/integration-feedback`, data);
};

export const cancelIntegration = async (system: Integration) => {
    await remove<void>(
        `${goDomain}v2/subscriptions/integrations/${toBackendIntegration(
            system
        )}`
    );
};

export const reactivateIntegration = async (system: Integration) => {
    await post<void>(
        `${goDomain}v2/subscriptions/integrations/${toBackendIntegration(
            system
        )}/reactivate`
    );
};

export const purchaseIntegration = async (system: Integration) => {
    Logger.info('purchaseIntegration called with system:', system);

    const requestUrl = `${goDomain}v2/subscriptions/integrations/${toBackendIntegration(
        system
    )}`;
    Logger.debug('Request URL:', requestUrl);

    Logger.debug('Sending POST request to server');
    await post<void>(requestUrl);
};

export const connectIntegration = async (req: IntegrationConnectRequest) => {
    Logger.info('connectIntegration called with request:', req);

    const endpoint = `${goDomain}v2/subscriptions/integrations/${toBackendIntegration(
        req.integration
    )}/connect`;
    Logger.info('Endpoint for connecting integration:', endpoint);

    try {
        Logger.info('Sending POST request to connect integration');
        await post<void>(endpoint, { ...req });
        Logger.info('Integration connected successfully');
    } catch (e) {
        Logger.error('Error in connecting integration:', e);
        // Additional error handling can be added here
    }
};

export const disconnectIntegration = async (
    req: IntegrationDisconnectRequest
) => {
    await remove<void>(
        `${goDomain}v2/subscriptions/integrations/${toBackendIntegration(
            req.integration
        )}/${req.id}`
    );
};

export const sendIntegrationInterest = async (system: Integration) => {
    await post<void>(`${goDomain}v2/subscriptions/integration-interest`, {
        system,
    });
};

export const sendIntegrationOnboardingCancelled = async (
    system: Integration
) => {
    await post<void>(
        `${goDomain}v2/subscriptions/integration-onboarding-canceled`,
        {
            system,
        }
    );
};
