import { StripeError } from '@stripe/stripe-js';
import { ConsignmentStatus } from 'api/consignments/types';
import { monthlyCosts, yearlyCosts } from 'constants/integrations';
import DateHelper, { RelativeDate } from 'helpers/DateHelper';
import { TFunction, i18n } from 'i18next';
import { DateTime } from 'luxon';

import { APISubscriptionTier, Interval } from '../api/subscriptions/types';
import { Currency } from '../types/UserData';
import { formatCost } from './CurrencyHelper';

export const getStatusText = (t: TFunction, status: ConsignmentStatus) => {
    switch (status) {
        case ConsignmentStatus.CONFIRMED:
            return t('orders:status.confirmed');
        case ConsignmentStatus.ORDERED:
            return t('orders:orderedFilterSingular');
        case ConsignmentStatus.IN_TRANSIT:
            return t('orders:inTransitFilterSingular');
        case ConsignmentStatus.DELIVERED:
            return t('orders:deliveredFilterSingular');
        case ConsignmentStatus.DELIVERED_MARKED:
            return t('orders:deliveredMarkedFilterSingular');
        case ConsignmentStatus.EXCEPTION:
            return t('orders:status.exception');
        case ConsignmentStatus.BOOKING_FAILED:
            return t('orders:status.bookingFailed');
        case ConsignmentStatus.CANCEL_PENDING:
            return t('orders:cancelPendingFilterSingular');
        case ConsignmentStatus.RETURN_BOOKED:
            return t('orders:returnBookedFilterSingular');
        case ConsignmentStatus.CANCELED:
            return t('orders:canceledFilterSingular');
        case ConsignmentStatus.PENDING:
            return t('orders:status.pending');
        default:
            return '-';
    }
};

export const getStatusFilterText = (
    t: TFunction,
    status: ConsignmentStatus
) => {
    switch (status) {
        case ConsignmentStatus.ORDERED:
            return t('orders:orderedFilter');
        case ConsignmentStatus.IN_TRANSIT:
            return t('orders:inTransitFilter');
        case ConsignmentStatus.DELIVERED:
            return t('orders:deliveredFilter');
        case ConsignmentStatus.EXCEPTION:
            return t('orders:exceptionFilter');
        case ConsignmentStatus.CANCELED:
            return t('orders:canceledFilter');
        default:
            return '-';
    }
};

export const getReadableDate = (
    t: TFunction,
    date: DateTime,
    dateFormat: Intl.DateTimeFormatOptions = DateTime.DATE_FULL
) => {
    const relativeDate = DateHelper.getRelativeDate(DateTime.now(), date);
    switch (relativeDate) {
        case RelativeDate.TODAY:
            return t('common.today');
        case RelativeDate.YESTERDAY:
            return t('common.yesterday');
        case RelativeDate.TOMORROW:
            return t('common.tomorrow');
        case RelativeDate.WITHIN_A_WEEK:
            return `${t('common.on')} ${date.weekdayLong}`;
        case RelativeDate.FORMATTED:
            return date.toLocaleString(dateFormat);
        default:
            return date.toLocaleString(dateFormat);
    }
};

export const getStripeErrorMessage = (t: TFunction, error: StripeError) => {
    if (error.type === 'card_error' && error.code === 'card_declined') {
        if (error.decline_code === 'generic_decline') {
            return t('settings:payment.cardDeclined');
        } else if (error.decline_code === 'insufficient_funds') {
            return t('settings:payment.insufficientFunds');
        }
    }
    return t('settings:payment.genericError');
};

export const getYearlyIntegrationCost = (
    lang: string,
    interval: Interval,
    tier: APISubscriptionTier,
    currency: Currency
) =>
    formatCost(
        interval === 'year'
            ? yearlyCosts[tier][currency]
            : monthlyCosts[tier][currency] * 12,
        lang,
        currency
    );

export const getMonthlyIntegrationCost = (
    lang: string,
    interval: Interval,
    tier: APISubscriptionTier,
    currency: Currency
) =>
    formatCost(
        interval === 'year'
            ? yearlyCosts[tier][currency] / 12
            : monthlyCosts[tier][currency],
        lang,
        currency,
        currency === 'EUR' ? 1 : 0
    );

/**
 * @param i18n i18n instance
 * @param currency The currency to convert to a symbol
 * To keep things short and more light-hearted in tone, we want to show the
 * currency symbol instead of the currency code in the input (e.g. € instead
 * of EUR). We do this by formatting a dummy value using the built-in Intl
 * library of the browser, and then removing the value itself along with any
 * spaces between it and the symbol.
 */
export const getCurrencySymbol = (i18n: i18n, currency: Currency) => {
    return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: currency,
    })
        .format(0)
        .replace(/\d+([,.]\d+\s*)?/g, '');
};
