import { useEffect, useState } from 'react';

import { useTeam, useUser } from './Queries';

export const useIsTeamMember = () => {
    // Detect God-mode
    const { user } = useUser();
    const { team } = useTeam();
    const [isTeamMember, setIsTeamMember] = useState(true);
    useEffect(() => {
        if (team?.members && user?.id) {
            setIsTeamMember(
                team?.members.some((member) => user?.id === member.id) || false
            );
        }
    }, [team?.members, user?.id]);
    return isTeamMember;
};
