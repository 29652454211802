import { getActiveSubscription } from 'constants/integrations';
import { DateTime } from 'luxon';

import { useFlags } from '../external/launchDarkly';
import { useTeam } from './Queries';
import { useSubscriptions } from './Queries/subscriptions';

type TrialStatus = 'notUsed' | 'used' | 'started' | 'ending' | 'ended';

const usePriceTierTrial = (): TrialStatus | undefined => {
    const { subscriptions } = useSubscriptions();
    const { plusTrialMarginGroup } = useFlags();
    const { team } = useTeam();

    if (!subscriptions || !plusTrialMarginGroup || !team?.createdAt) {
        return undefined;
    }

    if (getActiveSubscription(subscriptions.subscriptions, 'se_tier_2')) {
        return 'used';
    }
    // Only show banner to teams created after a specified date
    if (team.createdAt < DateTime.fromISO(plusTrialMarginGroup.startDate)) {
        if (subscriptions.priceTierSubscription.hasUsedTrial) {
            return 'used';
        }
        return 'notUsed';
    }

    const daysSinceCreation = team.createdAt.diffNow('days').days * -1;

    if (daysSinceCreation <= 9) return 'started';
    if (daysSinceCreation <= 13) return 'ending';
    if (daysSinceCreation <= 20) return 'ended';
    return 'used';
};

export default usePriceTierTrial;
