import { Button } from 'components/Buttons';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import {
    getAlternativeBrowsers,
    getBrowserWithVersion,
    getDownloadLink,
    getLogoSource,
    getLongName,
    getShortName,
} from 'helpers/BrowserHelper';
import { useWindowWidthContext } from 'hooks/WindowWidth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import breakpoints from 'theme/breakpoints';

import { FileDownload } from '../../assets/icons';
import DEPRECATEDRoundImage, {
    imageSize,
} from '../../components/Images/DEPRECATEDRoundImage';
import ContentCard from '../../components/Layout/ContentCard';
import MainHeader from '../MainHeader';

const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
`;

const Content = styled.div`
    background-color: ${({ theme }) => theme.colors.grey500};
    height: 100%;
    margin: auto;
    padding: 0 ${({ theme }) => theme.space.s6};
    padding-top: 155px;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        padding-top: 80px;
    }
`;

const HeaderWrapper = styled.div`
    background: 'black';
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const BrowserTable = styled.table`
    padding: ${({ theme }) => theme.space.s6};
    width: 100%;
`;

const ImageCell = styled.td<{ secondary: boolean }>`
    padding-right: ${({ theme }) => theme.space.s3};
    ${({ secondary, theme }) =>
        secondary ? `padding-top: ${theme.space.s6};` : ''}
`;

const NameCell = styled.td<{ secondary: boolean }>`
    padding-right: ${({ theme }) => theme.space.s3};
    ${({ secondary, theme }) =>
        secondary ? `padding-top: ${theme.space.s6};` : ''}
    width: 100%;
`;

const LinkCell = styled.td<{ secondary: boolean }>`
    ${({ secondary, theme }) =>
        secondary ? `padding-top: ${theme.space.s6};` : ''}
    text-align: right;
    white-space: nowrap;
`;

const CenteredCard = styled(ContentCard)`
    margin: 0 auto 0 auto;
    max-width: 100%;
`;

const Title = styled(Heading)`
    margin-bottom: ${({ theme }) => theme.space.s2};
`;

const TextWrapper = styled.div`
    margin: 0 auto ${({ theme }) => theme.space.s6} auto;
    max-width: 500px;
    text-align: center;
`;

const OldBrowserWarning = () => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);

    const mobileMathcer = window.matchMedia(`(max-width: ${breakpoints.xs})`);
    const width = useWindowWidthContext();
    useEffect(() => {
        setIsMobile(mobileMathcer.matches);
    }, [mobileMathcer.matches, width]);

    return (
        <Wrapper>
            <HeaderWrapper>
                <MainHeader />
            </HeaderWrapper>
            <Content>
                <TextWrapper>
                    <Title size={HeadingSize.LARGE}>
                        {t('oldBrowser.title')}
                    </Title>
                    <Text>
                        {t('oldBrowser.text1')}{' '}
                        {getBrowserWithVersion() || t('oldBrowser.yourBrowser')}{' '}
                        {t('oldBrowser.text2')}
                    </Text>
                </TextWrapper>
                <CenteredCard withShadow width="600px">
                    <BrowserTable>
                        {getAlternativeBrowsers().map((browser, index) => {
                            const secondary = index !== 0;
                            return (
                                <tr key={getShortName(browser)}>
                                    <ImageCell secondary={secondary}>
                                        <DEPRECATEDRoundImage
                                            src={getLogoSource(browser)}
                                            size={imageSize.medium}
                                        />
                                    </ImageCell>
                                    {!isMobile && (
                                        <NameCell secondary={secondary}>
                                            <Text size={TextSize.MEDIUM}>
                                                {getLongName(browser)}
                                            </Text>
                                        </NameCell>
                                    )}

                                    <LinkCell secondary={secondary}>
                                        <Button
                                            onClick={() => {
                                                const link =
                                                    getDownloadLink(browser);
                                                if (link) {
                                                    window.location.href = link;
                                                }
                                            }}
                                            variant={
                                                secondary
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                            iconAfter={<FileDownload />}
                                        >
                                            {isMobile
                                                ? getLongName(browser)
                                                : `${t(
                                                      'button.download'
                                                  )} ${getShortName(browser)}`}
                                        </Button>
                                    </LinkCell>
                                </tr>
                            );
                        })}
                    </BrowserTable>
                </CenteredCard>
            </Content>
        </Wrapper>
    );
};

export default OldBrowserWarning;
