import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const RoundImageContainer = styled.img`
    border-radius: 100%;
    box-shadow: ${({ hasShadow }) =>
        hasShadow ? '0 2px 4px rgba(0,0,0,0.2)' : ''};
    height: ${({ size }) => size || '24px'};
    position: relative;
    width: ${({ size }) => size || '24px'};
`;

export const imageSize = {
    small: '24px',
    medium: '52px',
    large: '80px',
};

const DEPRECATEDRoundImage = (props) => {
    return (
        <RoundImageContainer
            hasShadow={props.hasShadow}
            className={props.className}
            src={props.src}
            alt={props.alt}
            size={props.size}
        />
    );
};

DEPRECATEDRoundImage.propTypes = {
    size: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string,
    hasShadow: PropTypes.bool,
};

export default DEPRECATEDRoundImage;
