import styled from 'styled-components/macro';

const StyledModalContent = styled.div`
    grid-area: content;
    overflow-y: auto;
    padding-bottom: ${({ theme }) => theme.space.s3};
    padding-left: ${({ theme }) => theme.space.s8};
    padding-right: ${({ theme }) => theme.space.s8};
    padding-top: ${({ theme }) => theme.space.s3};
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding-left: ${({ theme }) => theme.space.s5};
        padding-right: ${({ theme }) => theme.space.s5};
    }
`;

export default StyledModalContent;
