import styled, { css } from 'styled-components/macro';

/* eslint-disable react/jsx-props-no-spreading */
export enum CaptionSize {
    MEDIUM,
    REGULAR,
}

interface CaptionProps {
    size?: CaptionSize;
}

const MEDIUM = css`
    font-weight: 500;
`;
const REGULAR = css`
    font-weight: normal;
`;

const StyledCaption = styled.span<CaptionProps>`
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ size }) => {
        switch (size) {
            case CaptionSize.MEDIUM:
                return MEDIUM;
            case CaptionSize.REGULAR:
                return REGULAR;
        }
        return '';
    }}
`;
type Props = CaptionProps & React.HTMLAttributes<HTMLSpanElement>;
const Caption = ({ size = CaptionSize.REGULAR, children, ...rest }: Props) => {
    return (
        <StyledCaption size={size} {...rest}>
            {children}
        </StyledCaption>
    );
};

export default Caption;
