import { includedMonthlyShipments } from 'constants/integrations';

import { Consignment } from '../consignments/types';
import {
    APISubscriptionTier,
    IntegrationCancellationInfo,
    Phase,
    PriceSubscriptionTier,
} from './types';

const getExternalImports = (consignments: Consignment[]) =>
    consignments.filter((c) => c.externalSource?.source === 'EXTERNAL_API')
        .length;

export const hasExceededImportLimit = (
    tier: APISubscriptionTier | undefined,
    importedOrdersBooked: number,
    consignments: Consignment[]
) => {
    const numberOfExternalImports = getExternalImports(consignments);
    // if were are not currently trying to book an external import there is no limit
    if (numberOfExternalImports === 0) {
        return false;
    }
    // if we have at least one import a tier is required
    if (tier === undefined) {
        return true;
    }

    if (!hasBookingLimit(tier)) {
        return false;
    }

    return (
        numberOfExternalImports + importedOrdersBooked >
        includedMonthlyShipments[tier]
    );
};

const hasBookingLimit = (tier: APISubscriptionTier): boolean => {
    return tier === 'integration_starter' || tier === 'integration_small';
};

export const hasReachedImportLimit = (
    tier: APISubscriptionTier | undefined,
    importedOrdersBooked: number,
    consignments: Consignment[]
) => {
    const numberOfExternalImports = getExternalImports(consignments);
    // if were are not currently trying to book an external import there is no limit
    if (numberOfExternalImports === 0) {
        return false;
    }
    // if we have at least one import a tier is required
    if (tier === undefined) {
        return true;
    }

    if (!hasBookingLimit(tier)) {
        return false;
    }
    return (
        numberOfExternalImports + importedOrdersBooked >=
        includedMonthlyShipments[tier]
    );
};

/**
 * Determines if an API tier is currently used and sold by Sendify, or if it is a legacy one.
 *
 * @return true if the tier is currently available for purchase, false otherwise.
 */
export const isAvailableAPITier = (tier: APISubscriptionTier): boolean =>
    tier === 'integration_starter' ||
    tier === 'integration_small' ||
    tier === 'integration_medium' ||
    tier === 'integration_enterprise';

export const isInvoiceCustomer = (
    current: Phase<PriceSubscriptionTier> | Phase<APISubscriptionTier>
): boolean => current.end === undefined;

export const getIntegrationStatus = (
    current: Phase<APISubscriptionTier>,
    next?: Phase<APISubscriptionTier>
) => {
    const { integrations: currentIntegrations } = current;
    const nextIntegrations = next?.integrations;
    const hasNext = !!next;

    return (currentIntegrations || []).reduce((prev, cur) => {
        const info: IntegrationCancellationInfo = {
            integration: cur.integration,
            canceled: false,
        };
        //Check if this integration is in the next phase
        const nextInfo = nextIntegrations?.find(
            (i) => i.integration === cur.integration
        );

        if (!nextIntegrations && hasNext) {
            info.canceled = true;
        } else if (nextIntegrations && !nextInfo) {
            info.canceled = true;
        }

        // duplicate the info with the qualtity the customer has
        const i = Array(cur.quantity)
            .fill(info)
            .map((i, index) => {
                // if the quantity of the next phase is less than the index were on then the user has cancelled
                if (nextInfo && nextInfo.quantity < index + 1) {
                    return { ...i, canceled: true };
                } else {
                    return i;
                }
            })
            .reverse();
        return [...prev, ...i];
    }, [] as IntegrationCancellationInfo[]);
};
