import * as Sentry from '@sentry/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
    APISubscriptionTier,
    Integration,
    Interval,
} from 'api/subscriptions/types';
import Badge from 'components/Badge';
import { Button } from 'components/Buttons';
import RadioButton from 'components/FormElements/RadioButton';
import RadioButtonGroup from 'components/FormElements/RadioButtonGroup';
import { GroupBase, SelectInstance } from 'components/FormElements/Select';
import ContentCard from 'components/Layout/ContentCard';
import { ModalHeader } from 'components/Modal';
import PaymentForm from 'components/PaymentForm';
import { PaymentForm as Form } from 'components/PaymentForm/types';
import ToggleButton from 'components/ToggleButton';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import { trialDays } from 'constants/priceTiers';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { trackPaymentButton } from 'external/analytics';
import { formatCost } from 'helpers/CurrencyHelper';
import {
    getMonthlyIntegrationCost,
    getStripeErrorMessage,
    getYearlyIntegrationCost,
} from 'helpers/TranslationHelper';
import { useTeam, useUser } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
    includedMonthlyShipments,
    isApiTier,
    monthlyCosts,
    yearlyCosts,
} from '../../constants/integrations';
import Logger from '../../helpers/LogHelper';
import usePriceTierTrial from '../../hooks/usePriceTierTrial';
import CostBreakdown from './CostBreakdown';
import { CostItem } from './CostBreakdown/CostBreakdown';
import IntegrationSelect from './IntegrationSelect';
import { IntegrationOptionType } from './IntegrationSelect/IntegrationValue';
import {
    ApiInfo,
    ConfirmButtonWrapper,
    Confirmation,
    Content,
    Footer,
    LeftAlignText,
    SubTitle,
    TierHeader,
    TierRadioButtonContent,
} from './StyledAPISubscriptionChange';

export type Props = {
    selectedTier: APISubscriptionTier;
    onSuccessfulSubmit?: () => void;
    interval: Interval;
    integration?: Integration;
};

/**
 * A payment form for the user to purchase a new integration tier bundle.
 */
const New = ({
    interval: initialInterval,
    onSuccessfulSubmit,
    selectedTier: initialTier,
    integration: initialIntegration,
}: Props) => {
    const stripe = useStripe();
    const { subscriptions, subscribe } = useSubscriptions();
    const elements = useElements();
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    const { user } = useUser();
    const [tier, setTier] = useState<APISubscriptionTier>(initialTier);
    const [interval, setInterval] = useState(initialInterval);
    const subscription = subscriptions?.apiSubscription;
    const trialStatus = usePriceTierTrial();
    let hasUsedTrial = trialStatus !== 'notUsed';

    const methods = useForm<Form>({
        defaultValues: {
            integration: initialIntegration,
        },
    });
    const {
        handleSubmit,
        clearErrors,
        setValue,
        control,
        errors,
        formState: { isSubmitting },
    } = methods;
    const formId = 'payment-form';
    const integration = useWatch({
        control,
        name: `integration`,
        defaultValue: initialIntegration,
    });
    useEffect(() => {
        return () => {
            clearErrors('cardComplete');
            setValue('cardComplete', undefined);
        };
        // The card field is reset when the component is unmounted, so we reset the form field.
    }, [clearErrors, setValue]);

    const productRef =
        useRef<
            SelectInstance<
                IntegrationOptionType,
                false,
                GroupBase<IntegrationOptionType>
            >
        >(null);
    const onSubmit = async (paymentForm: Form) => {
        Logger.info('onSubmit called with paymentForm:', paymentForm);

        if (!paymentForm.cardComplete) {
            Logger.info('Card not complete');
            return;
        }

        const { address, name, email } = paymentForm;
        trackPaymentButton({
            userName: name,
            countryCode: address.countryCode,
            city: address.city,
        });

        Logger.info('Payment form details:', { address, name, email });

        if (
            !stripe ||
            isSubmitting ||
            !elements ||
            !user ||
            !team ||
            tier === null
        ) {
            Logger.info('One of the required conditions failed:', {
                stripe,
                isSubmitting,
                elements,
                user,
                team,
                tier,
            });
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            Logger.info('Card element not found');
            return;
        }

        try {
            Logger.info('Attempting to subscribe with:', {
                stripe,
                name,
                address,
                email,
                tier,
                integration,
                cardElement,
                interval,
            });
            const confirmResult = await subscribe({
                stripe,
                name,
                address,
                email,
                tier,
                integration,
                cardElement,
                interval,
            });

            Logger.info('Subscription result:', confirmResult);

            if (confirmResult?.error) {
                Logger.info('Error during subscription:', confirmResult.error);
                toast.error(getStripeErrorMessage(t, confirmResult.error), {
                    autoClose: false,
                });
                Sentry.captureException(confirmResult.error);
                return;
            }

            if (onSuccessfulSubmit) {
                Logger.info('Calling onSuccessfulSubmit');
                onSuccessfulSubmit();
            }
        } catch (e) {
            Logger.error('Caught exception:', e);
            Sentry.captureException(e);
            toast.error(t('api.subscriptionChange.errors.bundleChange'), {
                autoClose: false,
            });
        }
    };

    if (!team || !subscription) {
        return null;
    }
    if (isApiTier(tier)) {
        hasUsedTrial = subscription.hasUsedTrial;
    }
    const { currency } = team;

    const numberOfIntervals = interval === 'year' ? 12 : 1;
    const costItems: CostItem[] = [
        {
            name: t('api.subscriptionChange.costBreakdown.tier', {
                tier: t(`tier.${tier}`),
            }),
            duration: Duration.fromObject(
                {
                    months: numberOfIntervals,
                },
                { conversionAccuracy: 'longterm' }
            ).shiftTo('seconds'),
            monthlyCost:
                interval === 'year'
                    ? yearlyCosts[tier][currency] / 12
                    : monthlyCosts[tier][currency],
            originalMonthlyCost:
                interval === 'year' ? monthlyCosts[tier][currency] : undefined,
        },
    ];
    if (integration) {
        costItems.push({
            name:
                integration === 'Other'
                    ? t('api.ownIntegration')
                    : t('api.subscriptionChange.costBreakdown.integration', {
                          integration,
                      }),
            duration: Duration.fromObject(
                {
                    months: numberOfIntervals,
                },
                { conversionAccuracy: 'longterm' }
            ).shiftTo('seconds'),
            monthlyCost: monthlyIntegrationCosts[integration][currency],
        } as CostItem);
    }

    const getPaymentText = () => {
        const today = DateTime.local();
        if (hasUsedTrial) {
            if (interval === 'year') {
                return t('api.subscriptionChange.bundle.yearInfo', {
                    startDate: today.toLocaleString({
                        month: 'long',
                        day: 'numeric',
                    }),
                    billingDate: today.toLocaleString({
                        month: 'long',
                        day: 'numeric',
                    }),
                });
            }
            return t('api.subscriptionChange.bundle.monthInfo', {
                date: today.day,
                billingDate: today.day,
            });
        }

        const firstDebitDay = today.plus({ days: trialDays });
        return t('api.subscriptionChange.bundle.trialInfo', {
            date: firstDebitDay.toLocaleString(DateTime.DATE_FULL),
            price: formatCost(
                costItems.reduce(
                    (acc, i) => acc + i.monthlyCost * i.duration.as('months'),
                    0
                ),
                i18n.language,
                team.currency,
                2,
                2
            ),
        });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <FormProvider {...methods}>
                <ModalHeader
                    title={
                        hasUsedTrial
                            ? t('api.subscriptionChange.bundle.title.new')
                            : t('api.subscriptionChange.bundle.title.newTrial')
                    }
                />
                <Content>
                    <ApiInfo>
                        <ToggleButton
                            fullWidth
                            onSelect={setInterval}
                            value={interval}
                            options={[
                                {
                                    value: 'month',
                                    title: t('interval.month'),
                                },
                                {
                                    value: 'year',
                                    title: t('interval.year'),
                                },
                            ]}
                        />

                        <TierHeader>
                            <Heading size={HeadingSize.SMALL}>
                                {t('payment.pickApiTier')}
                            </Heading>
                            {interval === 'year' && (
                                <Badge variant="success" textType="text">
                                    {t('payment.save', {
                                        percent: 20,
                                    })}
                                </Badge>
                            )}
                        </TierHeader>
                        <ContentCard withBorder withPadding>
                            <RadioButtonGroup
                                divider
                                value={tier}
                                onChange={setTier}
                            >
                                <RadioButton value="integration_starter">
                                    <TierRadioButtonContent>
                                        <Text size={TextSize.MEDIUM}>
                                            {t(`tier.integration_starter`)}
                                        </Text>
                                        <LeftAlignText size={TextSize.MEDIUM}>
                                            {`${getMonthlyIntegrationCost(
                                                i18n.language,
                                                interval,
                                                'integration_starter',
                                                currency
                                            )}/${t('pricePanel.month')}`}
                                        </LeftAlignText>
                                        <SubTitle>
                                            <Text>
                                                {t(`api.feature2`, {
                                                    count: includedMonthlyShipments[
                                                        'integration_starter'
                                                    ],
                                                })}
                                            </Text>
                                            <LeftAlignText>
                                                {`${getYearlyIntegrationCost(
                                                    i18n.language,
                                                    interval,
                                                    'integration_starter',
                                                    currency
                                                )}/${t('pricePanel.year')}`}
                                            </LeftAlignText>
                                        </SubTitle>
                                    </TierRadioButtonContent>
                                </RadioButton>

                                <RadioButton value="integration_small">
                                    <TierRadioButtonContent>
                                        <Text size={TextSize.MEDIUM}>
                                            {t(`tier.integration_small`)}
                                        </Text>
                                        <LeftAlignText size={TextSize.MEDIUM}>
                                            {`${getMonthlyIntegrationCost(
                                                i18n.language,
                                                interval,
                                                'integration_small',
                                                currency
                                            )}/${t('pricePanel.month')}`}
                                        </LeftAlignText>
                                        <SubTitle>
                                            <Text>
                                                {t(`api.feature2`, {
                                                    count: includedMonthlyShipments[
                                                        'integration_small'
                                                    ],
                                                })}
                                            </Text>
                                            <LeftAlignText>
                                                {`${getYearlyIntegrationCost(
                                                    i18n.language,
                                                    interval,
                                                    'integration_small',
                                                    currency
                                                )}/${t('pricePanel.year')}`}
                                            </LeftAlignText>
                                        </SubTitle>
                                    </TierRadioButtonContent>
                                </RadioButton>

                                <RadioButton value="integration_medium">
                                    <TierRadioButtonContent>
                                        <Text size={TextSize.MEDIUM}>
                                            {t(`tier.integration_medium`)}
                                        </Text>
                                        <LeftAlignText size={TextSize.MEDIUM}>
                                            {`${getMonthlyIntegrationCost(
                                                i18n.language,
                                                interval,
                                                'integration_medium',
                                                currency
                                            )}/${t('pricePanel.month')}`}
                                        </LeftAlignText>
                                        <SubTitle>
                                            <Text>
                                                {t(`api.feature2`, {
                                                    count: includedMonthlyShipments[
                                                        'integration_medium'
                                                    ],
                                                })}
                                            </Text>
                                            <LeftAlignText>
                                                {`${getYearlyIntegrationCost(
                                                    i18n.language,
                                                    interval,
                                                    'integration_medium',
                                                    currency
                                                )}/${t('pricePanel.year')}`}
                                            </LeftAlignText>
                                        </SubTitle>
                                    </TierRadioButtonContent>
                                </RadioButton>
                            </RadioButtonGroup>
                        </ContentCard>
                        <Controller
                            control={control}
                            defaultValue={null}
                            name="integration"
                            rules={{
                                required: t('errors.required').toString(),
                            }}
                            onFocus={() => {
                                productRef?.current?.inputRef?.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }}
                            render={({ value, onChange }) => (
                                <IntegrationSelect
                                    value={value}
                                    onChange={onChange}
                                    ref={productRef}
                                    error={!!errors.integration}
                                    errorMessage={errors.integration?.message}
                                />
                            )}
                        />
                    </ApiInfo>
                    <FormProvider {...methods}>
                        <PaymentForm />
                    </FormProvider>
                </Content>

                <Footer>
                    <Confirmation>
                        <CostBreakdown
                            items={costItems}
                            isTrial={!hasUsedTrial}
                            sumStartText={t(
                                'api.subscriptionChange.bundle.start',
                                {
                                    date: hasUsedTrial
                                        ? DateTime.local().toLocaleString(
                                              DateTime.DATE_FULL
                                          )
                                        : DateTime.local()
                                              .plus({
                                                  days: trialDays,
                                              })
                                              .toLocaleString(
                                                  DateTime.DATE_FULL
                                              ),
                                }
                            )}
                        />

                        <ConfirmButtonWrapper>
                            <Text>{getPaymentText()}</Text>
                            <Button
                                fullWidth
                                loading={isSubmitting}
                                type="submit"
                                form={formId}
                            >
                                {!hasUsedTrial
                                    ? t(
                                          'api.subscriptionChange.bundle.button.newTrial'
                                      )
                                    : t(
                                          'api.subscriptionChange.bundle.button.new'
                                      )}
                            </Button>
                        </ConfirmButtonWrapper>
                    </Confirmation>
                </Footer>
            </FormProvider>
        </form>
    );
};
export default New;
