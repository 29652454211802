import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    /*  Row-reverse so the forward button will be on the right if there is no back button. */
    flex-direction: row-reverse;
    justify-content: space-between;
`;

export const SpinnerWrapper = styled.div`
    display: grid;
    height: 100px;
    place-items: center;
    width: 50vw;
`;
