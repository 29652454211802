import { post } from 'api';

import { SendifyInsuranceClaim } from '../../components/ClaimForm/Sendify/types';
import * as config from '../../config';
import { goDomain } from '../../config';
import { Currency } from '../../types/UserData';
import ApiError from '../ApiError';
import { sendifyClaimToSendifyClaimRequest } from '../supportRequest/mapper';
import { InsurancePrice } from './types';

export const getSendifyInsurancePrice = async (
    goodsValue: number,
    currency: Currency
): Promise<InsurancePrice> => {
    const response = await post<InsurancePrice>(
        `${config.goDomain}v2/insurance/price`,
        { goodsValue, currency }
    );
    if (response.parsedBody) {
        return response.parsedBody;
    }
    throw new ApiError(response);
};

export const sendSendifyInsuranceClaim = async (
    claim: SendifyInsuranceClaim
): Promise<void> => {
    const request = await sendifyClaimToSendifyClaimRequest(claim);
    await post(`${goDomain}v2/insurance/claim`, request);
};
