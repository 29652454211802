import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { enableLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking';
import { disableAnonymousTracking } from '@snowplow/browser-tracker';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { initialize as initializeAmplitude } from 'external/amplitude';
import { createTracker } from 'external/dataPipeline';
import 'polyfills';
import { createRoot } from 'react-dom/client';

import App from './App';
// import i18n (needs to be bundled ;))
import './i18n';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_ENV === 'test') {
    // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
    const { worker } = require('./mocks/browser');
    worker.start();
}

// react-query setup
// We don't want any logs showing up, as they clutter the terminal
const noop = () => null;

Sentry.init({
    dsn: 'https://83325745e560415b9718b0841cc9286e@o389201.ingest.sentry.io/5238687',
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
});
const queryClient = new QueryClient({
    logger: {
        error: noop,
        log: noop,
        warn: noop,
    },
    defaultOptions: {
        queries: {
            /**
             * Average session time on web app is around 8.5 minutes
             * Assume we don't need to update the data within this time, by default.
             **/
            staleTime: 1000 * 60 * 8.5,
            /**
             * Based on the average session we can extend the cache time to 10 minutes
             * This ensures that the data is kept in cache longer than the average session duration.
             **/
            cacheTime: 1000 * 60 * 10,
            /**
             * Leaving this on `true` causes the queries to refetch on every LaunchDarkly flag change,
             * which leads to our database being overloaded when multiple clients are open. We thus
             * set it to `false`.
             */
            refetchOnMount: false,
        },
    },
});
(window as any).hsConversationsSettings = {
    loadImmediately: false,
};

const allowStatisticsCookies =
    window.Cookiebot?.consent?.statistics ||
    process.env.NODE_ENV === 'development'; // Cookiebot is not present in local development

if (allowStatisticsCookies) {
    initializeAmplitude();
}

// We don't want to initialize the user event pipeline when running Jest tests.
const enableUserEventPipeline =
    process.env.NODE_ENV !== 'test' && process.env.REACT_APP_EVENT_PIPELINE_URL;

if (enableUserEventPipeline) {
    createTracker();
    enableLinkClickTracking({
        pseudoClicks: true,
    });
}

// Starts tracking the user, if the user has accepted statistics cookies.
const initializeUserTracking = () => {
    if (!window.Cookiebot?.consent?.statistics) return;
    initializeAmplitude();
    if (enableUserEventPipeline) {
        disableAnonymousTracking({
            stateStorageStrategy: 'cookieAndLocalStorage',
        });
    }
};

window.addEventListener(
    'CookiebotOnConsentReady',
    initializeUserTracking,
    false
);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // We guarantee that the 'root' element exists.
root.render(
    <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <App />
    </QueryClientProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
