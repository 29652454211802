import Heading, { HeadingSize } from 'components/Typography/Heading';
import {
    CenteredContainer,
    StyledVerifiedEmail,
    SubTitle,
} from 'containers/ConfirmEmail/StyledConfirmEmail';
import { useTranslation } from 'react-i18next';

const Confirmed = () => {
    const { t } = useTranslation('confirmEmail');
    return (
        <CenteredContainer>
            <StyledVerifiedEmail />
            <Heading size={HeadingSize.MEDIUM}>
                {t('confirmEmail:valid.title')}
            </Heading>
            <SubTitle>{t('confirmEmail:valid.subtitle')}</SubTitle>
        </CenteredContainer>
    );
};

export default Confirmed;
