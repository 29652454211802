import styled from 'styled-components/macro';

const height = 24;
const width = 42;
const thumbSize = 18;
const thumbMargin = height / 2 - thumbSize / 2;

export const Input = styled.input<{ color?: string }>`
    appearance: none;
    background-color: ${({ theme }) => theme.colors.grey300};
    border-radius: ${height}px;
    cursor: pointer;
    height: ${height}px;
    margin: 0;
    min-height: ${height}px;
    min-width: ${width}px;
    position: relative;
    transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
    user-select: none;
    width: ${width}px;

    &::before {
        content: '';
        position: absolute;
        height: ${thumbSize}px;
        width: ${thumbSize}px;
        top: ${thumbMargin}px;
        left: ${thumbMargin}px;
        background-color: white;
        transition: all 0.15s ease-in-out;
        border-radius: 50%;
        cursor: pointer;
        z-index: 1;
    }

    &:checked {
        background-color: ${({ theme, color }) =>
            color ?? theme.colors.blue200};
        &::before {
            transform: translateX(${width - thumbSize - thumbMargin * 2}px);
        }
    }

    &:disabled {
        cursor: default;
        opacity: 0.4;
    }

    &:focus {
        outline: 1px solid ${({ theme }) => theme.colors.blue200};
        outline-offset: 2px;
    }
`;
