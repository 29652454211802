/* eslint-disable max-len */
import { css } from 'styled-components/macro';

/* New Classes Below */
export default css`
    .Row_Flex {
        display: flex;
    }

    .Flex_Column {
        flex-direction: column;
    }

    .Flex_Row {
        flex-direction: row;
    }

    .Flex_No_Shrink {
        flex-shrink: 0;
    }

    .Align_Center {
        align-items: center;
    }

    .Align_Self_Center {
        align-self: center;
    }

    .Justify_Spacearound {
        justify-content: space-around;
    }

    .Table_Content {
        height: calc(100vh - 257px) !important;
    }

    .ReactTable .rt-thead.-header {
        background: ${({ theme }) => theme.colors.white};
        box-shadow: 0 1px 0 ${({ theme }) => theme.colors.grey400};
        padding: 8px 0;
        position: sticky;
        top: 0;
        z-index: 3;
    }

    .pagination-bottom {
        background: ${({ theme }) => theme.colors.white};
        bottom: 0;
        padding: 0;
        position: sticky;
        width: 100%;
        z-index: 4;
    }

    /*Original Classes Below*/
    .Table {
        margin-top: 16px;
        width: 100%;
    }

    .headerRow {
        padding: 12px 0px 12px 0px;
    }

    .evenRow,
    .oddRow {
        box-shadow: inset 0px 1px 0px ${({ theme }) => theme.colors.grey500};
        color: #181818;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        /*ink 600 */
    }

    .oddRow {
        /* Ink 100 */
        background-color: ${({ theme }) => theme.colors.grey500};
    }

    .headerColumn {
        color: ${({ theme }) => theme.colors.black};
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-transform: none;
    }

    .exampleColumn {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .checkboxLabel {
        margin-left: 0.5rem;
    }

    .checkboxLabel:first-of-type {
        margin-left: 0;
    }

    .noRows {
        align-items: center;
        bottom: 0;
        color: ${({ theme }) => theme.colors.grey200};
        display: flex;
        font-size: 1em;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .DeliverTo_Description {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
    }

    .description {
        color: ${({ theme }) => theme.colors.grey200};
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 18px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: max-content;
        /* Ink 500 */
    }

    .description::first-letter {
        text-transform: capitalize;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .description.ellipsis {
        width: 100%;
    }

    .colText {
        box-sizing: border-box;
        color: ${({ theme }) => theme.colors.black};
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        margin-bottom: 0px;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .colText::first-letter {
        text-transform: capitalize;
    }

    .colText--small {
        box-sizing: border-box;
        color: #181818;
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        margin-bottom: 0px;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: max-content;
    }

    .colIcon {
        display: inline-block;
        line-height: 30px;
        margin: 0;
    }

    .colText span {
        color: ${({ theme }) => theme.colors.black};
    }

    .componentContainer {
        align-items: center;
        display: flex;
        height: auto;
        place-content: center;
        width: 100%;
    }

    .subComponent {
        background: ${({ theme }) => theme.colors.white};
        overflow-y: auto;
        padding: 0;
        place-self: center;
        width: 100%;
    }

    .subComponentAddons {
        float: left;
        height: auto;
        overflow: hidden;
        padding: 18px 10px 8px 10px;
        width: 93%;
    }

    .subComponentBooking {
        background: ${({ theme }) => theme.colors.grey500};
        border-top: 1px solid ${({ theme }) => theme.colors.grey400};
        color: ${({ theme }) => theme.colors.black};
        float: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 13px 15px 17px;
        width: 45%;
    }

    .componentContainerBooking {
        align-items: center;
        height: 45px;
        width: 100%;
    }

    .hiddenColumn {
        opacity: 0;
        transition: all 0.12s ease-in-out;
    }

    .ReactTable .rt-tbody .rt-tr-group:hover .hiddenColumn {
        opacity: 1;
        transition-delay: 0s;
    }

    .ReactTable {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .ReactTable * {
        box-sizing: border-box;
    }

    .ReactTable .rt-table {
        align-items: stretch;
        border-collapse: collapse;
        display: flex;
        flex: auto 1;
        flex-direction: column;
        overflow: visible;
        width: 100%;
    }
    .ReactTable .rt-tr-group {
        align-items: stretch;
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    .ReactTable .rt-tr {
        display: inline-flex;
        flex: 1 0 auto;
    }
    .ReactTable .rt-thead {
        color: ${({ theme }) => theme.colors.grey100};
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding: 0px 0px 12px 0px;
        user-select: none;
    }

    .ReactTable .rt-thead.-headerGroups {
        background: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .ReactTable .rt-thead.-filters {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .ReactTable .-pagination input,
    .ReactTable .-pagination select {
        background: ${({ theme }) => theme.colors.white};
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0px;
        font-size: inherit;
        font-weight: normal;
        outline: none;
        padding: 5px 7px;
    }

    .ReactTable .rt-thead.-filters input,
    .ReactTable .rt-thead.-filters select {
        background: ${({ theme }) => theme.colors.white};
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0px;
        font-size: inherit;
        font-weight: normal;
        outline: none;
        padding: 5px 7px;
    }

    .ReactTable .rt-thead .rt-tr {
        text-align: left;
    }

    .ReactTable .rt-th,
    .ReactTable .rt-td {
        flex: 1 0 0;
        overflow: hidden;
        padding: 12px 18px;
        text-overflow: ellipsis;
        transition: 0.3s ease;
        transition-property: width, min-width, padding, opacity;
        white-space: nowrap;
        /*place-self: center;*/
    }

    .ReactTable .rt-td {
        min-height: 60px;
    }

    .ReactTable .rt-thead .rt-th,
    .ReactTable .rt-thead .rt-td {
        align-items: center;
        background-position: right center;
        /* border-right: 1px solid rgba(0,0,0,0.05); */
        background-repeat: no-repeat;
        box-shadow: inset 0 0 0 0 transparent;
        display: flex;
        line-height: normal;
        padding: 5px 18px;
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .ReactTable .rt-tfoot .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
    }

    .ReactTable .rt-tbody .rt-td {
        overflow: visible;
        place-self: flex-start;
    }

    .ReactTable .rt-resizing .rt-th,
    .ReactTable .rt-resizing .rt-td {
        cursor: col-resize;
        transition: none !important;
        user-select: none;
    }

    .ReactTable .rt-thead .rt-th:last-child,
    .ReactTable .rt-thead .rt-td:last-child {
        border-right: 0;
        background-image: none;
    }

    .ReactTable .rt-thead .rt-th.-cursor-pointer,
    .ReactTable .rt-thead .rt-td.-cursor-pointer {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgMTAuNzE0Mkw2IDE0LjE0MjhMOSAxMC43MTQySDNaIiBmaWxsPSIjQ0NDQ0NDIi8+CjxwYXRoIGQ9Ik0zIDcuMjg1NzdMNiAzLjg1NzJMOSA3LjI4NTc3SDNaIiBmaWxsPSIjQ0NDQ0NDIi8+Cjwvc3ZnPgo=');
        cursor: pointer;
        width: max-content;
    }

    .ReactTable .rt-thead .rt-th.-sort-desc.-cursor-pointer,
    .ReactTable .rt-thead .rt-td.-cursor-pointer {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgMTAuNzE0Mkw2IDE0LjE0MjhMOSAxMC43MTQySDNaIiBmaWxsPSJibGFjayIvPgo8cGF0aCBkPSJNMyA3LjI4NTc3TDYgMy44NTcyTDkgNy4yODU3N0gzWiIgZmlsbD0iI0NDQ0NDQyIvPgo8L3N2Zz4K');
        cursor: pointer;
        width: max-content;
    }

    .ReactTable .rt-thead .rt-th.-sort-asc.-cursor-pointer,
    .ReactTable .rt-thead .rt-td.-cursor-pointer {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgMTAuNzE0Mkw2IDE0LjE0MjhMOSAxMC43MTQySDNaIiBmaWxsPSIjQ0NDQ0NDIi8+CjxwYXRoIGQ9Ik0zIDcuMjg1NzdMNiAzLjg1NzJMOSA3LjI4NTc3SDNaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K');
        cursor: pointer;
        width: max-content;
    }

    .ReactTable .rt-thead .rt-th:focus {
        outline: none;
    }

    .ReactTable .rt-thead .rt-resizable-header {
        overflow: visible;
    }

    .ReactTable .rt-thead .rt-resizable-header:last-child {
        overflow: hidden;
    }

    .ReactTable .rt-thead .rt-resizable-header-content {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ReactTable .rt-thead .rt-header-pivot:after,
    .ReactTable .rt-thead .rt-header-pivot:before {
        border: solid transparent;
        content: ' ';
        height: 0;
        left: 100%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        width: 0;
    }

    .ReactTable .rt-thead .rt-header-pivot:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: ${({ theme }) => theme.colors.white};
        border-width: 8px;
        margin-top: -8px;
    }

    .ReactTable .rt-thead .rt-header-pivot:before {
        border-color: rgba(102, 102, 102, 0);
        border-left-color: ${({ theme }) => theme.colors.grey500};
        border-width: 10px;
        margin-top: -10px;
    }

    .ReactTable .rt-tbody {
        display: flex;
        flex: 99999 1 auto;
        flex-direction: column;
        overflow: visible;
    }

    .ReactTable .rt-tbody .rt-tr-group {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    .ReactTable .rt-tbody .rt-tr-group:hover {
        background: ${({ theme }) => theme.colors.grey500};
    }

    .ReactTable .rt-tbody .rt-tr-group:last-child {
        border-bottom: 0;
    }

    .ReactTable .rt-tbody .rt-td:last-child {
        border-right: 0;
    }

    .ReactTable .rt-tbody .rt-expandable {
        cursor: pointer;
        text-overflow: clip;
    }

    .ReactTable .rt-tr:hover {
        opacity: 1 !important;
        transition: opacity 0.2s ease-in-out;
    }

    .ReactTable .rt-th.-cursor-pointer:hover {
        font-weight: 600;
    }

    .ReactTable .rt-th.-hidden,
    .ReactTable .rt-td.-hidden {
        border: 0 !important;
        min-width: 0 !important;
        opacity: 0 !important;
        padding: 0 !important;
        width: 0 !important;
    }

    .ReactTable .rt-expander {
        color: transparent;
        display: inline-block;
        margin: 0 10px;
        margin: 0;
        position: relative;
    }

    .ReactTable .rt-expander:after {
        border-left: 5.04px solid transparent;
        border-right: 5.04px solid transparent;
        border-top: 7px solid rgba(0, 0, 0, 0.8);
        content: '';
        cursor: pointer;
        height: 0;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        width: 0;
    }

    .ReactTable .rt-expander.-open:after {
        transform: translate(-50%, -50%) rotate(0);
    }

    .ReactTable .rt-resizer {
        bottom: 0;
        cursor: col-resize;
        display: none;
        position: absolute;
        right: -18px;
        top: 0;
        width: 36px;
        z-index: 10;
    }

    .ReactTable .rt-tfoot {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    .ReactTable .rt-tfoot .rt-td:last-child {
        border-right: 0;
    }

    .ReactTable.-striped .rt-tr.-odd {
        background-color: ${({ theme }) => theme.colors.grey500};
    }

    .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
        background: ${({ theme }) => theme.colors.grey500};
    }

    .ReactTable .-pagination {
        align-items: stretch;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 3px;
        z-index: -1;
    }

    .ReactTable .-pagination .-btn {
        appearance: none;
        background: rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 0px;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        display: block;
        font-size: 1em;
        height: 100%;
        outline: none;
        padding: 6px;
        transition: all 0.1s ease;
        width: 100%;
    }

    .ReactTable .-pagination .-btn[disabled] {
        cursor: default;
        opacity: 0.5;
    }

    .ReactTable .-pagination .-btn:not([disabled]):hover {
        background: rgba(0, 0, 0, 0.3);
        color: ${({ theme }) => theme.colors.white};
    }

    .-previous {
        color: ${({ theme }) => theme.colors.black};
        flex: 1 1;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: justify;
        width: max-content;
    }

    .ReactTable .-pagination .-previous,
    .ReactTable .-pagination .-next {
        flex: 1;
        text-align: center;
    }

    .ReactTable .-pagination .-center {
        align-items: center;
        display: flex;
        flex: 1.5;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 0;
        text-align: center;
    }

    .ReactTable .-pagination .-pageInfo {
        display: inline-block;
        margin: 3px 10px;
        white-space: nowrap;
    }

    .ReactTable .-pagination .-pageJump {
        display: inline-block;
    }

    .ReactTable .-pagination .-pageJump input {
        text-align: center;
        width: 70px;
    }

    .ReactTable .-pagination .-pageSizeOptions {
        margin: 3px 10px;
    }

    .ReactTable .rt-noData {
        background: rgba(255, 255, 255, 0.8);
        color: rgba(0, 0, 0, 0.5);
        display: block;
        left: 50%;
        padding: 20px;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
        z-index: 1;
    }

    .ReactTable .-loading {
        background: rgba(255, 255, 255, 0.8);
        bottom: 0;
        display: block;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.3s ease;
        z-index: -1;
    }

    .ReactTable .-loading > div {
        color: rgba(0, 0, 0, 0.6);
        display: block;
        font-size: 15px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-52%);
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        width: 100%;
    }

    .ReactTable .-loading.-active {
        opacity: 1;
        pointer-events: all;
        z-index: 2;
    }

    .ReactTable .-loading.-active > div {
        transform: translateY(50%);
    }

    /* custom pagination */
    .Table__itemCount {
        /* margin-top: 10px; */
        font-size: 12px;
    }

    .Table__visiblePagesWrapper {
        margin-right: 16px;
    }

    .Table__pagination {
        border-top: 1px solid ${({ theme }) => theme.colors.grey400};
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        font-size: 12px;
        font-weight: 500;
        justify-content: flex-end;
        padding: 8px 16px;
    }

    .Table__pageButton {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 12px;
        line-height: 18px;
        outline: none;
        padding: 0;
    }

    .Table__pageButton:disabled {
        color: ${({ theme }) => theme.colors.grey100};
        cursor: not-allowed;
        opacity: 0.25;
    }

    .Table__pageButton--active {
        color: ${({ theme }) => theme.colors.black};
        font-weight: normal;
    }

    .circleImage {
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 100%;
        height: 24px;
        margin-right: 12px;
        margin-top: 3px;
        place-self: flex-start;
        position: relative;
        width: 24px;
    }

    .shipment-label {
        color: ${({ theme }) => theme.colors.black};
        cursor: pointer;
    }

    .Table__prevPageWrapper {
        margin-right: 16px;
    }

    .Table__prevPageWrapper,
    .Table__nextPageWrapper {
        /*padding-top: 10px*/
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;
