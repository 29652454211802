import { get } from 'api';
import ApiError from 'api/ApiError';
import { BackendTeamSettings, TeamSettings } from 'api/teams/types';

import * as config from '../../config';
import { backendTeamSettingsToTeamSettings } from '../teams/mapper';

export const getTeamSettings = async (): Promise<TeamSettings> => {
    const response = await get<BackendTeamSettings>(
        `${config.goDomain}v2/settings`
    );

    if (response?.parsedBody) {
        return backendTeamSettingsToTeamSettings(response.parsedBody);
    }
    throw new ApiError(response);
};
