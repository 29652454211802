import { IconButton } from 'components/Buttons';
import Flag from 'components/Flag';
import { Alpha2Code } from 'i18n-iso-countries';
import styled from 'styled-components/macro';

import { Language, languages } from '../../../helpers/LanguageHelper';

const ButtonContainer = styled.div`
    display: grid;
    grid-column-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: repeat(3, min-content);
`;

const getFlag = (languageCode: Language): Alpha2Code => {
    switch (languageCode) {
        case 'sv':
            return 'SE';
        case 'de':
            return 'DE';
        case 'en':
            return 'GB';
    }
    throw new Error(`Unsupported language '${languageCode}'`);
};

interface Props {
    selectedLanguage: Language;
    onClick: (language: Language) => void;
}
const FlagButtons = (props: Props) => {
    const { selectedLanguage, onClick } = props;

    return (
        <ButtonContainer>
            {languages.map((languageCode) => {
                const flagCode = getFlag(languageCode);
                return (
                    <IconButton
                        key={languageCode}
                        data-testid={`language-flag-button-${flagCode}`}
                        className={
                            selectedLanguage === languageCode ? 'active' : ''
                        }
                        onClick={() => onClick(languageCode)}
                        icon={<Flag countryCode={flagCode} />}
                    />
                );
            })}
        </ButtonContainer>
    );
};

export default FlagButtons;
