import merge from 'lodash/merge';
import { useContext } from 'react';
import { StylesConfig } from 'react-select';
import { ThemeContext } from 'styled-components/macro';

import type { Props, Theme } from '.';
import { GroupBase } from '.';
import defaultComponents from './Components';

/**
 * Default values for react-select props. Includes theming, specifying where
 * the menu shall open, and custom default components.
 */
const useSelectProps = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>({
    components = {},
    id,
    ...props
}: Props<Option, IsMulti, Group>): Props<Option, IsMulti, Group> => {
    const themeContext = useContext(ThemeContext);
    const { styles: propStyles, ...rest } = props;
    return {
        inputId: id,
        menuPlacement: 'auto',
        classNamePrefix: 'react-select',
        components: {
            ...defaultComponents,
            ...components,
        },
        theme: (theme: Theme) => ({
            ...theme,
            ...themeContext,
            colors: {
                ...theme.colors,
                ...themeContext.colors,
                primary25: themeContext.colors.grey400,
                primary: themeContext.colors.black,
            },
        }),
        styles: merge(
            {
                clearIndicator: (styles) => ({
                    ...styles,
                    display: 'none',
                }),
                control: (base, state) => {
                    let style = {
                        ...base,
                        minHeight: 44,
                        borderColor: state.selectProps.error
                            ? themeContext.colors.red100
                            : themeContext.colors.grey300,
                        borderWidth: 1,
                        borderRadius: 0,
                        color: state.selectProps.error
                            ? themeContext.colors.red200
                            : themeContext.colors.black,
                        cursor: 'text',
                        '&:hover': {
                            borderColor: state.selectProps.error
                                ? themeContext.colors.red100
                                : themeContext.colors.grey300,
                        },
                    };
                    if (state.isFocused) {
                        style = {
                            ...style,
                            ...{
                                boxShadow: `0 0 0 1px inset ${
                                    state.selectProps.error
                                        ? themeContext.colors.red100
                                        : themeContext.colors.black
                                }`,
                                zIndex: themeContext.zIndex.popover,
                            },
                        };
                    }
                    if (state.isDisabled) {
                        style = {
                            ...style,
                            ...{
                                color: themeContext.colors.grey100,
                                backgroundColor: themeContext.colors.grey500,
                            },
                        };
                    }
                    return style;
                },
                indicatorSeparator: (base) => ({ ...base, display: 'none' }),
                input: (styles) => ({
                    ...styles,
                    color: themeContext.colors.black,
                    fontSize: 14,
                    marginLeft: themeContext.space.s1,
                }),
                indicatorsContainer: (styles) => ({
                    ...styles,
                    height: 42, // 44px (the intended total height) - 2 * 1px borders
                }),
                menu: (styles) => ({
                    ...styles,
                    borderRadius: 0,
                    zIndex: 6,
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                multiValueRemove: (base, state) => ({
                    ...base,
                    // Replace the red styling on focus with the grey hover style.
                    backgroundColor: state.isFocused
                        ? themeContext.colors.grey300
                        : 'inherit',
                    '&:hover': {
                        // Remove the red styling on hover.
                        backgroundColor: 'inherit',
                        color: 'inherit',
                    },
                }),
                singleValue: (base, state) => {
                    const style = {
                        ...base,
                        cursor: 'pointer',
                        color: themeContext.colors.black,
                    };
                    if (state.isDisabled) {
                        style.color = themeContext.colors.grey100;
                    }
                    return style;
                },
                option: (base, state) => {
                    const style = {
                        ...base,
                        cursor: 'pointer',
                        color: themeContext.colors.black,
                        minHeight: 44,
                        display: 'flex',
                        alignItems: 'center',
                    };
                    if (state.isSelected) {
                        style.color = themeContext.colors.white;
                    }
                    return style;
                },
                valueContainer: (styles) => ({
                    ...styles,
                    padding: `${themeContext.space.s1} ${themeContext.space.s2}`,
                }),
            } as StylesConfig<Option, IsMulti, Group>,
            propStyles
        ),
        ...rest,
    };
};

export default useSelectProps;
