import { Language } from 'helpers/LanguageHelper';
import { Alpha2Code } from 'i18n-iso-countries';

export enum SupportedCountries {
    Sweden = 'SE',
    Germany = 'DE',
}

const TldCountryMappings: {
    [tld: string]: { countryCode: Alpha2Code; language: Language };
} = {
    se: {
        countryCode: 'SE',
        language: 'sv',
    },
    de: {
        countryCode: 'DE',
        language: 'de',
    },
};

export const getLanguageFromDomain = (): Language => {
    const tld = window.location.origin.split('.').pop();

    if (tld && TldCountryMappings[tld]) {
        return TldCountryMappings[tld].language;
    }

    return TldCountryMappings.se.language;
};

export const getCountryFromDomain = (): Alpha2Code => {
    const tld = window.location.origin.split('.').pop();

    if (tld && TldCountryMappings[tld]) {
        return TldCountryMappings[tld].countryCode;
    }

    return TldCountryMappings.se.countryCode;
};
