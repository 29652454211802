import { get, post } from 'api';
import ApiError from 'api/ApiError';
import { Source } from 'api/consignments/types';
import { ZwapgridCodes, ZwapgridToken } from 'api/imports/types';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { TEAM_ID } from 'types/LocalStorage';

import * as config from '../../config';

export const getImports = async (
    params: any,
    source: Source,
    fromDate: DateTime,
    toDate: DateTime
) => {
    const query = {
        ...params,
        type: source,
        from_date: fromDate.toFormat('yyyy-MM-dd'),
        to_date: toDate.toFormat('yyyy-MM-dd'),
    };
    const response = await get(
        `${config.goDomain}v2/import/fortnox?${queryString.stringify(query)}`
    );
    return response.parsedBody;
};

export const getZwapgridOneTimeCode = async (
    teamHash: string
): Promise<ZwapgridCodes> => {
    const response = await get<ZwapgridCodes>(
        `${config.goDomain}v2/import/zwapgrid/${teamHash}/one-time-code`
    );
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    return response.parsedBody;
};

/**
 * Refresh zwapgrid tokens
 * @param teamHash Optional team hash param to use another team than the currently active one
 * this is used in the admin portal to act on other teams.
 */
export const refreshZwapgrid = async (
    teamHash?: string
): Promise<ZwapgridToken> => {
    const hash = teamHash ? teamHash : localStorage.getItem(TEAM_ID);
    const response = await post<ZwapgridToken>(
        `${config.goDomain}v2/import/zwapgrid/${hash}/refresh-token`
    );
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    return response.parsedBody;
};
/**
 * Retrieve token with authorization code
 * @param teamHash Optional team hash param to use another team than the currently active one
 * this is used in the admin portal to act on other teams.
 * @param authorizationCode code provided by the iFrame that is needed to get a token
 */
export const zwapgridToken = async (
    teamHash?: string,
    authorizationCode?: string
): Promise<ZwapgridToken> => {
    const hash = teamHash ? teamHash : localStorage.getItem(TEAM_ID);
    const response = await post<ZwapgridToken>(
        `${config.goDomain}v2/import/zwapgrid/${hash}/token`,
        {
            authorizationCode,
        }
    );
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    return response.parsedBody;
};
