import ContentCard from 'components/Layout/ContentCard';
import styled from 'styled-components/macro';

export const StyledShipmentFormSlider = styled.form`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s12};
    justify-content: center;
    padding-bottom: ${({ theme }) => theme.space.s16};
    padding-left: ${({ theme }) => theme.space.s6};
    padding-right: ${({ theme }) => theme.space.s6};
`;

export const FormContentWrapper = styled.div`
    display: grid;
    grid-column: 1;
    grid-gap: ${({ theme }) => theme.space.s12};
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
`;

interface AddressCardProps {
    hasVia?: boolean;
}

export const AddressCard = styled(ContentCard)<AddressCardProps>`
    grid-row: auto;
`;

export const ViaToggleWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: ${({ theme }) => theme.space.s4} 0;
    &:first-child {
        margin-right: 12px;
    }
`;

export const CardContentWrapper = styled.div`
    display: grid;
    grid-row-gap: ${({ theme }) => theme.space.s4};
    grid-template-columns: 100%;
`;

export const NewShipmentFormContainer = styled.div`
    overflow-x: auto;
`;

export const SliderContentContainer = styled.div`
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    max-height: calc(100vh - 48px);
    padding-top: ${({ theme }) => theme.space.s6};
`;
