/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RawArrowLeft } from './arrow-left.svg';
import { ReactComponent as RawArrowRight } from './arrow-right.svg';
import { ReactComponent as RawCheckmarkFilled } from './checkmark-filled.svg';
import { ReactComponent as RawCheckmark } from './checkmark.svg';
import { ReactComponent as RawCrossFilled } from './cross-filled.svg';
import { ReactComponent as RawCrossedUmbrella } from './crossed-umbrella.svg';
import { ReactComponent as RawCustomIntegration } from './custom-integration.svg';
import { ReactComponent as RawDelete } from './delete.svg';
import { ReactComponent as RawDocumentOutline } from './document-outline.svg';
import { ReactComponent as RawDropDownTriangleDown } from './dropdown_triangle_down.svg';
import { ReactComponent as RawInfo } from './info.svg';
import { ReactComponent as RawLightbulb } from './lightbulb.svg';
import { ReactComponent as RawRoundCheckmark } from './round-checkmark.svg';
import { ReactComponent as RawRoundCross } from './round-cross.svg';
import { ReactComponent as RawAddUmbrella } from './umbrella-add.svg';
import { ReactComponent as RawUmbrella } from './umbrella.svg';
import { ReactComponent as RawWarningOutline } from './warning-outline.svg';

export * from './ionicons';
export * from './material';

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawArrowRight
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowRight')}
        />
    );
};

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawArrowLeft
            {...props}
            role="img"
            aria-label={t('assets:icons.arrowLeft')}
        />
    );
};

export const Checkmark = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCheckmark
            {...props}
            role="img"
            aria-label={t('assets:icons.checkmark')}
        />
    );
};

export const CheckmarkFilled = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCheckmarkFilled
            {...props}
            role="img"
            aria-label={t('assets:icons.checkmark')}
        />
    );
};
export const CrossFilled = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCrossFilled
            {...props}
            role="img"
            aria-label={t('assets:icons.cross')}
        />
    );
};

export const DropDownTriangleDown = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDropDownTriangleDown
            {...props}
            role="img"
            aria-label={t('assets:icons.dropdownTriangleDown')}
        />
    );
};

export const Info = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawInfo {...props} role="img" aria-label={t('assets:icons.info')} />
    );
};
export const Lightbulb = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawLightbulb
            {...props}
            role="img"
            aria-label={t('assets:icons.lightbulb')}
        />
    );
};
export const RoundCheckmark = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawRoundCheckmark
            {...props}
            role="img"
            aria-label={t('assets:icons.roundCheckmark')}
        />
    );
};
export const RoundCross = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawRoundCross
            {...props}
            role="img"
            aria-label={t('assets:icons.roundCross')}
        />
    );
};

export const WarningOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawWarningOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.warningOutline')}
        />
    );
};
export const DocumentOutline = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDocumentOutline
            {...props}
            role="img"
            aria-label={t('assets:icons.ionicon.documentOutline')}
        />
    );
};

export const DeleteIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawDelete
            {...props}
            role="img"
            aria-label={t('icons.material.delete')}
        />
    );
};

export const CustomIntegrationIcon = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCustomIntegration
            {...props}
            role="img"
            aria-label={t('assets:icons.customIntegration')}
        />
    );
};
export const Umbrella = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawUmbrella
            {...props}
            role="img"
            aria-label={t('assets:icons.umbrella')}
        />
    );
};

export const CrossedUmbrella = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawCrossedUmbrella
            {...props}
            role="img"
            aria-label={t('assets:icons.crossedUmbrella')}
        />
    );
};

export const AddUmbrella = (props: SVGProps<SVGSVGElement>) => {
    const { t } = useTranslation('assets');
    return (
        <RawAddUmbrella
            {...props}
            role="img"
            aria-label={t('assets:icons.addUmbrella')}
        />
    );
};
