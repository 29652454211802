import { ExpiredEmail, VerifiedEmail } from 'assets/images';
import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const CenteredContainer = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: 380px;
    height: 100vh;
    place-content: center;
    place-items: center;
    text-align: center;
    width: 100vw;
    svg {
        margin-bottom: ${({ theme }) => theme.space.s6};
    }
`;

export const SubTitle = styled(Text)`
    color: ${({ theme }) => theme.colors.grey100};
`;

export const InputWrapper = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s2};
    grid-template-columns: auto auto;
    place-items: end;
`;

export const StyledVerifiedEmail = styled(VerifiedEmail)`
    width: 80px;
`;

export const StyledExpiredEmail = styled(ExpiredEmail)`
    width: 80px;
`;
