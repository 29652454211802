import flatpickr from 'flatpickr/dist/flatpickr.css';
import reacttooltips from 'react-tooltip/dist/react-tooltip.css';
import { createGlobalStyle } from 'styled-components/macro';

import CalendarStyles from './Calendar';
import TableStyles from './Table';

const GlobalStyles = createGlobalStyle`
    * {
        font-family: ${({ theme }) => theme.fontFamily};
    }

    html,
    body {
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${({ theme }) => theme.colors.black};
    }


    div {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.blue200};
    }

    a:hover {
        text-decoration: underline;
    }

    b {
        font-weight: 500;
    }

    #root {
        height: 100%;
    }


    /**  ---------- Styling Side  Footer ----*/

    .line-break--lg {
        height: 24px;
        clear: both;
    }

    .line-break--sm {
        height: 20px;
        clear: both;
    }

    /**  ---------- Styling Loading icon ----*/

    .pac-container {
        z-index: ${({ theme }) => theme.zIndex.popover};
    }

    .pac-container:after {
        background-image: none !important;
        height: 0px;
    }

    /** ----- Styling for Form Elements ----- **/
    input::-webkit-inner-spin-button {
        display: none;
    }


    /** ------- Styling for Shipping Card ----- */

    .shipment {
        color: ${({ theme }) => theme.colors.grey200};
        margin-left: 24px;

        &:before {
            content: '';
            width: 8px;
            height: 8px;
            background: white;
            position: absolute;
            margin-left: -20px;
            margin-top: 4px;
            border-radius: 6px;
            border: 2px solid ${({ theme }) => theme.colors.grey400};
        }

        &.container {
            &:before {
                content: '';
                width: 2px;
                height: 37px;
                background: ${({ theme }) => theme.colors.grey400};
                position: absolute;
                margin-left: 9px;
                margin-top: 14px;
                border: none;
            }
        }
    }

    /** ------- Styling for React Tooltip ----- */
    .__react_component_tooltip.type-dark.place-top {

        &.text {
            margin-top: -2px;

            &.show {
                opacity: 1;
                animation: tooltip-bounce-0 0.2s cubic-bezier(0.5, -0.5, 0.3, 1.3);
            }
        }

        &:after {
            border-top-color: ${({ theme }) => theme.colors.black};
        }
    }

    .__react_component_tooltip.type-dark.place-bottom {
        margin-top: 12px;

        &.text {
            margin-top: 16px;
            transition: opacity 0.3s ease;

            &.show {
                opacity: 1;
                animation: tooltip-bounce-bottom 0.3s cubic-bezier(0.5, -0.5, 0.3, 1.3);
            }
        }

        &:after {
            border-bottom-color: ${({ theme }) => theme.colors.black};
        }
    }

    #tooltip-activate-text {
        max-width: max-content;

        &::first-letter {
            text-transform: capitalize;
        }
    }

    .__react_component_tooltip {
        font-size: 14px !important;
        font-weight: 500 !important;
        border-radius: 0 !important;
        line-height: 20px !important;
    }


    .tooltip-toggle {
        width: 40px;
        height: 40px;
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
            width: 24px;
            height: 24px;
            opacity: 0.5;
        }
    }

    .tooltip-margin {
        &-bottom-fix {
            margin-bottom: -10px;
        }

        &-top-fix {
            margin-top: -10px;
        }
    }

    @keyframes tooltip-bounce-4 {
        0% {
            margin-top: 6px;
        }
        100% {
            margin-top: 4px;
        }
    }

    @keyframes tooltip-bounce-0 {
        0% {
            margin-top: 0px;
        }
        100% {
            margin-top: -2px;
        }
    }

    @keyframes tooltip-bounce-bottom {
        0% {
            margin-top: 10px;
        }
        100% {
            margin-top: 16px;
        }
    }

    /*----Ripple Effect-----*/
    .ripple {
        background-position: center;
        transition: background 0.4s;
    }

    .ripple:hover {
        background: ${({ theme }) =>
            `${theme.colors.grey400} radial-gradient(circle, transparent 1%, ${theme.colors.grey400}
 1%) center/15000%`};
    }

    .ripple:active {
        background-color: ${({ theme }) => theme.colors.grey500};
        background-size: 100%;
        transition: background 0s;
        outline: none;
    }

    /*------LIST ELEMENTS-------*/
    .action-button {
        height: 36px;
        width: 36px;
        background: transparent;
        cursor: pointer;
        transition: all 0.12s ease;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        padding: 8px;
        opacity: 0.56;

        &:hover {
            background: ${({ theme }) => theme.colors.grey400};
        }

        &.large {
            height: 44px;
            width: 44px;
        }

        &.small {
            height: 24px;
            width: 24px;
        }
    }

    .link-button {
        color: transparent;

        &:hover {
            text-decoration: none;
        }
    }


    /*------ORDERS-------*/
    .shipment-text-wrap {
        white-space: pre-wrap;
        max-width: 84px;
        word-break: break-all;
    }

    /*------UPLOAD-------*/
    .upload-container {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.grey400};
    }

    .upload-button {
        position: absolute;
        right: -10px;
        bottom: -10px;
        z-index: 1;
        background: ${({ theme }) => theme.colors.grey400};
        width: 56px;
        height: 56px;
        border-radius: 30px;
        opacity: 1;
        padding-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 0 8px ${({ theme }) => theme.colors.white};
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        & img {
            transition: all 0.2s ease-in-out;
            width: 36px;
            height: 36px;
        }

        &:hover {
            background: ${({ theme }) => theme.colors.grey300};

            & img {
                transform: scale(1.075) rotate(5deg);
            }
        }
    }

    input[type='file' i]::-webkit-file-upload-button {
        cursor: pointer;
    }

    /* Apply defalt styled first then overries */
    ${flatpickr}
    ${reacttooltips}
    ${CalendarStyles}
    ${TableStyles}
`;

export default GlobalStyles;
