import { useBookingFlowMethods } from 'hooks/BookingFlow/useBookingFlowMethods';
import { createContext, useContext } from 'react';
import { WithChildren } from 'types';

import { UseBookingFlowOptions } from './types';

const BookingFlowContext = createContext<UseBookingFlowOptions | null>(null);

BookingFlowContext.displayName = 'BookingFlow';

export const useBookingFlow = (): UseBookingFlowOptions =>
    useContext(BookingFlowContext) as UseBookingFlowOptions;

export const BookingFlowProvider = ({ children }: WithChildren) => {
    const methods = useBookingFlowMethods();
    return (
        <BookingFlowContext.Provider
            value={{ ...methods } as UseBookingFlowOptions}
        >
            {children}
        </BookingFlowContext.Provider>
    );
};
