import { updateUserSettings } from 'api/users';
import { Language, setFrontendLanguage } from 'helpers/LanguageHelper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DEPRECATEDDropdown from './DEPRECATEDDropdown';
import FlagButtons from './FlagButtons';

interface LanguageSwitchProps {
    backend?: boolean;
    dropdown?: boolean;
    border?: boolean;
    background?: boolean;
}

const LanguageSwitch = (props: LanguageSwitchProps) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState<Language>(
        i18n.language as Language
    );

    const dropdown = props.dropdown === undefined ? true : props.dropdown;
    const backend = props.backend === undefined ? true : props.backend;
    const mainLanguage = i18n.languages[0] as Language;
    useEffect(() => {
        setLanguage(mainLanguage);
    }, [mainLanguage]);

    const switchLanguage = async (newLanguage: Language) => {
        setFrontendLanguage(newLanguage);
        if (backend) {
            updateUserSettings({
                userLang: newLanguage,
            });
        }
    };

    return dropdown ? (
        <DEPRECATEDDropdown
            language={language}
            border={props.border}
            background={props.background}
            selectLanguage={switchLanguage}
        />
    ) : (
        <FlagButtons selectedLanguage={language} onClick={switchLanguage} />
    );
};

export default LanguageSwitch;
