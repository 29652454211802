import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { getSubscriptions } from 'api/subscriptions';
import { getTeam } from 'api/teams';
import { Team } from 'api/teams/types';
import { getUser } from 'api/users';
import { CurrentUser } from 'api/users/types';
import { identifyUser as identifyAnalyticsUser } from 'external/analytics';
import { useBeamerContext } from 'external/beamer';
import { identifyUser as identifyHotjarUser } from 'external/hotjar';
import { LDFlags } from 'external/launchDarkly';
import { useLDClient } from 'launchdarkly-react-client-sdk';

interface Identity {
    flags?: LDFlags;
    user: CurrentUser;
    team: Team;
}
const useIdentifyUser = () => {
    const queryClient = useQueryClient();
    const ldClient = useLDClient();
    const beamer = useBeamerContext();
    /**
     * Returns the identified user team and feature flags
     */
    const identify = async (): Promise<Identity> => {
        const [user, team, subscriptions] = await Promise.all([
            queryClient.fetchQuery(['user'], getUser),
            queryClient.fetchQuery(['team'], getTeam),
            queryClient.fetchQuery(['subscriptions'], getSubscriptions),
        ]);
        /**
         * Since LaunchDarkly is hosted in the US we don't want to send any
         * user data that will be stored in their databases.
         */
        await ldClient?.waitUntilReady();
        const flags = (await ldClient?.identify({
            kind: 'user',
            key: user.id.toString(),
            country: team.systemCountry,
            teamId: team.id,
        })) as LDFlags | undefined;
        identifyHotjarUser(user);
        identifyAnalyticsUser(user, team, subscriptions);
        beamer?.identify(
            `${user.id}`,
            user.email,
            user.activated,
            team.systemCountry
        );

        Sentry.configureScope((scope) => {
            scope.setUser({
                email: user.email,
                id: user.id.toString(),
            });
        });
        return { flags, user, team };
    };
    return { identify };
};

export default useIdentifyUser;
