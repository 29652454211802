import { ModalContent, ModalFooter } from 'components/Modal';
import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const Content = styled(ModalContent)`
    margin-top: 0;
`;

export const Footer = styled(ModalFooter)`
    display: flex;
    justify-content: space-between;
`;

export const InfoFooter = styled.div`
    background-color: ${({ theme }) => theme.colors.grey500};
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s1};
    grid-template-columns: 1fr auto;
    padding: ${({ theme }) => `${theme.space.s4} ${theme.space.s6}`};
`;
export const InfoContent = styled.div`
    display: grid;
    padding: ${({ theme }) => theme.space.s6};
`;

export const InfoText = styled(Text)`
    margin-bottom: ${({ theme }) => theme.space.s4};
`;
export const LeftAlignText = styled(Text)`
    text-align: end;
`;

export const InfoHeading = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s1};
`;
