import { Help, Menu, Settings } from 'assets/icons';
import {
    Close,
    Notification,
    NotificationActive,
    ShoppingCart,
} from 'assets/icons/material';
import { Button, IconButton } from 'components/Buttons';
import { AsyncSelect } from 'components/FormElements/Select';
import { trackCartOpened } from 'external/analytics';
import { selectorId, useBeamerContext } from 'external/beamer';
import { useFlags } from 'external/launchDarkly';
import { getHelpCenterLink } from 'helpers/LinkHelper';
import UserDataHelper from 'helpers/UserDataHelper';
import { useCartContext } from 'hooks/Cart';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components/macro';

import { searchTeam } from '../../api/teams';
import { useTeam, useTeamSwitch } from '../../hooks/Queries';
import { useSupportedIntegrations } from '../../hooks/useSupportedIntegrations';
import Divider from '../Divider';
import DEPRECATEDFlexContainer from '../Layout/DEPRECATEDFlexContainer';
import LanguageSwitch from '../Layout/LanguageSwitch/LanguageSwitch';
import Spinner from '../Spinner/Spinner';
import NavigationItem from './NavigationItem';

const GridRow = styled.div`
    margin: ${({ theme }) => `0 ${theme.space.s6}`};
`;

const LanguageRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => `0 ${theme.space.s6}`};
`;

export const SpinnerContainer = styled.div`
    align-items: center;
    display: flex;
    height: 68px;
    justify-content: center;
    width: 100%;
`;

const MobileMenuContainer = styled.div.attrs({})<{ isOpen: boolean }>`
    display: grid;
    padding-top: ${({ theme }) => theme.space.s6};
    grid-template-rows: min-content;
    grid-auto-rows: min-content;
    position: fixed;
    z-index: ${({ theme }) => theme.zIndex.slider};
    width: 350px;
    height: 100vh;
    top: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.white};
    transition: transform 0.3s ease-in-out;
    ${({ isOpen }) =>
        isOpen
            ? 'transform: translateX(0px);'
            : 'transform: translateX(500px);'}
    a {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: ${({ theme }) => theme.space.s2};
        padding: ${({ theme }) => `${theme.space.s3} ${theme.space.s6}`};
        color: ${({ theme }) => theme.colors.black};
    }
`;

const MobileMenuBackDrop = styled.div<{ isOpen: boolean }>`
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: flex;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(100vh);
    transition: opacity 0.2s ease-in-out;
    z-index: ${({ theme }) => theme.zIndex.sliderBackdrop};
    ${({ isOpen }) =>
        isOpen
            ? css`
                  opacity: 1;
                  transform: translateY(0);
              `
            : ''}
`;
const FullWitdhSelect = styled(AsyncSelect)`
    align-self: end;
    width: 100%;
`;
const MobileMenu = () => {
    const history = useHistory();
    const { showNavigation } = UserDataHelper;
    const { setCartVisibility, cartState } = useCartContext();
    const { consignments, isVisible } = cartState;
    const systems = useSupportedIntegrations();
    const enabled = systems.some((s) => s.status !== 'DISABLED');
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const openMenu = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    };
    const { beamer: showBeamer } = useFlags();
    const beamer = useBeamerContext();
    const closeMenu = () => {
        setIsOpen(false);
        document.body.style.overflow = 'unset';
    };

    const [selectedTeam, setSelectedTeam] = useState<{
        label: string;
        value: string;
        blocked: boolean;
    }>();
    const [isLoading, setIsLoading] = useState(false);
    const switchTeam = useTeamSwitch();
    const { team } = useTeam();
    useEffect(() => {
        if (team) {
            setSelectedTeam({
                label: team.name,
                value: team.id,
                blocked: team.blocked,
            });
        }
    }, [team]);
    useEffect(() => {
        if (selectedTeam) {
            submit();
        }
    }, [selectedTeam]);
    const submit = async () => {
        if (selectedTeam && team?.id !== selectedTeam.value) {
            setIsLoading(true);
            await switchTeam(selectedTeam.value);

            /**
             * Since we have many pages who do not currently subscribe
             * to the team hook, we will have to reload the page. This can
             * be removed, once those components have been rewritten.
             */
            history.go(0);
        }
    };
    const { t } = useTranslation();

    const logout = () => {
        UserDataHelper.clearUserData();
        toast.success(t('login:logoutMessage'));
    };

    return (
        <>
            <DEPRECATEDFlexContainer direction="row">
                <DEPRECATEDFlexContainer justifyContent="center">
                    <IconButton
                        data-cy="cart-button-toggle"
                        icon={<ShoppingCart />}
                        badge={
                            consignments.length > 0
                                ? consignments.length
                                : undefined
                        }
                        onClick={() => {
                            trackCartOpened('navbar');
                            setCartVisibility(!isVisible);
                        }}
                    />
                </DEPRECATEDFlexContainer>
                <IconButton icon={<Menu />} onClick={openMenu} />
            </DEPRECATEDFlexContainer>

            <MobileMenuBackDrop isOpen={isOpen} />
            <MobileMenuContainer isOpen={isOpen}>
                <LanguageRow>
                    <LanguageSwitch dropdown={false} />
                    <IconButton icon={<Close />} onClick={closeMenu} />
                </LanguageRow>
                <Divider />
                <div>
                    <NavigationItem
                        state={
                            location.pathname === '/dashboard' ||
                            location.pathname === '/'
                                ? 'active'
                                : 'normal'
                        }
                    >
                        <Link onClick={closeMenu} to="/dashboard">
                            {t('menu.dashboard')}
                        </Link>
                    </NavigationItem>
                    <NavigationItem
                        state={
                            location.pathname === '/newshipment'
                                ? 'active'
                                : 'normal'
                        }
                    >
                        <Link onClick={closeMenu} to="/newshipment">
                            {t('menu.newShipment')}
                        </Link>
                    </NavigationItem>
                    {enabled && (
                        <NavigationItem
                            state={
                                location.pathname === '/import'
                                    ? 'active'
                                    : 'normal'
                            }
                        >
                            <Link onClick={closeMenu} to="/import">
                                {t('menu.import')}
                            </Link>
                        </NavigationItem>
                    )}
                    <NavigationItem
                        state={
                            location.pathname === '/orders'
                                ? 'active'
                                : 'normal'
                        }
                    >
                        <Link onClick={closeMenu} to="/orders">
                            {t('menu.orders')}
                        </Link>
                    </NavigationItem>
                    {showNavigation && (
                        <NavigationItem
                            state={
                                location.pathname === '/admin'
                                    ? 'active'
                                    : 'normal'
                            }
                        >
                            <Link onClick={closeMenu} to="/admin">
                                {t('menu.admin')}
                            </Link>
                        </NavigationItem>
                    )}
                </div>
                <Divider />
                <div>
                    {showBeamer && (
                        <NavigationItem state="normal">
                            <a id={selectorId}>
                                {beamer.notificationNumber > 0 ? (
                                    <NotificationActive width="24px" />
                                ) : (
                                    <Notification width="24px" />
                                )}
                                {t('menu.notificationCenter')}
                            </a>
                        </NavigationItem>
                    )}
                    <NavigationItem
                        state={
                            location.pathname === '/settings'
                                ? 'active'
                                : 'normal'
                        }
                    >
                        <Link onClick={closeMenu} to="/settings">
                            <Settings />
                            {t('menu.settings')}
                        </Link>
                    </NavigationItem>
                    <NavigationItem state="normal">
                        <a
                            onClick={closeMenu}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getHelpCenterLink()}
                        >
                            <Help />
                            {t('menu.help')}
                        </a>
                    </NavigationItem>
                </div>
                <Divider />
                <GridRow>
                    {isLoading ? (
                        <SpinnerContainer>
                            <Spinner />
                        </SpinnerContainer>
                    ) : (
                        <FullWitdhSelect
                            label={t('team.activeTeam')}
                            placeholder={t('team.searchTeam')}
                            menuPortalTarget={document.body}
                            defaultOptions
                            menuPlacement="auto"
                            value={selectedTeam}
                            onChange={(option: any) => {
                                setSelectedTeam(option);
                            }}
                            loadOptions={async (query: string) => {
                                const teams = await searchTeam(query);
                                const options = teams.map((team) => ({
                                    label: team.name,
                                    value: team.id,
                                    blocked: team.blocked,
                                }));
                                return options;
                            }}
                        />
                    )}
                </GridRow>
                <Divider />
                <GridRow>
                    <Button variant="danger" fullWidth={true} onClick={logout}>
                        {t('menu.logout')}
                    </Button>
                </GridRow>
            </MobileMenuContainer>
        </>
    );
};

export default MobileMenu;
