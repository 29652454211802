import styled, { css } from 'styled-components/macro';

import { ButtonColors, StyledButton } from '../Button/StyledButton';

export const Badge = styled.div<{ $colors: IconButtonColors }>`
    align-content: center;
    align-items: center;
    background-color: ${({ $colors }) => $colors.badgeBackground};
    border: 2px solid ${({ $colors }) => $colors.badgeColor};
    border-radius: 8px;
    box-shadow: 0 0 1px transparent;
    color: ${({ $colors }) => $colors.badgeColor};
    display: grid;
    font-size: 12px;
    height: 16px;
    min-width: 16px;
    padding: 0 ${({ theme }) => theme.space.s1};
    place-content: center;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(6px, -4px);
`;
const sizeOptions = {
    small: css`
        height: 36px;
        width: 36px;
    `,
    large: css`
        height: 44px;
        width: 44px;
    `,
};

export interface IconButtonColors extends ButtonColors {
    badgeBackground?: string;
    badgeColor?: string;
}

export const StyledIconButton = styled(StyledButton)`
    overflow: visible;
    ${({ $size }) => sizeOptions[$size]}
    svg {
        height: 20px;
        width: 20px;
    }
    padding: 0;
    :hover {
        ${Badge} {
            border-color: ${({ theme }) => theme.colors.grey500};
        }
    }
`;
export const Container = styled.div<{ $colors: IconButtonColors }>`
    color: ${({ $colors }) => $colors.content};
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s1};
    justify-items: center;
    place-content: center;
    label {
        cursor: pointer;
    }
`;

export const Wrapper = styled.div<{ $loading?: boolean }>`
    display: grid;
    place-content: center;
    position: relative;
    visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
`;
