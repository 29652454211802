import styled from 'styled-components/macro';

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
`;

export const SpinnerContainer = styled.div`
    background: rgba(255, 255, 255, 0.8);
    display: grid;
    height: 100%;
    left: 0;
    place-items: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
`;
