import { getUsersResendConfirmEmail } from 'api/users';
import { Button } from 'components/Buttons';
import Input from 'components/FormElements/Input';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import {
    CenteredContainer,
    InputWrapper,
    StyledExpiredEmail,
    SubTitle,
} from 'containers/ConfirmEmail/StyledConfirmEmail';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
    email?: string;
}

const Expired = ({ email }: Props) => {
    const { t } = useTranslation('confirmEmail');

    const { push } = useHistory();

    const resendEmail = async () => {
        try {
            await getUsersResendConfirmEmail();
            toast.success(t('confirmEmail:expired.resendSuccess'));
        } catch {
            toast.error('confirmEmail:expired.resendFail');
        }
    };

    return (
        <CenteredContainer>
            <StyledExpiredEmail />
            <Heading size={HeadingSize.MEDIUM}>
                {t('confirmEmail:expired.title')}
            </Heading>
            {email ? (
                <>
                    <SubTitle>{t('confirmEmail:expired.subtitle')}</SubTitle>
                    <InputWrapper>
                        <Input
                            readOnly
                            value={email}
                            label={t('confirmEmail:expired.emailInputLabel')}
                        />
                        <Button onClick={resendEmail}>
                            {t('confirmEmail:expired.resendButton')}
                        </Button>
                    </InputWrapper>
                </>
            ) : (
                <>
                    <SubTitle>
                        {t('confirmEmail:expired.subtitleSignedOut')}
                    </SubTitle>
                    <Button onClick={() => push('/settings')}>
                        {t('confirmEmail:expired.resendButtonSignedOut')}
                    </Button>
                </>
            )}
        </CenteredContainer>
    );
};

export default Expired;
