import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react';

import { Input } from './StyledSwitch';

interface Props {
    /** The color of the slider when checked. Defaults to blue200. */
    color?: string;
    /**
     * Returns the new value of the switch; if an unchecked switch is
     * clicked, `checked` will be `true`.
     */
    onValueChange?: (isChecked: boolean) => void;
}

type InputProps = Props &
    Omit<
        InputHTMLAttributes<HTMLInputElement>,
        'aria-checked' | 'aria-readonly' | 'role' | 'type'
    >;

/**
 * A toggle switch component, used for boolean values.
 */
const Switch = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            checked,
            disabled,
            readOnly,
            onChange,
            onValueChange,
            ...rest
        }: InputProps,
        forwardedRef
    ) => {
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (disabled || readOnly) {
                return;
            }
            if (onValueChange) {
                onValueChange(e.target.checked);
            }
            if (onChange) {
                onChange(e);
            }
        };

        return (
            <Input
                disabled={disabled}
                aria-readonly={disabled || readOnly}
                aria-checked={checked}
                checked={checked}
                onChange={handleChange}
                ref={forwardedRef}
                role="switch"
                type="checkbox"
                {...rest}
            />
        );
    }
);

export default Switch;
