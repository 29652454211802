import { StyledModalHeader } from 'components/Modal/ModalHeader/StyledModalHeader';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { ReactNode } from 'react';

interface Props {
    title?: ReactNode;
    subtitle?: ReactNode;
    children?: ReactNode;
    className?: string;
}

const ModalHeader = ({ title, subtitle, children, className }: Props) => {
    return (
        <StyledModalHeader className={className}>
            {title && <Heading size={HeadingSize.MEDIUM}>{title}</Heading>}
            {subtitle && <Text>{subtitle}</Text>}
            {children}
        </StyledModalHeader>
    );
};

export default ModalHeader;
