/* eslint-disable react/jsx-props-no-spreading */
import { HTMLAttributes, forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';

export enum TextSize {
    MEDIUM,
    REGULAR,
}

interface Props {
    size?: TextSize;
}

const MEDIUM = css`
    font-weight: 500;
`;
const REGULAR = css`
    font-weight: normal;
`;

const StyledText = styled.span<Props>`
    font-size: 14px;
    line-height: 20px;
    min-height: 20px;
    ${({ size }) => {
        switch (size) {
            case TextSize.MEDIUM:
                return MEDIUM;
            case TextSize.REGULAR:
                return REGULAR;
        }
        return '';
    }}
`;

const Text = (
    {
        size = TextSize.REGULAR,
        children,
        ...rest
    }: React.PropsWithChildren<Props & HTMLAttributes<HTMLSpanElement>>,
    ref?: any
) => {
    return (
        <StyledText size={size} ref={ref} {...rest}>
            {children}
        </StyledText>
    );
};

export default forwardRef(Text);
