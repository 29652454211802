/* eslint-disable react/jsx-props-no-spreading */
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useIsAuthenticated } from 'api/auth';
import Identifier from 'components/PrivateRoute/Identifier';
import { BookingFlowProvider } from 'hooks/BookingFlow';
import { CartProvider } from 'hooks/Cart';
import { IntegrationsProvider } from 'hooks/Integrations';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { getTeam } from '../../api/teams';
import { getFrontendLanguage } from '../../helpers/LanguageHelper';
import UserDataHelper from '../../helpers/UserDataHelper';

const PrivateRoute = ({ children, ...rest }: PropsWithChildren<RouteProps>) => {
    const [stripePromise, setStripePromise] =
        useState<Promise<Stripe | null> | null>(null);
    useEffect(() => {
        const prepareStripe = async () => {
            let country = UserDataHelper.getSystemCountry();
            let key = '';
            if (!country) {
                const team = await getTeam();
                country = team.systemCountry;
            }
            switch (country) {
                case 'DE':
                    key = process.env.REACT_APP_STRIPE_DE as string;
                    break;
                case 'SE':
                    key = process.env.REACT_APP_STRIPE_SE as string;
                    break;
            }
            return loadStripe(key, {
                locale: getFrontendLanguage(),
            });
        };

        setStripePromise(prepareStripe());
    }, []);
    const location = useLocation<{ showComplicance?: boolean }>();

    const isAuthenticated = useIsAuthenticated();

    if (!children) return null;
    if (!isAuthenticated) {
        // Redirects to login while maintaining where the user wanted to go in location state so
        // login can redirect the user after login
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: {
                        referrer: location.pathname,
                        search: location.search,
                    },
                }}
            />
        );
    }

    return (
        <Route {...rest}>
            <Identifier>
                <Elements stripe={stripePromise}>
                    <BookingFlowProvider>
                        <IntegrationsProvider>
                            <CartProvider>{children}</CartProvider>
                        </IntegrationsProvider>
                    </BookingFlowProvider>
                </Elements>
            </Identifier>
        </Route>
    );
};

export default PrivateRoute;
