import { APISubscriptionTier } from 'api/subscriptions/types';
import { ArrowRight } from 'assets/icons';
import ContentCard from 'components/Layout/ContentCard';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import { includedMonthlyShipments } from 'constants/integrations';
import { getMonthlyIntegrationCost } from 'helpers/TranslationHelper';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { useTranslation } from 'react-i18next';

import { ChangeTierContainer, PlanInfo } from './StyledTierChange';

type Props = {
    newTier: APISubscriptionTier;
};

/**
 * Shows the current tier and the next (selected) tier, including their costs
 * per the interval the user has selected.
 */
const TierChange = ({ newTier }: Props) => {
    const { t, i18n } = useTranslation();
    const { team } = useTeam();
    const { subscriptions } = useSubscriptions();
    const interval = subscriptions?.apiSubscription?.interval;
    const currentTier = team?.apiTier;
    if (!team || !interval || !currentTier) {
        return null;
    }
    return (
        <ChangeTierContainer>
            <div>
                <Text>{t('settings:payment.currentPlan')}</Text>
                <ContentCard withBorder withPadding>
                    <Text size={TextSize.MEDIUM}>
                        {t(`settings:tier.${currentTier}`)}
                    </Text>
                    <Heading size={HeadingSize.MEDIUM}>
                        {`${getMonthlyIntegrationCost(
                            i18n.language,
                            interval,
                            currentTier,
                            team.currency
                        )}/${t('settings:pricePanel.month')}`}
                    </Heading>
                    <Text>
                        {t('settings:tierDescriptionUsage', {
                            count: includedMonthlyShipments[currentTier],
                        })}
                    </Text>
                </ContentCard>
            </div>
            <ArrowRight />
            <PlanInfo>
                <Text>{t('settings:payment.newPlan')}</Text>
                <ContentCard withBorder withPadding>
                    <Text size={TextSize.MEDIUM}>
                        {t(`settings:tier.${newTier}`)}
                    </Text>
                    <Heading size={HeadingSize.MEDIUM}>
                        {`${getMonthlyIntegrationCost(
                            i18n.language,
                            interval,
                            newTier,
                            team.currency
                        )}/${t('settings:pricePanel.month')}`}
                    </Heading>
                    <Text>
                        {t('settings:tierDescriptionUsage', {
                            count: includedMonthlyShipments[newTier],
                        })}
                    </Text>
                </ContentCard>
            </PlanInfo>
        </ChangeTierContainer>
    );
};

export default TierChange;
