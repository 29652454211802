import { Consignment, ConsignmentRequest } from 'api/consignments/types';
import { Dispatch, SetStateAction } from 'react';

export enum ModalType {
    EDIT,
    DELETE,
    DRAFT,
}
export interface ModalOptions {
    consignment: Consignment;
    type: ModalType;
}

export interface CartState {
    isVisible: boolean;
    isSubmitting: boolean;
    consignments: Consignment[];
    maximumNumberOfImportsExceeded: boolean;
    maximumNumberOfImportsReached: boolean;
}

export interface UseCartOptions {
    modalOptions?: ModalOptions;
    openEditModal: (consignment: Consignment) => void;
    openDraftModal: (consignment: Consignment) => void;
    openDeleteModal: (consignment: Consignment) => void;
    closeModal: () => void;
    refresh: () => void;
    customEditCallback?: (consignment: Consignment) => void;
    setCustomEditCallback: (
        callback: undefined | ((consignment: Consignment) => void)
    ) => void;
    setCartVisibility: Dispatch<SetStateAction<boolean>>;
    /**
     * Creates a new consignment and adds it to the cart.
     * Returns the newly created consignment
     */
    createAndAddToCart: (
        consignmentRequest: ConsignmentRequest
    ) => Promise<Consignment>;
    /**
     * Adds the consignment to the cart, optimistically.
     */
    moveToCart: (consignment: Consignment) => void;
    /**
     * Removes the consignment from the cart. Note that the consignment will
     * be permanently deleted, including if you send the ID of an import or
     * draft consignment.
     */
    removeFromCart: (consignmentId: string) => void;
    /**
     * Moves a consignment from the cart to the draft list. Note that also
     * imported shipments will be assigned a draft status, if the ID of one
     * is supplied instead of a cart consignment.
     */
    saveAsDraft: (consignmentId: string) => void;
    setCartUserId: (id: number) => void;
    /**
     * Updates a consignment in the backend and refreshes the cart. Note that
     * also draft and import consignments will be updated, if an ID of such is
     * supplied.
     */
    update: (
        consignmentId: string,
        consignment: ConsignmentRequest
    ) => Promise<Consignment>;
    cartState: CartState;
}
