import { get } from 'api';
import ApiError from 'api/ApiError';
import { Alpha2Code } from 'i18n-iso-countries';

import * as config from '../../config';

/**
 * Gets the public and bank holidays for the current year and the next.
 * @returns the dates as an array of "YYYY-MM-DD" strings
 * @throws ApiError
 */
export const getHolidays = async (
    countryCode: Alpha2Code,
    state?: string
): Promise<string[]> => {
    try {
        let url = `${config.goDomain}v2/holidays?country=${countryCode}`;
        if (state) {
            url = `${url}&state=${state}`;
        }
        const response = await get<string[]>(url);
        if (!response.parsedBody) {
            throw new ApiError(response);
        }
        return response.parsedBody;
    } catch (error: unknown) {
        const err = error as ApiError;
        throw err;
    }
};
