import { CourierCode } from 'api/consignments/types';
import { euMemberExceptions, euMembers } from 'constants/euMembers';
import { Alpha2Code } from 'i18n-iso-countries';

export interface Address {
    country: Alpha2Code | '';
    postalCode?: string;
}

export const isInEu = ({ country, postalCode }: Address): boolean => {
    if (country === '') {
        return false;
    }
    const isEuMember = euMembers.has(country);
    if (!isEuMember) {
        return false;
    }
    // We have an EU member, look for possible exceptions.
    if (postalCode === undefined) {
        return true;
    }
    const isException = euMemberExceptions[country]?.has(postalCode);
    return !isException;
};

export const requiresEuCustomsInvoice = (
    from: Address,
    to: Address,
    courierCode: CourierCode
): boolean => {
    if (from.country === to.country) {
        return false;
    }
    const withinEu = isInEu(from) && isInEu(to);

    /*
        Special case due to United Kingdom leaving the EU. Goods going to and from 
        Ireland with certain carriers will still need customs documents.
    */
    const toOrFromIreland = from.country === 'IE' || to.country === 'IE';
    if (toOrFromIreland && courierCode === CourierCode.DHL_FREIGHT_SWEDEN) {
        return true;
    }

    return !withinEu;
};
