import { Button } from 'components/Buttons';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-top: 1px solid ${({ theme }) => theme.colors.grey400};
    bottom: 0;
    display: flex;

    > * {
        margin-bottom: ${({ theme }) => theme.space.s2};
        margin-left: ${({ theme }) => theme.space.s2};
    }
    flex-wrap: wrap;
    justify-content: flex-end;
    left: 0;
    padding: 0 10%;
    padding-top: ${({ theme }) => theme.space.s2};
    position: fixed;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.fixed};
`;

export const SubmitButton = styled(Button)`
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: auto;
    }
`;

export const ErrorMessage = styled.div`
    align-content: center;
    background: ${({ theme }) => theme.colors.red300};
    color: ${({ theme }) => theme.colors.red100};
    display: grid;
    height: 40px;
    justify-content: center;
    padding: 5px;
    padding: 0 20px;
`;
