import { HomeOutline, LogoAmplify, LogoGitlab } from 'assets/icons/ionicons';
import { Button } from 'components/Buttons';
import Text, { TextSize } from 'components/Typography/Text';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getMarketingSiteLink } from '../../helpers/LinkHelper';
import DEPRECATEDFlexContainer from '../Layout/DEPRECATEDFlexContainer';
import LanguageSwitch from '../Layout/LanguageSwitch/LanguageSwitch';
import {
    ButtonWrapper,
    EnvironmentBanner,
    HeaderContainer,
    StyledLogo,
} from './StyledHeader';

interface Props {
    logo?: boolean;
    language?: boolean;
    login?: boolean;
    active?: boolean;
}
const PageHeader = ({ login, language, active, logo }: Props) => {
    const development = process.env.REACT_APP_ENV === 'development';
    const staging = window.location.href.includes('webdev');
    const preview = window.location.href.includes('pr-');
    const local =
        window.location.href.includes('localhost') ||
        window.location.href.includes('127.0.0.1');
    const { t } = useTranslation('signup');

    const { push } = useHistory();

    return (
        <HeaderContainer actions={!!language || !!login} active={!!active}>
            <DEPRECATEDFlexContainer
                alignItems="center"
                fullWidth
                direction="row"
                justifyContent="space-between"
            >
                {/* 
                    The switch here is to make developers aware of which environment
                    they are currently in. 
                */}
                {development ? (
                    <EnvironmentBanner staging={staging}>
                        {staging && (
                            <>
                                <LogoAmplify />
                                <Text size={TextSize.MEDIUM}>staging</Text>
                            </>
                        )}
                        {preview && (
                            <>
                                <LogoGitlab />
                                <Text size={TextSize.MEDIUM}>
                                    Preview{' '}
                                    {window.location.href
                                        .split('pr-')[1]
                                        ?.split('.')
                                        ?.shift()}
                                </Text>
                            </>
                        )}
                        {local && (
                            <>
                                <HomeOutline />
                                <Text size={TextSize.MEDIUM}>localhost</Text>
                            </>
                        )}
                    </EnvironmentBanner>
                ) : (
                    logo && (
                        <a href={getMarketingSiteLink()}>
                            <StyledLogo />
                        </a>
                    )
                )}

                {!!language || !!login ? (
                    <ButtonWrapper>
                        {!!login && (
                            <Button
                                onClick={() => push('/login')}
                                variant="secondary"
                            >
                                {t('signup:Login')}
                            </Button>
                        )}

                        {!!language && (
                            <LanguageSwitch backend={false} background border />
                        )}
                    </ButtonWrapper>
                ) : (
                    <></>
                )}
            </DEPRECATEDFlexContainer>
        </HeaderContainer>
    );
};

export default PageHeader;
