import { Integration } from 'api/subscriptions/types';
import {
    GroupBase,
    Select,
    SelectInstance,
} from 'components/FormElements/Select';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { useTeam } from 'hooks/Queries';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSupportedIntegrations } from '../../../hooks/useSupportedIntegrations';
import IntegrationOption from './IntegrationOption';
import IntegrationValue, { IntegrationOptionType } from './IntegrationValue';

type Props = {
    value: Integration | null;
    onChange: (value: Integration | null) => void;
    error: boolean;
    errorMessage?: string;
};

/**
 * Lets the user select an integration from a dropdown.
 */
const IntegrationSelect = (
    { value, onChange, error, errorMessage }: Props,
    ref: ForwardedRef<
        SelectInstance<
            IntegrationOptionType,
            false,
            GroupBase<IntegrationOptionType>
        >
    >
) => {
    const { team } = useTeam();
    const { t } = useTranslation('settings');
    const systems = useSupportedIntegrations();
    const enabledSystems = useMemo(
        () =>
            systems.filter(
                (s) =>
                    s.status === 'ENABLED' ||
                    s.status === 'BETA' ||
                    s.status === 'NEW'
            ),
        [systems]
    );
    if (!team) {
        return null;
    }
    const options: IntegrationOptionType[] = enabledSystems.map((info) => {
        const integration = info.integration;
        const cost = monthlyIntegrationCosts[integration];
        return {
            label:
                integration === 'Other' ? t('api.ownIntegration') : integration,
            integration: integration,
            cost: cost,
            currency: team.currency,
        };
    });

    return (
        <Select
            inputId="integration-select"
            menuPortalTarget={document.body}
            value={value ? options.find((o) => o.integration === value) : null}
            ref={ref}
            onChange={(value) => {
                onChange(value?.integration ?? null);
            }}
            components={{
                Option: IntegrationOption,
                SingleValue: IntegrationValue,
            }}
            label={t('api.subscriptionChange.integrationSelect.title')}
            placeholder={t(
                'api.subscriptionChange.integrationSelect.placeholder'
            )}
            options={options}
            error={error}
            errorMessage={errorMessage}
            getOptionValue={(o) => o.integration}
        />
    );
};
export default forwardRef(IntegrationSelect);
