import { KnownIntegrations } from 'api/subscriptions/types';

import PrimaryIntegrationLogo from './PrimaryIntegrationLogo';
import SubmarkIntegrationLogo from './SubmarkIntegrationLogo';

export type LogoFormat = 'submark' | 'primary';

const IntegrationLogo = ({
    integration,
    className,
    type = 'primary',
}: {
    integration: KnownIntegrations;
    className?: string;
    type?: LogoFormat;
}) => {
    if (type === 'submark') {
        return (
            <SubmarkIntegrationLogo
                className={className}
                integration={integration}
            />
        );
    }
    return (
        <PrimaryIntegrationLogo
            className={className}
            integration={integration}
        />
    );
};

export default IntegrationLogo;
