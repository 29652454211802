import { PriceSubscriptionTier } from 'api/subscriptions/types';
import { Currency, SystemCountry } from 'types/UserData';

type Price = { [key in Currency]: number };

export const yearlyCosts: { [key in PriceSubscriptionTier]: Price } = {
    se_tier_1: { ['SEK']: 0, ['EUR']: -1 },
    se_tier_2: { ['SEK']: 4548, ['EUR']: -1 },
    de_tier_1: { ['SEK']: -1, ['EUR']: 0 },
    se_partner_tier_1: { ['SEK']: 0, ['EUR']: -1 },
    se_partner_tier_2: { ['SEK']: 0, ['EUR']: -1 },
    se_partner_tier_3: { ['SEK']: 0, ['EUR']: -1 },
};

export const tierOrderByTier: { [key in PriceSubscriptionTier]: number } = {
    se_tier_1: 1,
    de_tier_1: 1,
    se_partner_tier_1: 2,
    se_partner_tier_2: 2,
    se_partner_tier_3: 2,
    se_tier_2: 3,
};

export const trialDays = 20;

export const getFreeTier = (country: SystemCountry): PriceSubscriptionTier =>
    country === 'SE' ? 'se_tier_1' : 'de_tier_1';
