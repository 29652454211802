import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    addExistingConsignmentToCart,
    createCartConsignment,
    deleteConsignment,
    getCart,
    setConsignmentStatusDraft,
    updateConsignment,
} from 'api/consignments';
import { Consignment, ConsignmentRequest } from 'api/consignments/types';
import { trackConsignmentAddedToCart } from 'external/analytics';
import { useTeam, useUser } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
    hasExceededImportLimit,
    hasReachedImportLimit,
} from '../../api/subscriptions/helper';
import { ModalOptions, ModalType, UseCartOptions } from './types';

export const useCart = (): UseCartOptions => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVisible, setCartVisibility] = useState(false);

    const { pathname } = useLocation();
    // Cart user is only used for god users to be able to view the cart of a different user to the one that is signed in
    const [cartUserId, setCartUserId] = useState<number>();
    const queryKey = 'cart';
    const queryClient = useQueryClient();
    const { user } = useUser();
    const { subscriptions } = useSubscriptions();
    const { team } = useTeam();

    const { data: consignments, isLoading } = useQuery(
        [queryKey],
        () => {
            if (user?.id) {
                return getCart(user?.id);
            }
            return [];
        },
        {
            enabled: !!user?.id,
        }
    );
    useEffect(() => {
        const fetch = async () => {
            setIsSubmitting(true);
            await queryClient.fetchQuery([queryKey], () => {
                if (cartUserId) {
                    return getCart(cartUserId);
                }
                return [];
            });
            setIsSubmitting(false);
        };
        if (cartUserId !== user?.id) {
            fetch();
        }
    }, [cartUserId]);

    // Only one modal can be displayed simultaneously.
    const [modalOptions, setModalOptions] = useState<ModalOptions>();
    const [customEditCallback, setCustomEditCallback] =
        useState<(consignment: Consignment) => void>();

    const closeModal = () => {
        setModalOptions(undefined);
    };

    const openEditModal = (consignment: Consignment) => {
        setModalOptions({ type: ModalType.EDIT, consignment });
    };

    const openDeleteModal = (consignment: Consignment) => {
        setModalOptions({ type: ModalType.DELETE, consignment });
    };

    const openDraftModal = (consignment: Consignment) => {
        setModalOptions({ type: ModalType.DRAFT, consignment });
    };

    /**
     * Optimistically displays a consignment in the cart.
     * @return The old cart
     */
    const addToCartCache = async (consignment: Consignment) => {
        await queryClient.cancelQueries([queryKey]);
        const previousCart = queryClient.getQueryData<Consignment[]>([
            queryKey,
        ]);
        if (previousCart) {
            queryClient.setQueryData(
                [queryKey],
                [consignment, ...previousCart]
            );
        }
        // We return the old cart, since the mutations use this for resetting the cart at failed optimistic updates.
        return previousCart;
    };

    /**
     * Optimistically removes a consignment from the cart.
     * @return The old cart
     */
    const removeFromCartCache = async (consignmentId: string) => {
        await queryClient.cancelQueries([queryKey]);
        const previousCart = queryClient.getQueryData<Consignment[]>([
            queryKey,
        ]);
        if (previousCart) {
            queryClient.setQueryData(
                [queryKey],
                previousCart.filter((c) => c.id !== consignmentId)
            );
        }
        // We return the old cart, since the mutations use this for resetting the cart at failed optimistic updates.
        return previousCart;
    };

    /**
     * Creates a new active consignment, which is then displayed in the cart.
     * The refresh of the cart is performed optimistically.
     * @returns The newly created consignment
     */
    const createAndAddToCart = async (consignment: ConsignmentRequest) => {
        setIsSubmitting(true);
        const createdConsignment = await createCartConsignment(consignment);
        await addToCartCache(createdConsignment);
        queryClient.invalidateQueries([queryKey]);
        trackConsignmentAddedToCart(pathname, consignment);
        setIsSubmitting(false);
        return createdConsignment;
    };

    /**
     * An optimistic adding of an existing consignment (an import or a draft) to the cart.
     * If it fails, the cart will be reset to its previous value.
     */
    const optimisticMoveToCart = useMutation(
        (consignment: Consignment) =>
            addExistingConsignmentToCart(consignment.id),
        {
            onMutate: addToCartCache,
            onError: (err, variables, previousValue) => {
                queryClient.setQueryData([queryKey], previousValue);
                queryClient.invalidateQueries([queryKey]);
            },
            onSuccess: () => {
                queryClient.invalidateQueries([queryKey]);
            },
        }
    );

    /**
     * An optimistic removal of a consignment from the cart. If it fails, the cart will be reset to its
     * previous value.
     */
    const optimisticRemoval = useMutation(deleteConsignment, {
        onMutate: removeFromCartCache,
        onError: (err, variables, previousValue) => {
            queryClient.setQueryData([queryKey], previousValue);
            queryClient.invalidateQueries([queryKey]);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
    });

    /**
     * An optimistic saving of a consignment in the cart as a draft. The consignment is
     * removed from the cart optimistically. If it fails, the cart will be reset to its
     * previous value.
     */
    const optimisticSaveAsDraft = useMutation(setConsignmentStatusDraft, {
        onMutate: removeFromCartCache,
        onError: (err, variables, previousValue) => {
            queryClient.setQueryData([queryKey], previousValue);
            queryClient.invalidateQueries([queryKey]);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
    });

    /**
     * Updates the consignment and automatically updates it in the cart.
     */
    const update = async (
        consignmentId: string,
        consignment: ConsignmentRequest
    ) => {
        setIsSubmitting(true);
        const response = await updateConsignment(consignmentId, consignment);
        await addToCartCache(response);
        queryClient.invalidateQueries([queryKey]);
        setIsSubmitting(false);
        return response;
    };

    return {
        modalOptions,
        openDeleteModal,
        openDraftModal,
        openEditModal,
        closeModal,
        setCartVisibility,
        setCustomEditCallback: (callback) =>
            setCustomEditCallback(() => callback),
        createAndAddToCart,
        moveToCart: (consignment) => optimisticMoveToCart.mutate(consignment),
        removeFromCart: (consignmentId) =>
            optimisticRemoval.mutate(consignmentId),
        saveAsDraft: (consignmentId) =>
            optimisticSaveAsDraft.mutate(consignmentId),
        update,
        refresh: () => queryClient.invalidateQueries([queryKey]),
        setCartUserId,
        customEditCallback,
        cartState: {
            consignments: consignments || [],
            isSubmitting: isSubmitting || isLoading,
            isVisible,
            maximumNumberOfImportsReached:
                !!subscriptions?.apiSubscription &&
                hasReachedImportLimit(
                    team?.apiTier,
                    subscriptions.apiSubscription.importedOrdersBooked,
                    consignments || []
                ),
            maximumNumberOfImportsExceeded:
                !!subscriptions?.apiSubscription &&
                hasExceededImportLimit(
                    team?.apiTier,
                    subscriptions.apiSubscription.importedOrdersBooked,
                    consignments || []
                ),
        },
    };
};
