import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

import { Size } from './Button';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    $fullWidth?: boolean;
    $isDisabled: boolean;
    $loading?: boolean;
    $size: Size;
    $colors: ButtonColors;
}

export interface ButtonColors {
    active?: string;
    content: string;
    hover?: string;
    background?: string;
    border?: string;
}

const sizeOptions = {
    small: css`
        height: 36px;
        padding: 0 0.75em;
    `,
    large: css`
        height: 44px;
        padding: 0 0.75em;
    `,
};

export const BaseButton = styled.button<ButtonProps>`
    -webkit-appearance: button;
    border: none;
    cursor: pointer;
    display: inline-grid;
    font-family: inherit;
    font-size: 100%;
    grid-template-columns: 100%;
    line-height: 1;
    margin: 0;
    outline: none;
    overflow: hidden;
    place-items: center;
    position: relative;
    text-transform: none;
    transition: all 0.12s ease-in-out;
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'min-content')};
    ${({ $isDisabled, $loading }) =>
        ($isDisabled || $loading) &&
        css`
            cursor: not-allowed;
        `}

    ${({ $size }) => sizeOptions[$size]}
`;

export const StyledButton = styled(BaseButton)<ButtonProps>`
    ${({ theme, $isDisabled, $loading, $colors }) => {
        const { colors } = theme;

        const { black, blue200, grey400, grey500, white } = colors;
        const {
            background = white,
            hover = grey500,
            active = grey400,
            content,
            border,
        } = $colors;
        const interactive = !$loading && !$isDisabled;

        let backgroundColor = background;
        let borderColor = border ? border : '';
        let color = content;

        // Disabled state should override the $loading background.
        if ($isDisabled || $loading) {
            if (background !== 'transparent') {
                // 66 = 40% opacity
                backgroundColor = `${backgroundColor}66`;
            }
            if (background === white || background === 'transparent') {
                // can't apply opacity to 'inherit'
                if (content === 'inherit') {
                    color = `${black}66`;
                } else {
                    color = `${content}66`;
                }
            }
            if (borderColor) {
                borderColor = `${borderColor}66`;
            }
        }

        return css`
            background-color: ${backgroundColor};
            border: 1px solid ${borderColor};
            color: ${color};
            :focus {
                box-shadow: 0px 0px 0px 1px ${white}, 0px 0px 0px 2px ${blue200};
            }
            :focus:not(:focus-visible) {
                box-shadow: none;
            }

            ${interactive &&
            css`
                :hover {
                    background-color: ${hover};
                }
                &.active,
                :active {
                    background-color: ${active};
                }
            `}
        `;
    }}
`;

export const ButtonContent = styled.div<{ $loading: boolean }>`
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all 0.12s ease-in-out;
    visibility: ${({ $loading }) => ($loading ? 'hidden' : 'block')};
`;

export const ButtonText = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

const iconBefore = {
    small: css`
        margin-right: 0.5em;
    `,
    large: css`
        margin-left: -0.25em;
        margin-right: 0.5em;
    `,
};

const iconAfter = {
    small: css`
        margin-left: 0.5em;
    `,
    large: css`
        margin-left: 0.5em;
        margin-right: -0.25em;
    `,
};

const IconWrapper = styled.div`
    display: grid;
    height: 100%;
    place-items: center;
    svg {
        height: 20px;
        width: 20px;
    }
`;

export const IconBeforeWrapper = styled(IconWrapper)<{ $size: Size }>`
    ${({ $size }) => iconBefore[$size]}
`;

export const IconAfterWrapper = styled(IconWrapper)<{ $size: Size }>`
    ${({ $size }) => iconAfter[$size]}
`;

export const SpinnerWrapper = styled.div`
    color: inherit;
    display: grid;
    height: 100%;
    place-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.popover};
`;

export const StyledButtonSpinner = styled(Spinner)`
    circle {
        stroke: currentColor !important;
    }
`;
