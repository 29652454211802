import { BackendAddress } from 'api/consignments/types';
import { toBackendPackageRequest } from 'api/packages/mapper';

import {
    BackendFreightForwarding,
    FreightForwarding,
    FreightForwardingParty,
} from './types';

export const toBackendFreightForwarding = (
    freightForwarding: FreightForwarding
): BackendFreightForwarding => {
    return {
        from_address: freightForwardingPartyToBackendAddress(
            freightForwarding.sender
        ),
        to_address: freightForwardingPartyToBackendAddress(
            freightForwarding.recipient
        ),
        packages: freightForwarding.packages.map(toBackendPackageRequest),
        freight_types: Array.from(freightForwarding.freightTypes),
        services: Array.from(freightForwarding.services),
        goods_value: freightForwarding.goodsValue,
        dates: {
            // Populate dates only if they have been selected
            earliest_pickup: freightForwarding.dates.earliestPickup,
            latest_delivery: freightForwarding.dates.latestDelivery,
        },
    };
};

export const freightForwardingPartyToBackendAddress = (
    party: FreightForwardingParty
): BackendAddress => {
    return {
        address_line_1: party.addressLine1,
        zip_code: party.postalCode,
        city: party.city,
        state: party.state,
        country_code: party.countryCode,
    };
};
