import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { reactivateIntegration } from 'api/subscriptions';
import { Integration } from 'api/subscriptions/types';
import {
    ConfirmButtonWrapper,
    Confirmation,
    Content,
    Footer,
    Overview,
    OverviewList,
    OverviewListItem,
    OverviewListLogo,
} from 'components/APISubscriptionChange/StyledAPISubscriptionChange';
import { Button } from 'components/Buttons';
import { ModalHeader } from 'components/Modal';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { monthlyIntegrationCosts } from 'containers/Settings/components/Api/ApiIntegrations/constants';
import { formatCost } from 'helpers/CurrencyHelper';
import { useTeam } from 'hooks/Queries';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import NextSteps from './NextSteps';

interface Props {
    integration: Integration;
    onSuccessfulSubmit: () => void;
}

/**
 * Lets the user reactivate a cancelled integration.
 */
const ReactivateIntegration = ({ integration, onSuccessfulSubmit }: Props) => {
    const queryClient = useQueryClient();
    const { t, i18n } = useTranslation('settings');
    const { team } = useTeam();
    const { subscriptions } = useSubscriptions();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async () => {
        setIsLoading(true);

        try {
            await reactivateIntegration(integration);
            onSuccessfulSubmit();
            queryClient.invalidateQueries(['subscriptions']);
        } catch (err) {
            Sentry.captureException(err);
            toast.error(t('api.subscriptionChange.errors.integrationChange'), {
                autoClose: false,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const interval = subscriptions?.apiSubscription.interval;
    const end = subscriptions?.apiSubscription?.current?.end;
    if (!team || !interval || !end) {
        return null;
    }

    const { currency } = team;

    const decimals = currency === 'EUR' ? 1 : 0;
    return (
        <>
            <ModalHeader
                title={t('api.subscriptionChange.integration.title.reactivate')}
            />
            <Content>
                <Overview>
                    <OverviewList>
                        <OverviewListItem>
                            <OverviewListLogo integration={integration} />
                            <Heading size={HeadingSize.MEDIUM}>
                                {t('api.subscriptionChange.costPerMonth', {
                                    cost: formatCost(
                                        monthlyIntegrationCosts[integration][
                                            currency
                                        ],
                                        i18n.language,
                                        currency,
                                        decimals
                                    ),
                                })}
                            </Heading>
                        </OverviewListItem>
                    </OverviewList>
                </Overview>
                <NextSteps
                    listItems={[
                        t(
                            'api.subscriptionChange.integration.nextSteps.integrationContinue'
                        ),
                        interval === 'year'
                            ? t(
                                  'api.subscriptionChange.integration.nextSteps.payPerYear'
                              )
                            : t(
                                  'api.subscriptionChange.integration.nextSteps.payPerMonth'
                              ),
                    ]}
                />
            </Content>

            <Footer>
                <Confirmation>
                    <ConfirmButtonWrapper>
                        <Text>
                            {interval === 'year'
                                ? t(
                                      'api.subscriptionChange.integration.reactivateYearInfo',
                                      {
                                          date: end.toLocaleString({
                                              month: 'long',
                                              day: 'numeric',
                                          }),
                                      }
                                  )
                                : t(
                                      'api.subscriptionChange.integration.reactivateMonthInfo',
                                      {
                                          date: end.day,
                                      }
                                  )}
                        </Text>
                        <Button
                            fullWidth
                            loading={isLoading}
                            onClick={onSubmit}
                        >
                            {t(
                                'api.subscriptionChange.integration.button.reactivate',
                                { integration }
                            )}
                        </Button>
                    </ConfirmButtonWrapper>
                </Confirmation>
            </Footer>
        </>
    );
};

export default ReactivateIntegration;
