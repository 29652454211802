import { IconButton } from 'components/Buttons';
import { Suspense } from 'react';
import { WithChildren } from 'types';

import { Close } from '../../assets/icons';
import {
    AbsoluteContainer,
    BackdropContainer,
    Container,
    Content,
} from './StyledSlider';

export type Position = 'left' | 'right';
interface Props {
    onClose: () => void;
    show: boolean;
    position?: Position;
    background?: string;
    width?: string;
    className?: string;
    'data-cy'?: string;
    'data-testid'?: string;
}
const Slider = ({
    onClose,
    show,
    background,
    width = '400px',
    position = 'right',
    children,
    className,
    'data-cy': dataCy,
    'data-testid': dataTestId,
}: WithChildren<Props>) => {
    return (
        <Suspense fallback={null}>
            <BackdropContainer onClick={onClose} show={show} />
            <Container
                data-cy={dataCy}
                data-testid={dataTestId}
                show={show}
                background={background}
                position={position}
                width={width}
            >
                <AbsoluteContainer>
                    <IconButton onClick={onClose} icon={<Close />} />
                </AbsoluteContainer>
                <Content className={className}>{children}</Content>
            </Container>
        </Suspense>
    );
};

export default Slider;
