import styled, { css } from 'styled-components/macro';

export enum ParagraphSize {
    MEDIUM,
    REGULAR,
}

interface ParagraphProps {
    size?: ParagraphSize;
}

const MEDIUM = css`
    font-weight: 500;
`;
const REGULAR = css`
    font-weight: normal;
`;

const StyledParagraph = styled.p<ParagraphProps>`
    font-size: 14px;
    line-height: 20px;
    ${({ size }) => {
        switch (size) {
            case ParagraphSize.MEDIUM:
                return MEDIUM;
            case ParagraphSize.REGULAR:
                return REGULAR;
        }
        return '';
    }}
`;
type Props = ParagraphProps & React.HTMLAttributes<HTMLSpanElement>;

const Paragraph = ({
    size = ParagraphSize.REGULAR,
    children,
    ...rest
}: Props) => {
    return (
        <StyledParagraph size={size} {...rest}>
            {children}
        </StyledParagraph>
    );
};

export default Paragraph;
