import { ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
// this should be the only import of react-tooltip
// eslint-disable-next-line no-restricted-imports
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';

import Heading, { HeadingSize } from '../Typography/Heading';
import Text from '../Typography/Text';
import {
    StyledTooltipContainer,
    TooltipBody,
    TooltipContent,
    TooltipLink,
} from './StyledTooltip';

interface TooltipLinkProps {
    href: string;
    text: string;
}

interface Props {
    title?: string;
    link?: TooltipLinkProps;
    children?: ReactNode;
}

/**
 * Custom react-tooltip to ensure that all tooltips are rendered into a portal. This allows us
 * to place tooltips in modals and other places where they would otherwise be hidden by
 * overflow rules.
 */
const Tooltip = ({
    link,
    title,
    place,
    children,
    clickable,
    className,
    ...rest
}: Props & ITooltip) => {
    const tooltipContainer = useMemo(() => {
        const containerId = 'tooltip-container';
        const tooltipContainer = document.getElementById(containerId);
        if (tooltipContainer == null) {
            const domNode = document.createElement('div');
            domNode.setAttribute('id', containerId);
            document.body.appendChild(domNode);
            return domNode;
        }
        return tooltipContainer;
    }, []);

    return createPortal(
        <StyledTooltipContainer
            className={className}
            type={title || link ? 'rich' : 'plain'}
        >
            <ReactTooltip
                variant="dark"
                clickable={clickable !== false}
                place={place || 'top'}
                delayHide={300}
                delayShow={300}
                className={className}
                {...rest}
            >
                <TooltipContent>
                    {(title || children) && (
                        <TooltipBody>
                            {title && (
                                <Heading size={HeadingSize.XSMALL}>
                                    {title}
                                </Heading>
                            )}
                            <Text>{children}</Text>
                        </TooltipBody>
                    )}
                    {link && (
                        <TooltipLink href={link.href} target="_blank">
                            {link.text}
                        </TooltipLink>
                    )}
                </TooltipContent>
            </ReactTooltip>
        </StyledTooltipContainer>,
        tooltipContainer
    );
};

export default Tooltip;
