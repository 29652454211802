import { IntegrationCredentials } from 'api/admin/types';
import { Integration } from 'api/subscriptions/types';
import { Step } from 'components/IntegrationsModal/types';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { useMemo, useState } from 'react';

import { isInvoiceCustomer } from '../../api/subscriptions/helper';
import { UseIntegrationsOptions } from './types';

export const useIntegrationsMethods = (): UseIntegrationsOptions => {
    const { isLoading, subscriptions } = useSubscriptions();
    const [isShown, setIsShown] = useState(false);
    const [integration, setInternalIntegration] = useState<Integration>();
    const [needsPurchaseIntegration, setNeedsPurchaseIntegration] =
        useState<boolean>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [credentials, setCredentials] = useState<IntegrationCredentials>();
    const [step, setStep] = useState<Step>();

    const setIntegration = (
        integration?: Integration,
        needsPurchase?: boolean
    ) => {
        setInternalIntegration(integration);
        // invoice customers do not have an end date, and they can't purchase integrations via stripe
        if (
            subscriptions?.apiSubscription.current &&
            !isInvoiceCustomer(subscriptions.apiSubscription.current)
        ) {
            setNeedsPurchaseIntegration(needsPurchase);
        }
    };
    const goBack = () => {
        switch (step) {
            case Step.INSTRUCTIONS:
                setStep(Step.INTEGRATION);
                break;
            case Step.ZWAPGRID:
                if (
                    integration === 'Fortnox' ||
                    integration === 'Visma E-Economy'
                ) {
                    setStep(Step.INTEGRATION);
                } else {
                    setStep(Step.INSTRUCTIONS);
                }
                break;
        }
    };

    function isZwapgridIntegration() {
        return (
            integration === 'Fortnox' ||
            integration === 'Shopify' ||
            integration === 'WooCommerce' ||
            integration === 'Visma E-Economy' ||
            integration === 'Specter'
        );
    }

    const goForward = () => {
        switch (step) {
            case Step.INTEGRATION:
                if (needsPurchaseIntegration) {
                    setStep(Step.PAYMENT);
                } else {
                    setStep(Step.INSTRUCTIONS);
                }
                break;
            case Step.PAYMENT:
                setStep(Step.INSTRUCTIONS);
                break;
            case Step.INSTRUCTIONS:
                if (isZwapgridIntegration()) {
                    setStep(Step.ZWAPGRID);
                } else {
                    setStep(Step.CONFIRMATION);
                }
                break;
            case Step.ZWAPGRID:
                setStep(Step.CONFIRMATION);
                break;
            case Step.CONFIRMATION:
                close();
                break;
        }
    };

    const canGoBack = useMemo(() => {
        switch (step) {
            case Step.ZWAPGRID:
            case Step.INSTRUCTIONS:
                return true;
        }
        return false;
    }, [step]);

    const canGoForward = step !== Step.ZWAPGRID;

    /**
     * Closes the modal.
     *
     * Calls tne cancellation callback, if present.
     */
    const close = () => {
        setIsShown(false);
    };

    /**
     * Shows the modal to the correct step dependent on where it's opened from.
     */
    const showCreateIntegration = (selected?: Integration) => {
        if (!selected) {
            setStep(Step.INTEGRATION);
        } else {
            setIntegration(selected);
            setStep(Step.INSTRUCTIONS);
        }
        setIsShown(true);
    };

    return {
        step,
        canGoBack,
        canGoForward,
        isLoading,
        integration,
        setIntegration,
        credentials,
        setCredentials,
        goForward,
        goBack,
        isSubmitting,
        setIsSubmitting,
        isShown,
        showCreateIntegration,
        close,
    };
};
