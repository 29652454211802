import { HorizontalDivider, VerticalDivider } from './StyledDivider';

interface Props {
    /** Direction of the divider. Defaults to horizontal */
    direction?: 'horizontal' | 'vertical';
    margin?: boolean;
}

/**
 * A line with some margin, separating two areas. Defaults to a horizontal divider.
 */
const Divider = ({ direction, margin = true }: Props) => {
    if (direction === 'vertical') {
        return <VerticalDivider margin={margin} />;
    }
    return <HorizontalDivider margin={margin} />;
};

export default Divider;
